import ConfirmationDialog from 'components/ConfirmationDialog';
import { SEARCH_MODE } from '../../redux/features/textAnalytics';

function InputLostDialog(props) {
  return (
    <ConfirmationDialog
      title="Switch Search Type"
      isOpen={props.isOpen}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
    >
      <span>
        Your input will be lost if you want to switch from{' '}
        <b>
          {props.mode === SEARCH_MODE.BASIC ? 'Basic' : 'Advanced'} Search mode
        </b>{' '}
        to{' '}
        <b>
          {props.mode === SEARCH_MODE.BASIC ? 'Advanced' : 'Basic'} Search mode
        </b>
      </span>
    </ConfirmationDialog>
  );
}

export default InputLostDialog;
