import ConfirmationDialog from 'components/ConfirmationDialog';

const ToggleVisibleDiscount = ({
  mode = 'normal',
  visible,
  onCancel,
  onConfirm,
  loading,
  onClose,
}) => {
  return (
    <ConfirmationDialog
      mode={mode}
      title={`${mode === 'normal' ? 'Show' : 'Hide'} Voucher`}
      isOpen={visible}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isLoading={loading}
      captions={{ confirm: mode === 'normal' ? 'Show' : 'Hide' }}
    >
      {`Are you sure to ${
        mode === 'normal' ? 'show' : 'hide'
      } this voucher on the user homepage?`}
    </ConfirmationDialog>
  );
};

export default ToggleVisibleDiscount;
