import React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useSelector } from 'react-redux';
import { Menu, MenuItem } from '../../components/CustomMenu';
import logo from '../../assets/images/esg-dataset-logo-bw.png';
import DashboardLayout from './index';
import DashboardMenu from './DashboardMenu';
import clsx from 'clsx';
import PATH_URL from '../../routers/path';
import { useHistory } from 'react-router-dom';

function DashboardAdmin(props) {
  const history = useHistory();
  const role = useSelector((state) => state.auth.role);
  return (
    <DashboardLayout>
      <div className="w-full h-full overflow-hidden">
        <nav className="flex fixed w-full pl-56 items-center justify-between h-16 bg-white text-grey70 z-10 shadow rounded">
          <div className="w-full px-6 flex items-center justify-between">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                if (props.redirectPath) history.push(props.redirectPath);
              }}
            >
              {props.icon && (
                <Icon
                  icon={props.icon}
                  className="fill-current text-grey80 mr-2"
                  iconSize={16}
                />
              )}
              <h4 className="text-md font-bold capitalize">{props.title}</h4>
            </div>
            <DashboardMenu isAdmin={role === 'admin'} />
          </div>
          <aside
            className="fixed top-0 left-0 overflow-auto z-30 w-56 h-full pt-4"
            style={{
              backgroundColor: '#3C4969',
            }}
          >
            <div className="w-full flex justify-center items-center mb-8">
              <img src={logo} alt="cesgs logo" className="h-12 w-44" />
            </div>
            <Menu>
              <MenuItem
                text="Users"
                to={PATH_URL.USER_LIST}
                iconNames={IconNames.PEOPLE}
                className="pl-6"
              />
              <MenuItem
                text="Sales"
                iconNames={IconNames.SHOPPING_CART}
                className="pl-6"
              >
                {/* <MenuItem text="Invoice" /> */}
                <MenuItem
                  text="Discount TA"
                  to={PATH_URL.VOUCHER_LIST}
                  className="pl-14"
                />
                <MenuItem
                  text="Discount QD"
                  to={PATH_URL.DISCOUNT_LIST}
                  className="pl-14"
                />
              </MenuItem>
              <MenuItem
                text="Variable"
                to={PATH_URL.VARIABLE_LIST}
                iconNames={IconNames.VARIABLE}
                className="pl-6"
              />
            </Menu>
          </aside>
        </nav>
        <div
          className={clsx(
            'flex pl-60 pr-4 bg-grey0 min-h-screen overflow-y-auto pt-20'
          )}
        >
          {props.children}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default DashboardAdmin;
