import { Dialog as BlueprintDialog, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const SIZE_VARIANTS = {
  small: 'w-104',
  normal: 'w-144',
  large: 'w-268',
};

export default function Dialog(props) {
  const size = props.size;
  const dismissButton = props.dismissButton;

  return (
    <BlueprintDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={clsx(
        SIZE_VARIANTS[size],
        'h-auto pb-5 rounded-lg border border-grey5 bg-white overflow-hidden shadow-lg focus:ring-grey40 focus:outline-none focus:border-transparent'
      )}
      backdropClassName="bg-black bg-opacity-50"
    >
      {props.header && (
        <div
          className={`w-full h-9 mt-4 mb-2 ${
            props.size === 'small' ? 'px-6' : 'px-4'
          } flex items-center justify-between`}
        >
          <h2 className="font-medium text-base text-grey100">{props.header}</h2>
          {dismissButton && (
            <button onClick={props.onClose}>
              <Icon
                icon={IconNames.CROSS}
                iconSize={23}
                className="fill-current text-grey50"
              />
            </button>
          )}
        </div>
      )}
      {props.children}
    </BlueprintDialog>
  );
}

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  dismissButton: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  children: PropTypes.node.isRequired,
};

Dialog.defaultProps = {
  size: 'normal',
  dismissButton: false,
};
