import React from 'react';
import routes from '../routers/routes';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import HelmetHOC from '../utils/Routes/HelmetHOC';
import { UserIsNotAuthenticatedRedir } from '../utils/Routes/AuthRoutingHOC';

const { Auth } = routes;
const authRoutes = Auth.routes;

const routesComponents = authRoutes.map((route) => (
  <Route
    exact
    key={route.path}
    path={route.path}
    render={() => (
      <HelmetHOC title={route.title}>
        <route.component />
      </HelmetHOC>
    )}
  />
));

function AuthRoutes(props) {
  return (
    <Auth.Layout>
      <Switch location={props.location}>
        {routesComponents}
        <Route>
          <Redirect to="/a/login" />
        </Route>
      </Switch>
    </Auth.Layout>
  );
}

export default withRouter(UserIsNotAuthenticatedRedir(AuthRoutes));
