import { useEffect } from 'react';
import clsx from 'clsx';
import AuthLayout from '../../layouts/AuthLayout';
import { Link } from 'react-router-dom';
import PATH_URL from 'routers/path';

export default function TermsConditions() {
  const headingClass = 'font-bold text-2xl text-grey100 text-center';
  const subHeadingClass = 'font-medium text-grey100';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AuthLayout navOnly={true}>
      <div className="w-full py-9 px-24">
        <header>
          <h5 className={headingClass}>Privacy Policy</h5>
          <h5 className={clsx(headingClass, 'mt-2')}>ESG Intelligence</h5>
        </header>
        <div className="w-full text-grey60 text-justify mt-12">
          Published and effective in use from 1 March 2021
          <Break />
          <span className={subHeadingClass}>1. Introduction</span>
          <br />
          Thank you for choosing ESG Intelligence!
          <Break />
          At ESG Intelligence, we want to provide the best experience for you so
          you can enjoy our products & services. For that, we need to understand
          your data access routine so that we can provide special and
          personalized products & services especially for you. Your privacy and
          the security of your personal data are very important to us.
          Therefore, we want to explain in a transparent manner how and why we
          collect, store, share and use your personal data - as well as explain
          the choices and controls you have when and how you choose to share
          your personal data
          <Break />
          That is our goal, and this Privacy Policy will explain our intentions
          in detail below.
          <Break />
          <span className={subHeadingClass}>2. About this Policy</span>
          <br />
          This policy provides important details regarding your personal data
          relationship with ESG Intelligence. This policy applies to all ESG
          Intelligence products & services and any related products & services
          (hereinafter referred to as "ESGI products & services"). The terms
          governing your use of ESGI products & services are governed by our
          <strong className="text-grey100">
            {' '}
            <Link
              to={PATH_URL.TERMS_CONDITIONS}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue90"
            >
              Terms and Conditions
            </Link>
          </strong>
          .
          <Break />
          From time to time, we may develop new products & services or offer
          additional products & services. If the launch of new products &
          services or additional products & services causes material changes in
          the way we collect or process your personal data, we will provide
          further information or additional terms or policies to you. Unless
          otherwise stated when we introduce new products & services or
          additional products & services, such products & services & services
          will be subject to this Policy.
          <Break />
          The purpose of this Policy is to:
          <ol className="list-decimal ml-16">
            <li>
              Ensure you understand what personal data we collect from you, the
              reasons why we collect it and use it, and who we share it with;
            </li>
            <li>
              Describe how we use the personal data that you share with us so
              that we can provide a satisfying experience for you when you use
              ESG Intelligence products & services; and
            </li>
            <li>
              Explain the rights and choices you have regarding your personal
              data that we collect and we process and how we will protect your
              privacy.
            </li>
          </ol>
          <br />
          We hope that this Policy can help you understand our privacy
          commitment to you. For information on how to contact us if you have
          any questions, you can refer to
          <strong className="text-grey100">
            {' '}
            <a
              href="#Section11"
              className="hover:text-blue90 hover:no-underline"
            >
              Section 11 'How to contact us'
            </a>
          </strong>{' '}
          below. Or, if you do not agree to the contents of this Policy, please
          remember that you have the choice to use or not to use ESG
          Intelligence products & services.
          <Break />
          <span className={subHeadingClass}>
            3. Your rights and preferences: Gives you choice and control.
          </span>
          <br />
          The General Data Protection Regulation ("GDPR") gives certain rights
          to people with respect to their personal data. In this regard, we are
          happy to offer transparency and access control to help users take
          advantage of these rights. As available and unless restricted under
          applicable law, the rights that people have are:
          <ul className="list-disc ml-16">
            <li>
              Right to access - the right to be notified about, and request
              access to, your personal data which we process;
            </li>
            <li>
              Right to correct - the right to ask us to change or update your
              personal data if it is inaccurate or incomplete;
            </li>
            <li>
              Right to erase - the right to ask us to erase your personal data;
            </li>
            <li>
              Right to limit - the right to request that we temporarily or
              permanently stop processing all or part of your personal data;
            </li>
            <li>
              Right to refuse
              <ul className="list-disc ml-20">
                <li>
                  the right to, at any time, object to our processing of your
                  personal data on grounds relating to your particular
                  situation;
                </li>
                <li>
                  the right to object to our processing of your personal data
                  for direct marketing purposes;
                </li>
              </ul>
            </li>
            <li>
              Right to data portability - the right to request an electronic
              copy of your personal data and the right to transmit that personal
              data for use in other parties' products & services;
            </li>
            <li>
              and The right not to be subject to automatic decision-making - the
              right not to be subject to a decision based solely on automatic
              decision-making, including profiling, where that decision could
              have legal consequences for you or cause similar significant
              consequences.
            </li>
          </ul>
          <br />
          If you have questions about your privacy, your rights, and how to
          apply them, please contact us. If you have concerns about our
          processing of your personal data, we hope that you will continue to
          work with us to resolve them.
          <Break />
          <span className={subHeadingClass}>
            4. Your personal data that we collect
          </span>
          <br />
          We have described in the table below the categories of your personal
          data that we collect and we use and how we collect them:
          <Break />
          <i>
            The table below describes the personal data collected when you
            register with ESG Intelligence products & services. :
          </i>
          <br />
          <Table
            headerWidth={['w-1/3', 'w-2/3']}
            headers={[
              'Categories of Personal data Category',
              'Description of categories',
            ]}
            contents={[
              [
                'User data',
                <>
                  The following is personal data provided by you or collected by
                  us in order for you to register for and use ESG Intelligence
                  products & services. Depending on the type of ESG Intelligence
                  product & service subscription program that you wish to
                  register, this data may include user name, email address,
                  telephone number, account type and institution.
                  <Break />
                  Some of the personal data that we will ask you to provide is
                  necessary in order for you to create your account.
                  <Break />
                  What personal data we collect depends on the type of ESG
                  Intelligence product & service program you choose, how you
                  created your account, and whether you use third party products
                  & services to register and use ESG Intelligence products &
                  services. If you use third party products & services to create
                  an account, we will receive personal data from these third
                  party products & services but only if you have given your
                  consent to the third party products & services to share your
                  personal data with us.
                </>,
              ],
            ]}
          />
          <br />
          <i>
            The table below describes the personal data collected during your
            use of ESG Intelligence products & services:
          </i>
          <br />
          <Table
            headerWidth={['w-1/3', 'w-2/3']}
            headers={[
              'Categories of Personal data Category',
              'Description of categories',
            ]}
            contents={[
              [
                'Usage data',
                <>
                  The following is your personal data that we collect when you
                  access and / or use ESG Intelligence products & services,
                  including:
                  <ul className="list-disc ml-6">
                    <li>
                      Information regarding the type of subscription program for
                      your ESG Intelligence products & services.
                    </li>
                    <li>
                      Information about your interactions with ESG Intelligence
                      products & services, such as your search results
                      (including the date and time of requests you made), your
                      subscription history, browsing history, download history,
                      and your interactions with ESG Intelligence products &
                      services, content, ESG users Other intelligence. This
                      information may also include details of your use of third
                      party applications in connection with ESG Intelligence
                      products & services.
                    </li>
                    <li>
                      Inferences regarding your interests and preferences are
                      made based on your use of ESG Intelligence products &
                      services.
                    </li>
                    <li>
                      User Content (as defined in the Terms and Conditions of
                      Use) that you post on ESG Intelligence, such as photos,
                      documents, and interactions with the ESG Intelligence
                      product & customer service team. Please note that we will
                      only access files from your device if you give us
                      permission to do so, and we will only access files that
                      you specifically choose to share with us and the metadata
                      associated with those files, such as file type and size.
                      We will never scan or take photo albums or cameras in your
                      device.
                    </li>
                    <li>
                      Certain technical data, which may include:
                      <ul className="list-disc ml-10">
                        <li>URL information;</li>
                        <li>
                          online identifiers including cookie data and IP
                          addresses;
                        </li>
                        <li>
                          information regarding the type of device you are
                          using, such as unique device ID, network connection
                          type (for example, wifi, 3G, LTE, Bluetooth),
                          operator, network and device performance, browser
                          type, language, information that enables digital
                          rights management, operating system, and the ESG
                          Intelligence application version;
                        </li>
                        <li>
                          your approximate location, which can be extracted or
                          inferred from certain technical data (for example,
                          your IP address, your device language setting, or
                          payment currency), to comply with the geographic
                          provisions of our license agreement, and to provide
                          you with personalized content and advertisements ; and
                        </li>
                        <li>
                          motion-generated or orientation-generated mobile
                          sensor data (for example, an accelerometer or
                          gyroscope) needed to provide you with specific
                          features of ESG Intelligence products & services.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </>,
              ],
              [
                'User Type Verification Data',
                `For users with the “student” type, we can use the information
                  you send in the form of a student card scan to help you verify
                  your status in the DIKTI database.`,
              ],
            ]}
          />
          <br />
          <i>
            The table below describes the personal data that you choose to share
            with us so that we can provide additional features / functionality
            to you
          </i>
          <br />
          <Table
            headerWidth={['w-1/3', 'w-2/3']}
            headers={[
              'Categories of personal data',
              'Description of categories',
            ]}
            contents={[
              [
                'Payment and Purchase Data',
                <>
                  We may collect certain personal data if you register with the
                  Program Trial or purchase any of our Paid Subscription
                  Programs or make other purchases through ESG Intelligence
                  products & services. What personal data is collected will vary
                  depending on the method of payment (for example, directly
                  through a mobile phone network provider or by bill) but will
                  include information such as:
                  <br />
                  <ul className="list-disc ml-6">
                    <li>Name;</li>
                    <li>Date of birth;</li>
                    <li>
                      Credit or debit card type, expiration date, and certain
                      digits of your card number;
                    </li>
                    <li>Postal code;</li>
                    <li>Cell phone number; and</li>
                    <li>Details of your purchase and payment history.</li>
                  </ul>
                </>,
              ],
              [
                'Contest, Survey and Sweepstakes Data',
                'When you finish filling out a form, answer a survey or questionnaire, or enter a contest, we collect the personal data that you provide.',
              ],
            ]}
          />
          <br />
          <i>
            The table below describes personal data collected from third party
            sources.
          </i>
          <br />
          We collect personal data about you from various third parties. These
          third party sources vary from time to time and include the following:
          <br />
          <Table
            headerWidth={['w-1/3', 'w-2/3']}
            headers={[
              'Category of third party sources',
              'Description of categories ',
            ]}
            contents={[
              [
                'Authentication partners',
                'When you register or log in to use our products & services using third party credentials we will collect your information from that third party to help you create an account with us.',
              ],
              [
                'Technical product & service partners',
                'We work with technical product & service partners who provide us with certain data, such as IP address mapping to approximate location data (e.g. city, country), so that we can provide ESG Intelligence products & services, content and features -features.',
              ],
              [
                'Payment partners',
                'If you choose to pay for a product & service or feature by invoice, we may receive data from our payment partners so that we can send your invoice, process your payment and provide what you purchased to you.',
              ],
              [
                'Advertisers and other advertising partners',
                'We may obtain certain data about you, such as your cookie id, mobile device id, or email address, and conclusions about your interests and preferences from certain advertisers and advertising partners that allow us to provide more advertisements. relevant and measure their effectiveness.',
              ],
            ]}
          />
          <br />
          <span className={subHeadingClass}>
            5. For what purposes do we use your personal data?
          </span>
          <br />
          When you use or interact with ESG Intelligence products & services, we
          use various technologies to process your personal data which we
          collect for various reasons. We explain in the table below the reasons
          why we process your personal data, the related legal basis that we
          refer to so that we can legally process your personal data, and the
          categories of personal data (contained in Section 4 'Your personal
          data we collect 'used for the following purposes:
          <br />
          <Table
            headerWidth={['w-1/3', 'w-1/3', 'w-1/3']}
            headers={[
              "Explanation of why ESG Intelligence processing your personal data (' the purpose of processing ')",
              'The Basic Law for the purpose of processing',
              'Categories of personal data used by ESG Intelligence for the purpose of processing',
            ]}
            contents={[
              [
                'To provide and personalize the products and services ESG Intelligence.',
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'The implementation of a Contract',
                      'Legitimate Interest',
                      'Agreement',
                    ].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'Data User',
                      'Data Usage',
                      'Data Payment and Purchase',
                      'Data Verification Subscription Program',
                      'Voice Data',
                    ].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'To understand, diagnose, troubleshoot and correct ESG Intelligence products & services',
                <>
                  <ul className="list-disc ml-4">
                    {['Execution of a Contract', 'Legitimate Interest'].map(
                      renderList
                    )}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {['User Data', 'Usage Data '].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'To evaluate and develop new features, technology and improvements to ESG Intelligence products & services.',
                <>
                  <ul className="list-disc ml-4">
                    {['Legitimate Interest', 'Agreement'].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {['User Data', 'Use of Voice Data'].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'For marketing, promotional and advertising purposes.',
                <>
                  <ul className="list-disc ml-4">
                    {['Legitimate Interest', 'Agreement'].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {['Data Users', 'Use of Voice Data'].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'To comply with legal obligations and law enforcement requests.',
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'Compliance with legal obligations',
                      'Legitimate Interest',
                    ].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'User data',
                      'Data Use of Data Payment and Purchase',
                      'Data Verification of Subscription Programs',
                      'Voice Data',
                      'Data Contests, Surveys and Sweepstakes',
                    ].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'To fulfill contractual obligations with third parties, such as license agreements and to take appropriate action in connection with reporting violations of intellectual property and inappropriate content.',
                <>
                  <ul className="list-disc ml-4">
                    {['Legitimate Interest'].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {['Data User', 'Data Use of Payment and Purchase Data'].map(
                      renderList
                    )}
                  </ul>
                </>,
              ],
              [
                'To make, carry out or defend legal claims.',
                <>
                  <ul className="list-disc ml-4">
                    {['Legitimate Interest'].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'User Data',
                      'Data Usage Payment and Purchase Data',
                      'Data Subscription Program Verification',
                      'Data Voice',
                      'Data Contest, Survey and Sweepstakes Data',
                    ].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'To carry out business planning, reporting and forecasting.',
                <>
                  <ul className="list-disc ml-4">
                    {['Legitimate Interest'].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {['User Data', 'Data Use of Payment and Purchase Data'].map(
                      renderList
                    )}
                  </ul>
                </>,
              ],
              [
                'To process your payment.',
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'Implementation of a Contract',
                      'Compliance with legal obligations',
                    ].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {['User Data', 'Data Payment and Purchase Data'].map(
                      renderList
                    )}
                  </ul>
                </>,
              ],
              [
                'To detect fraud, including fraudulent payments and fraudulent use of ESG Intelligence products & services.',
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'Implementation of a Contract',
                      'Compliance with legal obligations',
                      'Legitimate Interest',
                    ].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'User Data',
                      'Data Usage',
                      'Payment Data and Purchase',
                      'Subscription Program Verification Data',
                    ].map(renderList)}
                  </ul>
                </>,
              ],
              [
                'To conduct research, contests, surveys and sweepstakes.',
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'Implementation of a Contract',
                      'Legitimate Interest',
                      'Agreement',
                    ].map(renderList)}
                  </ul>
                </>,
                <>
                  <ul className="list-disc ml-4">
                    {[
                      'User Data',
                      'Data Usage',
                      'Data Contest, Survey and Sweepstakes Data',
                    ].map(renderList)}
                  </ul>
                </>,
              ],
            ]}
          />
          If you need further information regarding the balancing tests that
          have been carried out by ESG Intelligence to justify its grounding on
          the basis of legitimate interest law under the GDPR, you can refer to
          the Section 10 'How to contact us' for more information on how to
          contact us.
          <Break />
          <span className={subHeadingClass}>6. Sharing your personal data</span>
          <br />
          We have defined the categories of recipients of personal data
          collected or obtained from your use of ESG Intelligence products &
          services.
          <br />
          The following personal data will only be shared with the categories of
          recipients mentioned in the table below, if:
          <br />
          <ul className="list-disc ml-10">
            {[
              'you choose to use a special ESG Intelligence product & service feature which requires the sharing of certain personal data in order to properly use the ESG Intelligence product & service feature; or',
              'you give us permission to share personal data, for example by selecting the appropriate settings on ESG Intelligence products & services or giving consent to ESG Intelligence through the consent mechanism provided.',
            ].map(renderList)}
          </ul>
          <Table
            headerWidth={['w-1/3', 'w-2/3']}
            headers={['Recipient Category', 'Reasons for sharing']}
            contents={[
              [
                'Third party applications and devices connected to your ESG Intelligence Account',
                <>
                  If you connect your ESG Intelligence account to third party
                  applications and / or other devices, such as social media,
                  audio, television, or platforms and products & services, ESG
                  Intelligence share technical data with operators of such third
                  party applications and / or devices in order to connect with
                  your ESG Intelligence account, and / or facilitate the use of
                  ESG Intelligence products & services.
                  <Break />
                  Regarding certain third parties who may request or require us
                  to share your information with them, we will ask your
                  permission before we provide your information to these third
                  parties.
                </>,
              ],
              [
                'Support community',
                'When you register for an ESG Intelligence Support Account with the ESG Intelligence Support Community, we will ask you to create a custom username for the ESG Intelligence Support Community. This username will be publicly displayed to anyone accessing the ESG Intelligence Support Community along with any questions or comments you post.',
              ],
            ]}
          />
          <br />
          <i>Information that we can share</i>
          <br />
          <Table
            headerWidth={['w-1/3', 'w-2/3']}
            headers={['Recipient Category', 'Reasons for sharing']}
            contents={[
              [
                'Service providers',
                'We work with service providers who work on our behalf, who may require access to certain personal data in order to provide their services to us. These companies include companies we employ to provide product & customer service assistance, operate the technical infrastructure we need to provide ESG Intelligence products & services, assist in protecting and securing our systems and products & services, and helping to market ESG products and services. Intelligence itself as well as products, products & services, partner events and co-branded promotions in which ESG Intelligence is involved.',
              ],
              [
                'Payment processors',
                'We will share your personal data with payment processing parties who work with us as necessary so that they can process your payment, and for anti-fraud purposes.',
              ],
              [
                'Advertising partners',
                'We work with advertising partners so that we can customize the advertising content that you may receive on ESG Intelligence products & services. These partners assist us in delivering more relevant advertising and promotional messages to you, which may include interest-based advertising (also known as online behavior-based advertising), contextual advertising and generic advertising on ESG Intelligence products & services. We and our advertising partners can process certain personal data to help ESG Intelligence understand your interests or preferences so that we can provide more relevant advertisements to you.',
              ],
              [
                'ESG Intelligence Partners',
                <>
                  Depending on how you signed up for ESG Intelligence products &
                  services (for example through third party products & services
                  or mobile network providers), we share your ESG Intelligence
                  username or other User Data as required to create your
                  account. We may also share personal data with third parties
                  regarding your use of ESG Intelligence products & services,
                  such as whether and to what extent you have used the offer,
                  activated an ESG Intelligence account, or actively used ESG
                  Intelligence products & services.
                  <Break />
                  We may also share your personal data in a pseudonymised form
                  with our partners to help them better understand how the
                  content they license to us is performing and so that you can
                  access data through ESG Intelligence products & services.
                </>,
              ],
              [
                'Academic researchers',
                'We will share your personal data for activities such as statistical analysis and academic studies but only in a pseudonymous form.',
              ],
              [
                'Other ESG Intelligence groups of companies',
                'We will share your personal data with other ESG Intelligence groups of companies to carry out our day-to-day business operations and to enable us to maintain and provide you with ESG Intelligence products & services.',
              ],
              [
                'Law enforcement and data protection authorities',
                <>
                  We share your personal data when we believe in good faith that
                  we need to do so to comply with legal obligations under
                  applicable law, or respond to lawful legal processes, such as
                  search warrants, court orders or subpoena.
                  <Break />
                  We also share your personal data if we believe in good faith
                  that it is necessary for our legitimate interests, or the
                  interests of legitimate third parties related to national
                  security, law enforcement, litigation, criminal investigation,
                  protecting someone's security, or to prevent it from
                  happening. death or physical harm, provided that those
                  interests are not overridden by your interests or the
                  fundamental rights and freedoms that require protection of
                  your personal data.
                </>,
              ],
              [
                'Our business buyers',
                'We will share your personal data in the event that we sell or negotiate to sell our business to buyers or prospective buyers. In such situations, ESG Intelligence will continue to ensure the confidentiality of your personal data and provide notice to you before your personal data is transferred to the buyer or becomes subject to a different privacy policy.',
              ],
            ]}
          />
          <br />
          <span className={subHeadingClass}>
            7. Data retention and deletion
          </span>
          <br />
          We retain your personal data only as long as necessary to provide ESG
          Intelligence products & services to you and for legitimate and
          important business purposes, such as maintaining the performance of
          ESG Intelligence products & services, making data-driven business
          decisions regarding features and offerings. new, comply with our legal
          obligations, and to resolve disputes. We store some of your personal
          data as long as you are a user of ESG Intelligence products &
          services.
          <br />
          If you request it, we will delete or anonymize your personal data so
          that it no longer identifies you, unless we are legally allowed or
          required to keep certain personal data, including in the following
          situations:
          <br />
          <ul className="list-disc ml-10">
            {[
              'If there are unresolved problems related to your account , such as credit that still owes on your account or claims or disputes that have not been resolved, we will keep the personal data needed until the problem is resolved;',
              'Where it is necessary for us to retain personal data to fulfill our legal, tax, audit and accounting obligations, we will retain the necessary personal data for the period required by applicable law; and / or,',
              'if necessary for our legitimate business interests, such as fraud prevention or to keep our users safe.',
            ].map(renderList)}
          </ul>
          <Break />
          <span className={subHeadingClass}>
            8. Links retention and deletion
          </span>
          <br />
          We may display advertisements from third parties and other content
          that are linked to third party sites. We cannot control or be
          responsible for the privacy practices and content of third parties. If
          you click on a third party advertisement or link, please understand
          that you are leaving ESG Intelligence products & services and any
          personal data you provide will not be covered by this Policy. Please
          read their privacy policy to find out how they collect and process
          your personal data.
          <Break />
          <span className={subHeadingClass}>
            9. Maintaining the security of your personal data
          </span>
          <br />
          We are committed to protecting the personal data of our users. We
          implement appropriate technical and organizational measures to help
          protect the security of your personal data; however, please understand
          that no system will ever be completely secure. We have implemented
          various policies, including pseudonymization, encryption, access and
          storage policies to protect personal data from unauthorized access and
          storage of unnecessary personal data in our systems.
          <Break />
          Your password protects your user account, therefore we urge you to use
          a strong password that is unique to your ESG Intelligence account, and
          never share that password with anyone, restrict access to your
          computer and browser and log out of your account after using the
          product. & ESG Intelligence services.
          <Break />
          <span className={subHeadingClass}>10. Changes to this Policy</span>
          <br />
          There are times when we may change this Policy.
          <Break />
          If we make material changes to this Policy, we will provide
          notifications that you can easily find out about as appropriate
          according to the circumstances, for example, by displaying a prominent
          notification on ESG Intelligence products & services or by sending
          email and / or device notifications to you. We can tell you in
          advance.
          <Break />
          Thus, make sure you read the notice carefully.
          <Break />
          <span id="Section11" className={subHeadingClass}>
            11. How to contact us
          </span>
          <br />
          Thank you for reading our policy. If you have questions related to
          this Policy, you can contact us by sending an email to{' '}
          <strong className="text-grey100">
            <a href="mailto:info@cesgs.or.id" className="hover:text-blue90">
              info@cesgs.or.id
            </a>
          </strong>{' '}
          or chat to our{' '}
          <strong className="text-grey100">
            WhatsApp account: 081260003561
          </strong>
          .
          <Break />
          Hope you enjoy ESG Intelligence!
          <Break />
          &copy; ESG Intelligence.
        </div>
      </div>
    </AuthLayout>
  );
}

function Break() {
  return (
    <>
      <br />
      <br />
    </>
  );
}

function renderList(text, index) {
  return <li key={`list-${index}`}>{text}</li>;
}

function Table(props) {
  return (
    <table className="w-full mt-1">
      <thead>
        <tr className="bg-teal70 text-white border border-teal70">
          {props.headers.map((header, index) => (
            <td
              key={`content-header-${index}`}
              className={clsx(
                index < header.length && 'border-r border-r-white',
                props.headerWidth[index],
                'p-4 '
              )}
            >
              {header}
            </td>
          ))}
        </tr>
      </thead>
      <tbody className="bg-grey0 text-grey100 border border-grey5">
        {props.contents.map((content, index) => (
          <tr key={`row-content-${index}`} className="border-b border-b-grey5">
            {content.map((cell, index) => (
              <td
                key={`content-cell-${index}`}
                className="p-4 align-top border-r border-r-grey5"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
