import React from 'react';
import routes from '../routers/routes';
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  matchPath,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserIsAdmin } from '../utils/Routes/AuthRoutingHOC';

const generateRoutesComponents = (routes) =>
  routes.map((route) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

function AdminDashboardRoutes(props) {
  const locationPath = useSelector((state) => state.router.location.pathname);
  const { AdminDashboard } = routes;
  const currentRoutes = AdminDashboard.routes;
  const routeSelector = currentRoutes.filter(
    (item) => matchPath(locationPath, { path: item.path, exact: true }) !== null
  );
  const title = routeSelector.length > 0 ? routeSelector[0].title : 'Dashboard';
  const redirectPath =
    routeSelector.length > 0 ? routeSelector[0].redirectPath : null;
  const routesComponents = generateRoutesComponents(currentRoutes);

  return (
    <AdminDashboard.Layout title={title} redirectPath={redirectPath}>
      <Switch>
        {routesComponents}
        <Route>
          <Redirect to={currentRoutes[0].path} />
        </Route>
      </Switch>
    </AdminDashboard.Layout>
  );
}

export default withRouter(UserIsAdmin(AdminDashboardRoutes));
