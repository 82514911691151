import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { upperFirst } from 'lodash';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import { Icon, Popover, Position, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  getListDiscount,
  deleteDiscount,
  changeFilter,
  setSelected,
  changePageData,
  downloadDiscountList,
  toggleVisibleDiscount,
} from '../../redux/features/discountManagement';
import imageVoucherEmpty from '../../assets/images/empty-voucher.svg';
import AddDiscountDialog from './AddDiscount';
import EditDiscountDialog from './EditDiscount';
import DeleteDiscountDialog from './DeleteDiscount';
import ToggleVisibleDiscount from './ToggleVisibleDiscount ';
import { showToastSuccess, showToastError } from 'layouts/DashboardLayout';
import clsx from 'clsx';
import PATH_URL from 'routers/path';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';

function DiscountListPage() {
  const _isMounted = useRef(true);
  const history = useHistory();
  const downloadAnchorRef = useRef(null);
  const dispatch = useDispatch();
  const {
    list: discounts,
    pageData,
    filter,
    selected,
  } = useSelector((state) => state.discountManagement);
  const loadingGetListDiscount = useSelector(
    createLoadingSelector([getListDiscount.type])
  );
  const loadingDeleteDiscount = useSelector(
    createLoadingSelector([deleteDiscount.type])
  );
  const loadingDownloadDiscount = useSelector(
    createLoadingSelector([downloadDiscountList.type])
  );
  const loadingToggleVisibleDiscount = useSelector(
    createLoadingSelector([toggleVisibleDiscount.type])
  );
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const [showDialogEdit, setShowDialogEdit] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogToggleVisible, setShowDialogToggleVisible] = useState(false);
  const columns = [
    {
      Header: 'Discount',
      accessor: 'name',
    },
    {
      Header: 'Base',
      accessor: 'discountBase',
      customCell: ({ value }) => upperFirst(value),
    },
    {
      Header: 'Type',
      accessor: 'type',
      alignment: 'center',
      customCell: ({ value }) => (
        <span className="block text-center">{upperFirst(value)}</span>
      ),
    },
    {
      Header: 'Value',
      accessor: 'value',
      alignment: 'right',
      customCell: ({ value, row }) => (
        <span className="block text-right">
          {row.original && row.original.type === 'percentage'
            ? `${value} %`
            : currencyFormatter.format(value)}
        </span>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      sortType: 'datetime',
      customCell: ({ value }) => moment(value).format('DD MMM YYYY'),
    },
    {
      Header: 'Used',
      accessor: 'totalUsed',
      alignment: 'right',
      customCell: ({ value }) => (
        <span className="block text-right">{value}</span>
      ),
    },
    {
      Header: 'Limit',
      accessor: 'limit',
      alignment: 'right',
      customCell: ({ value }) => (
        <span className="block text-right">{value || '-'}</span>
      ),
    },
    {
      Header: 'Action',
      accessor: 'id',
      sortable: false,
      customCell: ({ value, row }) => {
        const menuClassName =
          'block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline';
        return (
          <Popover
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            targetTagName="div"
            className="w-min-content"
            targetClassName="inline-block"
            popoverClassName={`shadow-none bg-transparent ${Classes.POPOVER_DISMISS}`}
          >
            <div className="cursor-pointer inline-block">
              <Icon
                icon={IconNames.MORE}
                iconSize={20}
                className="fill-curent text-grey50 hover:text-grey50"
              />
            </div>
            <div className="w-40 p-2 mt-1 shadow top rounded overflow-y-auto bg-white">
              <ul>
                <button
                  className={clsx(menuClassName, 'text-grey50')}
                  onClick={() => {
                    dispatch(setSelected(row.original));
                    setShowDialogEdit(true);
                  }}
                >
                  Edit
                </button>
                {row.original.discountBase === 'voucher' && (
                  <button
                    className={clsx(menuClassName, 'text-grey50')}
                    onClick={() => {
                      dispatch(setSelected(row.original));
                      setShowDialogToggleVisible(true);
                    }}
                  >
                    {`${!row.original.isVisible ? 'Show' : 'Hide'} Voucher`}
                  </button>
                )}
                <button
                  className={clsx(
                    menuClassName,
                    row.original.totalUsed > 0
                      ? 'text-red30 cursor-not-allowed'
                      : 'text-red50'
                  )}
                  onClick={() => {
                    if (row.original.totalUsed === 0) {
                      dispatch(setSelected(row.original));
                      setShowDialogDelete(true);
                    }
                  }}
                  disabled={row.original.totalUsed > 0}
                >
                  Delete
                </button>
              </ul>
            </div>
          </Popover>
        );
      },
    },
  ];
  const filterTable = [
    {
      name: 'type',
      options: [
        {
          text: 'Percentage',
          value: 'percentage',
        },
        {
          text: 'Fixed Amount',
          value: 'fixed',
        },
      ],
    },
    {
      name: 'base',
      options: [
        {
          text: 'Voucher',
          value: 'voucher',
        },
        {
          text: 'Referral',
          value: 'referral',
        },
      ],
    },
  ];
  const isInitiallyEmpty =
    discounts.length === 0 &&
    Object.keys(filter).every((key) => ['', null].includes(filter[key]));

  const handleVisibleDiscount = useCallback(async () => {
    dispatch(
      toggleVisibleDiscount(
        {
          id: selected.id,
          isVisible: !selected.isVisible,
        },
        () => {
          showToastSuccess('Success toggle voucher visibility');
          setShowDialogToggleVisible(false);
          dispatch(
            getListDiscount({
              page: pageData.currentPageNumber,
              limit: pageData.limit,
              ...filter,
            })
          );
        },
        () => {
          showToastError('An error occurred');
          setShowDialogToggleVisible(false);
        }
      )
    );
  }, [dispatch, pageData, selected, filter]);
  const onSuccess = () => {
    dispatch(
      getListDiscount({
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...filter,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getListDiscount({
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...filter,
      })
    );
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch, pageData.currentPageNumber, pageData.limit, filter]);

  return (
    <>
      <div className="w-full p-2">
        {!loadingGetListDiscount && isInitiallyEmpty ? (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="mb-2">
              <img
                src={imageVoucherEmpty}
                className="w-full h-auto"
                alt="img-empty-voucherlist"
              />
            </div>
            <h1 className="text-xl text-grey100 font-bold mb-2">
              There is no voucher at this time
            </h1>
            <p className="text-sm text-grey50 mb-3">You can add voucher now</p>
            <button
              className="text-xs font-semibold px-4 py-2 text-white bg-green50 rounded-sm hover:bg-green60"
              onClick={() => setShowDialogAdd(true)}
            >
              <span className="mr-1">Add New Voucher</span>
              <Icon
                icon={IconNames.TAG}
                iconSize={15}
                className="fill-current text-white"
              />
            </button>
          </div>
        ) : (
          <>
            <div className="w-full flex flex-wrap justify-between items-center mb-5">
              <h1 className="text-lg text-grey70 font-bold">Discount List</h1>
              <Button color="success" onClick={() => setShowDialogAdd(true)}>
                <span className="mr-2">Add New Voucher</span>
                <Icon
                  icon={IconNames.TAG}
                  iconSize={15}
                  className="fill-current text-white"
                />
              </Button>
            </div>
            <CustomTable
              isLoading={loadingGetListDiscount}
              dataTable={discounts.map((v) => ({
                ...v,
                startDate: new Date(v.startDate),
              }))}
              additionalButton={[
                (props) => (
                  <button
                    onClick={() =>
                      dispatch(
                        downloadDiscountList(
                          (link) => {
                            const downloadAnchor = downloadAnchorRef.current;
                            downloadAnchor.setAttribute('href', link);
                            downloadAnchor.click();
                          },
                          () => showToastError('An error occurred')
                        )
                      )
                    }
                    className={clsx(
                      `whitespace-nowrap ml-3`,
                      props.baseClassName,
                      loadingDownloadDiscount
                        ? 'bg-green40 cursor-not-allowed'
                        : 'bg-green50'
                    )}
                    disabled={loadingDownloadDiscount}
                  >
                    {loadingDownloadDiscount ? (
                      'Please Wait...'
                    ) : (
                      <span>
                        Download
                        <Icon
                          iconSize={18}
                          icon={IconNames.CLOUD_DOWNLOAD}
                          className="fill-current text-white ml-2"
                        />
                      </span>
                    )}
                  </button>
                ),
              ]}
              onRowClick={(row, cell) => {
                if (cell.Header !== 'Action') {
                  dispatch(setSelected({ ...row }));
                  history.push(`${PATH_URL.DISCOUNT_LIST}/${row.id}`);
                }
              }}
              columnsTable={columns}
              filterTable={filterTable}
              initialFilterValue={filter}
              search={filter.q}
              onChangeFilter={(filter) => {
                dispatch(
                  changeFilter(
                    Object.keys(filter).reduce(
                      (allFilter, v) => ({
                        ...allFilter,
                        [v]: filter[v] && (filter[v].value || null),
                      }),
                      {}
                    )
                  )
                );
              }}
              page={pageData.currentPageNumber}
              totalPage={pageData.totalPages}
              totalRow={pageData.limit}
              onChangeTotalRow={(row) => {
                dispatch(changePageData({ limit: row, currentPageNumber: 1 }));
              }}
              onInputSearch={(text) => {
                dispatch(changeFilter({ q: text === '' ? null : text }));
              }}
              onChangePage={(page) => {
                dispatch(changePageData({ currentPageNumber: page }));
              }}
            />
            <a ref={downloadAnchorRef} href="_" download className="hidden">
              Download
            </a>
          </>
        )}
      </div>
      <AddDiscountDialog
        showDialog={showDialogAdd}
        setShowDialog={setShowDialogAdd}
        onSuccess={onSuccess}
      />
      <EditDiscountDialog
        showDialog={showDialogEdit}
        setShowDialog={setShowDialogEdit}
        onSuccess={onSuccess}
      />
      <DeleteDiscountDialog
        visible={showDialogDelete}
        onCancel={() => {
          setShowDialogDelete(false);
        }}
        onConfirm={async () => {
          await dispatch(
            deleteDiscount(
              selected.id,
              () => {
                showToastSuccess('Your discount has been successfully deleted');
                setShowDialogDelete(false);
                dispatch(getListDiscount({ page: 1, limit: pageData.limit }));
              },
              () => {
                showToastError(
                  'Your discount has not been successfully deleted'
                );
                setShowDialogDelete(false);
              }
            )
          );
        }}
        loading={loadingDeleteDiscount}
        onClose={() => {
          setShowDialogDelete(false);
        }}
      />
      <ToggleVisibleDiscount
        mode={!selected.isVisible ? 'normal' : 'danger'}
        visible={showDialogToggleVisible}
        onCancel={() => {
          setShowDialogToggleVisible(false);
        }}
        onConfirm={async () => {
          handleVisibleDiscount();
        }}
        loading={loadingToggleVisibleDiscount}
        onClose={() => {
          setShowDialogToggleVisible(false);
        }}
      />
    </>
  );
}

export default DiscountListPage;
