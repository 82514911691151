import { useState, useEffect } from 'react';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  convertFieldsToItems,
  convertOnelineToSymbol,
  convertSymbolToFields,
  validateOneline,
} from '../../utils/searchKeyword';
import {
  storeSearchKeywords,
  searchDocumentGroup,
} from '../../redux/features/textAnalytics';

function AddKeywordBasicPopover(props) {
  const dispatch = useDispatch();
  const searchKeywords = useSelector(
    ({ textAnalytics }) => textAnalytics.keywords.basic
  );
  const [submitStatus, setSubmitStatus] = useState('init');
  const [inputKeywords, setInputKeywords] = useState(searchKeywords);
  const handleSearch = (e) => {
    e.preventDefault();
    if (validateOneline(inputKeywords)) {
      const keywordSymbols = convertOnelineToSymbol(inputKeywords);
      const advancedKeywords = convertSymbolToFields(keywordSymbols);
      dispatch(
        storeSearchKeywords({
          symbols: keywordSymbols,
          basic: advancedKeywords[0].keyword,
          advanced: advancedKeywords,
          items: convertFieldsToItems(advancedKeywords),
        })
      );
      dispatch(searchDocumentGroup(true));
      setSubmitStatus('valid');
      setInputKeywords(advancedKeywords[0].keyword);
    } else {
      setSubmitStatus('invalid');
    }
  };

  useEffect(() => {
    setInputKeywords(searchKeywords);
  }, [searchKeywords]);

  return (
    <Popover
      isOpen={props.isOpen}
      autoFocus={false}
      position={Position.BOTTOM_RIGHT}
      minimal={true}
      className="w-full"
      targetClassName="w-full"
      popoverClassName="w-96 shadow-none"
    >
      <form onSubmit={handleSearch}>
        <div className="relative flex items-center w-132">
          <span className="absolute left-0 ml-2 rounded-md">
            <Icon
              icon={IconNames.SEARCH}
              iconSize={16}
              className="fill-current text-grey30"
            />
          </span>
          <input
            type="text"
            value={inputKeywords}
            onChange={({ target }) => setInputKeywords(target.value)}
            name="basicKeyword"
            autoComplete="off"
            className={`h-10 w-full text-sm text-grey50 bg-grey0 border ${
              submitStatus === 'invalid' ? 'border-red50' : 'border-grey10'
            } rounded-sm px-10 focus:outline-none focus:ring-transparent`}
            placeholder="Enter your keyword here"
          />
          <span className="absolute right-0 mr-2 rounded-md">
            <Icon
              icon={IconNames.INFO_SIGN}
              iconSize={18}
              onClick={props.isOpen ? props.onClose : props.onOpen}
              className={clsx('fill-current text-grey70 cursor-pointer')}
            />
          </span>
        </div>
      </form>
      <div className="w-full shadow py-4 px-4 bg-grey70 rounded-sm -ml-0.5">
        <ul className="w-full list-disc pl-5 pr-2 text-grey50">
          <li className="text-white h-9 text-xs leading-4">
            <span>Add commas (,) to separate keyword</span>
            <div className="w-96">
              <p className=" text-xs text-white font-bold">
                Example: carbon, emision, disclosure
              </p>
            </div>
          </li>
          <li className="text-white h-7 text-xs leading-4">
            <span>Add space ( ) to search for an exact phrase in sentence</span>
            <div className="w-96">
              <p className=" text-xs text-white font-bold">
                Example: carbon emision
              </p>
            </div>
          </li>
        </ul>
      </div>
    </Popover>
  );
}

export default AddKeywordBasicPopover;
