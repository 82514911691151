import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import configureStore from './redux/store';
import { setupAxios } from './utils/api';
import './style/index.css';
import App from './App';
import firebase, { rrfConfig } from './firebase';

const store = configureStore();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

setupAxios();

function IndexApp() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

ReactDOM.render(<IndexApp />, document.getElementById('root'));
