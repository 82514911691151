import PropTypes from 'prop-types';

export default function ProgressBar(props) {
  return (
    <div className="w-full rounded-full bg-grey20 h-2 overflow-hidden">
      <div
        className="h-full bg-blue50 transition-width"
        style={{
          width: `${props.percentage}%`,
        }}
      ></div>
    </div>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
};
