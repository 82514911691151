import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { upperFirst } from 'lodash';
import Spinner from 'components/Spinner';
import { useTable, useSortBy } from 'react-table';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import ResultTopBar from './ResultTopBar';
import Checkbox from './Checkbox';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  getSearchDetail,
  selectSearchResult,
} from '../../redux/features/textAnalytics';

function reportType(str) {
  return str
    .split('_')
    .map((word) => upperFirst(word))
    .join(' ');
}

function DocumentTable() {
  const dispatch = useDispatch();
  const tableContainerRef = useRef(null);
  const isLoading = useSelector(createLoadingSelector([getSearchDetail.type]));
  const { documents, loaded, tablePage } = useSelector(selectSearchResult);
  const [tableHovered, setTableHovered] = useState(false);
  const [data, setData] = useState([]);
  const [localVisibleCols, setLocalVisibleCols] = useState([
    'company.ticker',
    'year',
  ]);
  const columns = useMemo(
    () => [
      {
        Header: 'Ticker',
        accessor: 'company.ticker',
      },
      {
        Header: 'SIC',
        accessor: 'company.sic',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Document Type',
        accessor: (row) => reportType(row.type),
      },
      // {
      //   Header: 'Section',
      //   accessor: 'section',
      // },
      {
        Header: 'Total Keywords',
        accessor: (row) => {
          if (row.stats) {
            const keywords = row.stats.detail_keywords
              ? Object.keys(row.stats.detail_keywords).map((keyword) => {
                  return (
                    <li
                      key={keyword}
                    >{`${keyword} (${row.stats.detail_keywords[keyword]})`}</li>
                  );
                })
              : [];

            return keywords.length ? (
              <ul>
                {[
                  <li className="font-medium">
                    Total: {row.stats.total_keywords}
                  </li>,
                  ...keywords,
                ]}
              </ul>
            ) : null;
          }
          return '';
        },
      },
      {
        Header: 'Total Words',
        accessor: 'stats.total_words',
      },
      {
        Header: 'Total Words without Numeric',
        accessor: 'stats.total_words_non_numeric',
      },
      {
        Header: 'All Sentences',
        accessor: 'stats.all_sentences',
      },
      {
        Header: 'Sentences including Keywords',
        accessor: 'stats.including_keywords',
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    visibleColumns,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const allColumnsSelected = visibleColumns.length === allColumns.length;

  useEffect(() => {
    const start = (tablePage - 1) * 25;
    const maxRowPage = start + 25;
    setData(documents.slice(start, maxRowPage));
  }, [documents, tablePage]);

  useEffect(() => {
    if (loaded.length < documents.length && !isLoading) {
      data
        .map((doc) => doc.id)
        .filter((docId) => !loaded.includes(docId))
        .forEach((docId) => {
          dispatch(getSearchDetail(docId, true));
        });
    }
  }, [dispatch, documents, data, loaded, isLoading]);

  useEffect(() => {
    if (visibleColumns.length < allColumns.length)
      setLocalVisibleCols(visibleColumns.map((col) => col.id));
  }, [visibleColumns, allColumns]);

  return (
    <>
      {!isLoading && (
        <ResultTopBar
          columnToggler={
            <Popover
              position={Position.BOTTOM_RIGHT}
              minimal={true}
              popoverClassName="w-60 shadow-md p-3 bg-white rounded-md"
            >
              <button className="mr-3">
                <Icon
                  icon={IconNames.COLUMN_LAYOUT}
                  iconSize={16}
                  className={clsx('fill-current text-grey50')}
                />
              </button>
              <div className="w-full">
                <header className="font-medium text-base text-grey100">
                  Show Columns
                </header>
                <div className="flex flex-col flex-wrap mt-2 -ml-1">
                  <Checkbox
                    label="All Columns"
                    checked={allColumnsSelected}
                    onChange={() => {
                      if (allColumnsSelected) {
                        setHiddenColumns(
                          allColumns
                            .filter((col) => !localVisibleCols.includes(col.id))
                            .map((col) => col.id)
                        );
                      } else {
                        toggleHideAllColumns();
                      }
                    }}
                  />
                  {allColumns
                    .filter(
                      (col) => !['company.ticker', 'year'].includes(col.id)
                    )
                    .map((col) => (
                      <div key={col.id} className="w-full">
                        <Checkbox
                          label={col.Header}
                          {...col.getToggleHiddenProps()}
                          disabled={visibleColumns.length === allColumns.length}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Popover>
          }
        />
      )}
      <div className="w-full h-full overflow-x-hidden">
        {!isLoading ? (
          <div
            ref={tableContainerRef}
            className={clsx(
              'max-h-full overflow-y-auto',
              tableHovered ? 'overflow-x-auto' : 'overflow-x-hidden'
            )}
            onMouseOver={() => setTableHovered(true)}
            onMouseLeave={() => setTableHovered(false)}
          >
            <table
              {...getTableProps()}
              className="min-w-full"
              onMouseOver={() => setTableHovered(true)}
              onMouseLeave={() => setTableHovered(false)}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        onClick={(e) => {
                          column
                            .getHeaderProps(column.getSortByToggleProps())
                            .onClick(e);
                        }}
                        className={clsx(
                          'h-14 px-2 select-none text-grey50 whitespace-nowrap text-left text-xs font-medium border-b-2 border-grey40 cursor-pointer',
                          column.isSorted ? 'bg-blue0' : 'bg-white'
                        )}
                      >
                        <div className="h-full flex justify-between items-center">
                          {column.render('Header')}
                          <div className="ml-2">
                            <button
                              onClick={(e) => {
                                column
                                  .getHeaderProps(column.getSortByToggleProps())
                                  .onClick(e);
                              }}
                              className="flex flex-col items-center"
                            >
                              <Icon
                                icon={IconNames.CARET_UP}
                                iconSize={12}
                                className={clsx(
                                  'fill-current',
                                  column.isSorted && !column.isSortedDesc
                                    ? 'text-blue50'
                                    : 'text-grey20'
                                )}
                              />
                              <Icon
                                icon={IconNames.CARET_DOWN}
                                iconSize={12}
                                className={clsx(
                                  'fill-current -mt-1.5',
                                  column.isSorted && column.isSortedDesc
                                    ? 'text-blue50'
                                    : 'text-grey20'
                                )}
                              />
                            </button>
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="bg-white cursor-pointer hover:bg-blue0"
                    >
                      {row.cells.map((cell, index) => {
                        const {
                          column: { isSorted },
                        } = cell;
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={clsx(
                              'pl-3 py-3 text-grey100 text-xs font-normal',
                              isSorted && 'bg-blue0'
                            )}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full flex justify-center h-full items-center">
            <Spinner className="w-20 h-20 fill-current text-blue30" />
          </div>
        )}
      </div>
    </>
  );
}

export default DocumentTable;
