import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Button from 'components/Button';
import {
  convertFieldsToItems,
  convertFieldToSymbol,
  validateFields,
} from '../../utils/searchKeyword';
import {
  storeSearchKeywords,
  searchDocumentGroup,
} from '../../redux/features/textAnalytics';

const optionOperator = [
  {
    text: 'AND',
    value: 'AND',
  },
  {
    text: 'AND NOT',
    value: 'ANDNOT',
  },
];

function AddKeywordPopover(props) {
  const dispatch = useDispatch();
  const { advanced: advancedKeywords } = useSelector(
    ({ textAnalytics }) => textAnalytics.keywords
  );
  const [fields, setFields] = useState(advancedKeywords);
  const handleChangeKeyword = (fieldIndex, value) => {
    setFields(
      fields.map((field, index) => {
        if (fieldIndex === index) return { ...field, keyword: value };
        else return field;
      })
    );
  };
  const handleChangeOperator = (fieldIndex, value) => {
    setFields(
      fields.map((field, index) => {
        if (fieldIndex === index) return { ...field, operator: value };
        else return field;
      })
    );
  };
  const addField = () =>
    fields.length <= 4
      ? setFields((prev) => [...prev, { keyword: '', operator: '' }])
      : null;
  const removeField = (index) =>
    setFields(fields.filter((_, idx) => index !== idx));
  const resetKeywod = (index) =>
    setFields(
      fields.map((field, idx) =>
        idx === index ? { ...field, keyword: '' } : field
      )
    );
  const handleApply = (e) => {
    e.preventDefault();
    const trimmedFields = fields.reduce((acc, field, index) => {
      const keyword = field.keyword
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => {
          let processedWord = word.trim();
          // if there is space in keyword but no quote in it then quote it
          if (processedWord.includes(' ') && !processedWord.includes('"')) {
            processedWord = `"${processedWord}"`;
          }
          return processedWord;
        })
        .join(', ');
      const updatedField = { ...field, keyword };
      if (index === 0) acc = acc.concat(updatedField);
      if (index > 0 && (field.operator !== '' || field.keyword !== ''))
        acc = acc.concat(updatedField);
      return acc;
    }, []);
    setFields(trimmedFields);
    if (validateFields(trimmedFields)) {
      dispatch(
        storeSearchKeywords({
          symbols: convertFieldToSymbol(trimmedFields),
          basic: trimmedFields[0].keyword,
          advanced: trimmedFields,
          items: convertFieldsToItems(trimmedFields),
        })
      );
      dispatch(searchDocumentGroup(true));
      props.onClose();
    }
  };

  useEffect(() => {
    setFields(advancedKeywords);
  }, [advancedKeywords]);

  return (
    <form onSubmit={handleApply}>
      <div className="w-148 h-auto pb-0 mt-2 rounded-sm border border-grey5 bg-white overflow-hidden shadow-lg focus:ring-grey40 focus:outline-none focus:border-transparent">
        <div className="w-full p-4 flex flex-col">
          {fields.map((field, index) => (
            <div key={`keyword-${index}`} className="flex mb-3 items-end">
              {index > 0 && (
                <div className="mr-4">
                  <label htmlFor="operator" className="text-xs text-grey50">
                    Add operator
                  </label>
                  <select
                    name="operator"
                    value={field.operator}
                    onChange={(e) =>
                      handleChangeOperator(index, e.target.value)
                    }
                    className="w-full text-grey100 font-medium text-xs h-9 rounded border-grey10 mt-px"
                  >
                    <option value="">--</option>
                    {optionOperator.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className={`${index > 0 ? 'w-2/3' : 'w-full'}`}>
                <label htmlFor="keyword" className="text-xs text-grey50">
                  Keyword (use commas to separate and add OR operator)
                </label>
                <div className="w-full relative flex items-center">
                  <input
                    type="text"
                    name="keyword"
                    value={field.keyword}
                    onChange={(e) => handleChangeKeyword(index, e.target.value)}
                    className="w-full text-grey100 font-medium pr-8 text-xs h-9 rounded border-grey10 mt-px"
                  />
                  {field.keyword && (
                    <button
                      type="button"
                      onClick={() => resetKeywod(index)}
                      className="absolute right-3 -mt-1"
                    >
                      <Icon
                        icon={IconNames.CROSS}
                        iconSize={12}
                        className="fill-current text-grey70"
                      />
                    </button>
                  )}
                </div>
              </div>
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeField(index)}
                  className="text-red50 h-9 ml-2 text-xs font-medium"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          {fields.length < 5 && (
            <div className="flex items-center mt-1 mb-4">
              <button
                type="button"
                onClick={addField}
                className=" text-blue50 text-xs font-medium"
              >
                <Icon
                  icon={IconNames.PLUS}
                  iconSize={16}
                  className="fill-current text-blue50 mr-1"
                />
                <span>Add</span>
              </button>
            </div>
          )}
          <div className="flex">
            <Button
              view="outlined"
              color="danger"
              onClick={props.onClose}
              className="w-1/2 mr-4"
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handleApply} className="w-1/2">
              Search
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddKeywordPopover;
