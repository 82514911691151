import { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { upperFirst } from 'lodash';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import Button from 'components/Button';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  getListVariable,
  changeVariableListFilter,
  changeVariableListPage,
  setSelectedVariable,
  runVariableIndexing,
  getClassifications,
  togglePublishVariable,
} from '../../redux/features/variableManagement';
import imageEmpty from '../../assets/images/empty-variable.svg';
import useVisible from '../../hooks/useVisible';
import AddVariable from './AddVariable';
import EditVariable from './EditVariable';
import DeleteVariable from './DeleteVariable';
import ImportVariable from './ImportVariable';
import clsx from 'clsx';
import PATH_URL from '../../routers/path';
import { showToastError, showToastSuccess } from 'layouts/DashboardLayout';
import { actionSuccess } from 'redux/utils/actionCreator';
import DialogPublishVariable from './DialogPublishVariable';
import DownloadVariable from './DownloadVariable';

function VariableList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    filter,
    pageData,
    list: variables,
  } = useSelector(({ variableManagement }) => variableManagement.listVariable);
  const [dialogOpen, setDialogOpen] = useState({
    add: false,
    edit: false,
    import: false,
    delete: false,
    download: false,
  });
  const classifications = useSelector(
    (state) => state.variableManagement.classifications
  );
  const loadingGetListVariable = useSelector(
    createLoadingSelector([getListVariable.type])
  );
  const { ref, isVisible, setIsVisible } = useVisible();
  const [visibleDropDown, setVisibleDropdown] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    mode: 'normal',
    title: '',
    text: '',
    isLoading: false,
    confirm: '',
    dataVariable: {},
  });
  const [selectedVariables, setSelectedVariables] = useState([]);
  const reload = () => {
    dispatch(
      getListVariable({
        page: pageData.page,
        limit: pageData.limit,
        ...filter,
      })
    );
  };
  const togglePublished = async (dataVariable) => {
    setConfirmationDialog((prev) => ({ ...prev, isLoading: true }));
    const { id, isPublished } = dataVariable;
    const { type } = await dispatch(
      togglePublishVariable(id, {
        status: !isPublished,
      })
    );
    if (type === actionSuccess(togglePublishVariable.type)) {
      reload();
      showToastSuccess(
        `Your variable has ${!isPublished ? 'published' : 'unpublished'}`
      );
    } else {
      showToastError('An error occured');
    }
    setConfirmationDialog({
      isOpen: false,
      mode: 'normal',
      title: '',
      text: '',
      isLoading: false,
      captions: {
        confirm: '',
      },
      dataVariable: {},
    });
  };
  const getFormatPrice = useCallback(
    (rowVariable) =>
      rowVariable.classification
        ? currencyFormatter.format(rowVariable.classification.price)
        : (rowVariable.price && currencyFormatter.format(rowVariable.price)) ||
          '-',
    []
  );
  const columns = [
    {
      Header: 'VARIABLE NAME',
      accessor: 'name',
      customCell: ({ value, row }) => (
        <div>
          <a
            className="text-sm font-medium hover:text-blue70 hover:no-underline focus:outline-none"
            href={`${PATH_URL.VARIABLE_LIST}/${row.original.id}`}
            onClick={(e) => e.preventDefault()}
          >
            {value}
          </a>
          {row.original && (
            <p className="text-xs text-gray50">{row.original.description}</p>
          )}
        </div>
      ),
    },
    {
      Header: 'LAST INDEXING',
      accessor: 'lastIndexing',
      customCell: ({ value }) =>
        value ? moment(value).format('DD MMM YYYY') : '-',
    },
    {
      Header: 'LABEL',
      accessor: 'label',
      customCell: ({ value }) => upperFirst(value),
    },
    {
      Header: 'CLASS',
      accessor: 'classification',
      customCell: ({ value }) => (value ? value.name : 'Custom'),
    },
    {
      Header: 'BASE PRICE',
      accessor: 'clasification',
      customCell: ({ _, row }) => <>{getFormatPrice(row.original)}</>,
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      customCell: ({ value }) => (
        <span
          className={clsx(
            'inline-block text-xs rounded-full py-0.5 px-1.5 whitespace-nowrap',
            value === 'Not Indexed' && 'bg-grey5 text-grey70',
            value === 'Done' && 'bg-green5 text-green70',
            value === 'Running' && 'bg-blue5 text-blue70',
            value === 'Queue' && 'bg-orange5 text-orange70',
            value === 'Failed' && 'bg-red5 text-red70',
            value === 'Reported' && 'bg-red70 text-white',
            value === 'Revised' && 'bg-orange70 text-orange5'
          )}
        >
          {value}
        </span>
      ),
    },
    {
      Header: 'PUBLISH',
      accessor: 'isPublished',
      customCell: ({ value }) => (
        <span
          className={clsx(
            'inline-block text-xs rounded-full py-0.5 px-1.5 whitespace-nowrap',
            value ? 'bg-green5 text-green70' : 'bg-orange5 text-red70'
          )}
        >
          {value ? 'Yes' : 'No'}
        </span>
      ),
    },
    {
      Header: 'Action',
      accessor: 'id',
      sortable: false,
      customCell: ({ value, row }) => {
        const isDisabled = ['Queue', 'Running'].includes(row.original.status);
        const optionClass = `block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm transition-colors ${
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } py-2 hover:bg-grey5 hover:no-underline`;
        return (
          <Popover
            isOpen={isVisible && visibleDropDown === value}
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            targetTagName="div"
            className="w-min-content"
            targetClassName="inline-block"
            popoverClassName="shadow-none bg-transparent"
          >
            <div
              className="cursor-pointer inline-block"
              onClick={(e) => {
                e.stopPropagation();
                if (isVisible && visibleDropDown) {
                  setIsVisible(false);
                  setVisibleDropdown(0);
                } else {
                  setIsVisible(true);
                  setVisibleDropdown(value);
                }
              }}
            >
              <Icon
                icon={IconNames.MORE}
                iconSize={20}
                className="fill-curent text-grey50 hover:text-grey50"
              />
            </div>
            <div
              className="w-40 p-2 mt-1 shadow top rounded overflow-y-auto bg-white"
              ref={ref}
            >
              <ul>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      runVariableIndexing(row.original.id, [], reload, () => {
                        showToastError('An error occured');
                      })
                    );
                    setIsVisible(false);
                  }}
                  disabled={
                    isDisabled ||
                    row.original.isPublished ||
                    row.original.injectedData
                  }
                  className={`${optionClass} ${
                    isDisabled ? 'text-grey30' : 'text-grey50'
                  }`}
                >
                  Run Indexing
                </button>
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    setConfirmationDialog((prev) => ({
                      ...prev,
                      isOpen: true,
                      title: `${
                        !row.original.isPublished ? 'Publish' : 'Unpublish'
                      } Variable`,
                      text: `Are you sure you want to ${
                        !row.original.isPublished ? 'publish' : 'unpublish'
                      } this variable?`,
                      mode: !row.original.isPublished ? 'normal' : 'danger',
                      isLoading: false,
                      confirm: !row.original.isPublished
                        ? 'Publish'
                        : 'Unpublish',
                      dataVariable: row.original,
                    }));
                    setIsVisible(false);
                  }}
                  className={`${optionClass} text-grey50`}
                >
                  {!row.original.isPublished
                    ? 'Publish variable'
                    : 'Unpublish variable'}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedVariables([row.original.id]);
                    setDialogOpen((prev) => ({ ...prev, download: true }));
                  }}
                  className={`${optionClass} text-grey50`}
                >
                  Download
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogOpen((prev) => ({ ...prev, edit: true }));
                    dispatch(setSelectedVariable(row.original));
                  }}
                  disabled={isDisabled}
                  className={`${optionClass} ${
                    isDisabled ? 'text-grey30' : 'text-grey50'
                  }`}
                >
                  Edit variable
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogOpen((prev) => ({ ...prev, delete: true }));
                    dispatch(setSelectedVariable(row.original));
                  }}
                  disabled={isDisabled}
                  className={`${optionClass} ${
                    isDisabled ? 'text-red30' : 'text-red50'
                  } `}
                >
                  Delete variable
                </button>
              </ul>
            </div>
          </Popover>
        );
      },
    },
  ];
  const filterTable = useMemo(
    () => [
      {
        name: 'label',
        options: [
          {
            text: 'Environment',
            value: 'Environment',
          },
          {
            text: 'Social',
            value: 'Social',
          },
          {
            text: 'Governance',
            value: 'Governance',
          },
          {
            text: 'Finance',
            value: 'Finance',
          },
          {
            text: 'Others',
            value: 'Others',
          },
        ],
      },
      {
        name: 'class',
        options: classifications.map((v) => ({
          value: v.id,
          text: v.name,
        })),
      },
      {
        name: 'status',
        options: [
          {
            text: 'Not Indexed',
            value: 1,
          },
          {
            text: 'Queue',
            value: 2,
          },
          {
            text: 'Running',
            value: 3,
          },
          {
            text: 'Done',
            value: 5,
          },
          {
            text: 'Failed',
            value: 4,
          },
          {
            text: 'Reported',
            value: 6,
          },
          {
            text: 'Revised',
            value: 7,
          },
        ],
      },
    ],
    [classifications]
  );
  const ButtonImportCSV = () => (
    <button
      onClick={() => setDialogOpen((prev) => ({ ...prev, import: true }))}
      className="min-w-max ml-3 h-10 px-2 py-2 my-1 text-center bg-green50 rounded text-white focus:ring-transparent focus:outline-none"
    >
      Import Variable from CSV
      <Icon
        iconSize={18}
        icon={IconNames.CLOUD_DOWNLOAD}
        className="fill-current text-white ml-2"
      />
    </button>
  );

  const isInitiallyEmpty =
    variables.length === 0 &&
    Object.entries(filter).every((value) => !value === true); // no filter applied

  useEffect(() => {
    dispatch(
      getListVariable({
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...filter,
      })
    );
  }, [dispatch, pageData.currentPageNumber, pageData.limit, filter]);

  useEffect(() => {
    if (classifications.length === 0) dispatch(getClassifications());
  }, [dispatch, classifications]);

  return (
    <>
      <div className="w-full p-2">
        {!loadingGetListVariable && isInitiallyEmpty ? (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="mb-2">
              <img src={imageEmpty} className="w-full h-auto" alt="img-empty" />
            </div>
            <h1 className="text-xl text-grey100 font-bold mb-2">No Variable</h1>
            <p className="text-sm text-grey50 mb-3">
              There are no variables for now
            </p>
            <button
              onClick={() => setDialogOpen((prev) => ({ ...prev, add: true }))}
              className="text-xs font-semibold px-4 py-2 text-white bg-green50 rounded-sm hover:bg-green60"
            >
              <span className="mr-1">Add New Variable</span>
              <Icon
                icon={IconNames.VARIABLE}
                iconSize={15}
                className="fill-current text-white"
              />
            </button>
          </div>
        ) : (
          <>
            <div className="w-full flex flex-wrap justify-between items-center mb-5">
              <h1 className="text-base text-grey70 font-bold">Variable List</h1>
              <div className="flex">
                <Button
                  color="success"
                  size="small"
                  onClick={() =>
                    setDialogOpen((prev) => ({ ...prev, add: true }))
                  }
                >
                  <span className="mr-1">Add New Variable</span>
                  <Icon
                    icon={IconNames.VARIABLE}
                    iconSize={15}
                    className="fill-current text-white"
                  />
                </Button>
              </div>
            </div>
            <CustomTable
              isLoading={loadingGetListVariable}
              dataTable={variables.length > 0 ? variables : []}
              columnsTable={columns}
              additionalButton={[ButtonImportCSV]}
              filterTable={filterTable}
              initialFilterValue={{
                label: filter.label,
                class: filter.classification_id,
                status: filter.status,
              }}
              search={filter.q}
              onChangeFilter={(filter) => {
                dispatch(
                  changeVariableListFilter(
                    Object.keys(filter).reduce((allFilter, v) => {
                      const key = v === 'class' ? 'classification_id' : v;
                      return {
                        ...allFilter,
                        [key]: filter[v] && filter[v].value,
                      };
                    }, {})
                  )
                );
              }}
              page={pageData.currentPageNumber}
              totalPage={pageData.totalPages}
              totalRow={pageData.limit}
              onChangeTotalRow={(selected) => {
                dispatch(
                  changeVariableListPage({
                    currentPageNumber: 1,
                    limit: selected,
                  })
                );
              }}
              onInputSearch={(text) => {
                dispatch(changeVariableListFilter({ q: text }));
              }}
              onChangePage={(page) => {
                dispatch(changeVariableListPage({ currentPageNumber: page }));
              }}
              onRowClick={(row, cell) => {
                if (cell.Header !== 'Action')
                  history.push(`${PATH_URL.VARIABLE_LIST}/${row.id}`);
              }}
              renderBulkActions={(selectedRows) => {
                return (
                  <Button
                    color="success"
                    onClick={() => {
                      setDialogOpen((prev) => ({ ...prev, download: true }));
                      setSelectedVariables(
                        selectedRows.map((row) => row.original.id)
                      );
                    }}
                  >
                    Download Variable
                  </Button>
                );
              }}
            />
          </>
        )}
      </div>
      <AddVariable
        isOpen={dialogOpen.add}
        onClose={() => setDialogOpen((prev) => ({ ...prev, add: false }))}
        onSuccess={reload}
      />
      <EditVariable
        isOpen={dialogOpen.edit}
        onClose={() => setDialogOpen((prev) => ({ ...prev, edit: false }))}
        onSuccess={reload}
      />
      <ImportVariable
        isOpen={dialogOpen.import}
        onClose={() => setDialogOpen((prev) => ({ ...prev, import: false }))}
        onSuccess={reload}
      />
      <DeleteVariable
        isOpen={dialogOpen.delete}
        onClose={() => {
          setDialogOpen((prev) => ({ ...prev, delete: false }));
        }}
        onSuccess={() => {
          if (pageData.totalItems === 1) {
            dispatch(
              getListVariable({
                page: pageData.page > 1 ? pageData.page - 1 : pageData,
                limit: pageData.limit,
                ...filter,
              })
            );
          } else {
            reload();
          }
        }}
      />
      <DialogPublishVariable
        {...confirmationDialog}
        captions={{
          confirm: confirmationDialog.confirm,
        }}
        onConfirm={() => {
          togglePublished(confirmationDialog.dataVariable);
        }}
        onCancel={() =>
          setConfirmationDialog({
            isOpen: false,
            mode: 'normal',
            title: '',
            text: '',
            isLoading: false,
            captions: {
              confirm: '',
            },
            dataVariable: {},
          })
        }
      >
        {confirmationDialog.text}
      </DialogPublishVariable>
      <DownloadVariable
        isOpen={dialogOpen.download}
        selectedVariables={selectedVariables}
        onClose={() => {
          setDialogOpen((prev) => ({ ...prev, download: false }));
        }}
      />
    </>
  );
}

export default VariableList;
