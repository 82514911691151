import { useDispatch, useSelector } from 'react-redux';
import { IconNames } from '@blueprintjs/icons';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import googleLogo from '../../assets/images/google-logo.svg';
import { Icon } from '@blueprintjs/core';
import Button from 'components/Button';
import PATH_URL from '../../routers/path';
import {
  register as registerAction,
  registerGoogle,
} from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import { createErrorMessageSelector } from '../../redux/api/error';
import TextField from '../../components/TextField';

const schema = yup.object().shape({
  fullname: yup.string().required('Fullname is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup
    .string()
    .min(10, 'Invalid phone number')
    .matches(/^\d+$/, 'Phone number must not contain non numeric character')
    .required('Phone number is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/i, 'Password must contain at least one letter')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Confirmation password not match'),
});

function Register() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const loading = useSelector((state) =>
    createLoadingSelector([registerAction.type])(state)
  );
  const error = useSelector((state) =>
    createErrorMessageSelector([registerAction.type])(state)
  );

  const onSubmit = async (data) => {
    await dispatch(registerAction(data));
  };

  const handleRegisterGoogle = () => dispatch(registerGoogle());

  return (
    <div className="w-full flex flex-col">
      <header className="text-3xl font-semibold">
        Be the part of creating a more sustainable world
      </header>
      {error && (
        <p className="text-sm bg-red50 text-white p-1 rounded-sm">{error}</p>
      )}
      <p className="text-xl text-grey100 mt-2">
        Get 30% additional discount for student
      </p>
      <p className="text-sm">
        {[
          'Get access to million companies data',
          'Get valid and reliable data for lower cost',
          'Save 12+ hours every week',
        ].map((text, index) => (
          <span
            key={`text-${index + 1}`}
            className="flex align-center text-grey50 mt-2"
          >
            <Icon
              icon={IconNames.TICK}
              iconSize={20}
              className={`fill-current text-blue50 mr-1`}
            />
            {text}
          </span>
        ))}
      </p>
      <Button
        view="outlined"
        size="small"
        disabled={loading}
        onClick={handleRegisterGoogle}
        isLoading={loading}
        className="w-full mt-4"
      >
        <div className="flex items-center justify-center">
          <img src={googleLogo} height={24} width={24} alt="Google logo" />
          <span className="ml-1">Register with Google</span>
        </div>
      </Button>
      <div className="flex items-center w-full mt-2">
        <hr className="border-t border-grey30 w-full" />
        <span className="block text-sm text-grey30 font-normal mx-4">OR</span>
        <hr className="border-t border-grey30 w-full" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-2">
          <TextField
            type="text"
            name="fullname"
            ref={register}
            defaultBorder={false}
            label="Full Name"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
            nameIconRight={
              (errors.fullname && IconNames.ERROR) ||
              (dirtyFields.fullname && IconNames.TICK)
            }
            autoComplete="name"
          />
          <p className="text-xs text-red50 mt-px">{errors.fullname?.message}</p>
        </div>
        <div className="flex flex-col mt-2">
          <TextField
            type="text"
            name="phone"
            ref={register}
            defaultBorder={false}
            label="Phone"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
            nameIconRight={
              (errors.phone && IconNames.ERROR) ||
              (dirtyFields.phone && IconNames.TICK)
            }
            autoComplete="tel"
          />
          <p className="text-xs text-red50 mt-px">{errors.phone?.message}</p>
        </div>
        <div className="flex flex-col mt-2">
          <TextField
            type="email"
            name="email"
            ref={register}
            defaultBorder={false}
            label="Email Address"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
            nameIconRight={
              (errors.email && IconNames.ERROR) ||
              (dirtyFields.email && IconNames.TICK)
            }
            autoComplete="email"
          />
          <p className="text-xs text-red50 mt-px">{errors.email?.message}</p>
        </div>
        <div className="flex flex-col mt-2">
          <TextField
            type="password"
            name="password"
            ref={register}
            defaultBorder={false}
            label="Password"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
          />
          <p className="text-xs text-red50 mt-px">{errors.password?.message}</p>
        </div>
        <div className="flex flex-col mt-2">
          <TextField
            type="password"
            name="confirmPassword"
            ref={register}
            defaultBorder={false}
            label="Re-enter Password"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
          />
          <p className="text-xs text-red50 mt-px">
            {errors.confirmPassword?.message}
          </p>
        </div>
        <Button
          type="submit"
          color="primary"
          disabled={loading}
          isLoading={loading}
          className="w-full mt-3"
        >
          Create Account
        </Button>
        <p className="text-sm text-grey80 leading-relaxed mt-2">
          By selecting the "Create account" button, you are creating an ESG
          Intelligence account, and you agree to ESG Intelligence's{' '}
          <Link
            to={PATH_URL.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-blue50 hover:underline"
          >
            privacy policy
          </Link>{' '}
          and{' '}
          <Link
            to={PATH_URL.TERMS_CONDITIONS}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-blue50 hover:underline"
          >
            terms and conditions
          </Link>
        </p>
        <p className="text-sm text-center text-grey40 mt-3">
          Already have an account?{' '}
          <Link
            to={PATH_URL.LOGIN}
            className="text-blue50 font-bold hover:underline"
          >
            Sign in
          </Link>
        </p>
      </form>
    </div>
  );
}

export default Register;
