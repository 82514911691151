import {
  actionSuccess,
  createAction,
  createAsyncAction,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import ENDPOINTS from '../urls';

const PRE = 'userPayment';

export const updateInvoiceStatus = createAsyncAction(
  `${PRE}/UPDATE_INVOICES_STATUS`,
  (data, onSuccess = null, onError = null) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/UPDATE_INVOICES_STATUS`,
        'put',
        ENDPOINTS.UPDATE_INVOICES_STATUS,
        data,
        'application/json',
        () => {
          if (onSuccess) {
            onSuccess();
          }
        },
        (message) => {
          if (onError) {
            onError(message);
          }
        }
      );
    }
);

export const getPayment = createAsyncAction(
  `${PRE}/GET_PAYMENT_DATA`,
  (id, delay = 0) =>
    async (dispatch) => {
      await dispatch(checkMidtransStatus(id));
      async function _getPayment() {
        return await apiRequest(
          dispatch,
          `${PRE}/GET_PAYMENT_DATA`,
          'get',
          `${ENDPOINTS.GET_USER_QD_INVOICES}/${id}`
        );
      }
      if (delay > 0) {
        dispatch({ type: `${PRE}/GET_PAYMENT_DATA_REQUEST` });
        setTimeout(() => {
          _getPayment();
        }, delay);
      } else {
        _getPayment();
      }
    }
);

export const bulkDownloadQD = createAsyncAction(
  `${PRE}/BULK_DOWNLOAD_QD`,
  (urls) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/BULK_DOWNLOAD_QD`,
      'post',
      `${ENDPOINTS.BULK_DOWNLOAD_QD}`,
      {
        urls,
      }
    );
  }
);

export const cancelOrder = createAsyncAction(
  `${PRE}/CANCEL_ORDER`,
  (invoiceNumber, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/CANCEL_ORDER`,
        'post',
        `${ENDPOINTS.CANCEL_QD_ORDER}`,
        {
          invoiceNumber,
        },
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const checkMidtransStatus = createAsyncAction(
  `${PRE}/CHECK_MIDTRANS_STATUS`,
  (invoiceNumber) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/CHECK_MIDTRANS_STATUS`,
      'post',
      `${ENDPOINTS.CHECK_MIDTRANS_STATUS}`,
      {
        invoiceNumber,
      }
    );
  }
);

export const resetSelectedOrder = createAction(`${PRE}/RESET_SELECTED_ORDER`);

const initialState = {
  quantitative: {
    selected: {
      id: null,
      invoiceNumber: '',
      invoiceName: '',
      downloadUrl: null,
      grossPrice: '',
      netPrice: '',
      payment: {},
      dueDate: '',
      paidOn: null,
      orderStatus: '',
      paymentStatus: '',
      orders_details: [],
      voucher: null,
      referral: null,
      user: {},
    },
  },
};

const userPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(getPayment.type):
      return {
        ...state,
        quantitative: {
          ...state.quantitative,
          selected: action.payload,
        },
      };
    case resetSelectedOrder.type:
      return {
        ...state,
        quantitative: {
          ...state.quantitative,
          selected: { ...initialState.quantitative.selected },
        },
      };
    default:
      return state;
  }
};

export default userPaymentReducer;
