import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'components/Dialog';
import ProgressBar from 'components/ProgressBar';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { uploadVariableCSV } from 'redux/features/variableManagement';
import clsx from 'clsx';
import exampleFile from './data/ESG_Dataset_Import_Variable_Template.xlsx';
import { actionSuccess } from 'redux/utils/actionCreator';
import ErrorImportVariable from './ErrorImportVariable';
import { showToastSuccess } from '../../layouts/DashboardLayout';

export default function ImportVariableDialog(props) {
  const [uploadStatus, setUploadStatus] = useState('init');
  const [filename, setFilename] = useState('');
  const [error, setError] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const dispatch = useDispatch();
  const { visible: visibleProgress, progress } = useSelector(
    ({ variableManagement }) => variableManagement.upload
  );
  const loading = false;
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: '.csv',
    noClick: true,
    noKeyboard: true,
    maxSize: 5000000, // 5 mil. bytes => 5 MB
    multiple: false,
    onDropAccepted: (files) => {
      if (files.length > 0 && !loading) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = files[0];
          const formData = new FormData();
          formData.set('file', data);
          setFilename(data.name);
          const { type, payload } = await dispatch(uploadVariableCSV(formData));
          if (type === actionSuccess(uploadVariableCSV.type)) {
            setUploadStatus('done');
            props.onClose();
            props.onSuccess();
            setUploadStatus('init');
            showToastSuccess('CSV successfully imported');
          } else {
            setError(payload.message);
            setErrorDialog(true);
          }
        };
        reader.readAsDataURL(files[0]);
      }
    },
    onDropRejected: (files) => {
      const message = files[0].errors[0].message;
      setError(message);
      setErrorDialog(true);
    },
  });
  const handleCloseDialog = () => {
    setUploadStatus('init');
    props.onClose();
  };
  const handleCloseErrorDialog = () => {
    setError('');
    setErrorDialog(false);
  };
  return (
    <>
      <Dialog
        isOpen={props.isOpen}
        header={'Import variable from CSV'}
        onClose={handleCloseDialog}
        dismissButton
      >
        <form>
          <div className="h-28 w-full px-4 pb-2 flex flex-col">
            <div
              {...getRootProps({
                className:
                  'flex-grow flex justify-center items-center my-1 rounded border-2 border-dashed border-blue30 focus:outline-none',
              })}
            >
              <input {...getInputProps()} />
              <div
                className={clsx(
                  'flex items-center',
                  visibleProgress && 'w-full mx-3'
                )}
              >
                {visibleProgress && (
                  <div className="w-full">
                    <p className="text-center text-grey50">{filename}</p>
                    <ProgressBar percentage={progress} />
                  </div>
                )}
                {!visibleProgress && uploadStatus === 'init' && (
                  <>
                    <Icon
                      icon={IconNames.CIRCLE_ARROW_UP}
                      iconSize={14}
                      className="fill-current text-grey50 mr-1"
                    />
                    <p className="text-sm text-grey50">
                      Drag and Drop file here or
                      <span
                        onClick={open}
                        className="text-blue60 underline ml-1 cursor-pointer"
                      >
                        choose file
                      </span>
                    </p>
                  </>
                )}
                {!visibleProgress && uploadStatus === 'done' && (
                  <span className="text-blue50">
                    {filename}
                    <Icon
                      icon={IconNames.TICK_CIRCLE}
                      iconSize={16}
                      className="fill-current text-green50 ml-1"
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="w-full">
              <p className="text-xs text-grey40">
                Don't have a data file?{' '}
                <a
                  href={exampleFile}
                  download="Example_Import_Variable.xlsx"
                  className="text-blue50 hover:text-blue60 hover:no-underline"
                >
                  Download a sample file
                </a>
              </p>
            </div>
          </div>
        </form>
      </Dialog>
      <ErrorImportVariable
        isOpen={errorDialog}
        content={error}
        onClose={handleCloseErrorDialog}
      />
    </>
  );
}
