import { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PanelEditProfile from './PanelEditProfile';
import Password from './Password';
import AccountType from './AccountType';

function EditProfilePage() {
  const _isMounted = useRef(true);
  const ListPanel = [
    {
      key: 'profile',
      text: 'Profile',
    },
    {
      key: 'password',
      text: 'Password',
    },
    {
      key: 'accontType',
      text: 'Account Type',
    },
  ];
  const [currentPanel, setCurrentPanel] = useState(ListPanel[0].key);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);
  return (
    <div className="w-full mt-14">
      <div className="w-full mb-10 flex flex-col items-center">
        <div className="lg:w-8/12 w-10/12 flex">
          <div className="w-3/12">
            <h1 className="text-grey70 text-2xl font-bold mb-8">
              Edit Profile
            </h1>
            <ul className="w-full">
              {ListPanel.map((panel, index) => (
                <li
                  key={`panel-${index}`}
                  onClick={() => setCurrentPanel(panel.key)}
                  className={clsx(
                    'w-full mb-2 text-base font-normal cursor-pointer',
                    currentPanel === panel.key ? 'text-grey100' : 'text-grey30'
                  )}
                >
                  {panel.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="w-9/12">
            <div className="w-full">
              {currentPanel === 'profile' && <PanelEditProfile />}
              {currentPanel === 'password' && <Password />}
              {currentPanel === 'accontType' && <AccountType />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfilePage;
