import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PATH_URL from '../routers/path';
import { signOut } from '../redux/features/auth';

export default function UserMenu({ containerRef, isAdmin, setIsVisible }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleSignOut = () => dispatch(signOut());
  const getCurrentDashboard = () => {
    return location.pathname.split('/')[1] === 'm' ? 'admin' : 'user';
  };
  return (
    <div
      ref={containerRef}
      className="w-48 p-2 mt-1 shadow top rounded overflow-y-auto bg-white"
    >
      <ul>
        {isAdmin && (
          <Link
            className="block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm text-grey60 transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline"
            to={
              getCurrentDashboard() === 'admin'
                ? PATH_URL.HOME
                : PATH_URL.USER_LIST
            }
            onClick={() => setIsVisible(false)}
          >
            Switch Dashboard
          </Link>
        )}
        <Link
          className="block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm text-grey60 transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline"
          to={PATH_URL.USER_PROFILE}
          onClick={() => setIsVisible(false)}
        >
          My Profile
        </Link>
        <Link
          className={`block pl-2 mb-2 text-xs font-medium text-left rounded-sm text-grey60 transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline`}
          to={PATH_URL.EDIT_USER_PROFILE}
          onClick={() => setIsVisible(false)}
        >
          Edit Profile
        </Link>
        <li
          className={`block pl-2 text-xs font-medium text-left rounded-sm text-red60 transition-colors cursor-pointer py-2 hover:bg-grey5`}
          onClick={() => {
            setIsVisible(false);
            handleSignOut();
          }}
        >
          Log out
        </li>
      </ul>
    </div>
  );
}
