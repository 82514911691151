import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import clsx from 'clsx';
import moment from 'moment';
import { createLoadingSelector } from '../../redux/api/loading';
import DialogDetailInvoiceQD from './DialogDetailInvoiceQD';
import { getUserInvoicesQD } from 'redux/features/auth';
import Button from 'components/Button';
import PATH_URL from 'routers/path';
import { useHistory } from 'react-router';
import { bulkDownloadQD } from 'redux/features/userPayment';
import { actionSuccess } from 'redux/utils/actionCreator';
import { showToastError } from 'layouts/DashboardLayout';
import { upperFirst } from 'lodash-es';

export default function QuantitativeDatasetTableInvoice() {
  const history = useHistory();
  const _isMounted = useRef(true);
  const downloadAnchorRef = useRef(null);
  const dispatch = useDispatch();
  const userInvoices = useSelector((state) => state.auth.invoices.quantitative);
  const loadingStatusUserInvoices = useSelector((state) =>
    createLoadingSelector([getUserInvoicesQD.type])(state)
  );
  const loadingBulkDownload = useSelector(
    createLoadingSelector([bulkDownloadQD.type])
  );
  const [showDetailInvoice, setShowDetailInvoice] = useState({
    show: false,
    id: null,
  });
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchInvoices, setSearchInvoices] = useState('');
  const columns = [
    {
      Header: 'Invoice #',
      accessor: 'invoiceNumber',
      customCell: ({ value, row }) => (
        <p
          className="text-blue50 cursor-pointer"
          onClick={() => {
            if (['completed', 'canceled'].includes(row.original.orderStatus)) {
              setShowDetailInvoice({
                show: true,
                id: row.original.invoiceNumber,
              });
            } else {
              history.push({
                pathname: PATH_URL.PAYMENT_QD,
                search: `?order_id=${row.original.invoiceNumber}`,
              });
            }
          }}
        >
          {value}
        </p>
      ),
    },
    {
      Header: 'Items',
      accessor: 'invoiceName',
    },
    {
      Header: 'Paid On',
      accessor: 'paidOn',
      sortType: 'datetime',
      customCell: ({ value }) => (
        <>
          {value && value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-'}
        </>
      ),
    },
    {
      Header: 'STATUS',
      accessor: 'orderStatus',
      customCell: ({ value }) => {
        return (
          <div>
            <span
              className={clsx(
                'text-center capitalize px-2 rounded-full py-0.5',
                value === 'completed' && 'bg-green10 text-green50',
                value === 'processing' && 'bg-yellow10 text-yellow50',
                value === 'canceled' && 'bg-red10 text-red50'
              )}
            >
              {upperFirst(value)}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Total',
      accessor: 'netPrice',
      alignment: 'right',
      customCell: ({ value }) => (
        <span className="block text-right">
          {currencyFormatter.format(value)}
        </span>
      ),
    },
    {
      Header: 'Action',
      accessor: 'downloadUrl',
      alignment: 'center',
      sortable: false,
      customCell: ({ value, row }) => {
        return (
          <Button
            size="small"
            color="primary"
            disabled={value === null}
            className="mx-auto"
            onClick={() => {
              const downloadAnchor = downloadAnchorRef.current;
              downloadAnchor.setAttribute('href', row.original.downloadUrl);
              downloadAnchor.click();
            }}
          >
            Download Data
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(
      getUserInvoicesQD({
        q: searchInvoices,
        page: 1,
        limit: rowPerPage,
      })
    );
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch, rowPerPage, searchInvoices]);
  return (
    <>
      <CustomTable
        isLoading={loadingStatusUserInvoices}
        dataTable={userInvoices.list.map((v) => ({
          ...v,
          paidOn: new Date(v.paidOn),
        }))}
        additionalButton={[
          ({ selectedRows }) => {
            const completeSelectedRows = selectedRows.filter(
              (row) => row.orderStatus === 'completed'
            );
            return (
              <Button
                color="success"
                className="ml-2"
                disabled={completeSelectedRows.length === 0}
                onClick={async () => {
                  let fileUrl;
                  if (completeSelectedRows.length === 1) {
                    fileUrl = completeSelectedRows[0].downloadUrl;
                  } else {
                    const urls = selectedRows
                      .map((row) => row.downloadUrl)
                      .filter((url) => url !== null);
                    const { type, payload } = await dispatch(
                      bulkDownloadQD(urls)
                    );
                    if (type === actionSuccess(bulkDownloadQD.type)) {
                      fileUrl = payload;
                    } else {
                      showToastError('An error occured');
                    }
                  }
                  if (fileUrl && downloadAnchorRef.current) {
                    const downloadAnchor = downloadAnchorRef.current;
                    downloadAnchor.setAttribute('href', fileUrl);
                    downloadAnchor.click();
                  }
                }}
                isLoading={loadingBulkDownload}
              >
                Download Data
                <Icon
                  iconSize={18}
                  icon={IconNames.CLOUD_DOWNLOAD}
                  className="fill-current text-white ml-2"
                />
              </Button>
            );
          },
        ]}
        columnsTable={columns}
        page={userInvoices.currentPageNumber}
        totalPage={userInvoices.totalPages}
        totalRow={rowPerPage}
        onChangeTotalRow={(row) => {
          setRowPerPage(row);
        }}
        onInputSearch={(text) => {
          setSearchInvoices(text);
        }}
        onChangePage={(page) => {
          dispatch(
            getUserInvoicesQD({
              page: page,
              limit: rowPerPage,
              q: searchInvoices,
            })
          );
        }}
      />
      <a ref={downloadAnchorRef} href="_" className="hidden">
        Download
      </a>
      <DialogDetailInvoiceQD
        showDialog={showDetailInvoice.show}
        invoiceId={showDetailInvoice.id}
        setShowDialog={setShowDetailInvoice}
      />
    </>
  );
}
