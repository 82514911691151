import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TimePicker } from '@blueprintjs/datetime';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
import {
  getListVoucher,
  updateVoucherData,
  createVoucher,
} from '../../redux/features/voucherManagement';
import { createLoadingSelector } from '../../redux/api/loading';
export default function ModalAddVoucher({
  showDialog,
  setShowDialog,
  dataToUpdate = {},
  setDataToUpdate = null,
}) {
  const dispatch = useDispatch();
  const voucherType = ['percentage', 'amount'];
  const eligibility = ['everyone', 'general', 'student'];
  const [rangeTime, setRangeTime] = useState({
    startTime:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.startDate
        ? new Date(dataToUpdate.data.startDate).getTime()
        : 0,
    endTime:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.endDate
        ? new Date(dataToUpdate.data.endDate).getTime()
        : 0,
  });
  const loadingCRUDVoucher = useSelector((state) =>
    createLoadingSelector([
      createVoucher.type,
      updateVoucherData.type,
      getListVoucher.type,
    ])(state)
  );
  const defaultValues = {
    name:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.name
        ? dataToUpdate.data.name
        : '',
    type:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.type
        ? dataToUpdate.data.type
        : voucherType[0],
    value:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.value
        ? dataToUpdate.data.value
        : 0,
    eligibility:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.eligibility
        ? dataToUpdate.data.eligibility
        : eligibility[0],
    limit:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.limit
        ? dataToUpdate.data.limit
        : 0,
    isLimitedPerUser:
      dataToUpdate.id && dataToUpdate.data
        ? !!dataToUpdate.data.isLimitedPerUser
        : true,
    startDate:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.startDate
        ? moment(dataToUpdate.data.startDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    endDate:
      dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.endDate
        ? moment(dataToUpdate.data.endDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
  };
  const schema = Yup.object().shape({
    name: Yup.string().required('Voucher code is required field'),
    type: Yup.string().required(),
    value: Yup.number().required(),
    eligibility: Yup.string().required(),
    limit: Yup.number().required(),
    isLimitedPerUser: Yup.boolean().required(),
    startDate: Yup.date().required(),
    endDate: Yup.date().required(),
  });
  const { register, handleSubmit, getValues, reset, errors } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    let submitData = {
      ...data,
      startDate: `${moment(data.startDate).format('YYYY-MM-DD')} ${
        rangeTime.startTime
          ? moment(rangeTime.startTime).format('HH:mm:ss')
          : '00:00:00'
      }`,
      endDate: `${moment(data.endDate).format('YYYY-MM-DD')} ${
        rangeTime.endTime
          ? moment(rangeTime.endTime).format('HH:mm:ss')
          : '00:00:00'
      }`,
      status: true,
    };
    // Uppercase the voucher name, cause when use voucher we upppercase all the letter
    submitData.name = submitData.name.toUpperCase();
    try {
      if (!dataToUpdate.id) {
        dispatch(
          createVoucher(
            submitData,
            () => {
              showToastSuccess('Voucher has been created successfully');
              dispatch(getListVoucher());
              setShowDialog(false);
              resetForm();
            },
            (message) => {
              showToastError(message || 'Something wrong...');
            }
          )
        );
      } else {
        dispatch(
          updateVoucherData(
            { id: dataToUpdate.id, ...submitData },
            () => {
              showToastSuccess('Voucher has been updated successfully');
              dispatch(getListVoucher());
              setShowDialog(false);
              resetForm();
            },
            (message) => {
              showToastError(message || 'Something wrong...');
            }
          )
        );
      }
    } catch (err) {
      showToastError(err.message || 'Something wrong...');
    }
  };
  const resetForm = () => {
    if (setDataToUpdate) {
      setDataToUpdate({
        id: 0,
        data: {},
      });
    }
    setRangeTime({
      startTime: 0,
      endTime: 0,
    });
    reset(defaultValues);
  };
  return (
    <Dialog
      header={
        dataToUpdate.id && dataToUpdate.data && dataToUpdate.data.name
          ? dataToUpdate.data.name
          : 'Add new voucher'
      }
      isOpen={showDialog}
      onClose={() => setShowDialog(false)}
    >
      <div className="w-full px-6 py-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full mb-3">
            <label
              htmlFor="name-input"
              className="block text-grey60 text-xs mb-1"
            >
              Voucher Code
            </label>
            <input
              id="name-input"
              type="text"
              name="name"
              ref={register}
              placeholder="input voucher code"
              className={clsx(
                'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                'border-grey50'
              )}
            />
            <p
              className={clsx(
                'text-xs mt-2',
                errors.name?.message ? 'text-red50' : 'text-grey50'
              )}
            >
              {errors.name?.message ||
                'Customers will enter this voucher code at checkout'}
            </p>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 mr-1">
              <label
                htmlFor="voucherType-input"
                className="block text-grey60 text-xs mb-1"
              >
                Voucher Type
              </label>
              <select
                id="voucherType-input"
                name="type"
                ref={register}
                value={getValues('type')}
                className="w-full capitalize rounded border border-grey50 focus:border-blue50 focus:outline-none focus:ring-transparent"
              >
                {voucherType.map((voucher, index) => (
                  <option
                    key={`voucher-${index + 1}`}
                    value={voucher}
                    className="capitalize text-grey70"
                  >
                    {voucher}
                  </option>
                ))}
              </select>
              <p className="text-xs text-red50 mt-2">{errors.type?.message}</p>
            </div>
            <div className="w-1/2 ml-1">
              <label
                htmlFor="voucherValue-input"
                className="block text-grey60 text-xs mb-1"
              >
                Voucher Value
              </label>
              <input
                id="voucherValue-input"
                type="text"
                name="value"
                ref={register}
                className={clsx(
                  'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                  'border-grey50'
                )}
              />
              <p className="text-xs text-red50 mt-2">{errors.value?.message}</p>
            </div>
          </div>
          <div className="w-full mb-3">
            <label
              htmlFor="eligibility-input"
              className="block text-grey60 text-xs mb-1"
            >
              Customer Eligibility
            </label>
            <select
              id="eligibility-input"
              name="eligibility"
              ref={register}
              defaultValue={eligibility[0]}
              className="w-full capitalize rounded border border-grey50 focus:border-blue50 focus:outline-none focus:ring-transparent"
            >
              {eligibility.map((eligi, index) => (
                <option
                  key={`eligi-${index + 1}`}
                  value={eligi}
                  className="capitalize text-grey70"
                >
                  {eligi}
                </option>
              ))}
            </select>
            <p className="text-xs text-red50 mt-2">
              {errors.eligibility?.message}
            </p>
          </div>
          <div className="w-full mb-3">
            <label
              htmlFor="limit-input"
              className="block text-grey60 text-xs mb-1"
            >
              Usage Limit
            </label>
            <input
              id="limit-input"
              type="text"
              name="limit"
              ref={register}
              className={clsx(
                'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                'border-grey50'
              )}
            />
            <p className="text-xs text-red50 mt-2">{errors.limit?.message}</p>
          </div>
          <div className="flex items-center mb-3">
            <input
              id="limitOfUser"
              type="checkbox"
              name="isLimitedPerUser"
              ref={register}
              className="border text-teal2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-2"
            />
            <label htmlFor="limitOfUser" className="block text-grey100 text-xs">
              Limit to one use per customer
            </label>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 mr-1">
              <label
                htmlFor="startDate-input"
                className="block text-grey60 text-xs mb-1"
              >
                Start Date
              </label>
              <input
                id="startDate-input"
                type="date"
                name="startDate"
                ref={register}
                className={clsx(
                  'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                  'border-grey50'
                )}
              />
              <p className="text-xs text-red50 mt-2">
                {errors.startDate?.message}
              </p>
            </div>
            <div className="w-1/2 ml-1">
              <label
                htmlFor="fullName-input"
                className="block text-grey60 text-xs mb-1"
              >
                Start Time (WIB)
              </label>
              <div className="relative">
                <TimePicker
                  className={clsx(
                    'custom-time-picker border rounded w-full pt-1 pb-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                    'border-grey50'
                  )}
                  value={rangeTime ? new Date(rangeTime.startTime) : new Date()}
                  onChange={(time) => {
                    setRangeTime((prev) => ({
                      ...prev,
                      startTime: time.toUTCString(),
                    }));
                  }}
                />
                <Icon
                  icon={IconNames.TIME}
                  iconSize={15}
                  className="fill-curent text-grey50 hover:text-grey50 absolute top-3 right-2"
                />
              </div>
              <p className="text-xs text-red50 mt-2">
                {errors.fullName?.message}
              </p>
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 mr-1">
              <label
                htmlFor="endDate-input"
                className="block text-grey60 text-xs mb-1"
              >
                End Date
              </label>
              <input
                id="endDate-input"
                type="date"
                name="endDate"
                ref={register}
                className={clsx(
                  'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                  'border-grey50'
                )}
              />
              <p className="text-xs text-red50 mt-2">
                {errors.endDate?.message}
              </p>
            </div>
            <div className="w-1/2 ml-1">
              <label
                htmlFor="fullName-input"
                className="block text-grey60 text-xs mb-1"
              >
                End Time (WIB)
              </label>
              <div className="relative">
                <TimePicker
                  className={clsx(
                    'custom-time-picker border rounded w-full pt-1 pb-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                    'border-grey50'
                  )}
                  value={rangeTime ? new Date(rangeTime.endTime) : new Date()}
                  onChange={(time) => {
                    setRangeTime((prev) => ({
                      ...prev,
                      endTime: time.toUTCString(),
                    }));
                  }}
                />
                <Icon
                  icon={IconNames.TIME}
                  iconSize={15}
                  className="fill-curent text-grey50 hover:text-grey50 absolute top-3 right-2"
                />
              </div>
              <p className="text-xs text-red50 mt-2">
                {errors.fullName?.message}
              </p>
            </div>
          </div>
        </form>
        <div className="flex justify-end">
          <Button
            view="outlined"
            color="danger"
            className="mr-2"
            onClick={() => {
              resetForm();
              setShowDialog(false);
            }}
          >
            {dataToUpdate.id ? 'Cancel' : 'Discard'}
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            isLoading={loadingCRUDVoucher}
          >
            {dataToUpdate.id ? 'Update' : 'Save'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
