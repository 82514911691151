import { useState, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';

const TextField = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((prev) => !prev);
  const inputType = useMemo(() => {
    if (props.type !== 'password') {
      return 'text';
    } else {
      return !showPassword ? 'password' : 'text';
    }
  }, [showPassword, props.type]);
  return (
    <div id={props.id}>
      {props.label && (
        <label htmlFor={props.name} className="block text-grey60 text-sm mb-1">
          {props.label}
          {props.required && <span className="text-red50">*</span>}
        </label>
      )}
      <div className="relative flex items-center">
        {props.nameIconLeft && (
          <Icon
            icon={props.nameIconLeft}
            iconSize={16}
            className="fill-current text-grey40 absolute bottom-1/3 left-4"
          />
        )}
        {props.type === 'search' && !props.nameIconLeft && (
          <Icon
            icon={IconNames.SEARCH}
            iconSize={16}
            className="fill-current text-grey40 absolute bottom-1/3 left-4"
          />
        )}
        <input
          ref={ref}
          type={inputType}
          name={props.name}
          disabled={props.disabled}
          autoFocus={!!props.autoFocus}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          value={props.value}
          className={clsx(
            'w-full bg-white p-2 focus:outline-none focus:border-transparent',
            (props.type === 'password' || props.nameIconRight) && 'pr-12',
            (props.type === 'search' || props.nameIconLeft) && 'pl-12',
            props.disabled && 'bg-grey5',
            props.defaultBorder && 'border border-grey40 focus:ring-grey40',
            props.defaultRound && 'rounded-sm',
            props.className
          )}
          onChange={props.onChange}
          autoComplete={props.autoComplete}
        />
        {props.type === 'password' && !props.nameIconRight && (
          <button
            type="button"
            tabIndex="-1"
            onClick={toggleShowPassword}
            className="absolute right-4 focus:outline-none focus:border-transparent"
          >
            <Icon
              icon={!showPassword ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
              iconSize={20}
              className={`fill-current text-grey50`}
            />
          </button>
        )}
        {props.nameIconRight && (
          <Icon
            icon={props.nameIconRight}
            iconSize={16}
            className="fill-current text-grey40 absolute bottom-1/3 right-4"
          />
        )}
      </div>
    </div>
  );
});

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  defaultBorder: PropTypes.bool,
  defaultRound: PropTypes.bool,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  nameIconRight: PropTypes.string,
  nameIconLeft: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'search', 'email']),
  value: PropTypes.any,
  autoComplete: PropTypes.string,
};
TextField.defaultProps = {
  name: '',
  defaultBorder: true,
  className: '',
  defaultRound: true,
};

export default TextField;
