import { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useSelector } from 'react-redux';
import Chip from './KeywordChip';

function AdvancedKeywordBar(props) {
  const scrollContainerRef = useRef(null);
  const [arrowShown, setShowArrow] = useState({ left: false, right: false });
  const [shadeShown, setShowShade] = useState({ left: false, right: false });
  const searchKeywords = useSelector(
    ({ textAnalytics }) => textAnalytics.keywords
  );
  const renderKeywordChips = () => {
    return searchKeywords.advanced.reduce((acc, field, index) => {
      const { keyword, operator } = field;
      const processedKeyword = keyword
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => word.trim())
        .filter((word) => word.length > 0)
        .reduce((acc, word, index, arr) => {
          const separatorChip = <Chip key={`separator-${index}`} text="OR" />;
          acc = acc.concat(word);
          acc = index !== arr.length - 1 ? acc.concat(separatorChip) : acc;
          return acc;
        }, []);
      acc = operator
        ? acc.concat(
            <Chip key={`operator-${index}`} type="operator" text={operator} />
          )
        : acc;
      acc =
        processedKeyword.length > 0
          ? acc.concat(
              <Chip key={`word-${index}`} type="word" text={processedKeyword} />
            )
          : acc;
      return acc;
    }, []);
  };
  const chips = renderKeywordChips();
  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const currentScrollPos = scrollContainer.scrollLeft;
      if (direction === 'left')
        scrollContainer.scrollLeft = currentScrollPos + 20;
      else scrollContainer.scrollLeft = currentScrollPos - 20;
    }
  };
  const showArrowShade = useCallback(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const { scrollLeft, scrollWidth, offsetWidth } = scrollContainer;
      if (scrollLeft > 0) {
        setShowArrow((prev) => ({ ...prev, left: true }));
        setShowShade((prev) => ({ ...prev, left: true }));
        // if scroll maxed out
        if (scrollLeft === scrollWidth - offsetWidth)
          setShowShade((prev) => ({ ...prev, right: false }));
        else setShowShade((prev) => ({ ...prev, right: true }));
      } else {
        setShowArrow((prev) => ({ ...prev, left: false }));
        setShowShade((prev) => ({ ...prev, left: false }));
      }
    }
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      scrollContainer.addEventListener('scroll', showArrowShade);
      return () => {
        scrollContainer.removeEventListener('scroll', showArrowShade);
      };
    }
  }, [showArrowShade, props.isOpen]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const { clientWidth, scrollWidth } = scrollContainer;
      if (clientWidth < scrollWidth) {
        setShowArrow((prev) => ({ ...prev, right: true }));
        setShowShade((prev) => ({ ...prev, right: true }));
      } else {
        setShowArrow((prev) => ({ ...prev, right: false }));
        setShowShade((prev) => ({ ...prev, right: false }));
      }
    }
  }, [searchKeywords, props.isOpen]);

  return (
    <div className="flex items-center">
      {arrowShown.left && (
        <button
          onClick={() => handleScroll('right')}
          className="flex justify-center items-center -ml-2 mr-1"
        >
          <Icon
            icon={IconNames.CHEVRON_LEFT}
            iconSize={14}
            className="fill-current text-grey80"
          />
        </button>
      )}
      <div className="h-9 overflow-hidden relative">
        {shadeShown.left && (
          <span className="block h-9 w-5 absolute bg-gradient-to-r from-white"></span>
        )}
        {shadeShown.right && (
          <span className="block h-9 w-5 absolute right-0 bg-gradient-to-l from-white"></span>
        )}
        {chips.length > 0 ? (
          <div ref={scrollContainerRef} className="h-14 pt-2 overflow-x-scroll">
            {chips}
          </div>
        ) : (
          <span className="text-grey50 block mt-2">
            Enter your keyword by clicking Add
          </span>
        )}
      </div>
      <div className="flex items-center">
        {arrowShown.right && (
          <button
            onClick={() => handleScroll('left')}
            className="flex justify-center items-center ml-1"
          >
            <Icon
              icon={IconNames.CHEVRON_RIGHT}
              iconSize={14}
              className="fill-current text-grey80 mr-1"
            />
          </button>
        )}
        {!props.isOpen && (
          <button
            onClick={props.onTogglePopover}
            className={clsx(
              'flex justify-center items-center',
              arrowShown.right ? 'ml-2' : 'ml-6'
            )}
          >
            <Icon
              icon={IconNames.PLUS}
              iconSize={14}
              className="fill-current text-blue50 mr-1"
            />
            <span className="font-bold text-sm text-blue50">Add</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default AdvancedKeywordBar;
