import { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import useVisible from '../../hooks/useVisible';

function SelectInstitution(props) {
  const { ref, isVisible, setIsVisible } = useVisible();
  const [selected, setSelected] = useState('');
  const [input, setInput] = useState('');
  const handleChange = ({ target }) => {
    setInput(target.value);
    props.onSearch(target.value);
  };
  const handleSelect = (item) => {
    setSelected(item);
    setIsVisible(false);
    props.onItemSelected(item);
  };
  const renderItems = () => {
    const listClassName =
      'flex items-center py-1 px-1.5 mb-1 rounded text-left text-base text-grey60 cursor-pointer hover:bg-grey0';
    const filtered = props.items.filter((item) =>
      item.toLowerCase().includes(input.toLowerCase())
    );
    if (!props.loading && input === '' && filtered.length === 0)
      return (
        <li className={listClassName}>Please type your institution name</li>
      );
    if (props.loading) return <li className={listClassName}>Loading</li>;
    if (filtered.length > 0)
      return filtered.map((item, index) => (
        <li
          key={`institution-${index + 1}`}
          onClick={() => handleSelect(item)}
          className={clsx(
            'flex items-center py-1 px-1.5 mb-1 rounded text-left text-base text-grey60 cursor-pointer hover:bg-grey0',
            selected === item && 'bg-grey0'
          )}
        >
          {input.length > 0 ? (
            <Highlighter
              highlightClassName="text-grey100 bg-transparent"
              searchWords={[input]}
              textToHighlight={item}
            />
          ) : (
            item
          )}
        </li>
      ));
    return <li className={listClassName}>No institution found</li>;
  };

  return (
    <div ref={ref} className="relative">
      <input name="institution" type="hidden" ref={props.register} />
      <div
        className={clsx(
          'bg-grey5 w-full rounded-md px-3 flex items-center cursor-default',
          selected ? 'py-2' : 'h-10'
        )}
        onClick={() => setIsVisible(!isVisible)}
      >
        <span className="block">{selected}</span>
        <span
          className={clsx(
            'absolute right-3 transition-transform transform',
            isVisible ? 'rotate-180' : 'rotate-0'
          )}
        >
          <Icon
            icon={IconNames.CHEVRON_DOWN}
            iconSize={18}
            className="fill-current text-grey60"
          />
        </span>
      </div>
      {isVisible && (
        <div className="absolute top-10 bg-white rounded-md shadow-md p-2 w-full mb-4 z-10">
          <div className="w-full flex items-center">
            <span className="absolute left-5 -mt-2">
              <Icon
                icon={IconNames.SEARCH}
                iconSize={16}
                className="fill-current text-grey30"
              />
            </span>
            <input
              onChange={handleChange}
              value={input}
              type="text"
              autoFocus
              className="bg-grey0 w-full rounded border border-grey10 h-10 pl-9 mb-1.5 focus:border-blue50 focus:ring-0"
            />
          </div>
          <ul className="max-h-44 overflow-y-auto p-1 w-full">
            {renderItems()}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SelectInstitution;
