import { useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'components/Dialog';
import { currencyFormatter } from '../../utils/currencyFormatter';
import { createLoadingSelector } from '../../redux/api/loading';
import Spinner from 'components/Spinner';
import { upperFirst } from 'lodash';
import { checkMidtransStatus, getPayment } from 'redux/features/userPayment';
import String from 'utils/String';

export default function DialogDetailInvoiceQD({
  showDialog,
  setShowDialog,
  invoiceId,
}) {
  const dispatch = useDispatch();
  const selectedInvoice = useSelector(
    ({ userPayment }) => userPayment.quantitative.selected
  );
  const loading = useSelector(
    createLoadingSelector([getPayment.type, checkMidtransStatus.type])
  );
  const dataWithLabel = [
    {
      key: 'Email',
      value: selectedInvoice.user ? selectedInvoice.user.createdEmail : '-',
    },
    {
      key: 'Payment Method',
      value: selectedInvoice.payment.paymentMethod
        ? String.parsePaymentMethod(selectedInvoice.payment.paymentMethod)
        : '-',
    },
    ...(selectedInvoice.payment && selectedInvoice.payment.vaNumber
      ? [
          {
            key: 'Virtual Account Number',
            value: selectedInvoice.payment.vaNumber,
          },
        ]
      : []),
    ...(selectedInvoice.statusPaid
      ? [
          {
            key: 'Discount',
            value:
              (selectedInvoice.discount && selectedInvoice.discount.name) ||
              '-',
          },
        ]
      : []),
    {
      key: 'Order Status',
      value: upperFirst(selectedInvoice.orderStatus),
    },
    ...(selectedInvoice.referral
      ? [
          {
            key: 'Referral',
            value: selectedInvoice.referral.name,
          },
        ]
      : []),
  ];
  const getPrice = useCallback(
    (dataVariable) =>
      dataVariable.classification
        ? dataVariable.classification.price || 0
        : dataVariable.price || 0,
    []
  );
  useEffect(() => {
    if (showDialog && invoiceId) {
      dispatch(getPayment(invoiceId));
    }
  }, [dispatch, showDialog, invoiceId]);

  return (
    <Dialog
      header={!loading ? `Invoice ${selectedInvoice.invoiceNumber}` : ''}
      size={!loading ? 'large' : 'small'}
      isOpen={showDialog}
      onClose={() => {
        setShowDialog({
          show: false,
          id: null,
        });
      }}
      dismissButton
    >
      <div className="w-full px-4 pb-4">
        {loading && (
          <div className="w-full flex justify-center pt-7">
            <Spinner />
          </div>
        )}
        {!loading && (
          <>
            <div className="mb-5">
              <p className="text-sm text-grey50">
                {`Created on ${moment
                  .tz(selectedInvoice.createdAt, 'Asia/Bangkok')
                  .format('DD MMM YYYY, HH:mm')} WIB`}
              </p>
            </div>
            {selectedInvoice.orderStatus === 'processing' && (
              <p className="h-10 mb-5 bg-red10 text-red60 text-sm flex justify-center items-center">
                {`Pay before  ${moment
                  .tz(selectedInvoice.dueDate, 'Asia/Bangkok')
                  .format('DD MMM YYYY, HH:mm')} WIB`}
              </p>
            )}
            <div className="flex justify-between items-center w-full mb-5">
              <div className="flex flex-wrap">
                {dataWithLabel.map((data, index) => (
                  <div key={`dummy-${index}`} className="mr-10 mb-6">
                    <p className="text-sm font-normal text-gray50 mb-2">
                      {data.key}
                    </p>
                    <p className="text-sm text-grey100 font-medium">
                      {data.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full shadow">
              <table className="w-full">
                <thead>
                  <tr
                    style={{
                      backgroundColor: '#f7f7fa',
                    }}
                    className="p-2"
                  >
                    <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium">
                      Description
                    </td>
                    <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                      Total Data
                    </td>
                    <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                      Base Price
                    </td>
                    <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                      SubTotal
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {selectedInvoice.orders_details.map((item, index) => (
                    <tr key={`data-item-${index}`}>
                      <td className="w-3/5 px-5 py-3 text-grey90 text-xs">
                        <ul className="list-disc ml-3">
                          <li>
                            <span className="block text-grey100">
                              {item.variable ? item.variable.name : '-'}
                            </span>
                            {/* NOTE: Handle year range field (prev. implementation) */}
                            {item.yearRange && (
                              <span className="block text-grey50">
                                Year range:{' '}
                                {Array.isArray(item.yearRange)
                                  ? `${item.yearRange[0]} - ${item.yearRange[1]}`
                                  : '-'}
                              </span>
                            )}
                            {item.years && (
                              <span className="block text-grey50">
                                Years:{' '}
                                {Array.isArray(item.years)
                                  ? `${item.years.join(', ')}`
                                  : '-'}
                              </span>
                            )}
                            <span className="block text-grey50">
                              Company code:{' '}
                              {Array.isArray(item.companyCodes)
                                ? `${
                                    item.companyCodes[0] === '*'
                                      ? 'All Companies'
                                      : item.companyCodes.join(', ')
                                  }`
                                : '-'}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                        {item.totalObservation}
                      </td>
                      <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                        {item.variable &&
                          currencyFormatter.format(getPrice(item.variable))}
                      </td>
                      <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                        {item.variable &&
                          currencyFormatter.format(
                            item.totalObservation * getPrice(item.variable)
                          )}
                      </td>
                    </tr>
                  ))}
                  {(selectedInvoice.voucher || selectedInvoice.referral) && (
                    <tr className="border-t border-grey5">
                      <td
                        colSpan="3"
                        className="px-5 py-3 text-grey90 text-sm text-right"
                      >
                        Subtotal
                      </td>
                      <td className="px-5 py-3 text-grey90 text-sm text-right">
                        {currencyFormatter.format(selectedInvoice.grossPrice)}
                      </td>
                    </tr>
                  )}
                  {selectedInvoice.voucher && (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-5 py-3 text-grey90 text-sm text-right"
                      >
                        Discount {selectedInvoice.voucher.name}
                        {selectedInvoice.voucher.type === 'percentage' &&
                          `: ${selectedInvoice.voucher.value}%`}
                      </td>
                      <td className="px-5 py-3 text-red50 text-sm text-right">
                        {`- ${currencyFormatter.format(
                          selectedInvoice.voucher.type === 'fixed'
                            ? selectedInvoice.voucher.value
                            : `${
                                (selectedInvoice.voucher.value / 100) *
                                selectedInvoice.grossPrice
                              }`
                        )}`}
                      </td>
                    </tr>
                  )}
                  {selectedInvoice.referral && (
                    <tr>
                      <td
                        colSpan="3"
                        className="px-5 py-3 text-grey90 text-sm text-right"
                      >
                        Discount {selectedInvoice.referral.name}
                        {selectedInvoice.referral.type === 'percentage' &&
                          `: ${selectedInvoice.referral.value}%`}
                      </td>
                      <td className="px-5 py-3 text-red50 text-sm text-right">
                        {`- ${currencyFormatter.format(
                          selectedInvoice.referral.type === 'fixed'
                            ? selectedInvoice.referral.value
                            : `${
                                selectedInvoice.grossPrice *
                                (1 -
                                  (selectedInvoice.voucher
                                    ? selectedInvoice.voucher.value / 100
                                    : 0)) *
                                (selectedInvoice.referral.value / 100)
                              }`
                        )}`}
                      </td>
                    </tr>
                  )}
                  <tr
                    style={{
                      backgroundColor: '#f7f7fa',
                    }}
                    className="p-2"
                  >
                    <td
                      colSpan="3"
                      className="px-5 py-3 text-grey100 text-sm  font-semibold text-right"
                    >
                      Total
                    </td>
                    <td className="px-5 py-3 text-grey100 text-sm font-semibold text-right">
                      {currencyFormatter.format(selectedInvoice.netPrice)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}
