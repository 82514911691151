import PropTypes from 'prop-types';
import { ReactComponent as Loader } from '../../assets/images/loading.svg';

export default function Spinner(props) {
  return <Loader {...props} />;
}

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: 'h-10 w-10 fill-current text-grey30',
};
