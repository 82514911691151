import { useState, useRef } from 'react';
import { Icon } from '@blueprintjs/core';
import Dialog from 'components/Dialog';
import { IconNames } from '@blueprintjs/icons';
import { debounce } from 'lodash';
import Checkbox from './Checkbox';

function CompaniesDialog(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [appliedSearch, setAppliedSearch] = useState('');
  const applySearch = (searchTerm) => {
    setAppliedSearch(searchTerm);
  };
  const handleSearch = useRef(debounce(applySearch, 300)).current;
  const handleChange = (e) => {
    const { value: searchInput } = e.target;
    setSearchTerm(searchInput);
    if (searchInput) handleSearch(searchInput);
  };
  const renderCompanies = () => {
    const data = appliedSearch
      ? props.data.filter((company) => {
          const lowerCaseSearch = appliedSearch.toLowerCase();
          return (
            company.name.toLowerCase().includes(lowerCaseSearch) ||
            company.ticker.toLowerCase().includes(lowerCaseSearch)
          );
        })
      : props.data;
    if (data.length > 0)
      return (
        <div className="flex flex-wrap">
          {data.map((company) => {
            return (
              <div key={company.id} className="w-1/3 mb-2">
                <Checkbox
                  label={company.ticker}
                  checked={true}
                  disabled={true}
                />
              </div>
            );
          })}
        </div>
      );
    else {
      return <span>No companies found</span>;
    }
  };

  return (
    <Dialog
      header="More Companies"
      isOpen={props.isOpen}
      onClose={props.onClose}
      dismissButton
    >
      <div className="flex flex-col h-full w-full px-4">
        <div className="pb-4 flex justify-between items-center">
          <div className="relative flex items-center w-2/3 flex-grow mr-2">
            <span className="absolute left-0 ml-2 rounded-md">
              <Icon
                icon={IconNames.SEARCH}
                iconSize={16}
                className="fill-current text-grey30"
              />
            </span>
            <input
              type="text"
              name="search"
              placeholder="Search companies"
              autoComplete="off"
              value={searchTerm}
              onChange={handleChange}
              className="h-10 w-full bg-grey0 border-0 rounded-md pl-10 focus:outline-none focus:ring-transparent"
            />
          </div>
        </div>
        <div className="h-56 w-full pt-1 overflow-y-auto">
          {renderCompanies()}
        </div>
      </div>
    </Dialog>
  );
}

export default CompaniesDialog;
