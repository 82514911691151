import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { currencyFormatter } from 'utils/currencyFormatter';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import Voucher from 'assets/svg/discount_white.svg';
import Button from 'components/Button';
import { createLoadingSelector } from 'redux/api/loading';
import { getDetailQDVoucher } from 'redux/features/userSelectVariable';
import { showToastSuccess } from 'layouts/DashboardLayout';
import PATH_URL from 'routers/path';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';

const UserVoucherDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const onGetDetailVocher = useSelector(
    createLoadingSelector([getDetailQDVoucher.type])
  );
  const detailVoucher = useSelector(
    ({ userSelectVariable }) => userSelectVariable.detailQDVoucher
  );
  useEffect(() => {
    dispatch(getDetailQDVoucher(params.id));
  }, [dispatch, params.id]);
  return (
    <div className="w-full mt-8">
      <div className="w-full mb-10 flex flex-col items-center">
        <div className="lg:w-10/12 w-11/12 p-1">
          <h4 className="text-grey70 text-lg font-bold mb-4">
            Voucher Details
          </h4>
          <div className="flex justify-between items-start flex-wrap">
            <div className="w-full md:w-2/3 md:pr-2 mb-5 md:mb-0">
              <div className="border border-gray30 rounded-lg overflow-hidden ">
                <div className="p-4 bg-blue80">
                  <div className="flex mb-3 items-center">
                    <img
                      src={Voucher}
                      className="mr-2 h-5 w-5"
                      alt="voucher-icon"
                    />
                    {onGetDetailVocher ? (
                      <Skeleton width={150} />
                    ) : (
                      <p className="text-base font-medium text-white">
                        {detailVoucher.name}
                      </p>
                    )}
                  </div>
                  {onGetDetailVocher ? (
                    <Skeleton width={200} />
                  ) : (
                    <p className="text-white text-xs font-medium">
                      {detailVoucher.description}
                    </p>
                  )}
                </div>
                <div className="px-4 py-5">
                  <p className="text-gray100 text-sm font-semibold mb-3">
                    Terms and Condition
                  </p>
                  {onGetDetailVocher ? (
                    <Skeleton width="100%" height={200} />
                  ) : (
                    <div className={clsx('text-gray70 text-sm')}>
                      {detailVoucher.termsConditions && (
                        <ol className="list-decimal list-inside block w-full">
                          {detailVoucher.termsConditions
                            .split('\n')
                            .map((tnc, i) => (
                              <li key={i} className="text-xs text-gray70 mb-1">
                                {tnc}
                              </li>
                            ))}
                        </ol>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 md:pl-2">
              <div className="border border-gray30 rounded-md px-5 py-4">
                <p className="text-sm mb-3 font-semibold">Vouchers Detail</p>
                {onGetDetailVocher ? (
                  <>
                    <Skeleton width="100%" height={25} />
                    <Skeleton width="100%" height={25} />
                  </>
                ) : (
                  <>
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center">
                        <Icon
                          icon={IconNames.TIME}
                          iconSize={18}
                          className="fill-current text-grey50 mr-3"
                        />
                        <p className="text-xs">End date</p>
                      </div>
                      <p className="text-sm font-medium">
                        {moment(
                          detailVoucher.endDate,
                          'YYYY-MM-DD HH:mm:ss Z'
                        ).format('D MMM YYYY')}
                      </p>
                    </div>
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center">
                        <Icon
                          icon={IconNames.SHOPPING_CART}
                          iconSize={18}
                          className="fill-current text-grey50 mr-3"
                        />
                        {detailVoucher.minimumPurchase && (
                          <p className="text-xs">Min. purchase</p>
                        )}
                      </div>

                      <p className="text-sm font-medium">
                        {!detailVoucher.minimumPurchase
                          ? 'No minimum purchase'
                          : currencyFormatter.format(
                              detailVoucher.minimumPurchase
                            )}
                      </p>
                    </div>
                  </>
                )}

                <Button
                  color="primary"
                  size="small"
                  className="w-full mt-6 h-11"
                  onClick={() => {
                    navigator.clipboard.writeText(detailVoucher.name);
                    showToastSuccess('Voucher code copied successfully');
                    history.push(
                      `${PATH_URL.SELECT_VARIABLE}?voucher=${detailVoucher.name}`
                    );
                  }}
                >
                  Use Voucher Code
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVoucherDetail;
