import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/app';
import Button from 'components/Button';
import { showToastSuccess, showToastError } from 'layouts/DashboardLayout';
import TextField from '../../../components/TextField';

const passwordPattern = Yup.string()
  .min(8, 'Password must be at least 8 characters')
  .matches(/[A-Z]/i, 'Password must contain at least one letter')
  .matches(/\d/, 'Password must contain at least one number')
  .required('Password is required');

const schema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: passwordPattern,
  newConfirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword')],
    'Confirmation password not match'
  ),
});

export default function Password() {
  const [loading, setLoading] = useState(false);
  const [passwordMode, changePasswordMode] = useState({
    oldPassword: 'password',
    newPassword: 'password',
    newConfirmPassword: 'password',
  });

  const { reset, register, handleSubmit, errors } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const oldPassword = errors?.oldPassword?.message;
  const passwordError = errors?.newPassword?.message;
  const confirmPasswordError = errors?.newConfirmPassword?.message;

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { newPassword, oldPassword } = data;
      const user = firebase.auth().currentUser;
      // Check existing user
      await firebase.auth().signInWithEmailAndPassword(user.email, oldPassword);
      await user.updatePassword(newPassword);
      showToastSuccess('Your password has been updated successfully');
      setLoading(false);
      reset(
        {
          oldPassword: '',
          newPassword: '',
          newConfirmPassword: '',
        },
        {
          isDirty: false,
        }
      );
      changePasswordMode({
        oldPassword: 'password',
        newPassword: 'password',
        newConfirmPassword: 'password',
      });
    } catch (e) {
      if (
        e.code === 'auth/user-not-found' ||
        e.code === 'auth/wrong-password'
      ) {
        showToastError('Old password does not match');
        setLoading(false);
        return false;
      }
      showToastError('Your password failed to be updated');
      setLoading(false);
    }
  };

  return (
    <div className="w-full">
      <p className="mb-5 text-base text-grey50">
        To change your password, you'll need to verify the current one first and
        be sure change it regularly as well as anytime you specify it's been
        compromised
      </p>
      <div className={'mb-5'}>
        <label htmlFor="currentPassword" className="text-grey60 text-sm mb-1">
          Confirm your current password
        </label>
        <TextField
          id={'oldPassword'}
          type={passwordMode.oldPassword}
          name="oldPassword"
          ref={register}
          defaultBorder={false}
          className="border border-blue50 rounded w-full p-2 px-3 mt-1.5 focus:ring-grey40 focus:outline-none focus:border-blue50"
        />
        {oldPassword && (
          <p className="text-xs text-red50 mt-px pt-1">{oldPassword}</p>
        )}
      </div>
      <div className={'mb-5'}>
        <label htmlFor="currentPassword" className="text-grey60 text-sm mb-1">
          Enter your new password
        </label>
        <TextField
          id={'newPassword'}
          type={passwordMode.newPassword}
          name={'newPassword'}
          ref={register}
          defaultBorder={false}
          className="border border-blue50 rounded w-full p-2 px-3 mt-1.5 focus:ring-grey40 focus:outline-none focus:border-blue50"
        />
        {passwordError && (
          <p className="text-xs text-red50 mt-px pt-1">{passwordError}</p>
        )}
      </div>
      <div className={'mb-5'}>
        <label htmlFor="currentPassword" className="text-grey60 text-sm mb-1">
          Retype new password
        </label>
        <TextField
          id={'newConfirmPassword'}
          type={passwordMode.newConfirmPassword}
          name={'newConfirmPassword'}
          ref={register}
          defaultBorder={false}
          className="border border-blue50 rounded w-full p-2 px-3 mt-1.5 focus:ring-grey40 focus:outline-none focus:border-blue50"
        />

        {confirmPasswordError && (
          <p className="text-xs text-red50 mt-px pt-1">
            {confirmPasswordError}
          </p>
        )}
      </div>
      <Button
        color="primary"
        onClick={handleSubmit(onSubmit)}
        className="mt-6"
        isLoading={loading}
      >
        Change Password
      </Button>
    </div>
  );
}
