import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/esg-dataset-logo.png';
import { Link } from 'react-router-dom';
import DashboardMenu from './DashboardMenu';
import clsx from 'clsx';
import ProfileInformation from './ProfileInformation';
import DashboardLayout from './index';
import PATH_URL from '../../routers/path';
import FreetrialInformation from './FreetrialInformation';
import {
  userHasActiveFreeTrial,
  userSubsActiveTextAnalytics,
} from '../../redux/features/auth';
import { getDueDays } from '../../utils/date';
import FloatHelp from 'components/FloatHelp';

function DashboardUser(props) {
  const userData = useSelector((state) => state.auth);
  const role = useSelector((state) => state.auth.role);
  const userLastInvoice = useSelector(
    (state) => state.userManagement.userLastInvoice
  );
  const subsActive = useSelector(userSubsActiveTextAnalytics);
  const typeInformation = () => {
    if (!userData.emailVerified) {
      return 'unVerified';
    } else if (
      new Date().getTime() < new Date(userLastInvoice.dueDate) &&
      userLastInvoice.orderStatus === 'processing'
    ) {
      return 'unPaid';
    } else if (
      subsActive &&
      subsActive.expireAt &&
      getDueDays(subsActive.expireAt) > -1 &&
      getDueDays(subsActive.expireAt) <= 3
    ) {
      return 'expired';
    }
    return '';
  };
  const freeTrialActive = useSelector(userHasActiveFreeTrial);
  return (
    <DashboardLayout>
      <div className="flex flex-col w-full min-h-screen">
        <div className="sticky top-0 z-20">
          {freeTrialActive && (
            <FreetrialInformation isAdmin={role === 'admin'} />
          )}
          <nav
            className={clsx(
              'flex w-full px-6 items-center justify-between h-16 bg-white text-grey70 shadow rounded'
            )}
          >
            <div className="w-full  flex items-center justify-between">
              <div className="w-full flex items-center">
                <Link
                  to={PATH_URL.HOME}
                  className="text-base font-bold text-blue-cesgs flex items-center focus:outline-none"
                >
                  <img src={logo} alt="cesgs logo" className="h-10 w-30 mr-2" />
                </Link>
              </div>
            </div>
            <DashboardMenu isAdmin={role === 'admin'} />
          </nav>
          {!!typeInformation() && (
            <ProfileInformation typeInformation={typeInformation()} />
          )}
        </div>
        <div className={clsx('flex flex-grow bg-white')}>{props.children}</div>
      </div>
      <FloatHelp />
    </DashboardLayout>
  );
}

export default DashboardUser;
