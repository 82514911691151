import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import {
  getDirectReportXlsx,
  getDirectReportPdf,
} from 'redux/features/textAnalytics';
import { actionFailure } from 'redux/utils/actionCreator';
import { createLoadingSelector } from 'redux/api/loading';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function autofitWidth(worksheet, minWidth = 10) {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minWidth,
        cell.value ? cell.value.toString().length : 0
      );
    });
    column.width = maxColumnLength + 2;
  });
}

function addBorder(worksheet) {
  worksheet.eachRow(function (row, num) {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });
}

function DownloadQueryDialog(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const searchDetail = useSelector((state) => state.textAnalytics);
  const loadingXlsx = useSelector(
    createLoadingSelector([getDirectReportXlsx.type])
  );
  const loadingPdf = useSelector(
    createLoadingSelector([getDirectReportPdf.type])
  );
  const handleDownloadXlsx = async () => {
    const { payload, type: actionType } = await dispatch(getDirectReportXlsx());
    if (actionType === actionFailure(getDirectReportXlsx.type)) return;
    const { stats: statsPayload, data: sentencePayload } = payload;
    const {
      keywords,
      filter: { yearRange, documentSections, companies },
    } = searchDetail;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('Summary Query');
    workbook.addWorksheet('Query Result Statistics');
    workbook.addWorksheet('Notes');
    let worksheet = workbook.getWorksheet(1);
    worksheet.columns = [
      { key: 'A', width: 10 },
      { key: 'B', width: 102 },
    ];
    worksheet.addRows([
      { A: 'Account', B: user.email },
      { A: 'Keywords', B: keywords.symbols },
      { A: 'Filter Period', B: `${yearRange.from}-${yearRange.to}` },
      {
        A: 'Filter Documents Section',
        B:
          !documentSections.isWhole && documentSections.selected.length > 0
            ? Object.values(documentSections.selectedNames).join(', ')
            : 'Whole documents',
      },
      {
        A: 'Filter Companies',
        B:
          companies.selected.length > 0
            ? companies.selected.map((company) => company.ticker).join(', ')
            : 'All companies',
      },
      { A: 'Download time', B: moment().format('D MMMM YYYY, HH:mm') },
    ]);
    addBorder(worksheet);
    autofitWidth(worksheet);
    worksheet = workbook.getWorksheet(2);
    const cellStyles = { alignment: { vertical: 'middle' } };
    worksheet.columns = [
      { key: 'ticker', header: 'Ticker', style: cellStyles },
      { key: 'sic', header: 'SIC', style: cellStyles },
      { key: 'year', header: 'Year', style: cellStyles },
      { key: 'documentType', header: 'Document Type', style: cellStyles },
      // { key: 'Section', header: 'Section' },
      {
        key: 'keywords',
        header: 'Total Keywords',
        style: { alignment: { wrapText: true } },
      },
      { key: 'totalWords', header: 'Total Words', style: cellStyles },
      {
        key: 'totalWordsNonNumeric',
        header: 'Total Words without numeric',
        style: cellStyles,
      },
      { key: 'allSentences', header: 'All Sentences', style: cellStyles },
      {
        key: 'includingKeywords',
        header: 'Sentences including keywords',
        style: cellStyles,
      },
    ];
    worksheet.addRows(statsPayload);
    addBorder(worksheet);
    autofitWidth(worksheet);
    worksheet = workbook.getWorksheet(3);
    const styling = {
      alignment: { vertical: 'middle', horizontal: 'center' },
    };
    worksheet.columns = [
      {
        key: 'ticker',
        header: 'Ticker',
        style: styling,
      },
      {
        key: 'year',
        header: 'Year',
        style: styling,
      },
      {
        key: 'keywords',
        header: 'Total Keywords',
        width: 20,
        style: { alignment: { ...styling.alignment, wrapText: true } },
      },
      {
        key: 'sentences',
        header: 'Sentences',
        width: 70,
        style: {
          alignment: { vertical: 'middle', wrapText: true },
        },
      },
    ];
    worksheet.addRows(sentencePayload.data);
    addBorder(worksheet);
    sentencePayload.mergeString.forEach((mergeStr) =>
      worksheet.mergeCells(mergeStr)
    );
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(
      blob,
      `Search_result-${moment().format('D-MMMM-YYYY-HH-mm')}.xlsx`
    );
  };
  const handleDownloadPDF = async () => {
    const { payload, type: actionType } = await dispatch(getDirectReportPdf());
    if (actionType === actionFailure(getDirectReportPdf.type)) return;
    const { stats: statsPayload, data: sentencePayload } = payload;
    const {
      keywords,
      filter: { yearRange, documentSections, companies },
    } = searchDetail;
    const docDefinition = {
      content: [
        {
          text: 'Summary Query',
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        {
          table: {
            widths: [140, '*'],
            body: [
              ['Account', user.email],
              ['Keywords', keywords.symbols],
              ['Filter Period', `${yearRange.from}-${yearRange.to}`],
              [
                'Filter Documents Section',
                !documentSections.isWhole &&
                documentSections.selected.length > 0
                  ? Object.values(documentSections.selectedNames).join(', ')
                  : 'Whole documents',
              ],
              [
                'Filter Companies',
                companies.selected.length > 0
                  ? companies.selected.map((company) => company.name).join(', ')
                  : 'All companies',
              ],
              ['Download time', moment().format('D MMMM YYYY, HH:mm')],
            ],
          },
        },
        {
          text: 'Query Result Statistics',
          fontSize: 18,
          bold: true,
          pageBreak: 'before',
          margin: [0, 0, 0, 10],
          pageOrientation: 'landscape',
        },
        {
          table: {
            body: [
              [
                'Ticker',
                'SIC',
                'Year',
                'Document Type',
                // 'Section',
                'Total Keywords',
                'Total Words',
                'Total Words without numeric',
                'All Sentences',
                'Sentences including keywords',
              ],
              ...statsPayload.map((stat) => [
                stat.ticker,
                stat.sic,
                stat.year,
                stat.documentType,
                stat.keywords,
                stat.totalWords,
                stat.totalWordsNonNumeric,
                stat.allSentences,
                stat.includingKeywords,
              ]),
            ],
          },
        },
        {
          text: 'Notes',
          fontSize: 18,
          bold: true,
          pageBreak: 'before',
          margin: [0, 0, 0, 10],
        },
        {
          table: {
            widths: [80, 80, 90, 120, '*', '*', '*'],
            body: sentencePayload,
          },
        },
      ],
    };
    pdfMake
      .createPdf(docDefinition)
      .download(`Search-result-${moment().format('D-MMMM-YYYY-HH-mm')}.pdf`);
  };
  return (
    <Dialog
      header="Download Option"
      size="small"
      isOpen={props.isOpen}
      onClose={props.onClose}
      dismissButton
    >
      <div className="w-full px-6 flex flex-col">
        <Button
          color="primary"
          onClick={handleDownloadXlsx}
          className="mb-2"
          isLoading={loadingXlsx}
        >
          Download XLSX
        </Button>
        <Button
          view="outlined"
          onClick={handleDownloadPDF}
          isLoading={loadingPdf}
          className="text-grey100"
        >
          Download PDF
        </Button>
      </div>
    </Dialog>
  );
}

export default DownloadQueryDialog;
