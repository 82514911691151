import React from 'react';

function IconDatasets(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2078 5.29375C14.1151 5.2004 14.0048 5.12637 13.8833 5.07595C13.7618 5.02553 13.6315 4.99971 13.5 5H11.5V4.5C11.5 3.57174 11.1313 2.6815 10.4749 2.02513C9.8185 1.36875 8.92826 1 8 1C7.07174 1 6.1815 1.36875 5.52513 2.02513C4.86875 2.6815 4.5 3.57174 4.5 4.5V5H2.5C2.23478 5 1.98043 5.10536 1.79289 5.29289C1.60536 5.48043 1.5 5.73478 1.5 6V12.75C1.5 13.9688 2.53125 15 3.75 15H12.25C12.8395 15.0002 13.4057 14.7697 13.8275 14.3578C14.0398 14.1553 14.2088 13.9119 14.3244 13.6423C14.4399 13.3727 14.4997 13.0824 14.5 12.7891V6C14.5004 5.86881 14.4748 5.73884 14.4246 5.61761C14.3745 5.49638 14.3008 5.38631 14.2078 5.29375ZM5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C8.66304 2 9.29893 2.26339 9.76777 2.73223C10.2366 3.20107 10.5 3.83696 10.5 4.5V5H5.5V4.5ZM11.5 7.5C11.5 8.42826 11.1313 9.3185 10.4749 9.97487C9.8185 10.6313 8.92826 11 8 11C7.07174 11 6.1815 10.6313 5.52513 9.97487C4.86875 9.3185 4.5 8.42826 4.5 7.5V7C4.5 6.86739 4.55268 6.74021 4.64645 6.64645C4.74021 6.55268 4.86739 6.5 5 6.5C5.13261 6.5 5.25979 6.55268 5.35355 6.64645C5.44732 6.74021 5.5 6.86739 5.5 7V7.5C5.5 8.16304 5.76339 8.79893 6.23223 9.26777C6.70107 9.73661 7.33696 10 8 10C8.66304 10 9.29893 9.73661 9.76777 9.26777C10.2366 8.79893 10.5 8.16304 10.5 7.5V7C10.5 6.86739 10.5527 6.74021 10.6464 6.64645C10.7402 6.55268 10.8674 6.5 11 6.5C11.1326 6.5 11.2598 6.55268 11.3536 6.64645C11.4473 6.74021 11.5 6.86739 11.5 7V7.5Z"
        fill="current"
      />
    </svg>
  );
}

export default IconDatasets;
