export function convertFieldToSymbol(fields) {
  return fields.reduce((acc, field) => {
    const { operator, keyword } = field;
    if (keyword.length > 0) {
      const keywordArr = keyword
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => word.trim());
      if (operator === 'AND' || operator === '') {
        let sep = operator ? '+' : '';
        acc += sep + keywordArr.join('/');
      } else {
        acc += ' -' + keywordArr.join('/');
      }
    }
    return acc;
  }, '');
}

export function convertToFields(searchTerm) {
  const keyword = searchTerm
    .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
    .filter((word) => word.length)
    .map((word) => {
      let processedWord = word.trim();
      // if there is space in keyword but no quote in it then quote it
      if (processedWord.includes(' ') && !processedWord.includes('"')) {
        processedWord = `"${processedWord}"`;
      }
      return processedWord;
    })
    .join(', ');
  return [
    {
      operator: '',
      keyword,
    },
  ];
}

export function convertOnelineToSymbol(searchTerm) {
  return searchTerm
    .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
    .map((word) => word.trim())
    .filter((word) => word.length > 0)
    .join('/');
}

export function convertSymbolToFields(searchTerm) {
  const operators = searchTerm.match(/\+| -|^-/g);
  return searchTerm
    .split(/\+| -|^-/)
    .filter((word) => word.length)
    .map((field, index) => {
      const keyword = field
        .split(/\/(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => {
          let processedWord = word.trim();
          // if there is space in keyword but no quote in it then quote it
          if (processedWord.includes(' ') && !processedWord.includes('"')) {
            processedWord = `"${processedWord}"`;
          }
          return processedWord;
        })
        .join(', ');
      return {
        operator:
          index > 0 ? (operators[index - 1] === '+' ? 'AND' : 'ANDNOT') : '',
        keyword,
      };
    });
}

export function convertGroupToSymbol(fields) {
  return Object.keys(fields).reduce((acc, fieldName) => {
    const keywordArr = fields[fieldName].map((word) => {
      // if there is space in keyword but no quote in it then quote it
      if (word.includes(' ') && !word.includes('"')) {
        word = `"${word}"`;
      }
      return word;
    });
    if (keywordArr.length > 0) {
      if (['andKeywords', 'exactKeywords', 'orKeywords'].includes(fieldName)) {
        let sep = '+';
        if (fieldName === 'andKeywords') sep = '';
        if (fieldName === 'orKeywords') acc += sep + keywordArr.join('/');
        else acc += sep + keywordArr.join('+');
      } else {
        acc += ' ' + keywordArr.map((word) => '-' + word).join(' ');
      }
    }
    return acc;
  }, '');
}

export function convertOnelineToItems(searchTerm) {
  return searchTerm
    .split(/\+| -|^-/)
    .filter((word) => word.length)
    .reduce((acc, chunk) => {
      acc = acc.concat(
        chunk
          .split(/\/(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
          .map((word) => word.trim().replaceAll('"', ''))
      );
      return acc;
    }, []);
}

export function convertFieldsToItems(fields) {
  return fields.reduce((acc, field) => {
    const { operator, keyword } = field;
    if (keyword.length > 0) {
      const keywordArr = keyword
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => word.trim().replaceAll('"', ''));
      if (operator === 'AND' || operator === '') {
        acc = acc.concat(keywordArr);
      }
    }
    return acc;
  }, []);
}

export function validateOneline(keywords) {
  const quotes = keywords.match(/"/g) || [];
  const splitted = keywords.split(',').filter((word) => word.length);
  return (
    quotes.length % 2 === 0 && splitted.length > 0 && splitted.length <= 15
  );
}

export function validateFields(fields) {
  let isValid = true;
  for (let i = 0; i < fields.length; i++) {
    const current = fields[i];
    // if keyword or operator in second index of field is empty
    if (current.keyword === '' || (i > 0 && current.operator === '')) {
      isValid = false;
      break;
    }
    // if number of quotes present is odd
    if ((current.keyword.match(/"/g) || []).length % 2 !== 0) {
      isValid = false;
      break;
    }
  }
  return isValid;
}

export function validateGroupedKeywords(keywordsData) {
  return Object.keys(keywordsData).map((keywordField) => {
    const quotes = keywordsData[keywordField].match(/"/g) || [];
    return quotes.length % 2 === 0;
  });
}

export function parseGroupedKeywords(keywordsData) {
  const parsed = Object.keys(keywordsData).map((keywordField) => {
    const currentKeywords = keywordsData[keywordField];
    // if keywords string is not contain any quotes
    if (currentKeywords.indexOf('"') === -1) {
      return currentKeywords
        .split(',')
        .map((word) => word.trim())
        .filter((word) => word.length > 0);
    } else {
      // split keywords by comma but not in quoted words
      return currentKeywords
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => word.trim())
        .filter((word) => word.length > 0);
    }
  });
  return parsed;
}
