import clsx from 'clsx';
import GoldCrown from 'assets/svg/crown_gold.svg';
import SilverCrown from 'assets/svg/crown_silver.svg';
import BronzeCrown from 'assets/svg/crown_bronze.svg';

const CLASSIFICATION_ICONS = {
  gold: GoldCrown,
  silver: SilverCrown,
  bronze: BronzeCrown,
};
const IconClasification = ({ type, className = '' }) => (
  <img
    src={
      CLASSIFICATION_ICONS[String(type).toLowerCase()] ||
      CLASSIFICATION_ICONS['bronze']
    }
    alt={'CompanyVariableDetail'}
    className={clsx('h-4 w-4', className)}
  />
);

export default IconClasification;
