import { Link, useLocation } from 'react-router-dom';
import Cart from 'assets/svg/user_failed_get_data.svg';
import Button from 'components/Button';
import PATH_URL from 'routers/path';
import { useDispatch } from 'react-redux';
import { getPayment } from 'redux/features/userPayment';
import qs from 'qs';

export default function Failed() {
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <div className={'w-full flex flex-col items-center mt-8'}>
      <img src={Cart} alt="Failed to get order data" />
      <div
        className={'text-center flex flex-col items-center'}
        style={{ width: '430px' }}
      >
        <p className={'text-grey100 text-2xl font-bold mt-4'}>
          Failed to get your order data
        </p>
        <p className={'text-xl text-grey50 mt-3 pb-4'}>
          You can try again or go directly to your billing history to view order
          details
        </p>
        <Button
          color="primary"
          onClick={() => {
            if (location.search.includes('order_id')) {
              const paramsObj = qs.parse(location.search.slice(1));
              const { order_id: invoiceNumber } = paramsObj;
              dispatch(getPayment(invoiceNumber, 1000));
            }
          }}
          className="mt-3"
        >
          Try to get my order detail
        </Button>
        <Link
          to={PATH_URL.USER_PROFILE}
          className="text-sm text-blue50 mt-2 hover:text-blue50 hover:no-underline"
        >
          Go to billing history
        </Link>
      </div>
    </div>
  );
}
