import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Radio = forwardRef((props, ref) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        ref={ref}
        type="radio"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className="mr-2 focus:outline-none focus:ring-0 focus:border-transparent"
        checked={props.checked}
      />
      {typeof props.label === 'string' ? (
        <span>{props.label}</span>
      ) : (
        props.label
      )}
    </label>
  );
});

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

Radio.defaultProps = {
  onChange: () => {},
};

export default Radio;
