import FloatHelp from 'components/FloatHelp';
import { useLocation } from 'react-router';

const HIDDEN_FLOAT_URLS = ['/search-detail'];

function FullLayout(props) {
  const { pathname } = useLocation();
  const isVisible =
    HIDDEN_FLOAT_URLS.filter((url) => pathname.includes(url)).length === 0;
  return (
    <div className="h-screen overflow-hidden">
      {props.children}
      {isVisible && <FloatHelp />}
    </div>
  );
}

export default FullLayout;
