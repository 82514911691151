import { useEffect, useState } from 'react';
import moment from 'moment';
import { getUserLastInvoices } from 'redux/features/userManagement';
import { updateInvoiceStatus } from 'redux/features/userPayment';
import { createLoadingSelector } from 'redux/api/loading';
import BCAVa from './VA/bca';
import BRIVa from './VA/bri';
import BNIVa from './VA/bni';
import MandiriVa from './VA/mandiri';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'utils/currencyFormatter';
import { showToastError, showToastSuccess } from 'layouts/DashboardLayout';
import ModalDetailInvoice from './ModalDetailInvoice';
import Spinner from 'components/Spinner';
import { push } from 'connected-react-router';
import PATH_URL from 'routers/path';

const getPaymentMethodClue = (origin, string) => {
  return origin ? origin.indexOf(string) > -1 : false;
};

export default function PaymentAwaiting() {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const userLastInvoice = useSelector(
    (state) => state.userManagement.userLastInvoice
  );
  const dueDateAsT = moment
    .tz(userLastInvoice.dueDate, 'Asia/Bangkok')
    .format('YYYY-MM-DDTHH:mm:ss');
  const dateNow = moment().format('YYYY-MM-DDTHH:mm:ss');
  const differenceInSecond = moment(dueDateAsT).diff(dateNow, 'seconds');
  const loading = useSelector((state) =>
    createLoadingSelector([getUserLastInvoices.type])(state)
  );
  const [showDetailInvoice, setShowDetailInvoice] = useState(false);

  useEffect(() => {
    dispatch(getUserLastInvoices(userDetail.id));
  }, [dispatch, userDetail.id]);

  useEffect(() => {
    if (
      userLastInvoice?.orderStatus === 'completed' &&
      userLastInvoice?.paymentStatus === 'paid'
    ) {
      dispatch(push(PATH_URL.HOME));
    }
  }, [dispatch, userLastInvoice]);

  const paymentMethod = userLastInvoice?.paymentMethod?.toLowerCase();
  const userLastInvoiceID = userLastInvoice?.id?.toString();
  const dueDate =
    userLastInvoice &&
    `${moment
      .tz(userLastInvoice.dueDate, 'Asia/Bangkok')
      .format('DD MMM YYYY, HH:mm')} WIB`;
  const netPrice =
    userLastInvoice && currencyFormatter.format(userLastInvoice.netPrice);
  const vaNumber = userLastInvoice?.vaNumber;
  const isBCA = getPaymentMethodClue(paymentMethod, 'bca');
  const isBri = getPaymentMethodClue(paymentMethod, 'bri');
  const isBni = getPaymentMethodClue(paymentMethod, 'bni');
  const isMandiri = getPaymentMethodClue(paymentMethod, 'mandiri');

  const cancelOrder = () => {
    dispatch(
      updateInvoiceStatus(
        {
          id: userLastInvoiceID,
          orderStatus: 'cancelled',
          paymentStatus: 'unpaid',
        },
        () => {
          showToastSuccess('Your order has been cancelled');
          dispatch(getUserLastInvoices(userDetail.id));
        },
        () => {
          showToastError('Failed to cancel order');
        }
      )
    );
  };

  return (
    <div className={'w-full flex flex-column items-center flex-col py-14'}>
      {loading && <Spinner className="h-16 w-16 fill-current text-blue40" />}
      {!loading && isBCA && (
        <BCAVa
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancelOrder}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isBri && (
        <BRIVa
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancelOrder}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isBni && (
        <BNIVa
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancelOrder}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isMandiri && (
        <MandiriVa
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancelOrder}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      <ModalDetailInvoice
        email={userDetail.email}
        showDialog={showDetailInvoice}
        detailInvoice={userLastInvoice}
        setShowDialog={setShowDetailInvoice}
      />
    </div>
  );
}
