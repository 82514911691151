import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DiscountCard from 'components/DiscountCard';
import Button from 'components/Button';
import CSSTransition from 'components/CSSTransition';
import PATH_URL from 'routers/path';
import { useHistory } from 'react-router-dom';

function UserVoucher() {
  const history = useHistory();
  const availableVouchers = useSelector(
    ({ userSelectVariable }) => userSelectVariable.availableQDVoucher
  );
  const [shownMore, setShownMore] = useState(false);

  useEffect(() => {
    if (availableVouchers.length === 0) history.push(PATH_URL.HOME);
  }, [availableVouchers, history]);

  return (
    <div className="w-full flex justify-center mt-9">
      <div className="lg:w-10/12 w-11/12 p-1">
        <h1 className="text-grey70 text-2xl font-bold mb-4">
          All Available Voucher
        </h1>
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 transition-all">
          {availableVouchers.slice(0, 6).map((voucher) => (
            <DiscountCard
              key={voucher.name}
              url={`${PATH_URL.USER_VOUCHER_LIST}/${voucher.id}`}
              code={voucher.name}
              description={voucher.description}
              endDate={voucher.endDate}
              minimumPurchase={voucher.minimumPurchase}
            />
          ))}
        </div>
        {availableVouchers.length > 6 && (
          <>
            <CSSTransition
              in={shownMore}
              timeout={100}
              onEnterClassName="opacity-0"
              onEnteredClassName="opacity-1"
            >
              <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 transition-opacity mt-4">
                {availableVouchers.slice(6).map((voucher) => (
                  <DiscountCard
                    key={voucher.name}
                    url={`${PATH_URL.USER_VOUCHER_LIST}/${voucher.name}`}
                    code={voucher.name}
                    description={voucher.description}
                    endDate={voucher.endDate}
                    minimumPurchase={voucher.minimumPurchase}
                  />
                ))}
              </div>
            </CSSTransition>
            <Button
              view="outlined"
              color="primary"
              onClick={() => setShownMore((prev) => !prev)}
              className="my-6 mx-auto"
            >
              Show {shownMore ? 'Less' : 'More'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default UserVoucher;
