import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import DeleteQueryDialog from './DeleteQueryDialog';
import RenameQueryDialog from './RenameQueryDialog';
import useVisible from '../../hooks/useVisible';
import {
  changeSelectedSavedQuery,
  storeSearchKeywords,
  changeSelectedCompanies,
  changeSelectedSections,
  changeSelectedSectionNames,
  toggleWholeSections,
  changeYearRange,
  searchDocumentGroup,
  getDecompressedSavedQuery,
  selectedQuerySelector,
  changeSelectedQueryDocument,
  SEARCH_MODE,
  toggleSearchMode,
} from '../../redux/features/textAnalytics';
import { userSubsActiveTextAnalytics } from '../../redux/features/auth';
import {
  convertFieldsToItems,
  convertFieldToSymbol,
} from 'utils/searchKeyword';

function SavedQueriesSidebar(props) {
  const dispatch = useDispatch();
  const { data: queries, selected } = useSelector(
    ({ textAnalytics }) => textAnalytics.savedQueries
  );
  const searchMode = useSelector(
    ({ textAnalytics }) => textAnalytics.searchMode
  );
  const subsActive = useSelector(userSubsActiveTextAnalytics);
  const selectedQuery = useSelector(selectedQuerySelector);
  const searchFilter = useSelector(({ textAnalytics }) => textAnalytics.filter);
  const { ref, isVisible, setIsVisible } = useVisible();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const handleSelectQuery = (id) => {
    if (selectedQuery.id !== id) {
      dispatch(changeSelectedSavedQuery(id));
      dispatch(getDecompressedSavedQuery(id));
      dispatch(changeSelectedQueryDocument(null));
    }
  };
  const handleReaccess = () => {
    const { documentSection, companies, yearRange, keywords } = selectedQuery;
    if (searchMode === SEARCH_MODE.BASIC) dispatch(toggleSearchMode());
    dispatch(
      storeSearchKeywords({
        symbols: convertFieldToSymbol(keywords),
        basic: keywords[0].keywords,
        advanced: keywords,
        items: convertFieldsToItems(keywords),
      })
    );
    dispatch(changeYearRange({ from: yearRange[0], to: yearRange[1] }));
    dispatch(changeSelectedCompanies(companies));
    dispatch(
      changeSelectedSections(documentSection.map((section) => section.id))
    );
    dispatch(
      changeSelectedSectionNames(
        documentSection.reduce((acc, section) => {
          acc = { ...acc, [section.id]: section.name };
          return acc;
        }, {})
      )
    );
    const sectionIsEmpty = documentSection.length === 0;
    if (searchFilter.documentSections.isWhole !== sectionIsEmpty)
      dispatch(toggleWholeSections());
    dispatch(searchDocumentGroup(true));
  };

  return (
    <>
      <div className="flex-grow overflow-y-auto w-full flex flex-col items-center">
        <div className="h-10 w-full px-4 flex-shrink-0 flex justify-center items-center cursor-pointer">
          <div className="flex w-full justify-between">
            <h5 className="text-xs tracking-wider font-semibold uppercase">
              Saved Queries
            </h5>
          </div>
        </div>
        <div className="w-full flex flex-col items-center cursor-pointer overflow-y-auto">
          {queries.map((query) => (
            <div
              key={query.id}
              onClick={() => handleSelectQuery(query.id)}
              className={clsx(
                'group flex justify-between items-center px-4 py-3 w-full transition-colors active:bg-grey0 hover:bg-grey0',
                selected === query.id && 'bg-grey0'
              )}
            >
              <span className="text-xs text-blue100">{query.name}</span>
              <Popover
                isOpen={isVisible && selected === query.id}
                position={Position.BOTTOM_LEFT}
                minimal={true}
                popoverClassName="shadow"
              >
                <button
                  onClick={() => setIsVisible(true)}
                  className={clsx(
                    selected === query.id
                      ? 'visible'
                      : 'invisible group-hover:visible'
                  )}
                >
                  <Icon
                    icon={IconNames.COG}
                    iconSize={18}
                    className="fill-current text-grey70"
                  />
                </button>
                <div
                  ref={ref}
                  className="w-48 flex flex-col rounded shadow-lg bg-white overflow-hidden"
                >
                  {subsActive && (
                    <button
                      onClick={handleReaccess}
                      className="w-full px-4 py-2 text-sm text-left font-medium text-grey60 hover:bg-grey0"
                    >
                      Re-access query
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setRenameDialogOpen(true);
                      setIsVisible(false);
                    }}
                    className="w-full px-4 py-2 text-sm text-left font-medium text-grey60 hover:bg-grey0"
                  >
                    Rename query
                  </button>
                  <button
                    onClick={() => {
                      props.setDownloadDialogOpen(true);
                      setIsVisible(false);
                    }}
                    className="w-full px-4 py-2 text-sm text-left font-medium text-grey60 hover:bg-grey0"
                  >
                    Download query result
                  </button>
                  <button
                    onClick={() => {
                      setDeleteDialogOpen(true);
                      setIsVisible(false);
                    }}
                    className="w-full px-4 py-2 text-sm text-left font-medium text-red60 hover:bg-grey0"
                  >
                    Delete query
                  </button>
                </div>
              </Popover>
            </div>
          ))}
        </div>
      </div>
      {/* Delete query dialog */}
      <DeleteQueryDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      {/* Rename query dialog */}
      <RenameQueryDialog
        query={selectedQuery}
        isOpen={renameDialogOpen}
        onClose={() => setRenameDialogOpen(false)}
      />
    </>
  );
}

export default SavedQueriesSidebar;
