import { useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import illustration from '../../assets/images/auth-form-image.jpg';
import logo from '../../assets/images/esg-dataset-logo.png';
import { ALERT_TYPE, selectAlert } from '../../redux/features/ui';
import PATH_URL from '../../routers/path';
import FloatHelp from 'components/FloatHelp';

function AuthLayout(props) {
  const containerRef = useRef();
  const topbarRef = useRef();
  const alert = useSelector(selectAlert);
  const auth = useSelector(({ firebase }) => firebase.auth);
  const checkScroll = useCallback(() => {
    if (containerRef.current && topbarRef.current) {
      const container = containerRef.current;
      const topbar = topbarRef.current;
      if (container.scrollTop > 0) {
        topbar.classList.add('shadow');
      } else {
        topbar.classList.remove('shadow');
      }
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) container.addEventListener('scroll', checkScroll, true);
    return () => {
      if (container) container.removeEventListener('scroll', checkScroll, true);
    };
  }, [checkScroll]);

  return (
    <div className="h-screen w-full flex">
      {!props.navOnly && (
        <div className="relative w-3/5 h-full hidden md:block lg:block xl:block">
          <img
            src={illustration}
            alt="fill form illustration"
            className="absolute w-full h-full object-top object-cover z-0"
          />
        </div>
      )}
      <div
        ref={containerRef}
        className={clsx(
          props.navOnly ? 'w-full' : 'w-full md:w-2/5 lg:w-2/5 xl:w-2/5',
          'max-h-full overflow-y-auto'
        )}
      >
        <div
          ref={topbarRef}
          className="w-full sticky z-20 top-0 bg-white flex py-4 items-center justify-end pr-10"
        >
          <div className="block w-full pl-10">
            <div className="h-full flex items-center w-44 md:w-11 lg:w-44 overflow-hidden">
              <a
                href="https://www.esgi.ai/"
                className="h-12 flex items-center w-44 md:w-11 lg:w-44 mr-2"
              >
                <img
                  src={logo}
                  alt="cesgs logo"
                  className="h-12 w-44 object-contain md:object-cover lg:object-contain object-left-top"
                />
              </a>
            </div>
          </div>
          {auth.isLoaded && auth.isEmpty && (
            <div className="h-full flex-shrink-0 flex items-center md:flex md:items-center lg:flex lg:items-center xl:flex xl:items-center">
              <Link
                to={PATH_URL.LOGIN}
                className="text-grey50 text-base mr-5 hover:no-underline hover:text-grey50"
              >
                Login
              </Link>
              <Link
                to={PATH_URL.REGISTER}
                className="text-white text-base bg-blue50 rounded-full px-3.5 py-2 font-medium hover:no-underline hover:text-white"
              >
                Register
              </Link>
            </div>
          )}
        </div>
        {alert.message && (
          <div
            className={clsx(
              'py-3.5',
              alert.type === ALERT_TYPE.SUCCESS && 'bg-green10 text-green60',
              alert.type === ALERT_TYPE.ERROR && 'bg-red10 text-red60'
            )}
          >
            <p className="text-xs tracking-wide text-center">{alert.message}</p>
          </div>
        )}
        <div className={clsx(!props.navOnly && 'px-10 py-9')}>
          {props.children}
        </div>
      </div>
      <FloatHelp />
    </div>
  );
}

export default AuthLayout;
