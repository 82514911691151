import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { upperFirst } from 'lodash';

export default function ErrorImportVariable(props) {
  const handleCloseDialog = () => {
    props.onClose();
  };
  return (
    <Dialog
      isOpen={props.isOpen}
      header={'Import CSV Failed'}
      onClose={handleCloseDialog}
    >
      <form>
        <div className="w-full px-4 pb-2 flex flex-col">
          <div className="flex items-center px-5 py-3 bg-red5 rounded">
            <Icon
              icon={IconNames.WARNING_SIGN}
              iconSize={20}
              className="fill-current text-red70 mr-3.5"
            />
            <div className="text-grey100 text-xs">
              {typeof props.content === 'object' ? (
                <ul className="list-disc ml-3">
                  {props.content.errors.map((error, index) => (
                    <li key={`error-${index}`}>{error}</li>
                  ))}
                </ul>
              ) : (
                <p>{props.content}</p>
              )}
            </div>
          </div>
          <div className="text-grey100 mt-4 text-xs">
            {typeof props.content === 'object'
              ? `Please review the variables in the following lines and re-upload the CSV file`
              : `Please make sure the CSV file contains the required data and the file format must have .csv extension`}
          </div>
          {typeof props.content === 'object' && (
            <div className="mt-4 overflow-y-auto max-h-48">
              {props.content.rows.map((row) => (
                <div
                  key={`error-line-${row.rowNumber}`}
                  className="mb-3 text-xs"
                >
                  <p className="text-grey100">{`Line (#${row.rowNumber}): ${
                    row.variableName || 'null'
                  }`}</p>
                  <p className="text-red60">{`Error: ${upperFirst(
                    row.error
                  )}`}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-full mt-4 px-4 flex items-center justify-end">
          <Button onClick={handleCloseDialog} color="primary">
            Re-Upload CSV
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
