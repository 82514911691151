import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { initTransformationDocuments } from 'redux/features/variableManagement';
import { createLoadingSelector } from 'redux/api/loading';
import { showToastSuccess } from 'layouts/DashboardLayout';
import { showToastError } from 'layouts/DashboardLayout';
import CustomSelectYear from './CustomSelectYear';
import { range } from 'lodash';

const START_YEAR = 2010;
const END_YEAR = 2030;

const YEAR_RANGE = range(START_YEAR, END_YEAR + 1).map((year) => ({
  value: year,
  text: year,
}));

export default function DialogInitDocument(props) {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.variableManagement.selected);
  const [selectedYear, setSelectedYear] = useState([
    YEAR_RANGE[0].value,
    YEAR_RANGE[YEAR_RANGE.length - 1].value,
  ]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const id = selected.id;
    const years = range(selectedYear[0], selectedYear[1] + 1);
    dispatch(
      initTransformationDocuments(
        id,
        years,
        () => {
          showToastSuccess('Successfully init documents.');
          props.onSuccess();
          props.onClose();
        },
        () => {
          showToastError('An error occurred.');
        }
      )
    );
  };
  const loadingInitDocument = useSelector(
    createLoadingSelector([initTransformationDocuments.type])
  );

  useEffect(() => {
    if (!props.isOpen) {
      setSelectedYear([
        YEAR_RANGE[0].value,
        YEAR_RANGE[YEAR_RANGE.length - 1].value,
      ]);
    }
  }, [props.isOpen]);

  return (
    <Dialog
      isOpen={props.isOpen}
      header={'Init Document'}
      onClose={props.onClose}
      size="small"
    >
      <form onSubmit={handleSubmit}>
        <div className="w-full px-6 pb-2">
          <div className="flex flex-col">
            <div>
              <label htmlFor="name" className="text-grey50">
                Select year range
              </label>
              <div className="flex justify-between mt-2">
                <div className="mr-2">
                  <CustomSelectYear
                    filter={YEAR_RANGE.filter(
                      (year) => year.value <= selectedYear[1]
                    )}
                    selectedValue={selectedYear[0]}
                    onChangeFilter={(selected) => {
                      setSelectedYear((prev) => [selected.value, prev[1]]);
                    }}
                  />
                </div>
                <CustomSelectYear
                  filter={YEAR_RANGE.filter(
                    (year) => year.value >= selectedYear[0]
                  )}
                  selectedValue={selectedYear[1]}
                  onChangeFilter={(selected) => {
                    setSelectedYear((prev) => [prev[0], selected.value]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-6 flex items-center justify-end">
          <Button
            view="outlined"
            color="danger"
            onClick={props.onClose}
            className="mr-4"
            disabled={loadingInitDocument}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" isLoading={loadingInitDocument}>
            Init Document
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
