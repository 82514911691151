import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { upperFirst } from 'lodash';
import userPhoto from '../../assets/images/userProfileDefault.svg';
import { Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import moment from 'moment';
import {
  COLOR_VARIANTS_ENABLED as BUTTON_COLOR,
  SIZE_VARIANTS as BUTTON_SIZE,
} from 'components/Button';
import Tab from 'components/Tab/index';
import TabPanel from 'components/Tab/Panel/index';
import TableInvoiceTA from './TableInvoiceTA';
import TableInvoiceQD from './TableInvoiceQD';
import PATH_URL from '../../routers/path';
import warningYellow from 'assets/svg/warning_yellow.svg';
import { getDueDays } from '../../utils/date';

function UserProfilePage() {
  const location = useLocation();
  const history = useHistory();
  const tableRef = useRef(null);
  const userDetail = useSelector((state) => state.auth);
  const userType = userDetail.type;
  const userApprovalStatus = userDetail.profile.statusApproval;
  const showWarning =
    userType.toLowerCase() === 'student' &&
    userApprovalStatus.toLowerCase() === 'awaiting';

  const [valueTab, setValueTab] = useState(0);
  const optionsTab = [
    { label: 'Quantitative Data' },
    { label: 'Text Analytics' },
  ];
  const subsActive = userDetail.usersubscriptions?.filter(
    (data) => new Date().getTime() < new Date(data.expireAt).getTime()
  );

  useEffect(() => {
    if (location.search.includes('billing')) {
      const params = location.search.slice(1);
      const paramsObj = qs.parse(params);
      const tabIndex = paramsObj.billing === 'qd' ? 0 : 1;
      setValueTab(tabIndex);
    }
  }, [location.search]);

  return (
    <>
      <div className="w-full mt-14">
        <div className="w-full mb-10 flex flex-col items-center">
          <div className="lg:w-10/12 w-11/12 p-1">
            <h4 className="text-grey70 font-bold mb-4">My Profile</h4>
            <div className="flex flex-col bg-white shadow rounded mb-10 px-2">
              <div className="flex justify-between items-start pt-5 px-5 mb-2 ">
                <div className="w-96 flex items-center justify-start ">
                  <div
                    className="w-20 h-20 mr-3 rounded-full overflow-hidden bg-no-repeat bg-cover bg-center shadow"
                    style={{
                      backgroundImage: `url(${
                        userDetail.photoUrl || userPhoto
                      })`,
                    }}
                  />
                  <div>
                    <p className="text-sm text-grey100 font-semibold  mb-2">
                      {(userDetail &&
                        userDetail.profile &&
                        userDetail.profile.fullName) ||
                        '-'}
                    </p>
                    <p className="text-sm text-gray50 mb-1">
                      {(userDetail && userDetail.email) || '-'}
                    </p>
                    {userDetail && userDetail.phoneNumber && (
                      <p className="text-sm text-gray50">
                        {userDetail && userDetail.phoneNumber}
                      </p>
                    )}
                  </div>
                </div>
                <Link
                  to={PATH_URL.EDIT_USER_PROFILE}
                  className={`${BUTTON_SIZE['small']} ${BUTTON_COLOR['filled']['primary']} hover:no-underline`}
                >
                  Edit Profile
                </Link>
              </div>
              <div className="min-w-min h-full flex flex-wrap py-3 px-2">
                <div className="mx-4 mb-6 px-3">
                  <p className="text-sm font-medium text-gray50 mb-2">Status</p>
                  <p
                    className={clsx(
                      'text-sm text-grey100 font-medium',
                      !(userDetail && userDetail.emailVerified) && 'text-red50'
                    )}
                  >
                    {userDetail && userDetail.emailVerified
                      ? 'Active'
                      : 'Unverified'}
                  </p>
                </div>
                <div className="mx-4 mb-6 px-3">
                  <p className="text-sm font-medium text-gray50 mb-2">
                    Account type
                  </p>
                  <div className={'flex'}>
                    <p className="text-sm text-grey100 font-medium">
                      {userDetail && upperFirst(userDetail.type)}
                    </p>
                    {showWarning && (
                      <Tooltip
                        className={'self-center ml-1.5'}
                        openOnTargetFocus={false}
                        content={<p className="text-xs">Awaiting Approval</p>}
                        position={Position.TOP}
                      >
                        <img
                          src={warningYellow}
                          alt="waiting for student approval"
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                {userDetail && userDetail.type === 'student' && (
                  <div className="mx-4 mb-6 px-3">
                    <p className="text-sm font-medium text-gray50 mb-2">
                      Student Identification Number
                    </p>
                    <p className="text-sm text-grey100 font-medium">
                      {(userDetail &&
                        userDetail.profile &&
                        userDetail.profile.nim) ||
                        '-'}
                    </p>
                  </div>
                )}

                <div className="mx-4 mb-6 px-3">
                  <p className="text-sm font-medium text-gray50 mb-2">
                    Institution
                  </p>
                  <p className="text-sm text-grey100 font-medium">
                    {(userDetail && userDetail.profile.institution) || '-'}
                  </p>
                </div>
                <div className="mx-4 mb-6 px-3">
                  <p className="text-sm font-medium text-gray50 mb-2">
                    Document
                  </p>
                  {userDetail && userDetail.type !== 'general' ? (
                    <div className="flex items-center">
                      <a
                        href={
                          (userDetail &&
                            userDetail.profile &&
                            userDetail.profile.cardIdUrl) ||
                          '#'
                        }
                        target={
                          userDetail &&
                          userDetail.profile &&
                          userDetail.profile.cardIdUrl
                            ? '_blank'
                            : '_self'
                        }
                        rel="noreferrer"
                        className="mr-2 text-sm text-blue50 font-semibold"
                      >
                        {`Student ID Card ${
                          userDetail &&
                          userDetail.profile &&
                          userDetail.profile.fullName &&
                          userDetail.profile.fullName.split(/\s+/)[0] + '.jpg'
                        }`}
                      </a>
                      <Icon
                        iconSize={18}
                        icon={IconNames.DOWNLOAD}
                        className="fill-current text-blue50"
                      />
                    </div>
                  ) : (
                    <p className="text-sm text-grey100 font-medium">-</p>
                  )}
                </div>
              </div>
            </div>
            <h4 className="text-grey70 font-bold mb-4">My Active Plan</h4>
            <div className="bg-white shadow rounded mb-10">
              {subsActive.length > 0 &&
                subsActive.map((data, index) => (
                  <div
                    key={data.id}
                    className={clsx(
                      'w-full pt-5 pb-2 px-8 flex items-start justify-between',
                      index > 0 && 'border-t border-grey5'
                    )}
                  >
                    <div className="min-w-min h-full ">
                      <p className="text-teal70 text-2xl font-bold capitalize mb-5">
                        {data.subscription.name ||
                          `${data.subscription.option} Subscription`}
                      </p>

                      <div className="mb-6">
                        <p className={clsx('text-sm text-grey50 font-medium')}>
                          Active Until
                          <span className={clsx('text-sm text-grey100')}>
                            {` ${moment(data.expireAt).format('DD MMM YYYY')} `}
                          </span>
                          {data.expireAt && getDueDays(data.expireAt) <= 3 && (
                            <span className="text-red50 text-sm">{`(Will expire in ${getDueDays(
                              data.expireAt
                            )} days)`}</span>
                          )}
                        </p>
                      </div>
                    </div>
                    {data.subscription.option !== 'trial' && data.status && (
                      <Link
                        to={`${PATH_URL.USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC}?subscription=${data.subscription.option}`}
                        className={`${BUTTON_SIZE['normal']} ${BUTTON_COLOR['filled']['primary']} hover:no-underline`}
                        style={{
                          height: 'fit-content',
                        }}
                      >
                        Extend Subscription
                      </Link>
                    )}
                  </div>
                ))}
              {subsActive.length === 0 && (
                <p className="text-center p-2 pb-5 font-semibold text-grey100">
                  There is no active plan
                </p>
              )}
            </div>
            <h4 className="text-grey70 font-bold mb-4" ref={tableRef}>
              My Billing History
            </h4>
            <div className={'mt-8'}>
              <Tab
                active={valueTab}
                onChange={(tabIndex) => {
                  const billing = tabIndex === 0 ? 'qd' : 'ta';
                  history.push({
                    pathname: PATH_URL.USER_PROFILE,
                    search: `?billing=${billing}`,
                  });
                }}
                options={optionsTab}
              />
              <TabPanel value={valueTab} index={0}>
                <TableInvoiceQD />
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <TableInvoiceTA />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfilePage;
