export default function Chip({ type, text }) {
  if (type === 'word')
    return (
      <span className="text-sm whitespace-nowrap font-bold py-2 px-4 rounded bg-teal1 text-teal2">
        {text}
      </span>
    );
  else if (type === 'operator') {
    return (
      <span className="text-sm font-bold py-2 px-4 rounded bg-orange1 text-orange2 mx-3">
        {text}
      </span>
    );
  }
  return <span className="text-sm font-bold text-orange2 mx-3">{text}</span>;
}
