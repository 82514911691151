import qs from 'qs';
import api from '../../utils/api';

const { NODE_ENV } = process.env;

export const apiRequest = async (
  dispatch,
  actionType,
  method,
  url,
  data,
  contentType = 'application/json',
  onSuccess,
  onFailure,
  onUploadProgress
) => {
  dispatch({ type: `${actionType}_REQUEST` });
  const dataOrParams = ['get', 'delete'].includes(method) ? 'params' : 'data';
  try {
    let options = {
      url,
      method,
      headers: { 'content-type': contentType },
      [dataOrParams]: data,
      paramsSerializer: (params) => {
        // filter out params with empty value
        const filtered = Object.keys(params).reduce((acc, key) => {
          if (params[key]) return { ...acc, [key]: params[key] };
          return acc;
        }, {});
        return qs.stringify(filtered);
      },
    };
    if (onUploadProgress) options = { ...options, onUploadProgress };
    let response = await api.request(options);

    // log API Request in development. disable on staging and production
    if (NODE_ENV === 'development') {
      console.log(response.data.data);
    }
    if (onSuccess) onSuccess(response.data.data);
    return dispatch({
      type: `${actionType}_SUCCESS`,
      payload: response.data.data,
    });
  } catch (error) {
    let errorMsg = error;
    if (typeof error.response != `undefined`) {
      errorMsg = error.response.data;
    }
    if (onFailure) onFailure(error);
    return dispatch({
      type: `${actionType}_FAILURE`,
      payload: errorMsg,
    });
  }
};

export const apiAction =
  (
    actionType = 'API_CALL',
    method = 'get',
    url = '',
    data = null,
    onSuccess = () => {},
    onFailure = () => {}
  ) =>
  async (dispatch) =>
    apiRequest(dispatch, actionType, method, url, data, onSuccess);
