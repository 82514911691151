import ConfirmationDialog from 'components/ConfirmationDialog';
import { getUserProfile, freeTrialFeature } from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import { useSelector, useDispatch } from 'react-redux';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
export default function DialogReasonRejected({
  setShowDialog,
  showDialog,
  ...props
}) {
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    createLoadingSelector([getUserProfile.type, freeTrialFeature.type])(state)
  );
  const setFreeTrial = async () => {
    try {
      const result = await dispatch(freeTrialFeature());
      if (result.type === `${freeTrialFeature.type}_SUCCESS`) {
        await dispatch(getUserProfile());
        showToastSuccess('Success to start the trial for 7 days');
      } else {
        throw new Error('Failed to start the free trial');
      }
    } catch (error) {
      showToastError('Failed to start the free trial');
    }
  };
  return (
    <ConfirmationDialog
      title="7-day Free Trial"
      isOpen={showDialog}
      onCancel={() => {
        setShowDialog(false);
      }}
      onConfirm={() => setFreeTrial()}
      isLoading={loading}
      captions={{ confirm: 'Try Now' }}
    >
      You will start the trial for 7 days. During the trial, you can access all
      features with 6 dummy documents
    </ConfirmationDialog>
  );
}
