const ENDPOINTS = {
  AUTH: '/one-login/auth',
  INSTITUTIONS: '/one-login/users/institutions',
  UPLOAD_PROFILE_PICT: '/one-login/uploads?path=photoProfile',
  UPLOAD_STUDENT_ID_CARD: '/one-login/uploads?path=photoCardId',
  USER_LIST: '/one-login/users',
  USER_APPROVAL: '/one-login/users/user-student',
  USER_CHANGE_ROLE: '/one-login/users/change-role',
  USER_BLOCK: '/one-login/users/block-student',
  USER_SUBSCRIBER: '/one-login/users/subscribers',
  USER_SUMMARY: '/one-login/users/user-summary',
  USER_DETAIL: '/one-login/users/user-detail',
  USER_INVOICES: '/one-login/users/user-invoices',
  USER_BILLINGS: '/one-login/users/user-billings',
  USER_SEARCH: '/one-login/users',
  UPDATED_STUDENT_STATUS: '/one-login/users/accept-student',
  STORE_SURVEY_DATA: '/one-login/users/send-survey',
  SEARCH_DOCUMENT_GROUP: '/index-search/search/documents',
  SEARCH_DOCUMENT_STATS: '/index-search/search/stats',
  SEARCH_DOCUMENT_PAGES: '/index-search/search/pages',
  SEARCH_DOCUMENT_CONTENTS: '/index-search/search/contents',
  GET_COMPANY_LIST: '/index-search/companies',
  GET_SECTION_LIST: '/index-search/sections',
  GET_PERIOD_RANGE: '/index-search/periodes',
  VOUCHER_LIST: '/one-login/payments/discounts',
  CREATE_VOUCHER: '/one-login/payments/discount',
  DELETE_VOUCHER: '/one-login/payments/discount',
  UPDATE_INVOICES_STATUS: '/one-login/payments/update-status',
  SAVED_QUERY: '/index-search/savedquery',
  DECODE_SAVED_QUERY: '/index-search/savedquery/decode',
  CHECK_DISCOUNT: '/one-login/payments/check-discount',
  DO_PAYMENT: '/one-login/payments/pay',
  PAYMENT_PLANS: '/one-login/payments/plans',
  GET_REPORT_STATS: '/index-search/reports/stats',
  GET_REPORT_SENTENCES_XLSX: '/index-search/reports/sentences/xls',
  GET_REPORT_SENTENCES_PDF: '/index-search/reports/sentences/pdf',
  SAVED_QUERY_NOTES: '/index-search/savedquery/notes',
  FREE_TRIAL: '/one-login/payments/trial',
  GET_DIRECT_REPORT_XLSX: '/index-search/reports/direct/xls',
  GET_DIRECT_REPORT_PDF: '/index-search/reports/direct/pdf',
  VARIABLE_LIST: '/quantitative/variables',
  VARIABLE_LIST_DETAIL: '/quantitative/variables/index',
  VARIABLE_DETAIL_INFORMATION: '/quantitative/variables',
  UPLOAD_VARIABLE_CSV: '/quantitative/variables/import',
  GET_VARIABLE_CATEGORIES: '/quantitative/categories',
  GET_VARIABLE_CLASSIFICATIONS: '/quantitative/classifications',
  VARIABLE_TRANSFORMATION: '/quantitative/variables',
  TOGGLE_PUBLISH_VARIABLE: '/quantitative/variables/publish',
  VARIABLE_INDEXING: '/quantitative/variables/index',
  INIT_TRANSFORMATION_DOCUMENTS: 'quantitative/transformations/init',
  GET_VARIABLE_INDEXING_STATS: '/quantitative/variables/indexing-stats',
  EDIT_VARIABLE: '/quantitative/variables',
  GET_SUGGESTIONS: '/quantitative/suggestions',
  TRANSFORMATION_OUTPUT_EXAMPLE: '/quantitative/transformations/test',
  GET_LIST_GROUP_CATEGORY: '/quantitative/categories/groups',
  PREVIEW_SELECTED_VARIABLE: '/quantitative/variables/preview',
  CREATE_ORDER_QUANTITATIVE_DATASET: '/quantitative/orders',
  GET_USER_QD_INVOICES: '/quantitative/orders',
  CANCEL_QD_ORDER: '/quantitative/orders/cancel',
  ADD_VARIABLE_STATS: '/quantitative/variables/stats',
  BULK_DOWNLOAD_QD: '/quantitative/orders/download-library',
  DISCOUNT: '/quantitative/discounts',
  DISCOUNT_DOWNLOAD: '/quantitative/discounts/download',
  DISCOUNT_USAGE: '/quantitative/discounts/usage',
  GET_AVAIABLE_QD_VOUCHER: '/quantitative/discounts/available',
  CHECK_DISCOUNT_QD: '/quantitative/discounts/check',
  CHECK_MIDTRANS_STATUS: 'quantitative/orders/check-status',
  DOWNLOAD_VARIABLE_TRANSFORMATION: 'quantitative/orders/download-data-admin',
  GET_INDUSTRY_CLASSIFICATION: 'quantitative/industry-classifications',
  NULLIFY_TRANSFORMATION_RESULT: '/quantitative/variables/nullify',
  IMPORT_TRANSFORMATION_RESULT: '/quantitative/variables/import-data',
  DOWNLOAD_USER_LIST_CSV: '/one-login/users/download',
  DOWNLOAD_SUBSCRIBER_LIST_CSV: '/one-login/users/subscribers/download',
  DOWNLOAD_STUDENT_APPROVAL_LIST_CSV: '/one-login/users/user-student/download',
  REPORT_TRANSFORMATION_RESULT: '/quantitative/variables/report',
  CLEAR_INITIALIZED_DOCUMENTS: '/quantitative/variables/clear-documents',
};

export default ENDPOINTS;
