export default function Select({
  loading,
  options,
  label,
  defaultValue,
  onChange,
}) {
  if (loading) {
    return <div>Loading</div>;
  }
  let selectProperty = {};
  if (selectProperty) {
    selectProperty.value = defaultValue;
  }
  return (
    <label className="block">
      <span className="text-gray-700">{label}</span>
      <select
        onChange={onChange}
        className="block w-full mt-1 rounded-md border border-blue-50 shadow-sm focus:border-blue-50 focus:ring focus:ring-blue-50 focus:ring-opacity-50"
        {...selectProperty}
      >
        {options.map((value, index) => {
          const { label, value: innerValue } = value;
          const labelAsValue = `${innerValue}:${label}`;
          return (
            <option value={labelAsValue} key={index}>
              {label}
            </option>
          );
        })}
      </select>
    </label>
  );
}
