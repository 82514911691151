import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { upperFirst } from 'lodash';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  getListVoucher,
  deleteVoucher,
} from '../../redux/features/voucherManagement';
import imageVoucherEmpty from '../../assets/images/empty-voucher.svg';
import useVisible from '../../hooks/useVisible';
import ModalAddVoucher from './ModalAddVoucher';
import ModalDeleteVoucher from './ModalDeleteVoucher';
import { showToastSuccess, showToastError } from 'layouts/DashboardLayout';

function VoucherListPage() {
  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const [rowPerPage, setrowPerPage] = useState(10);
  const [searchInvoices, setSearchInvoices] = useState('');
  const [filterVoucher, setFilterVoucher] = useState({});
  const ListVoucher = useSelector(
    (state) => state.voucherManagement.listVoucher
  );
  const loadingGetListVoucher = useSelector((state) =>
    createLoadingSelector([getListVoucher.type])(state)
  );
  const loadingDeleteVoucher = useSelector((state) =>
    createLoadingSelector([deleteVoucher.type])(state)
  );
  const { ref, isVisible, setIsVisible } = useVisible();
  const [visibleDropDown, setVisibleDropdown] = useState(0);
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState({
    id: 0,
    data: {},
  });
  const updateVoucher = (id, data) => {
    setDataToUpdate({
      id,
      data,
    });
    setShowDialogAdd(true);
  };
  const [visibleDialogDeleteVoucher, setVisibleDialogDeleteVoucher] =
    useState(false);
  const [IDVoucher, setIDVoucher] = useState(null);
  const deleteVoucherAction = (id) => {
    setVisibleDialogDeleteVoucher(true);
    setVisibleDropdown(0);
    setIDVoucher(id);
  };
  const columns = [
    {
      Header: 'Discount',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
      customCell: ({ value }) => <>{upperFirst(value)}</>,
    },
    {
      Header: 'Value',
      accessor: 'value',
      customCell: ({ value, row }) => (
        <>
          {row.original && row.original.type === 'percentage'
            ? `${value} %`
            : currencyFormatter.format(value)}
        </>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      sortType: 'datetime',
      customCell: ({ value }) => <>{moment(value).format('DD MMM YYYY')}</>,
    },
    {
      Header: 'Used',
      accessor: 'invoices',
      customCell: ({ value, row }) => <>{(value && value.length) || '-'}</>,
    },
    {
      Header: 'Limit',
      accessor: 'limit',
    },
    {
      Header: 'Action',
      accessor: 'id',
      sortable: false,
      customCell: ({ value, row }) => (
        <Popover
          isOpen={isVisible && visibleDropDown === value}
          position={Position.BOTTOM_RIGHT}
          minimal={true}
          targetTagName="div"
          className="w-min-content"
          targetClassName="inline-block"
          popoverClassName="shadow-none bg-transparent"
        >
          <div
            className="cursor-pointer inline-block"
            onClick={() => {
              if (isVisible && visibleDropDown) {
                setIsVisible(false);
                setVisibleDropdown(0);
              } else {
                setIsVisible(true);
                setVisibleDropdown(value);
              }
            }}
          >
            <Icon
              icon={IconNames.MORE}
              iconSize={20}
              className="fill-curent text-grey50 hover:text-grey50"
            />
          </div>
          <div
            className="w-40 p-2 mt-1 shadow top rounded overflow-y-auto bg-white"
            ref={ref}
          >
            <ul>
              <button
                className="block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm text-grey50 transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline"
                onClick={() => updateVoucher(value, row.original)}
              >
                Edit voucher
              </button>
              <button
                className="block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm text-red50 transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline"
                onClick={() => deleteVoucherAction(value)}
              >
                Delete voucher
              </button>
            </ul>
          </div>
        </Popover>
      ),
    },
  ];
  const filterTable = [
    {
      name: 'type',
      options: [
        {
          text: 'Percentage',
          value: 'percentage',
        },
        {
          text: 'Fixed Amount',
          value: 'amount',
        },
      ],
    },
  ];
  useEffect(() => {
    dispatch(
      getListVoucher({
        page: 1,
        limit: rowPerPage,
        q: searchInvoices,
        ...filterVoucher,
      })
    );
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch, rowPerPage, searchInvoices, filterVoucher]);
  return (
    <>
      <div className="w-full p-2">
        {!loadingGetListVoucher &&
          !(ListVoucher.discounts && ListVoucher.discounts.length > 0) &&
          searchInvoices === '' &&
          !Object.keys(filterVoucher).length > 0 && (
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="mb-2">
                <img
                  src={imageVoucherEmpty}
                  className="w-full h-auto"
                  alt="img-empty-voucherlist"
                />
              </div>
              <h1 className="text-xl text-grey100 font-bold mb-2">
                There is no voucher at this time
              </h1>
              <p className="text-sm text-grey50 mb-3">
                You can add voucher now
              </p>
              <button
                className="text-xs font-semibold px-4 py-2 text-white bg-green50 rounded-sm hover:bg-green60"
                onClick={() => setShowDialogAdd(true)}
              >
                <span className="mr-1">Add New Voucher</span>
                <Icon
                  icon={IconNames.TAG}
                  iconSize={15}
                  className="fill-current text-white"
                />
              </button>
            </div>
          )}
        {(loadingGetListVoucher ||
          (ListVoucher.discounts && ListVoucher.discounts.length > 0) ||
          !(searchInvoices === '') ||
          Object.keys(filterVoucher).length > 0) && (
          <>
            <div className="w-full flex flex-wrap justify-between items-center mb-5">
              <h1 className="text-lg text-grey70 font-bold mb-4">Discount</h1>
              <button
                className="text-xs font-semibold px-4 py-2 text-white bg-green50 rounded-sm hover:bg-green60"
                onClick={() => setShowDialogAdd(true)}
              >
                <span className="mr-1">Add New Voucher</span>
                <Icon
                  icon={IconNames.TAG}
                  iconSize={15}
                  className="fill-current text-white"
                />
              </button>
            </div>
            <CustomTable
              isLoading={loadingGetListVoucher}
              dataTable={
                ListVoucher.discounts && ListVoucher.discounts.length > 0
                  ? ListVoucher.discounts.map((v) => ({
                      ...v,
                      startDate: new Date(v.startDate),
                    }))
                  : []
              }
              columnsTable={columns}
              filterTable={filterTable}
              initialFilterValue={filterVoucher}
              onChangeFilter={(filter) => {
                setFilterVoucher(
                  Object.keys(filter).reduce(
                    (allFilter, v) => ({
                      ...allFilter,
                      [v]: filter[v] && filter[v].value,
                    }),
                    {}
                  )
                );
              }}
              page={ListVoucher.currentPageNumber || 1}
              totalPage={ListVoucher.totalPages || 1}
              totalRow={rowPerPage}
              onChangeTotalRow={(row) => {
                setrowPerPage(row);
              }}
              onInputSearch={(text) => {
                setSearchInvoices(text);
              }}
              onChangePage={(page) => {
                dispatch(
                  getListVoucher({
                    page: page,
                    limit: rowPerPage,
                    q: searchInvoices,
                    ...filterVoucher,
                  })
                );
              }}
            />
          </>
        )}
      </div>
      {showDialogAdd && (
        <ModalAddVoucher
          showDialog={showDialogAdd}
          setShowDialog={setShowDialogAdd}
          dataToUpdate={dataToUpdate}
          setDataToUpdate={setDataToUpdate}
        />
      )}
      <ModalDeleteVoucher
        visible={visibleDialogDeleteVoucher}
        onCancel={() => {
          setVisibleDialogDeleteVoucher(false);
        }}
        onConfirm={async () => {
          const { payload } = await dispatch(
            deleteVoucher({
              id: parseInt(IDVoucher, 10),
            })
          );
          if (payload) {
            showToastSuccess('Your voucher has been successfully deleted');
            setVisibleDialogDeleteVoucher(false);
            await dispatch(getListVoucher({ page: 1, limit: rowPerPage }));
          } else {
            showToastError('Your voucher has not been successfully deleted');
            setVisibleDialogDeleteVoucher(false);
          }
        }}
        loading={loadingDeleteVoucher}
        onClose={() => {
          setVisibleDialogDeleteVoucher(false);
        }}
      />
    </>
  );
}

export default VoucherListPage;
