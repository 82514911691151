import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TimePicker } from '@blueprintjs/datetime';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import TextField from 'components/TextField';
import SelectField from 'components/SelectField';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
import {
  getListDiscount,
  createDiscount,
} from '../../redux/features/discountManagement';
import { createLoadingSelector } from '../../redux/api/loading';

const VOUCHER_TYPES = [
  { value: 'percentage', label: 'Percentage' },
  { value: 'fixed', label: 'Amount' },
];
const ELIGIBILITIES = [
  { value: 'everyone', label: 'Everyone' },
  { value: 'general', label: 'General' },
  { value: 'student', label: 'Student' },
];
const MAX_DESCRIPTION = 40;
const schema = Yup.object().shape({
  discountBase: Yup.string().required(),
  name: Yup.string()
    .matches(
      /^[A-Z0-9]+$/,
      "Discount code can't contain space and should be uppercase"
    )
    .required('Discount code is required'),
  type: Yup.string().required(),
  value: Yup.number()
    .typeError('Discount value should be a number')
    .positive("Discount value can't be negative")
    .moreThan(0, "Discount value can't be zero")
    .required('Discount value is required'),
  hasMinimumPurchase: Yup.boolean().nullable(),
  minimumPurchase: Yup.number().typeError(
    'Minimum purchase should be a number'
  ),
  termsConditions: Yup.string(),
  description: Yup.string().required('Description is required'),
  eligibility: Yup.string().required(),
  isLimitedUsage: Yup.boolean().required(),
  limit: Yup.mixed()
    .typeError('Usage limit should be a number')
    .when('isLimitedUsage', {
      is: true,
      then: Yup.number()
        .moreThan(0, 'Limit should greater than zero')
        .required(),
    }),
  isLimitedPerUser: Yup.boolean().required(),
  startDate: Yup.date().typeError('Invalid date').required(),
  endDate: Yup.date().typeError('Invalid date').required(),
});

export default function ModalAddDiscount({
  showDialog,
  setShowDialog,
  onSuccess,
}) {
  const dispatch = useDispatch();
  const [rangeTime, setRangeTime] = useState({
    startTime: new Date().getTime(),
    endTime: new Date().getTime(),
  });
  const [dateRangeError, setDateRangeError] = useState('');
  const loadingAddDiscount = useSelector(
    createLoadingSelector([createDiscount.type, getListDiscount.type])
  );
  const defaultValues = {
    discountBase: 'referral',
    name: '',
    type: VOUCHER_TYPES[0].value,
    value: 0,
    hasMinimumPurchase: false,
    minimumPurchase: 0,
    termsConditions: '',
    isVisible: false,
    description: '',
    eligibility: ELIGIBILITIES[0].value,
    isLimitedUsage: true,
    limit: 0,
    isLimitedPerUser: true,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const { register, handleSubmit, watch, control, reset, errors } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const isLimitedUsage = watch('isLimitedUsage');
  const hasMinimumPurchase = watch('hasMinimumPurchase');
  const discountBase = watch('discountBase');
  const onSubmit = async (data) => {
    const { hasMinimumPurchase, isLimitedUsage, termsConditions, ...formData } =
      data;
    const startDate = `${moment(data.startDate).format('YYYY-MM-DD')} ${
      rangeTime.startTime
        ? moment(rangeTime.startTime).format('HH:mm:ss')
        : '00:00:00'
    }`;
    const endDate = `${moment(data.endDate).format('YYYY-MM-DD')} ${
      rangeTime.endTime
        ? moment(rangeTime.endTime).format('HH:mm:ss')
        : '00:00:00'
    }`;
    const startDateMilisec = new Date(startDate).getTime();
    const endDateMilisec = new Date(endDate).getTime();
    if (startDateMilisec < endDateMilisec) {
      setDateRangeError('');
      let submitData = {
        ...formData,
        minimumPurchase: hasMinimumPurchase ? formData.minimumPurchase : 0,
        limit: isLimitedUsage ? formData.limit : null,
        startDate,
        endDate,
      };
      if (termsConditions) submitData.termsConditions = termsConditions;
      dispatch(
        createDiscount(
          submitData,
          () => {
            showToastSuccess('Your discount code has been added successfully');
            onSuccess();
            setShowDialog(false);
            resetForm();
          },
          () => {
            showToastError('An error occurred');
          }
        )
      );
    } else {
      setDateRangeError('Invalid date and time range');
    }
  };
  const resetForm = () => {
    setRangeTime({
      startTime: 0,
      endTime: 0,
    });
    reset(defaultValues);
  };
  return (
    <Dialog
      header="Add new discount code"
      isOpen={showDialog}
      onClose={() => setShowDialog(false)}
    >
      <div className="w-full px-4 py-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full mb-3">
            <label
              htmlFor="discountBase"
              className="block text-grey60 text-xs mb-1"
            >
              Discount Base
            </label>
            <SelectField
              ref={register}
              name="discountBase"
              options={[
                { value: 'referral', label: 'Referral' },
                { value: 'voucher', label: 'Voucher' },
              ]}
            />
          </div>
          <div className="w-full mb-3">
            <label htmlFor="name" className="block text-grey60 text-xs mb-1">
              Discount Code
            </label>
            <TextField
              ref={register}
              type="text"
              name="name"
              placeholder="Enter your discount code without space"
            />
            <p
              className={clsx(
                'text-xs mt-px',
                errors.name?.message ? 'text-red50' : 'text-grey30'
              )}
            >
              {errors.name?.message ||
                'Customers will enter this voucher code at checkout'}
            </p>
          </div>
          <div className="w-full mb-3">
            <label
              htmlFor="description"
              className="block text-grey60 text-xs mb-1"
            >
              Description
            </label>
            <Controller
              control={control}
              name="description"
              render={({ onChange, value }) => (
                <>
                  <TextField
                    type="text"
                    name="description"
                    placeholder="Enter your discount description"
                    value={value}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= MAX_DESCRIPTION) {
                        onChange(inputValue);
                      }
                    }}
                  />
                  <div className="flex justify-between">
                    <p className="text-xs mt-px text-red50">
                      {errors.description?.message}
                    </p>
                    <p className={clsx('text-xs mt-px text-grey40 text-right')}>
                      {value.length} / {MAX_DESCRIPTION}
                    </p>
                  </div>
                </>
              )}
            />
          </div>
          {discountBase.toLowerCase() === 'voucher' && (
            <>
              <div className="w-full mb-3">
                <label
                  htmlFor="termsConditions"
                  className="block text-grey60 text-xs mb-1"
                >
                  Terms and Condition
                </label>
                <div>
                  <textarea
                    ref={register}
                    name="termsConditions"
                    placeholder="Enter terms and conditions"
                    rows="3"
                    className="w-full text-sm resize-y bg-white p-2 focus:outline-none focus:border-transparent border border-grey40 focus:ring-grey40 rounded-sm"
                  ></textarea>
                  <p className="text-xs mt-px text-red50">
                    {errors.termsConditions?.message}
                  </p>
                  <ul className="list-disc list-inside color-grey50 mt-1 mb-2">
                    <li className="text-xs mt-px text-grey50">
                      Separate each term with Enter
                    </li>
                    <li className="text-xs mt-px text-grey50">
                      Don't use numbers in front of each term
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex items-center mb-3 mt-3">
                <input
                  type="checkbox"
                  name="isVisible"
                  ref={register}
                  className="border text-teal2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-2"
                />
                <label
                  htmlFor="isVisible"
                  className="block text-grey100 text-xs"
                >
                  Show voucher on the user homepage
                </label>
              </div>
            </>
          )}

          <div className="flex mb-3">
            <div className="w-1/2 mr-1">
              <label htmlFor="type" className="block text-grey60 text-xs mb-1">
                Discount Type
              </label>
              <SelectField ref={register} name="type" options={VOUCHER_TYPES} />
              <p className="text-xs text-red50 mt-2">{errors.type?.message}</p>
            </div>
            <div className="w-1/2 ml-1">
              <label htmlFor="value" className="block text-grey60 text-xs mb-1">
                Discount Value
              </label>
              <TextField
                ref={register}
                type="text"
                name="value"
                className={clsx(
                  'text-right',
                  watch('type') === 'amount' && 'pr-3'
                )}
                nameIconRight={
                  watch('type') === 'percentage' ? IconNames.PERCENTAGE : null
                }
              />
              <p className="text-xs text-red50 mt-px">
                {errors.value?.message}
              </p>
            </div>
          </div>
          {discountBase.toLowerCase() === 'voucher' && (
            <div className="flex items-center mb-2">
              <input
                name="hasMinimumPurchase"
                type="checkbox"
                className="border text-teal2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-2"
                ref={register}
              />
              <label
                htmlFor="hasMinimumPurchase"
                className="block text-grey100 text-xs"
              >
                Set minimum purchase to use voucher
              </label>
            </div>
          )}

          {discountBase.toLowerCase() === 'voucher' && hasMinimumPurchase && (
            <div className="w-full mb-3">
              <label
                htmlFor="minimumPurchase"
                className="block text-grey60 text-xs mb-1"
              >
                Minimum Purchase
              </label>
              <TextField ref={register} name="minimumPurchase" />
              <p className="text-xs text-red50 mt-px">
                {errors.minimumPurchase?.message}
              </p>
            </div>
          )}
          <div className="w-full mb-3">
            <label
              htmlFor="eligibility"
              className="block text-grey60 text-xs mb-1"
            >
              Customer Eligibility
            </label>
            <SelectField
              ref={register}
              name="eligibility"
              options={ELIGIBILITIES}
            />
            <p className="text-xs text-red50 mt-2">
              {errors.eligibility?.message}
            </p>
          </div>

          <div className="flex items-center mb-1">
            <input
              ref={register}
              name="isLimitedUsage"
              type="checkbox"
              className="border text-teal2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-2"
            />
            <label
              htmlFor="isLimitedUsage"
              className="block text-grey100 text-xs"
            >
              Set discount usage limits (unlimited usage if unchecked)
            </label>
          </div>
          {isLimitedUsage && (
            <div className="w-full mb-1">
              <label htmlFor="limit" className="block text-grey60 text-xs mb-1">
                Usage Limit
              </label>
              <TextField ref={register} name="limit" />
              <p className="text-xs text-red50 mt-px">
                {errors.limit?.message}
              </p>
            </div>
          )}
          <div className="flex items-center mb-3 mt-3">
            <input
              type="checkbox"
              name="isLimitedPerUser"
              ref={register}
              className="border text-teal2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-2"
            />
            <label
              htmlFor="isLimitedPerUser"
              className="block text-grey100 text-xs"
            >
              Limit to one use per customer
            </label>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 mr-1">
              <label
                htmlFor="startDate-input"
                className="block text-grey60 text-xs mb-1"
              >
                Start Date
              </label>
              <input
                id="startDate-input"
                type="date"
                name="startDate"
                ref={register}
                className={clsx(
                  'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                  'border-grey50'
                )}
              />
              <p className="text-xs text-red50 mt-2">
                {errors.startDate?.message}
              </p>
            </div>
            <div className="w-1/2 ml-1">
              <label
                htmlFor="fullName-input"
                className="block text-grey60 text-xs mb-1"
              >
                Start Time (WIB)
              </label>
              <div className="relative">
                <TimePicker
                  className={clsx(
                    'custom-time-picker border rounded w-full pt-1 pb-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                    'border-grey50'
                  )}
                  value={rangeTime ? new Date(rangeTime.startTime) : new Date()}
                  onChange={(time) => {
                    setRangeTime((prev) => ({
                      ...prev,
                      startTime: time.toUTCString(),
                    }));
                  }}
                />
                <Icon
                  icon={IconNames.TIME}
                  iconSize={15}
                  className="fill-curent text-grey50 hover:text-grey50 absolute top-3 right-2"
                />
              </div>
            </div>
          </div>
          <div className="flex mb-3">
            <div className="w-1/2 mr-1">
              <label
                htmlFor="endDate-input"
                className="block text-grey60 text-xs mb-1"
              >
                End Date
              </label>
              <input
                id="endDate-input"
                type="date"
                name="endDate"
                ref={register}
                className={clsx(
                  'border rounded w-full p-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                  'border-grey50'
                )}
              />
              <p className="text-xs text-red50 mt-1">
                {errors.endDate?.message || dateRangeError}
              </p>
            </div>
            <div className="w-1/2 ml-1">
              <label
                htmlFor="fullName-input"
                className="block text-grey60 text-xs mb-1"
              >
                End Time (WIB)
              </label>
              <div className="relative">
                <TimePicker
                  className={clsx(
                    'custom-time-picker border rounded w-full pt-1 pb-2 px-3 focus:ring-transparent focus:outline-none focus:border-blue50',
                    'border-grey50'
                  )}
                  value={rangeTime ? new Date(rangeTime.endTime) : new Date()}
                  onChange={(time) => {
                    setRangeTime((prev) => ({
                      ...prev,
                      endTime: time.toUTCString(),
                    }));
                  }}
                />
                <Icon
                  icon={IconNames.TIME}
                  iconSize={15}
                  className="fill-curent text-grey50 hover:text-grey50 absolute top-3 right-2"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="flex justify-end">
          <Button
            view="outlined"
            color="danger"
            className="mr-2"
            onClick={() => {
              resetForm();
              setShowDialog(false);
            }}
          >
            Discard
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            isLoading={loadingAddDiscount}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
