import { createAction } from '../utils/actionCreator';

const PRE = 'UI';

export const displayAlert = createAction(`${PRE}/DISPLAY_ALERT`);
export const dismissAlert = createAction(`${PRE}/DISMISS_ALERT`);
export const toggleSelectedRow = createAction(`${PRE}/TOGGLE_SELECTED_ROW`);
export const selectAllRows = createAction(`${PRE}/SELECT_ALL_ROWS`);
export const resetSelectedRows = createAction(`${PRE}/RESET_SELECTED_ROWS`);

export const selectAlert = (state) => state.ui.alert;

export const ALERT_TYPE = Object.freeze({
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
});

const initialState = {
  alert: {
    type: null,
    message: '',
  },
  table: {
    selectedRows: [], // collection of ids
    selectedRowsOriginal: [], // collection of original data
  },
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case displayAlert.type:
      return {
        ...state,
        alert: action.payload,
      };
    case toggleSelectedRow.type: {
      const { selectedRows, selectedRowsOriginal } = state.table;
      const selectedData = action.payload;
      const selectedId = selectedData.id;
      const updatedOriginals = selectedRows.includes(selectedId)
        ? selectedRowsOriginal.filter((data) => data.id !== selectedId)
        : selectedRowsOriginal.concat(selectedData);
      return {
        ...state,
        table: {
          ...state.table,
          selectedRows: updatedOriginals.map((data) => data.id),
          selectedRowsOriginal: updatedOriginals,
        },
      };
    }
    case selectAllRows.type: {
      return {
        ...state,
        table: {
          ...state.table,
          selectedRows: action.payload.map((data) => data.id),
          selectedRowsOriginal: action.payload,
        },
      };
    }
    case resetSelectedRows.type: {
      return {
        ...state,
        table: {
          ...state.table,
          selectedRows: [],
          selectedRowsOriginal: [],
        },
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
