import {
  actionFailure,
  actionSuccess,
  createAction,
  createAsyncAction,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import ENDPOINTS from '../urls';

export const uploadVariableCSV = createAsyncAction(
  'IMPORT_VARIABLE',
  (file) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'IMPORT_VARIABLE',
      'post',
      `${ENDPOINTS.UPLOAD_VARIABLE_CSV}`,
      file,
      'multipart/form-data',
      null,
      null,
      (progress) => {
        const { loaded, total } = progress;
        const calculatedProgress = Math.floor((loaded / total) * 100);
        dispatch(changeProgress(calculatedProgress));
      }
    );
  }
);

export const getCategories = createAsyncAction(
  'GET_VARIABLE_CATEGORIES',
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_CATEGORIES',
      'get',
      `${ENDPOINTS.GET_VARIABLE_CATEGORIES}`
    );
  }
);

export const getClassifications = createAsyncAction(
  'GET_VARIABLE_CLASSIFICATIONS',
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_CLASSIFICATIONS',
      'get',
      `${ENDPOINTS.GET_VARIABLE_CLASSIFICATIONS}`
    );
  }
);

export const createVariableTransformation = createAsyncAction(
  'CREATE_VARIABLE_TRANSFORMATION',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'CREATE_VARIABLE_TRANSFORMATION',
      'post',
      `${ENDPOINTS.VARIABLE_TRANSFORMATION}`,
      data
    );
  }
);

export const getVariableData = createAsyncAction(
  'GET_VARIABLE_DATA',
  (id) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_DATA',
      'get',
      `${ENDPOINTS.VARIABLE_TRANSFORMATION}/${id}`
    );
  }
);

export const runVariableIndexing = createAsyncAction(
  'RUN_VARIABLE_INDEXING',
  (variableId, documentIds = [], onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'RUN_VARIABLE_INDEXING',
        'post',
        `${ENDPOINTS.VARIABLE_INDEXING}`,
        {
          variableIds: [variableId],
          documentIds,
          statuses: [],
        },
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const runAllVariableIndexing = createAsyncAction(
  'RUN_ALL_VARIABLE_INDEXING',
  (onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'RUN_ALL_VARIABLE_INDEXING',
        'post',
        `${ENDPOINTS.VARIABLE_INDEXING}`,
        {
          variableIds: [],
          documentIds: [],
        },
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const updateVariableTransformation = createAsyncAction(
  'UPDATE_VARIABLE_TRANSFORMATION',
  (id, data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'UPDATE_VARIABLE_TRANSFORMATION',
      'patch',
      `${ENDPOINTS.VARIABLE_TRANSFORMATION}/${id}`,
      data
    );
  }
);

export const togglePublishVariable = createAsyncAction(
  'TOGGLE_PUBLISH_VARIABLE',
  (id, data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'TOGGLE_PUBLISH_VARIABLE',
      'patch',
      `${ENDPOINTS.TOGGLE_PUBLISH_VARIABLE}/${id}`,
      data
    );
  }
);

export const deleteVariableTransformation = createAsyncAction(
  'DELETE_VARIABLE_TRANSFORMATION',
  (id, data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'DELETE_VARIABLE_TRANSFORMATION',
      'delete',
      `${ENDPOINTS.VARIABLE_TRANSFORMATION}/${id}`
    );
  }
);

export const getListVariable = createAsyncAction(
  'GET_LIST_VARIABLE',
  (params) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_LIST_VARIABLE',
      'get',
      ENDPOINTS.VARIABLE_LIST,
      params
    );
  }
);

export const getListVariableDetail = createAsyncAction(
  'GET_LIST_VARIABLE_DETAIL',
  (id, params = {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'GET_LIST_VARIABLE_DETAIL',
        'get',
        ENDPOINTS.VARIABLE_LIST_DETAIL,
        {
          id,
          ...params,
        }
      );
    }
);

export const getVariableDetailInformation = createAsyncAction(
  'GET_VARIABLE_DETAIL_INFORMATION',
  (id) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_DETAIL_INFORMATION',
      'get',
      `${ENDPOINTS.VARIABLE_DETAIL_INFORMATION}/${id}`
    );
  }
);

export const getVariableSuggestions = createAsyncAction(
  'GET_VARIABLE_SUGGESTIONS',
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_SUGGESTIONS',
      'get',
      ENDPOINTS.GET_SUGGESTIONS
    );
  }
);

export const runTransformationExample = createAsyncAction(
  'TEST_TRANSFORMATION_OUTPUT_EXAMPLE',
  (query) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'TEST_TRANSFORMATION_OUTPUT_EXAMPLE',
      'post',
      ENDPOINTS.TRANSFORMATION_OUTPUT_EXAMPLE,
      {
        data: {
          transformation: query,
        },
      }
    );
  }
);

export const getVariableRefId = createAsyncAction(
  'GET_VARIABLE_REF_ID',
  (variableName) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_REF_ID',
      'get',
      `${ENDPOINTS.GET_SUGGESTIONS}/${variableName}`
    );
  }
);

export const getVariableIndexingStats = createAsyncAction(
  'GET_VARIABLE_INDEXING_STATS',
  (id) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_VARIABLE_INDEXING_STATS',
      'get',
      `${ENDPOINTS.GET_VARIABLE_INDEXING_STATS}/${id}`
    );
  }
);

export const initTransformationDocuments = createAsyncAction(
  'INIT_TRANSFORMATION_DOCUMENTS',
  (id, years, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'INIT_TRANSFORMATION_DOCUMENTS',
        'post',
        ENDPOINTS.INIT_TRANSFORMATION_DOCUMENTS,
        {
          variableIds: [id],
          documentIds: [],
          years,
        },
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const downloadVariableTransformation = createAsyncAction(
  'DOWNLOAD_VARIABLE_TRANSFORMATION',
  (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'DOWNLOAD_VARIABLE_TRANSFORMATION',
        'post',
        ENDPOINTS.DOWNLOAD_VARIABLE_TRANSFORMATION,
        data,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const reportTransformationResult = createAsyncAction(
  'REPORT_TRANSFORMATION_RESULT',
  (id, taskId, variableId, notes, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'REPORT_TRANSFORMATION_RESULT',
        'post',
        `${ENDPOINTS.REPORT_TRANSFORMATION_RESULT}/${id}`,
        {
          taskId,
          variableId,
          notes,
        },
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const nullifyTransformationResult = createAsyncAction(
  'NULLIFY_TRANSFORMATION_RESULT',
  (variableId, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'NULLIFY_TRANSFORMATION_RESULT',
        'post',
        ENDPOINTS.NULLIFY_TRANSFORMATION_RESULT,
        { id: variableId },
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const importTransformationResult = createAsyncAction(
  'IMPORT_TRANSFORMATION_RESULT',
  (variableId, formData, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'IMPORT_TRANSFORMATION_RESULT',
        'post',
        `${ENDPOINTS.IMPORT_TRANSFORMATION_RESULT}/${variableId}`,
        formData,
        'multipart/form-data',
        onSuccess,
        onError,
        (progress) => {
          const { loaded, total } = progress;
          const calculatedProgress = Math.floor((loaded / total) * 100);
          dispatch(changeProgress(calculatedProgress));
        }
      );
    }
);

export const clearInitializedDocuments = createAsyncAction(
  'CLEAR_INITIALIZED_DOCUMENTS',
  (variableId, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'CLEAR_INITIALIZED_DOCUMENTS',
        'post',
        `${ENDPOINTS.CLEAR_INITIALIZED_DOCUMENTS}/${variableId}`,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const changeProgress = createAction('CHANGE_IMPORT_VARIABLE_PROGRESS');
export const hideProgress = createAction('HIDE_IMPORT_VARIABLE_PROGRESS');
export const setSelectedVariable = createAction('SET_SELECTED_VARIABLE');
export const changeVariableListFilter = createAction(
  'CHANGE_VARIABLE_LIST_FILTER'
);
export const changeVariableListPage = createAction('CHANGE_VARIABLE_LIST_PAGE');
export const changeVariableDetailListFilter = createAction(
  'CHANGE_VARIABLE_DETAIL_LIST_FILTER'
);
export const changeVariableDetailListPage = createAction(
  'CHANGE_VARIABLE_DETAIL_LIST_PAGE'
);
export const resetVariableDetailListFilter = createAction(
  'RESET_VARIABLE_DETAIL_LIST_FILTER'
);
export const resetVariableDetailListPage = createAction(
  'RESET_VARIABLE_DETAIL_LIST_PAGE'
);
export const resetProgress = createAction('RESET_IMPORT_VARIABLE_PROGRESS');

const initialState = {
  editVariable: [],
  listVariableDetail: {
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
    },
    filter: {
      q: '',
      year: '',
      status: '',
    },
    contents: [],
  },
  listVariable: {
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
    },
    filter: {
      q: '',
      classification_id: '',
      label: '',
      status: '',
    },
    list: [],
  },
  categories: [],
  classifications: [],
  selected: {
    id: '',
    name: '',
    label: '',
    description: '',
    output: '',
    category: {
      id: '',
      name: '',
    },
    classification: {
      id: '',
      name: '',
      price: '',
    },
    last_indexing: '',
    indexingStats: {
      totalDocument: 0,
      successful: 0,
      queued: 0,
      failed: 0,
    },
    transformation: '',
    isPublished: false,
    injectedData: false,
    price: 0,
  },
  upload: {
    visible: false,
    progress: 0,
  },
  suggestions: {},
};

const variableManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(getListVariable.type): {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        listVariable: {
          ...state.listVariable,
          pageData,
          list,
        },
      };
    }
    case changeVariableListFilter.type:
      return {
        ...state,
        listVariable: {
          ...state.listVariable,
          filter: {
            ...state.listVariable.filter,
            ...action.payload,
          },
          pageData: {
            ...state.listVariable,
            currentPageNumber: 1,
          },
        },
      };
    case changeVariableListPage.type:
      return {
        ...state,
        listVariable: {
          ...state.listVariable,
          pageData: {
            ...state.listVariable.pageData,
            ...action.payload,
          },
        },
      };
    case actionSuccess(getCategories.type):
      return {
        ...state,
        categories: action.payload,
      };
    case actionSuccess(getClassifications.type):
      return {
        ...state,
        classifications: action.payload,
      };
    case actionSuccess(getVariableData.type):
      return {
        ...state,
        selected: action.payload,
      };
    case changeProgress.type: {
      return {
        ...state,
        upload: {
          ...state.upload,
          visible: true,
          progress: action.payload,
        },
      };
    }
    case resetProgress.type: {
      return {
        ...state,
        upload: {
          ...state.upload,
          visible: false,
          progress: 0,
        },
      };
    }
    case setSelectedVariable.type:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    case actionSuccess(uploadVariableCSV.type):
    case actionFailure(uploadVariableCSV.type):
      return {
        ...state,
        upload: initialState.upload,
      };
    case actionSuccess(getListVariableDetail.type): {
      const { contents, ...pageData } = action.payload;
      return {
        ...state,
        listVariableDetail: {
          ...state.listVariableDetail,
          pageData,
          contents,
        },
      };
    }
    case actionSuccess(getVariableDetailInformation.type):
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    case actionSuccess(getVariableSuggestions.type): {
      return {
        ...state,
        suggestions: action.payload,
      };
    }
    case actionSuccess(getVariableIndexingStats.type): {
      const { document, queue, success, failed } = action.payload;
      return {
        ...state,
        selected: {
          ...state.selected,
          indexingStats: {
            totalDocument: document.total,
            queued: queue.total,
            successful: success.total,
            failed: failed.total,
          },
        },
      };
    }
    case changeVariableDetailListFilter.type: {
      return {
        ...state,
        listVariableDetail: {
          ...state.listVariableDetail,
          filter: { ...state.listVariableDetail.filter, ...action.payload },
          pageData: {
            ...state.listVariableDetail.pageData,
            currentPageNumber: 1,
          },
        },
      };
    }
    case changeVariableDetailListPage.type: {
      return {
        ...state,
        listVariableDetail: {
          ...state.listVariableDetail,
          pageData: { ...state.listVariableDetail.pageData, ...action.payload },
        },
      };
    }
    case resetVariableDetailListFilter.type: {
      return {
        ...state,
        listVariableDetail: {
          ...state.listVariableDetail,
          filter: { ...initialState.listVariableDetail.filter },
        },
      };
    }
    case resetVariableDetailListPage.type: {
      return {
        ...state,
        listVariableDetail: {
          ...state.listVariableDetail,
          pageData: { ...initialState.listVariableDetail.pageData },
        },
      };
    }
    default:
      return state;
  }
};

export default variableManagementReducer;
