import {
  createAsyncAction,
  createAction,
  actionSuccess,
  actionFailure,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import ENDPOINTS from '../urls';
import { range, uniq } from 'lodash';

const PRE = 'buyVariable';

export const getListGroupCategory = createAsyncAction(
  `${PRE}/GET_LIST_GROUP_CATEGORY`,
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_LIST_GROUP_CATEGORY`,
      'get',
      `${ENDPOINTS.GET_LIST_GROUP_CATEGORY}`
    );
  }
);

export const getFilterPeriodRange = createAsyncAction(
  `${PRE}/GET_FILTER_PERIOD_RANGE`,
  () => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_FILTER_PERIOD_RANGE`,
      'get',
      ENDPOINTS.GET_PERIOD_RANGE
    );
  }
);
export const getClassifications = createAsyncAction(
  `${PRE}/GET_VARIABLE_CLASSIFICATIONS`,
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_VARIABLE_CLASSIFICATIONS`,
      'get',
      `${ENDPOINTS.GET_VARIABLE_CLASSIFICATIONS}`
    );
  }
);
export const getFilterCompanyJASICA = createAsyncAction(
  `${PRE}/GET_FILTER_COMPANY_JASICA`,
  () => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_FILTER_COMPANY_JASICA`,
      'get',
      ENDPOINTS.GET_COMPANY_LIST,
      { groups: 'JASICA' }
    );
  }
);
export const getFilterCompanySIC = createAsyncAction(
  `${PRE}/GET_FILTER_COMPANY_SIC`,
  () => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_FILTER_COMPANY_SIC`,
      'get',
      ENDPOINTS.GET_COMPANY_LIST,
      { groups: 'SIC' }
    );
  }
);
export const setSelectedYears = createAction('SET_SELECTED_YEARS');

export const previewSelectedVariable = createAsyncAction(
  `${PRE}/PREVIEW_SELECTED_VARIABLE`,
  (data) => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/PREVIEW_SELECTED_VARIABLE`,
      'post',
      ENDPOINTS.PREVIEW_SELECTED_VARIABLE,
      data
    );
  }
);

export const createOrderDataset = createAsyncAction(
  `${PRE}/CREATE_ORDER_QUANTITATIVE_DATASET`,
  (data, onSuccess, onError) => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/CREATE_ORDER_QUANTITATIVE_DATASET`,
      'post',
      ENDPOINTS.CREATE_ORDER_QUANTITATIVE_DATASET,
      data,
      'application/json',
      onSuccess,
      onError
    );
  }
);

export const addVariableStats = createAsyncAction(
  `${PRE}/ADD_VARIABLE_STATS`,
  (data, onSuccess, onError) => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/ADD_VARIABLE_STATS`,
      'post',
      ENDPOINTS.ADD_VARIABLE_STATS,
      data,
      'application/json',
      onSuccess,
      onError
    );
  }
);

export const updateVariableStas = createAction('UPDATE_VARIABLE_STATS');

export const getAvailableQDVoucher = createAsyncAction(
  `${PRE}/GET_AVAIABLE_QD_VOUCHER`,
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_AVAIABLE_QD_VOUCHER`,
      'get',
      `${ENDPOINTS.GET_AVAIABLE_QD_VOUCHER}`
    );
  }
);

export const checkDiscountQD = createAsyncAction(
  `${PRE}/CHECK_DISCOUNT_QD`,
  (data, onSuccess, onError) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/CHECK_DISCOUNT_QD`,
      'POST',
      ENDPOINTS.CHECK_DISCOUNT_QD,
      data,
      'application/json',
      onSuccess,
      onError
    );
  }
);
export const getDetailQDVoucher = createAsyncAction(
  `${PRE}/GET_DETAIL_QD_VOUCHER`,
  (id) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_DETAIL_QD_VOUCHER`,
      'get',
      `${ENDPOINTS.DISCOUNT}/${id}`
    );
  }
);
export const setSelectedClassification = createAction(
  `${PRE}/SET_SELECTED_CLASSIFICATION`
);
export const addSelectedClassification = createAction(
  `${PRE}/ADD_SELECTED_CLASSIFICATION`
);
export const removeSelectedClassification = createAction(
  `${PRE}/REMOVE_SELECTED_CLASSIFICATION`
);
export const resetSelectedClassification = createAction(
  `${PRE}/RESET_SELECTED_CLASSIFICATION`
);
export const getSICList = createAsyncAction(
  `${PRE}/GET_SIC_LIST`,
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_SIC_LIST`,
      'get',
      `${ENDPOINTS.GET_INDUSTRY_CLASSIFICATION}`,
      { type: 'SIC' }
    );
  }
);
export const getIDXICList = createAsyncAction(
  `${PRE}/GET_IDX_IC_LIST`,
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_IDX_IC_LIST`,
      'get',
      `${ENDPOINTS.GET_INDUSTRY_CLASSIFICATION}`,
      { type: 'IDX-IC' }
    );
  }
);
export const getTickerList = createAsyncAction(
  `${PRE}/GET_TICKER_LIST`,
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_TICKER_LIST`,
      'get',
      `${ENDPOINTS.GET_COMPANY_LIST}`
    );
  }
);

const initialState = {
  filterYear: {
    listYear: [],
    selectedYears: [],
  },
  classifications: [],
  filterCompany: {
    'IDX-IC': [],
    SIC: [],
    tickers: [],
    selected: {},
  },
  listVariableStats: [],
  listVariableGroup: [],
  previewVariable: {
    columnNames: [],
    rows: [],
  },
  availableQDVoucher: [],
  detailQDVoucher: {},
};

const userSelectVariableReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(getListGroupCategory.type):
      return {
        ...state,
        listVariableGroup: action.payload || [],
      };
    case actionSuccess(getFilterPeriodRange.type):
      const listYear = range(action.payload[0], action.payload[1] + 1);
      return {
        ...state,
        filterYear: {
          ...state.filterYear,
          listYear,
        },
      };
    case actionFailure(getFilterPeriodRange.type):
      return {
        ...state,
        filterYear: {
          ...state.filterYear,
          listYear: [],
        },
      };
    case setSelectedYears.type:
      return {
        ...state,
        filterYear: {
          ...state.filterYear,
          selectedYears: action.payload,
        },
      };
    case actionSuccess(getFilterCompanyJASICA.type):
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          JASICA: action.payload,
        },
      };

    case actionSuccess(getFilterCompanySIC.type):
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          SIC: action.payload,
        },
      };
    case actionSuccess(getClassifications.type):
      return {
        ...state,
        classifications: action.payload,
      };
    case actionSuccess(previewSelectedVariable.type):
      return {
        ...state,
        previewVariable: action.payload,
      };
    case actionSuccess(addVariableStats.type):
      return {
        ...state,
        listVariableStats: action.payload,
      };
    case updateVariableStas.type:
      return {
        ...state,
        listVariableStats: action.payload,
      };
    case actionSuccess(getAvailableQDVoucher.type):
      return {
        ...state,
        availableQDVoucher: action.payload,
      };
    case actionSuccess(getDetailQDVoucher.type):
      return {
        ...state,
        detailQDVoucher: action.payload,
      };
    case setSelectedClassification.type:
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          selected: action.payload,
        },
      };
    case addSelectedClassification.type: {
      const { code, value } = action.payload;
      const selected = Object.assign({}, state.filterCompany.selected, {
        [code]: value,
      });
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          selected,
        },
      };
    }
    case removeSelectedClassification.type: {
      const code = action.payload;
      const copySelected = Object.assign({}, state.filterCompany.selected);
      delete copySelected[code];
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          selected: copySelected,
        },
      };
    }
    case resetSelectedClassification.type:
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          selected: {},
        },
      };
    case actionSuccess(getSICList.type): {
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          SIC: action.payload,
        },
      };
    }
    case actionSuccess(getIDXICList.type): {
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          'IDX-IC': action.payload,
        },
      };
    }
    case actionSuccess(getTickerList.type): {
      return {
        ...state,
        filterCompany: {
          ...state.filterCompany,
          tickers: uniq(action.payload.map((data) => data.ticker)),
        },
      };
    }
    default:
      return state;
  }
};

export default userSelectVariableReducer;
