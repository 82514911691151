import { useHistory } from 'react-router-dom';
import Mandiri from 'assets/svg/bank_mandiri.svg';
import Countdown from '../Countdown';
import PATH_URL from 'routers/path';
import Button from 'components/Button';

export default function MandiriVa({
  vaNumber,
  price,
  dueDate,
  cancel,
  setShowDetailInvoice,
  differenceInSecond,
}) {
  const history = useHistory();
  const maxWidthPhone = {
    maxWidth: '480px',
  };
  return (
    <div>
      <div className={'text-center'}>
        <div className={'text-xl'}>Complete payment in</div>
        <Countdown differenceInSecond={differenceInSecond} />
        <div className={'text-xl text-gray50 font-normal pb-2'}>
          Payment due date
        </div>

        <div className={'text-xl text-grey100'}>{dueDate}</div>
      </div>
      <div
        className={'mt-10 px-4 pb-4 rounded border mb-4'}
        style={maxWidthPhone}
      >
        <div className={'flex justify-between py-6'}>
          <div className={'text-xl font-medium'}>Mandiri Virtual Account</div>
          <div>
            <img src={Mandiri} alt={'mandiri'} className={'w-14'} />
          </div>
        </div>
        <div className={'flex justify-between py-6'}>
          <div>
            <div className={'text-sm text-gray50'}>Virtual Account Number</div>
            <div>{vaNumber}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(vaNumber);
              }}
            >
              Copy
            </button>
          </div>
        </div>
        <div className={'flex justify-between'}>
          <div>
            <div className={'text-sm text-gray50'}>Total Payment</div>
            <div className={'text-xl text-red50 font-medium'}>{price}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                setShowDetailInvoice(true);
              }}
            >
              View Detail
            </button>
          </div>
        </div>
      </div>

      <div className={'flex justify-between'}>
        <Button
          view="outlined"
          size="medium"
          onClick={cancel}
          className="w-1/2"
        >
          Cancel Order
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={() => {
            history.push(PATH_URL.USER_PROFILE);
          }}
          className="w-1/2 ml-4"
        >
          View My Billing
        </Button>
      </div>

      <div style={maxWidthPhone}>
        <div className={'text-xl font-medium py-4'}>Payment Method</div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>ATM Mandiri</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              On the main menu, choose Pay/Buy
            </li>
            <li className={'text-gray50 text-sm'}>Choose Others</li>
            <li className={'text-gray50 text-sm'}>Choose Multi Payment</li>
            <li className={'text-gray50 text-sm'}>
              Enter 70012 (Midtrans company code) and press Correct
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter your Payment Code and press Correct
            </li>
            <li className={'text-gray50 text-sm'}>
              Your payment details will appear on the payment confirmation page.
              If the information is correct press Yes
            </li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>Internet Banking</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              Login to Mandiri Internet Banking
              (https://ibank.bankmandiri.co.id/)
            </li>
            <li className={'text-gray50 text-sm'}>
              From the main menu choose Payment, then choose Multi Payment
            </li>
            <li className={'text-gray50 text-sm'}>
              Select your account in From Account, then in Billing Name select
              Midtrans
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter the Payment Code and you will receive your payment details
            </li>
            <li className={'text-gray50 text-sm'}>
              Confirm your payment using your Mandiri Token
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
