import { useHistory } from 'react-router-dom';
import Cart from 'assets/svg/cart.svg';
import Button from 'components/Button';
import PATH_URL from 'routers/path';

export default function PaymentCanceled() {
  const history = useHistory();
  return (
    <div className={'w-full flex flex-col items-center mt-40'}>
      <img src={Cart} alt="Payment Canceled" />
      <div
        className={'text-center flex flex-col items-center'}
        style={{ width: '430px' }}
      >
        <p className={'text-grey100 text-2xl font-bold mt-4'}>
          You haven't made any order
        </p>
        <p className={'text-xl text-grey50 mt-3 pb-4'}>
          Your order has been successfully canceled
        </p>
        <Button
          color="primary"
          onClick={() => {
            history.push(PATH_URL.SELECT_VARIABLE);
          }}
          className="mt-3"
        >
          Buy Dataset Now
        </Button>
      </div>
    </div>
  );
}
