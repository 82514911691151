import { useState, useMemo, useCallback, useEffect } from 'react';
import { Icon, Position, Popover, Tooltip, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import Button from '../../components/Button';
import CustomCollapseCategory from './CustomCollapseCategory';
import emptySelectedData from '../../assets/svg/emptySelectedData.svg';
import { currencyFormatter, numberFormatter } from 'utils/currencyFormatter';
import { debounce, uniq } from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { showToastError } from 'layouts/DashboardLayout';
import {
  getFilterPeriodRange,
  setSelectedYears,
  getListGroupCategory,
  previewSelectedVariable,
  updateVariableStas,
  addVariableStats,
  addSelectedClassification,
  removeSelectedClassification,
  resetSelectedClassification,
  setSelectedClassification,
  getTickerList,
  getIDXICList,
  getSICList,
} from '../../redux/features/userSelectVariable';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'components/Dialog';
import Skeleton from 'react-loading-skeleton';
import { createLoadingSelector } from '../../redux/api/loading';
import CustomDialogPayment from './CustomDialogPayment';
import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';

const TOOLTIPS = Object.freeze({
  'IDX-IC': 'IDX Industrial Classification',
  SIC: 'Standard Industrial Classification',
});

function UserSelectVariable() {
  const dispatch = useDispatch();
  const loadingGroupedVariable = useSelector(
    createLoadingSelector([getListGroupCategory.type])
  );
  const loadingFilter = useSelector(
    createLoadingSelector([
      getFilterPeriodRange.type,
      getSICList.type,
      getIDXICList.type,
      getTickerList.type,
    ])
  );
  const loadingVariableStats = useSelector(
    createLoadingSelector([addVariableStats.type])
  );
  const filterYear = useSelector(
    (state) => state.userSelectVariable.filterYear
  );
  const filterCompany = useSelector(
    (state) => state.userSelectVariable.filterCompany
  );

  const classificationOptions = useMemo(
    () => ({
      all: 'All industries',
      'IDX-IC': 'IDX-IC',
      SIC: 'SIC',
      tickers: 'Custom',
    }),
    []
  );
  const schema = Yup.object().shape({
    'company-codes': Yup.string().required(),
    'option-company-codes': Yup.string().required(),
  });
  const { register, watch, getValues, setValue } = useForm({
    defaultValues: {
      category: 'All categories',
      'company-codes': 'ticker',
      'option-company-codes': 'all',
    },
    shouldUnregister: false,
    resolver: yupResolver(schema),
  });
  const labels = useMemo(
    () => [
      'All categories',
      'Environment',
      'Social',
      'Governance',
      'Finance',
      'Others',
    ],
    []
  );
  const [searchKey, setSearchKey] = useState('');
  const [searchClassification, setSearchClassification] = useState('');
  const [appliedSearchClassification, setAppliedSearchClassification] =
    useState('');
  const [resetAll, setResetAll] = useState(false);
  const [collapsedSubs, setCollapsedSubs] = useState([]);
  const listVariableGroup = useSelector(
    (state) => state.userSelectVariable.listVariableGroup
  );
  const selectedClassification = useSelector(
    (state) => state.userSelectVariable.filterCompany.selected
  );
  const selectedClassificationCodes = Object.keys(selectedClassification);
  const selectedFilterCompany = watch('option-company-codes');
  const selectedLabel = watch('category');
  const searchAndFilterCategory = useMemo(
    () =>
      listVariableGroup
        .map((group) => ({
          ...group,
          variables: (group.variables || []).filter((variable) => {
            let status = true;
            if (selectedLabel.toLowerCase() !== 'all categories') {
              status =
                status &&
                variable.label.toLowerCase() === selectedLabel.toLowerCase();
            }
            if (searchKey) {
              const regex = RegExp(`${searchKey}`, 'i');
              status = status && regex.test(variable.name);
            }
            return status;
          }),
        }))
        .filter((v) => v.variables.length > 0),
    [listVariableGroup, searchKey, selectedLabel]
  );
  const [selectedVariable, setSelectedVariable] = useState({});
  const isAllSelected = useMemo(() => {
    const lengthSelected = Object.keys(selectedVariable).reduce(
      (sum, v) => sum + selectedVariable[v].length,
      0
    );
    return (
      lengthSelected > 0 &&
      lengthSelected ===
        searchAndFilterCategory.reduce((sum, v) => sum + v.variables.length, 0)
    );
  }, [selectedVariable, searchAndFilterCategory]);
  const canSelectAll = useMemo(
    () =>
      searchAndFilterCategory.reduce((sum, v) => sum + v.variables.length, 0) >
      0,
    [searchAndFilterCategory]
  );
  const listVariableStats = useSelector(
    (state) => state.userSelectVariable.listVariableStats
  );
  const [selectedToRemove, setSelectedToRemove] = useState([]);
  const totalAmountCart = useMemo(
    () =>
      listVariableStats.reduce(
        (sum, v) => sum + +(v.basePrice || 0) * +(v.totalCleanObservation || 0),
        0
      ),
    [listVariableStats]
  );
  const [showDialogReset, setShowDialogReset] = useState(false);
  const [showDialogRemove, setShowDialogRemove] = useState({
    show: false,
    toRemove: [],
  });
  const [showDialogPayment, setShowDialogPayment] = useState(false);
  const onGetPreview = useSelector(
    createLoadingSelector([previewSelectedVariable.type])
  );
  const [showDialogPreview, setDialogPreview] = useState(false);
  const [summaryExpanded, expandSummary] = useState(false);
  const previewVariable = useSelector(
    (state) => state.userSelectVariable.previewVariable
  );

  const dataOrderVariable = useCallback(
    (variables, withCodeVariant = true) => {
      let companyCodes;
      if (selectedClassificationCodes.length === 0) {
        if (selectedFilterCompany === 'SIC') {
          companyCodes = ['SIC-*'];
        } else if (selectedFilterCompany === 'IDX-IC') {
          companyCodes = ['IDX_IC-*'];
        } else {
          companyCodes = ['*'];
        }
      } else if (selectedFilterCompany === 'SIC') {
        companyCodes = selectedClassificationCodes.map((code) => `SIC-${code}`);
      } else if (selectedFilterCompany === 'IDX-IC') {
        companyCodes = selectedClassificationCodes.map(
          (code) => `IDX_IC-${code}`
        );
      } else {
        // ticker
        companyCodes = selectedClassificationCodes;
      }
      return {
        ...(withCodeVariant ? { companyCodeVariant: 'TICKER' } : {}),
        years:
          filterYear.selectedYears.length === 0
            ? filterYear.listYear
            : filterYear.selectedYears,
        companyCodes,
        variables,
      };
    },
    [filterYear, selectedFilterCompany, selectedClassificationCodes]
  );
  const addVariable = () => {
    dispatch(
      addVariableStats(
        dataOrderVariable(
          uniq(
            Object.keys(selectedVariable).reduce(
              (all, selected) => [
                ...all,
                ...selectedVariable[selected].map((v) => v.id),
              ],
              []
            )
          ),
          false
        ),
        (data) => {
          if (data.length === 0) {
            showToastError('The selected variable data is emtpy');
          }
        },
        () => {
          showToastError('Something went wrong');
        }
      )
    );
  };
  const resetFilter = () => {
    if (resetAll) {
      setSelectedVariable({});
      setSearchKey('');
      setValue('option-company-codes', 'all');
      setValue('category', 'All categories');
      dispatch(setSelectedYears([]));
      setResetAll(false);
    }
    dispatch(updateVariableStas([]));
    setShowDialogReset(false);
  };
  const handleCheckClassification = (code, value) => {
    if (!selectedClassificationCodes.includes(`${code}`)) {
      dispatch(addSelectedClassification({ code, value }));
    } else {
      dispatch(removeSelectedClassification(code));
    }
  };
  // eslint-disable-next-line
  const applySearchStr = useCallback(
    debounce((value) => {
      setAppliedSearchClassification(value);
    }, 300),
    []
  );
  const handleSearchClassification = (event) => {
    const { value } = event.target;
    setSearchClassification(value);
    applySearchStr(value);
  };
  const applySearchClassification = (acc, classification) => {
    const regexp = new RegExp(appliedSearchClassification, 'i');
    if (appliedSearchClassification === '') return acc.concat(classification);
    if (selectedFilterCompany !== 'tickers') {
      if (
        regexp.test(classification.code) ||
        regexp.test(classification.sector)
      )
        return acc.concat(classification);
      else {
        const matchSub = classification.subsectors.filter(
          (sub) => regexp.test(sub.code) || regexp.test(sub.name)
        );
        if (matchSub.length > 0) {
          return acc.concat({
            ...classification,
            subsectors: matchSub,
          });
        }
        return acc;
      }
    } else {
      if (regexp.test(classification)) return acc.concat(classification);
      return acc;
    }
  };
  const getSelectedYearsLabel = () => {
    const { selectedYears } = filterYear;
    const totalSelected = selectedYears.length;
    if (totalSelected > 0)
      return `${totalSelected} year${totalSelected > 1 ? 's' : ''}`;
    return 'All years';
  };
  const getIndustryLabel = (filterIndustry) => {
    if (filterIndustry === 'SIC') return 'Industry';
    if (filterIndustry === 'IDX-IC') return 'Sector';
    return 'Companies';
  };
  const toggleCollapsedSubs = (id) => {
    if (collapsedSubs.includes(id)) {
      setCollapsedSubs(
        collapsedSubs.filter((collapsedId) => collapsedId !== id)
      );
    } else {
      setCollapsedSubs(collapsedSubs.concat(id));
    }
  };
  const handleRemoveSummary = (type, value) => {
    if (listVariableStats.length > 0) {
      setShowDialogReset(true);
      return;
    }
    if (type === 'Year') {
      const { selectedYears: selected } = filterYear;
      dispatch(
        setSelectedYears(
          selected.filter((selectedYear) => selectedYear !== value)
        )
      );
    } else {
      const copySelected = Object.assign({}, selectedClassification);
      delete copySelected[value];
      dispatch(setSelectedClassification(copySelected));
    }
  };
  const displaySummary = () => {
    const { selectedYears } = filterYear;
    const appliedYears =
      selectedYears.length === 0
        ? [{ type: 'Year', value: 'All years' }]
        : selectedYears.map((year) => ({
            type: 'Year',
            value: year,
            onRemove: () => handleRemoveSummary('Year', year),
          }));
    let appliedIndustry;
    if (selectedClassificationCodes.length === 0) {
      if (['SIC', 'IDX-IC'].includes(selectedFilterCompany))
        appliedIndustry = [{ type: 'Sector', value: 'All sectors' }];
      else appliedIndustry = [{ type: 'Company', value: 'All companies' }];
    } else {
      appliedIndustry = selectedClassificationCodes.map((code) => ({
        type: 'Sector',
        value: selectedClassification[code],
        onRemove: () => handleRemoveSummary('Sector', code),
      }));
    }
    let combinedFilter = [...appliedYears, ...appliedIndustry];
    const buttonClassName =
      'px-3 py-1.5 text-grey70 bg-white border border-grey30 rounded-sm';
    const mapToComponent = (filter, index) => {
      return (
        <div key={`summary-${index}`} className={buttonClassName}>
          <span className="mr-3">
            {filter.type}: {filter.value}
          </span>
          {filter.onRemove && (
            <button onClick={filter.onRemove}>
              <Icon
                icon={IconNames.CROSS}
                iconSize={16}
                className="fill-current -mt-1"
              />
            </button>
          )}
        </div>
      );
    };
    const chips = combinedFilter.map(mapToComponent);
    if (combinedFilter.length > 3) {
      if (!summaryExpanded) {
        return chips.slice(0, 3).concat(
          <button
            key="show-more"
            onClick={() => expandSummary(true)}
            className={buttonClassName}
          >
            +{combinedFilter.length - 3}
          </button>
        );
      } else {
        return chips.concat(
          <button
            key="show-less"
            onClick={() => expandSummary(false)}
            className={buttonClassName}
          >
            Show less
          </button>
        );
      }
    }
    return chips;
  };

  useEffect(() => {
    dispatch(resetSelectedClassification());
    setSearchClassification('');
    setAppliedSearchClassification('');
  }, [dispatch, selectedFilterCompany]);

  useEffect(() => {
    dispatch(updateVariableStas([]));
    dispatch(getFilterPeriodRange());
    dispatch(getTickerList());
    dispatch(getSICList());
    dispatch(getIDXICList());
    dispatch(getListGroupCategory());
  }, [dispatch]);

  return (
    <div className="w-full">
      <div className="w-full mb-9 flex flex-col items-center pb-4 bg-blue0">
        <div className="lg:w-10/12 w-11/12 p-1">
          <div className="flex flex-col justify-between mb-5">
            <div className="w-full relative mt-9">
              <div className="w-full flex items-center">
                <span className="absolute left-4 rounded-md">
                  <Icon
                    icon={IconNames.SEARCH}
                    iconSize={16}
                    className="fill-current text-grey30 -mt-1"
                  />
                </span>
                <input
                  type="text"
                  name="keywords"
                  onChange={(e) => setSearchKey(e.target.value)}
                  placeholder="Search variable"
                  autoComplete="off"
                  className={`h-12 w-full bg-white border border-grey30 focus:border-grey50
                   rounded pl-11 focus:outline-none focus:ring-transparent`}
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-between mt-6">
              <Popover
                minimal
                position={Position.BOTTOM_LEFT}
                popoverClassName="w-full shadow-none"
                targetClassName="w-full"
                className="w-full mr-5"
              >
                <div
                  className="flex items-center justify-between border border-grey30 rounded bg-white p-3 cursor-pointer w-full"
                  style={{ minWidth: 210 }}
                >
                  <span>{getSelectedYearsLabel()}</span>
                  <Icon
                    icon={IconNames.CARET_DOWN}
                    iconSize={16}
                    className="fill-current text-blue50 -mt-1"
                  />
                </div>
                <div className="mt-2">
                  <div
                    className="rounded shadow-lg p-6 bg-white"
                    style={{ width: 400 }}
                  >
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-grey100 font-medium">Select Year</h2>
                      <button
                        className="text-red50"
                        onClick={() => {
                          dispatch(setSelectedYears([]));
                        }}
                      >
                        Reset
                      </button>
                    </div>
                    <div
                      className="grid grid-cols-3 gap-x-4 gap-y-5 overflow-y-auto"
                      style={{ maxHeight: 252 }}
                    >
                      {!loadingGroupedVariable ? (
                        filterYear.listYear.map((year) => {
                          return (
                            <Checkbox
                              key={year}
                              label={`${year}`}
                              onChange={() => {
                                if (listVariableStats.length > 0) {
                                  setShowDialogReset(true);
                                  return;
                                }
                                const { selectedYears: selected } = filterYear;
                                const updatedYears = selected.includes(year)
                                  ? selected.filter(
                                      (selectedYear) => selectedYear !== year
                                    )
                                  : selected.concat(year);
                                dispatch(setSelectedYears(updatedYears));
                              }}
                              checked={filterYear.selectedYears.includes(year)}
                              className="font-medium"
                            />
                          );
                        })
                      ) : (
                        <>
                          <Skeleton width={50} height={20} />
                          <Skeleton width={50} height={20} />
                          <Skeleton width={50} height={20} />
                          <Skeleton width={50} height={20} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Popover>
              <Popover
                minimal
                position={Position.BOTTOM_LEFT}
                popoverClassName="w-full shadow-none"
                targetClassName="w-full"
                className="w-full mr-5"
              >
                <div
                  className="flex items-center justify-between overflow-hidden border border-grey30 rounded bg-white p-3 cursor-pointer w-full"
                  style={{ minWidth: 210 }}
                >
                  <span>
                    {classificationOptions[watch('option-company-codes')]}
                  </span>
                  <Icon
                    icon={IconNames.CARET_DOWN}
                    iconSize={16}
                    className="fill-current text-blue50 -mt-1"
                  />
                </div>
                <div className="mt-2">
                  <div className="rounded shadow-lg p-6 bg-white w-80">
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-grey100 font-medium">
                        Classification
                      </h2>
                      <button
                        className="text-red50"
                        onClick={() => setValue('option-company-codes', 'all')}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="flex flex-col gap-y-6">
                      {[
                        { value: 'all', label: 'All industries' },
                        { value: 'IDX-IC', label: 'IDX-IC' },
                        { value: 'SIC', label: 'SIC' },
                        {
                          value: 'tickers',
                          label: 'Custom (select company manually)',
                        },
                      ].map((classification) => (
                        <div
                          key={classification.value}
                          className="flex items-center cursor-pointer"
                        >
                          <Radio
                            ref={register}
                            label={
                              <Tooltip
                                disabled={!TOOLTIPS[classification.value]}
                                openOnTargetFocus={false}
                                content={
                                  <p className="text-xs text-white max-w-md">
                                    {TOOLTIPS[classification.value]}
                                  </p>
                                }
                                position={Position.RIGHT}
                                className="mt-px"
                              >
                                <span>{classification.label}</span>
                              </Tooltip>
                            }
                            name="option-company-codes"
                            onChange={(event) => {
                              if (listVariableStats.length > 0) {
                                setShowDialogReset(true);
                                event.preventDefault();
                              } else {
                                setValue(
                                  'option-company-codes',
                                  event.target.value
                                );
                              }
                            }}
                            checked={
                              watch('option-company-codes') ===
                              classification.value
                            }
                            value={classification.value}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover>
              <Popover
                minimal
                position={Position.BOTTOM_LEFT}
                popoverClassName="w-full shadow-none"
                targetClassName="w-full"
                className="w-full mr-5"
              >
                <div
                  className="flex items-center justify-between overflow-hidden border border-grey30 rounded bg-white p-3 cursor-pointer w-full"
                  style={{ minWidth: 210 }}
                >
                  <span>
                    {['all', 'tickers'].includes(selectedFilterCompany) &&
                      !loadingFilter &&
                      `${selectedClassificationCodes.length || 'All'} compan${
                        selectedClassificationCodes.length !== 1 ? 'ies' : 'y'
                      }`}
                    {['IDX-IC', 'SIC'].includes(selectedFilterCompany) &&
                      !loadingFilter &&
                      `${selectedClassificationCodes.length || 'All'} sector${
                        selectedClassificationCodes.length !== 1 ? 's' : ''
                      }`}
                    {loadingFilter && 'Loading...'}
                  </span>
                  <Icon
                    icon={IconNames.CARET_DOWN}
                    iconSize={16}
                    className="fill-current text-blue50 -mt-1"
                  />
                </div>
                {selectedFilterCompany !== 'all' && !loadingFilter ? (
                  <div className="mt-2" style={{ width: 528 }}>
                    <div className="rounded shadow-lg p-6 bg-white w-full">
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-grey100 font-medium">
                          {getIndustryLabel(selectedFilterCompany)}
                        </h2>
                        <button
                          className="text-red50"
                          onClick={() => {
                            setSearchClassification('');
                            setAppliedSearchClassification('');
                            dispatch(resetSelectedClassification());
                          }}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="w-full relative mb-4">
                        <div className="w-full flex items-center">
                          <span className="absolute left-2 rounded-md">
                            <Icon
                              icon={IconNames.SEARCH}
                              iconSize={16}
                              className="fill-current text-grey30 mb-px"
                            />
                          </span>
                          <input
                            type="text"
                            name="keywords"
                            placeholder={`Search ${
                              selectedFilterCompany === 'tickers'
                                ? 'company'
                                : 'sector or sub-sector'
                            }`}
                            value={searchClassification}
                            onChange={handleSearchClassification}
                            autoComplete="off"
                            className={`w-full h-10 bg-white border border-grey30 focus:border-grey50
                   rounded-sm pl-8 focus:outline-none focus:ring-transparent`}
                          />
                        </div>
                      </div>
                      <div
                        className={clsx(
                          'w-full max-h-56 overflow-y-auto',
                          selectedFilterCompany !== 'tickers'
                            ? 'flex flex-col gap-y-4 '
                            : 'grid grid-cols-3 gap-4'
                        )}
                      >
                        {filterCompany[selectedFilterCompany]
                          ?.reduce(applySearchClassification, [])
                          .map((classification) => {
                            if (selectedFilterCompany !== 'tickers') {
                              const label = `${
                                selectedFilterCompany === 'SIC'
                                  ? `SIC ${classification.code} - `
                                  : ''
                              }${classification.sector}`;
                              const selectedParent =
                                selectedClassificationCodes.includes(
                                  `${classification.code}`
                                );
                              const subsectorData =
                                classification.subsectors.reduce(
                                  (acc, data) => {
                                    if (
                                      selectedClassificationCodes.find(
                                        (selected) =>
                                          selected === `${data.code}`
                                      )
                                    ) {
                                      acc = {
                                        ...acc,
                                        selected: acc.selected + 1,
                                      };
                                    }
                                    acc = {
                                      ...acc,
                                      codes: acc.codes.concat(`${data.code}`),
                                    };

                                    return acc;
                                  },
                                  { selected: 0, codes: [] }
                                );
                              return (
                                <div key={classification.sector}>
                                  <div
                                    onClick={() =>
                                      toggleCollapsedSubs(classification.code)
                                    }
                                    className="flex items-center justify-between cursor-pointer"
                                  >
                                    <Checkbox
                                      label={''}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={() => {
                                        if (listVariableStats.length > 0) {
                                          setShowDialogReset(true);
                                          return;
                                        }
                                        if (
                                          !selectedParent &&
                                          subsectorData.selected > 0
                                        ) {
                                          const subCodes = subsectorData.codes;
                                          const filteredSubs =
                                            selectedClassificationCodes
                                              .filter(
                                                (selected) =>
                                                  !subCodes.includes(selected)
                                              )
                                              .reduce((acc, key) => {
                                                return {
                                                  ...acc,
                                                  [key]:
                                                    selectedClassification[key],
                                                };
                                              }, {});
                                          const { code, sector } =
                                            classification;
                                          dispatch(
                                            setSelectedClassification({
                                              ...filteredSubs,
                                              [code]: sector,
                                            })
                                          );
                                        } else {
                                          handleCheckClassification(
                                            classification.code,
                                            classification.sector
                                          );
                                        }
                                      }}
                                      checked={selectedParent}
                                      className="font-medium"
                                    />
                                    <div className="w-full flex items-center justify-between font-medium">
                                      <span>{label}</span>
                                      <Icon
                                        icon={
                                          !collapsedSubs.includes(
                                            classification.code
                                          )
                                            ? IconNames.CHEVRON_UP
                                            : IconNames.CHEVRON_DOWN
                                        }
                                        iconSize={18}
                                        className="fill-current text-grey70 mr-1"
                                      />
                                    </div>
                                  </div>
                                  <Collapse
                                    isOpen={
                                      !collapsedSubs.includes(
                                        classification.code
                                      )
                                    }
                                  >
                                    <div className="ml-7 flex flex-col mt-2 gap-y-3">
                                      {classification.subsectors.map(
                                        (subsector) => {
                                          const labelSub =
                                            selectedFilterCompany === 'SIC'
                                              ? `SIC ${subsector.code} - ${subsector.name}`
                                              : subsector.name;
                                          const selectedSub =
                                            selectedClassificationCodes.includes(
                                              `${subsector.code}`
                                            );
                                          return (
                                            <Checkbox
                                              key={`subsector-${subsector.code}`}
                                              label={labelSub}
                                              onChange={() => {
                                                if (
                                                  listVariableStats.length > 0
                                                ) {
                                                  setShowDialogReset(true);
                                                  return;
                                                }
                                                const totalSub =
                                                  classification.subsectors
                                                    .length;
                                                if (
                                                  !selectedSub &&
                                                  subsectorData.selected ===
                                                    totalSub - 1
                                                ) {
                                                  // select parent
                                                  const subCodes =
                                                    subsectorData.codes;
                                                  const filteredSubs =
                                                    selectedClassificationCodes
                                                      .filter(
                                                        (selected) =>
                                                          !subCodes.includes(
                                                            selected
                                                          )
                                                      )
                                                      .reduce((acc, key) => {
                                                        return {
                                                          ...acc,
                                                          [key]:
                                                            selectedClassification[
                                                              key
                                                            ],
                                                        };
                                                      }, {});
                                                  const { code, sector } =
                                                    classification;
                                                  dispatch(
                                                    setSelectedClassification({
                                                      ...filteredSubs,
                                                      [code]: sector,
                                                    })
                                                  );
                                                } else {
                                                  handleCheckClassification(
                                                    subsector.code,
                                                    subsector.name
                                                  );
                                                }
                                              }}
                                              disabled={selectedParent}
                                              checked={
                                                selectedParent || selectedSub
                                              }
                                              className="font-medium"
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </Collapse>
                                </div>
                              );
                            } else {
                              return (
                                <Checkbox
                                  key={classification}
                                  label={classification}
                                  onChange={() => {
                                    if (listVariableStats.length > 0) {
                                      setShowDialogReset(true);
                                      return;
                                    }
                                    handleCheckClassification(
                                      classification,
                                      classification
                                    );
                                  }}
                                  checked={selectedClassificationCodes.includes(
                                    classification
                                  )}
                                  className="font-medium"
                                />
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
              </Popover>
              <Popover
                minimal
                position={Position.BOTTOM_LEFT}
                popoverClassName="w-full shadow-none"
                usePortal={false}
                targetClassName="w-full"
                className="w-full mr-5"
              >
                <div
                  className="flex items-center justify-between overflow-hidden border border-grey30 rounded bg-white p-3 cursor-pointer w-full"
                  style={{ minWidth: 210 }}
                >
                  <span>{watch('category')}</span>
                  <Icon
                    icon={IconNames.CARET_DOWN}
                    iconSize={16}
                    className="fill-current text-blue50 -mt-1"
                  />
                </div>
                <div className="mt-2">
                  <div className="rounded shadow-lg p-6 bg-white w-80">
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-grey100 font-medium">Category</h2>
                      <button
                        className="text-red50"
                        onClick={() => setValue('category', 'All categories')}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="flex flex-col gap-y-6">
                      {labels.map((label) => (
                        <Radio
                          key={label}
                          ref={register}
                          name="category"
                          value={label}
                          label={label}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Popover>
              <button
                onClick={() => {
                  setResetAll(true);
                  if (listVariableStats.length > 0) setShowDialogReset(true);
                  else resetFilter();
                }}
                className="text-red50 font-medium whitespace-nowrap"
              >
                Reset Filter
              </button>
            </div>
            <div className="w-full mt-6 flex flex-wrap gap-x-3 gap-y-6">
              {displaySummary()}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mb-10">
        <div className="flex lg:w-10/12 w-11/12 p-1">
          <div className="flex flex-col w-1/2 pr-7">
            {searchAndFilterCategory.length > 0 && (
              <>
                <div className="flex justify-between">
                  <div
                    className={clsx(
                      'flex items-center cursor-pointer',
                      !canSelectAll && 'opacity-70'
                    )}
                    onClick={() =>
                      setSelectedVariable(
                        isAllSelected
                          ? {}
                          : searchAndFilterCategory.reduce(
                              (allObj, v) => ({
                                ...allObj,
                                [v.name]: v.variables,
                              }),
                              {}
                            )
                      )
                    }
                  >
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      className="appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent mr-2 cursor-pointer"
                      onChange={() => {}}
                    />
                    <p className={`text-grey80 font-medium text-base`}>
                      Select All
                    </p>
                  </div>
                  <Button
                    color="primary"
                    className="w-32"
                    type="button"
                    view="filled"
                    onClick={() => {
                      addVariable();
                    }}
                    disabled={
                      Object.keys(selectedVariable).filter(
                        (v) => selectedVariable[v].length > 0
                      ).length === 0
                    }
                  >
                    {loadingVariableStats ? 'Please Wait...' : 'Add Variable'}
                  </Button>
                </div>
                <div
                  className="mt-3 overflow-y-auto pr-2 pb-6"
                  style={{
                    maxHeight: '70vh',
                  }}
                >
                  <div>
                    {!loadingGroupedVariable &&
                      searchAndFilterCategory.map((v, i) => (
                        <CustomCollapseCategory
                          key={i}
                          initialShow={true}
                          dataCategory={v}
                          selectedData={selectedVariable[`${v.name}`] || []}
                          onSelectAll={(status) => {
                            if (status) {
                              setSelectedVariable((prev) => ({
                                ...prev,
                                [`${v.name}`]: v.variables,
                              }));
                            } else {
                              setSelectedVariable((prev) => ({
                                ...prev,
                                [`${v.name}`]: [],
                              }));
                            }
                          }}
                          onSelectItem={(status, item) => {
                            if (status) {
                              setSelectedVariable((prev) => ({
                                ...prev,
                                [`${v.name}`]: [
                                  ...(selectedVariable[`${v.name}`] || []),
                                  item,
                                ],
                              }));
                            } else {
                              setSelectedVariable((prev) => ({
                                ...prev,
                                [`${v.name}`]: [
                                  ...(
                                    selectedVariable[`${v.name}`] || []
                                  ).filter(
                                    (variable) => variable.id !== item.id
                                  ),
                                ],
                              }));
                            }
                          }}
                          className="mb-3"
                        />
                      ))}
                  </div>
                </div>
              </>
            )}
            {loadingGroupedVariable && (
              <div className="mt-3 pr-2">
                {new Array(10).fill(0).map((_, i) => (
                  <div className="flex items-center mb-3" key={i}>
                    <Skeleton width={20} height={20} className="mr-2" />
                    <Skeleton width={200} height={20} />
                  </div>
                ))}
              </div>
            )}
            {!loadingGroupedVariable &&
              !(searchAndFilterCategory.length > 0) && (
                <p className="text-sm text-center font-medium text-gray80 mt-5">
                  No Data
                </p>
              )}
          </div>
          <div className="w-1/2 pr-2 pl-7 border-l border-grey20">
            {listVariableStats.length > 0 && (
              <div className="bg-gray0 rounded-sm flex items-center mb-3 px-4 py-3">
                <Icon
                  icon={IconNames.INFO_SIGN}
                  iconSize={15}
                  className="fill-curent text-grey50 hover:text-grey50 mr-3"
                />
                <p className="text-gray90 text-xs">
                  Some variables may contain N/A (Not Available) because the
                  information is not disclosed in the company's annual report
                </p>
              </div>
            )}
            <div className="flex justify-between items-center mb-5">
              <p className="text-base text-gray80 font-bold">
                Your Selected Data
              </p>
              {listVariableStats.length > 0 && (
                <Button
                  color="primary"
                  className="w-min-max"
                  type="button"
                  view="filled"
                  onClick={() => {
                    dispatch(
                      previewSelectedVariable(
                        dataOrderVariable(listVariableStats.map((v) => v.id))
                      )
                    );
                    setDialogPreview(true);
                  }}
                  disabled={onGetPreview}
                  isLoading={onGetPreview}
                >
                  Show Result Preview
                </Button>
              )}
            </div>
            {!(listVariableStats.length > 0) && (
              <div className="flex flex-col justify-center items-center mt-20">
                <img
                  src={emptySelectedData}
                  alt="icon-empty"
                  className="w-5/12 lg:w-3/12 mb-5"
                />
                <p className="font-bold text-sm text-gray80">
                  You haven't selected a variable
                </p>
                <p className="text-sm text-gray50">
                  Select a variable in the list of variables on the side
                </p>
              </div>
            )}
            {listVariableStats.length > 0 && (
              <div className="">
                <div className="flex justify-between items-center mb-6">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      if (
                        selectedToRemove.length === listVariableStats.length
                      ) {
                        setSelectedToRemove([]);
                      } else {
                        setSelectedToRemove(listVariableStats.map((v) => v.id));
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      className="appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent mr-2 cursor-pointer"
                      checked={
                        selectedToRemove.length === listVariableStats.length
                      }
                      onChange={() => {}}
                    />
                    <p className={`text-grey80 font-medium text-sm`}>
                      Select All
                    </p>
                  </div>
                  {selectedToRemove.length > 0 && (
                    <button
                      className="text-red50 font-medium text-sm"
                      onClick={() => {
                        setShowDialogRemove({
                          show: true,
                          toRemove: selectedToRemove,
                        });
                      }}
                    >
                      Remove
                    </button>
                  )}
                </div>
                <div
                  className="overflow-y-auto pr-2"
                  style={{
                    maxHeight: '70vh',
                  }}
                >
                  {listVariableStats.map((selected, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-end mb-5"
                    >
                      <div
                        className="flex items-start cursor-pointer"
                        onClick={() => {
                          if (selectedToRemove.includes(selected.id)) {
                            setSelectedToRemove((prev) => [
                              ...prev.filter((v) => v !== selected.id),
                            ]);
                          } else {
                            setSelectedToRemove((prev) => [
                              ...prev,
                              selected.id,
                            ]);
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedToRemove.includes(selected.id)}
                          className="appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent mr-3 cursor-pointer"
                          onChange={() => {}}
                        />
                        <div className="-mt-1">
                          <p className={`text-grey90 font-medium text-sm mb-1`}>
                            {selected.name}
                          </p>
                          <p className="text-sm text-gray50 mb-1">
                            {`Total observation (${selected.years.length} years): ${selected.totalObservation}`}
                          </p>
                          <p className="text-sm text-gray50 mb-1">
                            {`Percentage of data N/A: ${selected.percentageNA}%`}
                          </p>
                          <p className="text-sm font-medium text-gray90">
                            {`Data available: ${numberFormatter.format(
                              parseFloat(selected.totalCleanObservation || 0)
                            )} x ${`${currencyFormatter.format(
                              selected.basePrice
                            )}/data`}`}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm font-medium text-gray80 mr-2">{`${currencyFormatter.format(
                          selected.totalCleanObservation * selected.basePrice
                        )}`}</p>
                        <button
                          onClick={() => {
                            setShowDialogRemove({
                              show: true,
                              toRemove: [selected.id],
                            });
                          }}
                        >
                          <Icon
                            iconSize={18}
                            icon={IconNames.TRASH}
                            className="fill-current text-red50"
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {listVariableStats.length > 0 && (
        <div
          className="flex fixed bottom-10 left-0 right-0 mx-auto px-10 py-3 bg-indigo80 justify-between items-center rounded-sm cursor-pointer"
          style={{
            width: '60%',
          }}
          onClick={() => {
            setShowDialogPayment(true);
          }}
        >
          <div className="flex items-center">
            <div className="rounded-full h-6 w-6 bg-indigo50 p-3 mr-2 flex items-center justify-center">
              <Icon
                iconSize={14}
                icon={IconNames.SHOPPING_CART}
                className="fill-current text-white"
              />
            </div>
            <p className="text-white font-medium">{`Buy ${listVariableStats.length} variables`}</p>
          </div>
          <div className="flex items-center">
            <p className="text-white font-bold mr-5">
              {currencyFormatter.format(totalAmountCart)}
            </p>

            <Icon
              iconSize={20}
              icon={IconNames.ARROW_RIGHT}
              className="fill-current text-white"
            />
          </div>
        </div>
      )}
      <ConfirmationDialog
        title="Change Filter"
        isOpen={showDialogReset}
        onCancel={() => {
          setShowDialogReset(false);
        }}
        onConfirm={() => {
          resetFilter();
        }}
      >
        Your selected variable will be lost if you want to change or reset the
        filter
      </ConfirmationDialog>
      <ConfirmationDialog
        title="Remove Selected Variables?"
        isOpen={showDialogRemove.show}
        onCancel={() => {
          setShowDialogRemove({
            show: false,
            toRemove: [],
          });
        }}
        onConfirm={() => {
          dispatch(
            updateVariableStas(
              listVariableStats.filter(
                (variable) => !showDialogRemove.toRemove.includes(variable.id)
              )
            )
          );
          setSelectedToRemove((prev) =>
            prev.filter((id) => !showDialogRemove.toRemove.includes(id))
          );
          setShowDialogRemove({
            show: false,
            toRemove: [],
          });
        }}
        captions={{
          confirm: 'Yes',
          cancel: 'No',
        }}
      >
        Are you sure to remove the selected variable from your cart?
      </ConfirmationDialog>
      <Dialog
        isOpen={showDialogPreview}
        header={'Preview Result'}
        onClose={() => {
          setDialogPreview(false);
        }}
        dismissButton
        size={'large'}
      >
        <div
          className="mx-4 my-4"
          style={{
            maxHeight: '30rem',
          }}
        >
          <div className="bg-gray0 rounded-sm flex items-center mb-3 px-4 py-3">
            <Icon
              icon={IconNames.INFO_SIGN}
              iconSize={15}
              className="fill-curent text-grey50 hover:text-grey50 mr-3"
            />
            <p className="text-gray90 text-xs">
              <span className="block">
                Some variables may contain N/A (Not Available) because the
                information is not disclosed in the company's annual report
              </span>
              <span className="block">
                All companies listed in this result preview are examples only
              </span>
            </p>
          </div>
          <div className="overflow-y-auto">
            <table className="min-w-full">
              <thead className="bg-teal50 text-white">
                <tr>
                  {onGetPreview &&
                    new Array(8).fill(0).map((_, i) => (
                      <th key={i} className={clsx('min-w-min px-3 py-4')}>
                        <Skeleton width={50} />
                      </th>
                    ))}
                  {!onGetPreview &&
                    previewVariable.columnNames.map((header, i) => (
                      <th
                        key={i}
                        className={clsx(
                          'min-w-min px-3 py-4 text-left text-xs font-semi-bold uppercase cursor-pointer whitespace-nowrap'
                        )}
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {onGetPreview &&
                  new Array(8).fill(0).map((_, i) => (
                    <tr key={i}>
                      {new Array(8).fill(0).map((_, i) => (
                        <td key={i} className={clsx('min-w-min px-3 py-4')}>
                          <Skeleton width={50} />
                        </td>
                      ))}
                    </tr>
                  ))}
                {!onGetPreview &&
                  previewVariable.rows.map((v, i) => (
                    <tr key={i}>
                      <td className="min-w-min p-3 text-grey80 text-sm font-normal">
                        {v[getValues('company-codes')]}
                      </td>
                      {['companyName', 'year'].map((columnCell, i) => (
                        <td
                          key={i}
                          className="min-w-min p-3 text-grey80 text-sm font-normal whitespace-nowrap"
                        >
                          {v[columnCell]}
                        </td>
                      ))}
                      {previewVariable.columnNames
                        .filter((_, i) => i > 2)
                        .map((columnCell, i) => (
                          <td
                            key={i}
                            className="min-w-min p-3 text-grey80 text-sm font-normal whitespace-nowrap"
                          >
                            {v[columnCell]}
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>
      {showDialogPayment && (
        <CustomDialogPayment
          open={showDialogPayment}
          statsVariable={listVariableStats}
          companyCodes={getValues('option-company-codes')}
          onClose={() => setShowDialogPayment(false)}
          totalAmountCart={totalAmountCart}
          dataOrder={() =>
            dataOrderVariable(listVariableStats.map((v) => v.id))
          }
        />
      )}
    </div>
  );
}

export default UserSelectVariable;
