import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import {
  VIEW_MODE,
  changeResultViewMode,
  changeTablePage,
  selectSearchResult,
} from '../../redux/features/textAnalytics';

function ResultTopBar(props) {
  const dispatch = useDispatch();
  const searchResult = useSelector(selectSearchResult);
  const viewMode = useSelector(({ textAnalytics }) => textAnalytics.viewMode);

  const renderTablePageNav = () => {
    const { tablePage, documents } = searchResult;
    const start = (tablePage - 1) * 25 + 1;
    const maxRowPage = tablePage * 25;
    const maxPage = Math.ceil(documents.length / 25);
    const label = `${start} - ${
      maxRowPage < documents.length ? maxRowPage : documents.length
    } of ${documents.length}`;
    return (
      <div className="flex">
        <span className="text-sm text-grey50">{label}</span>
        <div className="ml-3">
          <button
            onClick={() =>
              tablePage > 1 && dispatch(changeTablePage(tablePage - 1))
            }
          >
            <Icon
              icon={IconNames.CHEVRON_LEFT}
              iconSize={18}
              className={clsx(
                'fill-current',
                tablePage > 1 ? 'text-grey50' : 'text-grey20'
              )}
            />
          </button>
          <button
            onClick={() =>
              maxPage > 1 &&
              tablePage < maxPage &&
              dispatch(changeTablePage(tablePage + 1))
            }
            className="ml-2"
          >
            <Icon
              icon={IconNames.CHEVRON_RIGHT}
              iconSize={18}
              className={clsx(
                'fill-current',
                maxPage > 1 && tablePage < maxPage
                  ? 'text-grey50'
                  : 'text-grey20'
              )}
            />
          </button>
        </div>
      </div>
    );
  };
  return (
    <div
      className={clsx(
        'h-12 w-full flex flex-shrink-0 justify-between items-center',
        viewMode === VIEW_MODE.LIST && 'border-b border-grey5'
      )}
    >
      <span className="block text-xs text-blue100 ml-4">
        {viewMode === VIEW_MODE.LIST
          ? `${searchResult.documents.length} results`
          : renderTablePageNav()}
      </span>
      <div className="mr-7">
        {viewMode === VIEW_MODE.TABLE && props.columnToggler}
        <button
          onClick={() => dispatch(changeResultViewMode(VIEW_MODE.LIST))}
          className="mr-2"
        >
          <Icon
            icon={IconNames.LIST}
            iconSize={16}
            className={clsx(
              'fill-current',
              viewMode === VIEW_MODE.LIST ? 'text-blue50' : 'text-grey20'
            )}
          />
        </button>
        <button onClick={() => dispatch(changeResultViewMode(VIEW_MODE.TABLE))}>
          <Icon
            icon={IconNames.GRID_VIEW}
            iconSize={16}
            className={clsx(
              'fill-current',
              viewMode === VIEW_MODE.TABLE ? 'text-blue50' : 'text-grey20'
            )}
          />
        </button>
      </div>
    </div>
  );
}

export default ResultTopBar;
