import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import clsx from 'clsx';
import moment from 'moment';
import { getUserInvoicesTA } from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import DialogDetailInvoiceTA from './DialogDetailInvoiceTA';

export default function TextAnalyticTableInvoice() {
  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);

  const userInvoices = useSelector(
    (state) => state.auth.invoices.textAnalytics
  );
  const loadingStatusUserInvoices = useSelector((state) =>
    createLoadingSelector([getUserInvoicesTA.type])(state)
  );
  const [showDetailInvoice, setShowDetailInvoice] = useState({
    show: false,
    data: {},
  });
  const [rowPerPage, setrowPerPage] = useState(10);
  const [searchInvoices, setSearchInvoices] = useState('');
  const columns = [
    {
      Header: 'Invoice',
      accessor: 'invoiceNumber',
      customCell: ({ value, row }) => (
        <p
          className="text-blue50 cursor-pointer"
          onClick={() =>
            setShowDetailInvoice({
              show: true,
              data: {
                ...row.original,
                user: userDetail,
              },
            })
          }
        >
          {value}
        </p>
      ),
    },
    {
      Header: 'Plans',
      accessor: 'category',
      customCell: ({ value, row }) => (
        <p className="capitalize">
          {row.original &&
            row.original.subscription &&
            `${row.original.subscription.name} ${row.original.subscription.option}`}
        </p>
      ),
    },
    {
      Header: 'Paid On',
      accessor: 'createdAt',
      sortType: 'datetime',
      customCell: ({ value }) => <>{moment(value).format('DD MMM YYYY')}</>,
    },
    {
      Header: 'STATUS',
      accessor: 'orderStatus',
      customCell: ({ value }) => {
        return (
          <div>
            <span
              className={clsx(
                'text-center capitalize px-2 rounded-full py-0.5',
                value &&
                  value.toLowerCase() === 'completed' &&
                  'bg-green10 text-green50',
                value &&
                  value.toLowerCase() === 'processing' &&
                  'bg-yellow10 text-yellow50',
                value &&
                  value.toLowerCase() === 'cancelled' &&
                  'bg-red10 text-red50'
              )}
            >
              {value}
            </span>
          </div>
        );
      },
    },
    {
      Header: 'Total',
      accessor: 'netPrice',
      customCell: ({ value }) => <>{currencyFormatter.format(value)}</>,
    },
  ];
  useEffect(() => {
    if (userDetail.id) {
      dispatch(
        getUserInvoicesTA({
          page: 1,
          limit: rowPerPage,
        })
      );
    }
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch, userDetail.id, rowPerPage]);
  return (
    <>
      <CustomTable
        isLoading={loadingStatusUserInvoices}
        dataTable={
          userInvoices.invoices.length > 0
            ? userInvoices.invoices.map((v) => ({
                ...v,
                createdAt: new Date(v.createdAt),
              }))
            : []
        }
        columnsTable={columns}
        page={userInvoices.currentPageNumber || 1}
        totalPage={userInvoices.totalPages || 1}
        totalRow={rowPerPage}
        onChangeTotalRow={(row) => {
          setrowPerPage(row);
          dispatch(
            getUserInvoicesTA(userDetail.id, {
              page: 1,
              limit: row,
              q: searchInvoices,
            })
          );
        }}
        onInputSearch={(text) => {
          setSearchInvoices(text);
          dispatch(
            getUserInvoicesTA(userDetail.id, {
              page: 1,
              limit: rowPerPage,
              q: text,
            })
          );
        }}
        onChangePage={(page) => {
          dispatch(
            getUserInvoicesTA(userDetail.id, {
              page: page,
              limit: rowPerPage,
              q: searchInvoices,
            })
          );
        }}
      />
      <DialogDetailInvoiceTA
        showDialog={showDetailInvoice.show}
        detailInvoice={showDetailInvoice.data}
        setShowDialog={setShowDetailInvoice}
      />
    </>
  );
}
