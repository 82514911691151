import ConfirmationDialog from 'components/ConfirmationDialog';

function DialogCancelOrder(props) {
  return (
    <ConfirmationDialog
      title="Cancel Order"
      isOpen={props.isOpen}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
      isLoading={props.isLoading}
      captions={{ confirm: 'Yes', cancel: 'No' }}
    >
      Are you sure you want to cancel this order? If you have a voucher applied
      then the voucher will be lost
    </ConfirmationDialog>
  );
}

export default DialogCancelOrder;
