import { useHistory } from 'react-router-dom';
import BRI from 'assets/svg/bank_bri.svg';
import Countdown from '../Countdown';
import PATH_URL from 'routers/path';
import Button from 'components/Button';

export default function BRIVa({
  vaNumber,
  price,
  dueDate,
  cancel,
  setShowDetailInvoice,
  differenceInSecond,
}) {
  const history = useHistory();
  const maxWidthPhone = {
    maxWidth: '480px',
  };
  return (
    <div>
      <div className={'text-center'}>
        <div className={'text-xl'}>Complete payment in</div>
        <Countdown differenceInSecond={differenceInSecond} />
        <div className={'text-xl text-gray50 font-normal pb-2'}>
          Payment due date
        </div>

        <div className={'text-xl text-grey100'}>{dueDate}</div>
      </div>
      <div
        className={'mt-10 px-4 pb-4 rounded border mb-4'}
        style={maxWidthPhone}
      >
        <div className={'flex justify-between py-6'}>
          <div className={'text-xl font-medium'}>BRI Virtual Account</div>
          <div>
            <img alt="bri" src={BRI} className={'w-14'} />
          </div>
        </div>
        <div className={'flex justify-between py-6'}>
          <div>
            <div className={'text-sm text-gray50'}>Virtual Account Number</div>
            <div>{vaNumber}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(vaNumber);
              }}
            >
              Copy
            </button>
          </div>
        </div>
        <div className={'flex justify-between'}>
          <div>
            <div className={'text-sm text-gray50'}>Total Payment</div>
            <div className={'text-xl text-red50 font-medium'}>{price}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                setShowDetailInvoice(true);
              }}
            >
              View Detail
            </button>
          </div>
        </div>
      </div>

      <div className={'flex justify-between'}>
        <Button
          view="outlined"
          size="medium"
          onClick={cancel}
          className="w-1/2"
        >
          Cancel Order
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={() => {
            history.push(PATH_URL.USER_PROFILE);
          }}
          className="w-1/2 ml-4"
        >
          View My Billing
        </Button>
      </div>

      <div style={maxWidthPhone}>
        <div className={'text-xl font-medium py-4'}>Payment Method</div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>ATM BRI</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              On the main menu, choose Other Transaction
            </li>
            <li className={'text-gray50 text-sm'}>Choose Payment</li>
            <li className={'text-gray50 text-sm'}>Choose Other</li>
            <li className={'text-gray50 text-sm'}>Choose BRIVA</li>
            <li className={'text-gray50 text-sm'}>
              Enter your BRIVA Number and press Correct
            </li>
            <li className={'text-gray50 text-sm'}>
              Amount to be paid, payment code, and merchant name will appear on
              the payment confirmation page. If the information is correct,
              press Yes
            </li>
            <li className={'text-gray50 text-sm'}>
              Payment is finished. Save your payment receipt
            </li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>Internet Banking</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              Log in to your Internet Banking BRI
            </li>
            <li className={'text-gray50 text-sm'}>Choose Payment & Purchase</li>
            <li className={'text-gray50 text-sm'}>Choose sub menu BRIVA</li>
            <li className={'text-gray50 text-sm'}>Input BRIVA number</li>
            <li className={'text-gray50 text-sm'}>
              Amount to be paid, payment code, and merchant name will appear on
              the payment confirmation page. If the information is correct,
              choose Send
            </li>
            <li className={'text-gray50 text-sm'}>
              Input password and mToken, choose Send
            </li>
            <li className={'text-gray50 text-sm'}>
              Payment is finished, choose Print to have payment receipt
            </li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>BRImo</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              Log in to your BRI Mobile app, choose Mobile Banking BRI
            </li>
            <li className={'text-gray50 text-sm'}>
              Choose Payment, then choose BRIVA
            </li>
            <li className={'text-gray50 text-sm'}>Input BRIVA Number</li>
            <li className={'text-gray50 text-sm'}>
              Amount to be paid, payment code, and merchant name will appear on
              the payment confirmation page. If the information is correct,
              choose Continue
            </li>
            <li className={'text-gray50 text-sm'}>
              Input your Mobile Banking BRI PIN, choose Ok
            </li>
            <li className={'text-gray50 text-sm'}>
              Payment is finished. Save the notification as your payment receipt
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
