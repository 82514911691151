import { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { Icon, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { currencyFormatter } from 'utils/currencyFormatter';
import Alert from 'components/Alert/index';
import Voucher from 'assets/svg/discout_with_percent.svg';
import {
  createOrderDataset,
  checkDiscountQD,
} from '../../redux/features/userSelectVariable';
import { useSelector, useDispatch } from 'react-redux';
import { showToastError } from 'layouts/DashboardLayout';
import { createLoadingSelector } from '../../redux/api/loading';
import './customStyle.css';
import clsx from 'clsx';
import PATH_URL from 'routers/path';
import { upperFirst } from 'lodash';

const MINIMUM_ORDER = 50_000;

const HRCustom = ({ className = '' }) => (
  <div style={{ borderBottom: '2px solid #eaeaea' }} className={className} />
);

function CustomDialogPayment({
  open = false,
  statsVariable = [],
  companyCodes = '',
  onClose = undefined,
  totalAmountCart = 0,
  dataOrder = undefined,
}) {
  const location = useLocation();
  const minimumOrder = useMemo(() => MINIMUM_ORDER, []);
  const [showAllVarialbe, setShowAllVarialble] = useState(false);
  const [inputVoucher, setInputVoucher] = useState('');
  const [inputReferral, setInputReferral] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [statusDiscount, setStatusDiscount] = useState({
    voucher: {
      status: false,
      message: '',
      discount: {},
    },
    referral: {
      status: false,
      message: '',
      discount: {},
    },
  });
  const titleDiscount = useCallback(
    (type) => {
      return statusDiscount[type] &&
        statusDiscount[type].discount &&
        statusDiscount[type].discount.value
        ? ` ${statusDiscount[type].discount.name}: ${
            statusDiscount[type].discount.type === 'fixed'
              ? currencyFormatter.format(statusDiscount[type].discount.value)
              : `${statusDiscount[type].discount.value}%`
          }`
        : '';
    },
    [statusDiscount]
  );
  const valueDiscountVoucher = useMemo(() => {
    return statusDiscount.voucher &&
      statusDiscount.voucher.discount &&
      statusDiscount.voucher.discount.value
      ? `${
          statusDiscount.voucher.discount.type === 'fixed'
            ? statusDiscount.voucher.discount.value || 0
            : (statusDiscount.voucher.discount.value / 100) * totalAmountCart ||
              0
        }`
      : 0;
  }, [statusDiscount, totalAmountCart]);
  const valueDiscountReferral = useMemo(() => {
    const amountPriceWithDiscount = totalAmountCart - valueDiscountVoucher;
    return statusDiscount.referral &&
      statusDiscount.referral.discount &&
      statusDiscount.referral.discount.value
      ? `${
          statusDiscount.referral.discount.type === 'fixed'
            ? statusDiscount.referral.discount.value || 0
            : (statusDiscount.referral.discount.value / 100) *
                (amountPriceWithDiscount > 0 ? amountPriceWithDiscount : 0) || 0
        }`
      : 0;
  }, [statusDiscount, totalAmountCart, valueDiscountVoucher]);

  const amountCart = useMemo(() => {
    const amount =
      totalAmountCart - valueDiscountVoucher - valueDiscountReferral;
    return amount > 0 ? amount : 0;
  }, [totalAmountCart, valueDiscountVoucher, valueDiscountReferral]);
  const onCreateOrder = useSelector(
    createLoadingSelector([createOrderDataset.type])
  );
  const onValidateVoucher = useSelector(
    createLoadingSelector([checkDiscountQD.type])
  );

  const selectPayment = () => {
    /*global snap*/
    if (snap) {
      dispatch(
        createOrderDataset(
          {
            ...(dataOrder ? dataOrder() : {}),
            discountIds: Object.keys(statusDiscount)
              .filter(
                (v) =>
                  statusDiscount[v].status &&
                  statusDiscount[v].discount &&
                  statusDiscount[v].discount.id
              )
              .map((v) => statusDiscount[v].discount.id),
          },
          (result) => {
            if (result.snapToken) {
              if (onClose) onClose();
              snap.pay(result.snapToken, {
                onSuccess() {
                  history.push({
                    pathname: PATH_URL.PAYMENT_QD,
                    search: `?order_id=${result.invoiceNumber}`,
                  });
                },
                onPending() {
                  history.push({
                    pathname: PATH_URL.PAYMENT_QD,
                    search: `?order_id=${result.invoiceNumber}`,
                  });
                },
                onError(result) {},
              });
            } else {
              showToastError('Something went wrong');
            }
          },
          () => {
            showToastError('Failed to create order');
          }
        )
      );
    } else {
      showToastError('Midtrans snap failed to load');
    }
  };
  const validateDiscountCode = useCallback(
    async (code, type = 'voucher') => {
      dispatch(
        checkDiscountQD(
          {
            discountCode: code,
            totalPrice: totalAmountCart,
          },
          (data) => {
            if (type === 'referral') {
              if (!data.status) {
                setInputReferral('');
              }
              if (data.status && data.discount.discountBase !== 'referral') {
                setInputReferral('');
                setStatusDiscount((prev) => ({
                  ...prev,
                  referral: {
                    message: 'The referral code you entered is incorrect',
                    status: false,
                  },
                }));
              } else {
                setStatusDiscount((prev) => ({
                  ...prev,
                  referral: {
                    ...data,
                    ...(data.status
                      ? {
                          message: `You have successfully used the referral code ${
                            data.discount.name
                          } and get a discount of ${
                            data.discount.type === 'fixed'
                              ? currencyFormatter.format(data.discount.value)
                              : `${data.discount.value}%`
                          }`,
                        }
                      : {}),
                  },
                }));
              }
            } else {
              if (!data.status) {
                setInputVoucher('');
              }
              if (data.status && data.discount.discountBase !== 'voucher') {
                setInputVoucher('');
                setStatusDiscount((prev) => ({
                  ...prev,
                  voucher: {
                    message: 'The voucher code you entered is incorrect',
                    status: false,
                  },
                }));
              } else {
                setStatusDiscount((prev) => ({
                  ...prev,
                  voucher: {
                    ...data,
                    ...(data.status
                      ? {
                          message: `You have successfully used the voucher code ${
                            data.discount.name
                          } and get a discount of ${
                            data.discount.type === 'fixed'
                              ? currencyFormatter.format(data.discount.value)
                              : `${data.discount.value}%`
                          }`,
                        }
                      : {}),
                  },
                }));
              }
            }
          }
        )
      );
    },
    [dispatch, totalAmountCart]
  );
  useEffect(() => {
    const queryParam = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParam.voucher) {
      validateDiscountCode(queryParam.voucher);
      setInputVoucher(queryParam.voucher);
    }
  }, []); //eslint-disable-line
  return (
    <Dialog
      isOpen={open}
      header={'Buy Quantitative Data'}
      onClose={() => onClose && onClose()}
      dismissButton={!onCreateOrder}
      size={'normal'}
    >
      <div className="px-4 my-4 overflow-auto">
        <div className="w-full">
          <div className="overflow-y-auto mb-2 max-h-56">
            {statsVariable.length > 0 && (
              <div className="flex justify-between items-end mb-5">
                <div>
                  <p className={`text-grey60 font-medium text-sm`}>
                    {statsVariable[0].name}
                  </p>
                  <p className="text-xs text-gray50 my-1">
                    {`${statsVariable[0].years.length} years`}
                    <span className="ml-2 capitalize">{`${upperFirst(
                      companyCodes
                    )} Companies`}</span>
                  </p>
                  <p className="text-sm text-gray90">
                    {`${currencyFormatter.format(
                      statsVariable[0].basePrice || 0
                    )} x ${statsVariable[0].totalCleanObservation}`}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm text-gray80 mr-2">{`${currencyFormatter.format(
                    (statsVariable[0].basePrice || 0) *
                      +(statsVariable[0].totalCleanObservation || 0)
                  )}`}</p>
                </div>
              </div>
            )}

            {statsVariable.length > 1 && (
              <Collapse isOpen={showAllVarialbe} keepChildrenMounted={true}>
                {statsVariable
                  .filter((_, i) => i > 0)
                  .map((selected, i) => (
                    <div
                      key={i}
                      className="flex justify-between items-end mb-5"
                    >
                      <div>
                        <p className={`text-grey60 font-medium text-sm`}>
                          {selected.name}
                        </p>
                        <p className="text-xs text-gray50 my-1">
                          {`${selected.years.length} years`}
                          <span className="ml-2 capitalize">{`${upperFirst(
                            companyCodes
                          )} Companies`}</span>
                        </p>
                        <p className="text-sm text-gray90">
                          {`${currencyFormatter.format(
                            selected.basePrice || 0
                          )} x ${selected.totalCleanObservation}`}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm text-gray80 mr-2">{`${currencyFormatter.format(
                          (selected.basePrice || 0) *
                            +(selected.totalCleanObservation || 0)
                        )}`}</p>
                      </div>
                    </div>
                  ))}
              </Collapse>
            )}
          </div>
          {statsVariable.length > 1 && (
            <Button
              color="primary"
              className="w-full"
              type="button"
              view="outlined"
              onClick={() => setShowAllVarialble((prev) => !prev)}
            >
              {`Show ${showAllVarialbe ? 'less' : 'more'} variables`}{' '}
              <Icon
                iconSize={16}
                icon={
                  showAllVarialbe
                    ? IconNames.CHEVRON_UP
                    : IconNames.CHEVRON_DOWN
                }
                className="fill-current ml-2"
              />
            </Button>
          )}
        </div>
        <HRCustom className="my-5" />
        <div>
          <div className="mb-6">
            <div className="w-full mb-8">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (inputVoucher) {
                    validateDiscountCode(inputVoucher);
                  }
                }}
                className="relative flex mb-3 w-full"
              >
                <div className="relative flex flex-grow items-center">
                  <img
                    src={Voucher}
                    className="absolute top-0 bottom-0 my-auto left-2.5"
                    alt="voucher-code"
                  />
                  {inputVoucher && (
                    <span
                      type="button"
                      className="absolute right-2.5 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setInputVoucher('');
                        setStatusDiscount((prev) => ({
                          ...prev,
                          voucher: {
                            message: '',
                            status: false,
                          },
                        }));
                      }}
                    >
                      <Icon
                        icon={IconNames.CROSS}
                        iconSize={16}
                        className="fill-current text-grey50"
                      />
                    </span>
                  )}
                  <input
                    placeholder="Enter your voucher code here"
                    type="text"
                    className={clsx(
                      'w-full h-12 pl-10 pr-1 rounded-tl rounded-bl border-2 border-gray20 focus:border-gray20 focus:ring-0 cursor-text',
                      statusDiscount.voucher.message &&
                        !statusDiscount.voucher.status
                        ? 'border-red50 focus:border-red50'
                        : 'border-gray20 focus:border-blue50'
                    )}
                    name="voucher_code"
                    value={inputVoucher}
                    onChange={(e) => setInputVoucher(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className={clsx(
                      'text-white font-bold py-2 px-4 -ml-1 h-full rounded-tr rounded-br',
                      !inputVoucher || onValidateVoucher
                        ? 'bg-blue30'
                        : 'bg-blue50 ',
                      inputVoucher && 'hover:bg-blue60'
                    )}
                    disabled={!inputVoucher || onValidateVoucher}
                    type="submit"
                  >
                    Apply
                  </button>
                </div>
              </form>
              {statusDiscount.voucher.message && (
                <Alert
                  text={statusDiscount.voucher.message}
                  type={statusDiscount.voucher.status ? 'success' : 'error'}
                />
              )}
              {statusDiscount.voucher.message && statusDiscount.voucher.status && (
                <div className="bg-yellow5 rounded-sm flex items-center my-3 px-4 py-3">
                  <Icon
                    icon={IconNames.WARNING_SIGN}
                    iconSize={15}
                    className="fill-curent text-grey90 hover:text-grey50 mr-3"
                  />
                  <p className="text-gray90 text-xs">
                    The voucher code you used will expire after you process the
                    selected payment
                  </p>
                </div>
              )}
            </div>
            <div className="w-full">
              <p className="font-medium text-base text-grey80">
                Use Referral Code
              </p>
              <p className="font-normal text-xs text-grey70 mb-5">
                You will get 5% discount after completing the transaction using
                a valid referral code
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (inputReferral) {
                    validateDiscountCode(inputReferral, 'referral');
                  }
                }}
                className="relative flex mb-3"
              >
                <div className="relative flex-grow flex items-center">
                  {inputReferral && (
                    <span
                      type="button"
                      className="absolute right-2.5"
                      onClick={(e) => {
                        e.stopPropagation();
                        setInputReferral('');
                        setStatusDiscount((prev) => ({
                          ...prev,
                          referral: {
                            message: '',
                            status: false,
                          },
                        }));
                      }}
                    >
                      <Icon
                        icon={IconNames.CROSS}
                        iconSize={16}
                        className="fill-current text-grey50"
                      />
                    </span>
                  )}
                  <input
                    placeholder="Enter a referral code"
                    type="text"
                    value={inputReferral}
                    onChange={(e) => setInputReferral(e.target.value)}
                    className={clsx(
                      'w-full h-12 pl-2 pr-1  border-0 border-b-2 border-gray20 focus:border-blue50 focus:ring-0 outline-none',
                      statusDiscount.referral.message &&
                        !statusDiscount.referral.status
                        ? 'border-red50 focus:border-red50'
                        : 'border-gray20 focus:border-blue50'
                    )}
                    name="voucher_code"
                  />
                </div>
                <div>
                  <button
                    className={clsx(
                      'text-white font-bold py-2 px-4 -ml-1 h-full rounded-tr rounded-br',
                      !inputReferral || onValidateVoucher
                        ? 'bg-blue30'
                        : 'bg-blue50 ',
                      inputReferral && 'hover:bg-blue60'
                    )}
                    disabled={!inputReferral || onValidateVoucher}
                    type="submit"
                  >
                    Apply
                  </button>
                </div>
              </form>
              {statusDiscount.referral.message && (
                <Alert
                  text={statusDiscount.referral.message}
                  type={statusDiscount.referral.status ? 'success' : 'error'}
                />
              )}
            </div>
          </div>
          <HRCustom />
          <div className="py-4">
            <div className="flex flex-row justify-between">
              <div className="text-base">Sub Total</div>
              <div className="text-lg">
                {currencyFormatter.format(totalAmountCart)}
              </div>
            </div>
            {statusDiscount.voucher.status && (
              <div className="flex flex-row justify-between">
                <div className="text-base">{`Voucher ${titleDiscount(
                  'voucher'
                )}`}</div>
                <div className="text-lg text-red50">
                  {`- ${currencyFormatter.format(valueDiscountVoucher)}`}
                </div>
              </div>
            )}
            {statusDiscount.referral.status && (
              <div className="flex flex-row justify-between">
                <div className="text-base">{`Referral ${titleDiscount(
                  'referral'
                )}`}</div>
                <div className="text-lg text-red50">
                  {`- ${currencyFormatter.format(valueDiscountReferral)}`}
                </div>
              </div>
            )}
          </div>
          <HRCustom className="mb-6" />
          <div className="flex flex-row justify-between">
            <div className="text-lg font-medium">Total</div>
            <div className="text-lg font-medium">
              {currencyFormatter.format(amountCart)}
            </div>
          </div>
          <Button
            color="primary"
            size="medium"
            className="w-full mt-6"
            onClick={() => {
              selectPayment();
            }}
            isLoading={onCreateOrder}
            disabled={onCreateOrder || amountCart < minimumOrder}
          >
            Select Payment
          </Button>
          {amountCart < minimumOrder && (
            <p className="font-normal text-sm text-red50 mt-2 text-center">
              Your order cannot be continued because it is below{' '}
              {currencyFormatter.format(minimumOrder)}
            </p>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default CustomDialogPayment;
