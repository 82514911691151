import { useSelector, useDispatch } from 'react-redux';
import { showToastError } from './Toast';
import {
  deleteSavedQuery,
  selectedQuerySelector,
} from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';
import ConfirmationDialog from 'components/ConfirmationDialog';

function DeleteQueryDialog(props) {
  const dispatch = useDispatch();
  const selectedQuery = useSelector(selectedQuerySelector);
  const loading = useSelector(createLoadingSelector([deleteSavedQuery.type]));
  const handleDelete = () => {
    dispatch(
      deleteSavedQuery(selectedQuery.id, () => {
        props.onClose();
        showToastError('The query was deleted successfully');
      })
    );
  };

  return (
    <ConfirmationDialog
      title="Delete Query"
      isOpen={props.isOpen}
      onCancel={props.onClose}
      onConfirm={handleDelete}
      isLoading={loading}
      mode="danger"
      captions={{
        confirm: 'Delete',
      }}
    >
      Are you sure you want to delete <b>Query {selectedQuery?.name}</b>?
    </ConfirmationDialog>
  );
}

export default DeleteQueryDialog;
