import {
  createAsyncAction,
  actionSuccess,
  createAction,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import ENDPOINTS from '../urls';

export const getUserSearch = createAsyncAction(
  'GET_USER_SEARCH',
  (payload = '') =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'GET_USER_SEARCH',
        'get',
        `${ENDPOINTS.USER_SEARCH}${payload}`
      );
    }
);

export const checkDiscount = createAsyncAction(
  'CHECK_DISCOUNT',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'CHECK_DISCOUNT',
      'post',
      ENDPOINTS.CHECK_DISCOUNT,
      data
    );
  }
);

export const doPayment = createAsyncAction(
  'DO_PAYMENT',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'DO_PAYMENT',
      'post',
      ENDPOINTS.DO_PAYMENT,
      data
    );
  }
);

export const paymentPlans = createAsyncAction(
  'PAYMENT_PLANS',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'PAYMENT_PLANS',
      'get',
      ENDPOINTS.PAYMENT_PLANS,
      data
    );
  }
);

export const getUserSummary = createAsyncAction(
  'GET_USER_SUMMARY',
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_USER_SUMMARY',
      'get',
      `${ENDPOINTS.USER_SUMMARY}`
    );
  }
);

export const getDetailUser = createAsyncAction(
  'GET_DETAIL_USER',
  (id) => async (dispatch, getState, getFirebase) => {
    return await apiRequest(
      dispatch,
      'GET_DETAIL_USER',
      'get',
      `${ENDPOINTS.USER_DETAIL}/${id}`
    );
  }
);
export const getListUser = createAsyncAction(
  'GET_LIST_USER',
  () => async (dispatch, getState) => {
    const { filter, pageData, sortBy } = getState().userManagement.users;
    return await apiRequest(
      dispatch,
      'GET_LIST_USER',
      'get',
      `${ENDPOINTS.USER_LIST}`,
      {
        ...filter,
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...sortBy,
      }
    );
  }
);
export const getUserInvoices = createAsyncAction(
  'GET_USER_INVOICES',
  (id, params = {}) =>
    async (dispatch) =>
      await apiRequest(
        dispatch,
        'GET_USER_INVOICES',
        'get',
        `${ENDPOINTS.USER_INVOICES}`,
        {
          userId: id,
          ...params,
        }
      )
);
export const getUserLastInvoices = createAsyncAction(
  'GET_USER_LAST_INVOICES',
  (id) => async (dispatch, getState, getFirebase) =>
    await apiRequest(
      dispatch,
      'GET_USER_LAST_INVOICES',
      'get',
      `${ENDPOINTS.USER_INVOICES}?paymentStatus=unpaid&userId=${id}&page=1&limit=1`
    )
);
export const getListUserApproval = createAsyncAction(
  'GET_LIST_USER_APPROVAL',
  () => async (dispatch, getState) => {
    const { filter, pageData, sortBy } = getState().userManagement.approvals;
    return await apiRequest(
      dispatch,
      'GET_LIST_USER_APPROVAL',
      'get',
      ENDPOINTS.USER_APPROVAL,
      {
        ...filter,
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...sortBy,
      }
    );
  }
);

export const getListUserSubscriber = createAsyncAction(
  'GET_LIST_USER_SUBSCRIBER',
  () => async (dispatch, getState) => {
    const { filter, pageData, sortBy } = getState().userManagement.subscribers;
    return await apiRequest(
      dispatch,
      'GET_LIST_USER_SUBSCRIBER',
      'get',
      ENDPOINTS.USER_SUBSCRIBER,
      {
        ...filter,
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...sortBy,
      }
    );
  }
);

export const blockUser = createAsyncAction(
  'BLOCK_USER',
  (data, onSuccess = null, onError = null) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'BLOCK_USER',
        'put',
        ENDPOINTS.USER_BLOCK,
        data,
        'application/json',
        () => {
          if (onSuccess) {
            onSuccess();
          }
        },
        (message) => {
          if (onError) {
            onError(message);
          }
        }
      );
    }
);

export const changeUserRole = createAsyncAction(
  'CHANGE_USER_ROLE',
  (data, onSuccess = null, onError = null) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'CHANGE_USER_ROLE',
        'put',
        ENDPOINTS.USER_CHANGE_ROLE,
        data,
        'application/json',
        () => {
          if (onSuccess) {
            onSuccess();
          }
        },
        () => {
          if (onError) {
            onError();
          }
        }
      );
    }
);

export const updatedStudentStatus = createAsyncAction(
  'UPDATED_STUDENT_STATUS',
  (data, onSuccess = null, onError = null) =>
    async (dispatch, getState, getFirebase) => {
      return await apiRequest(
        dispatch,
        'UPDATED_STUDENT_STATUS',
        'put',
        ENDPOINTS.UPDATED_STUDENT_STATUS,
        data,
        'application/json',
        () => {
          if (onSuccess) {
            onSuccess();
          }
        },
        () => {
          if (onError) {
            onError();
          }
        }
      );
    }
);

export const downloadUserListCSV = createAsyncAction(
  'DOWNLOAD_USER_LIST_CSV',
  (onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'DOWNLOAD_USER_LIST_CSV',
        'post',
        ENDPOINTS.DOWNLOAD_USER_LIST_CSV,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const downloadSubscriberListCSV = createAsyncAction(
  'DOWNLOAD_SUBSCRIBER_LIST_CSV',
  (onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'DOWNLOAD_SUBSCRIBER_LIST_CSV',
        'post',
        ENDPOINTS.DOWNLOAD_SUBSCRIBER_LIST_CSV,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const downloadStudentApprovalListCSV = createAsyncAction(
  'DOWNLOAD_STUDENT_APPROVAL_LIST_CSV',
  (onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        'DOWNLOAD_STUDENT_APPROVAL_LIST_CSV',
        'post',
        ENDPOINTS.DOWNLOAD_STUDENT_APPROVAL_LIST_CSV,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const changeFilterUsers = createAction('CHANGE_FILTER_USERS');
export const changeFilterSubscribers = createAction(
  'CHANGE_FILTER_SUBSCRIBERS'
);
export const changeFilterApprovals = createAction('CHANGE_FILTER_APPROVALS');
export const changePageDataUsers = createAction('CHANGE_PAGE_DATA_USERS');
export const changePageDataSubscribers = createAction(
  'CHANGE_PAGE_DATA_SUBSCRIBERS'
);
export const changePageDataApprovals = createAction(
  'CHANGE_PAGE_DATA_APPROVALS'
);
export const setSelectedTab = createAction('SET_SELECTED_TAB');
export const changeSortUsers = createAction('CHANGE_SORT_USERS');
export const changeSortSubscribers = createAction('CHANGE_SORT_SUBSCRIBERS');
export const changeSortApprovals = createAction('CHANGE_SORT_APPROVALS');

const DEFAULT_LIMIT = 10;

const initialState = {
  userDetail: {},
  changeUserRole: [],
  blockUser: [],
  listUserSummary: [],
  checkDiscount: [],
  doPayment: [],
  paymentPlans: {},
  userInvoices: {},
  userLastInvoice: {},
  users: {
    list: [],
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: DEFAULT_LIMIT,
      totalPages: 0,
      totalItems: 0,
    },
    filter: {
      q: '',
      role: null,
      type: null,
    },
    sortBy: {
      sort: null,
      order: null,
    },
  },
  subscribers: {
    list: [],
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: DEFAULT_LIMIT,
      totalPages: 0,
      totalItems: 0,
    },
    filter: {
      q: '',
    },
    sortBy: {
      sort: null,
      order: null,
    },
  },
  approvals: {
    list: [],
    pageData: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: DEFAULT_LIMIT,
      totalPages: 0,
      totalItems: 0,
    },
    filter: {
      q: '',
      type: 'student',
    },
    sortBy: {
      sort: null,
      order: null,
    },
  },
  selectedTab: 0,
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(getUserSearch.type):
    case actionSuccess(getListUser.type):
      const { users, ...pageData } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          list: users,
          pageData,
        },
      };
    case actionSuccess(getDetailUser.type):
      return {
        ...state,
        userDetail: action.payload,
      };
    case actionSuccess(changeUserRole.type):
      return {
        ...state,
        changeUserRole: action.payload,
      };
    case actionSuccess(blockUser.type):
      return {
        ...state,
        blockUser: action.payload,
      };
    case actionSuccess(getListUserApproval.type): {
      const { users, ...pageData } = action.payload;
      return {
        ...state,
        approvals: {
          ...state.approvals,
          list: users,
          pageData,
        },
      };
    }
    case actionSuccess(getListUserSubscriber.type): {
      const { users, ...pageData } = action.payload;
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          list: users,
          pageData,
        },
      };
    }
    case actionSuccess(getUserSummary.type):
      return {
        ...state,
        listUserSummary: action.payload,
      };
    case actionSuccess(getUserInvoices.type):
      return {
        ...state,
        userInvoices: action.payload,
      };
    case actionSuccess(getUserLastInvoices.type):
      return {
        ...state,
        userLastInvoice:
          (action.payload &&
            action.payload.invoices &&
            action.payload.invoices.length > 0 && {
              ...action.payload.invoices[0],
            }) ||
          {},
      };
    case actionSuccess(checkDiscount.type):
      return {
        ...state,
        checkDiscount: action.payload,
      };
    case actionSuccess(doPayment.type):
      return {
        ...state,
        doPayment: action.payload,
      };
    case actionSuccess(paymentPlans.type):
      return {
        ...state,
        paymentPlans: action.payload,
      };
    case changeFilterUsers.type: {
      return {
        ...state,
        users: {
          ...state.users,
          pageData: {
            ...state.users.pageData,
            currentPageNumber: 1,
          },
          filter: {
            ...state.users.filter,
            ...action.payload,
          },
        },
      };
    }
    case changeFilterSubscribers.type: {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          pageData: {
            ...state.subscribers.pageData,
            currentPageNumber: 1,
          },
          filter: {
            ...state.subscribers.filter,
            ...action.payload,
          },
        },
      };
    }
    case changeFilterApprovals.type: {
      return {
        ...state,
        approvals: {
          ...state.approvals,
          pageData: {
            ...state.approvals.pageData,
            currentPageNumber: 1,
          },
          filter: {
            ...state.approvals.filter,
            ...action.payload,
          },
        },
      };
    }
    case changePageDataUsers.type: {
      return {
        ...state,
        users: {
          ...state.users,
          pageData: {
            ...state.users.pageData,
            ...action.payload,
          },
        },
      };
    }
    case changePageDataSubscribers.type: {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          pageData: {
            ...state.subscribers.pageData,
            ...action.payload,
          },
        },
      };
    }
    case changePageDataApprovals.type: {
      return {
        ...state,
        approvals: {
          ...state.approvals,
          pageData: {
            ...state.approvals.pageData,
            ...action.payload,
          },
        },
      };
    }
    case setSelectedTab.type: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case changeSortUsers.type: {
      return {
        ...state,
        users: {
          ...state.users,
          sortBy: action.payload,
        },
      };
    }
    case changeSortSubscribers.type: {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          sortBy: action.payload,
        },
      };
    }
    case changeSortApprovals.type: {
      return {
        ...state,
        approvals: {
          ...state.approvals,
          sortBy: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default userManagementReducer;
