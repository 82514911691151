import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Button from '../../components/Button';
import { Select } from '@blueprintjs/select';
import clsx from 'clsx';

const CustomSelectYear = ({
  filter,
  selectedValue = '',
  onChangeFilter = null,
  disabled = false,
  className = '',
}) => {
  return (
    <Select
      activeItem={selectedValue}
      filterable={false}
      disabled={disabled}
      popoverProps={{
        minimal: true,
        popoverClassName: 'shadow rounded',
        boundary: 'scrollParent',
      }}
      itemListRenderer={({ items, renderItem }) => {
        return (
          <div className="flex flex-col w-44 max-h-52 rounded overflow-y-auto bg-white">
            {items.map(renderItem)}
          </div>
        );
      }}
      onItemSelect={(item) => {
        if (onChangeFilter) {
          onChangeFilter(item);
        }
      }}
      items={filter}
      itemRenderer={(option, { handleClick, modifiers }) => {
        return (
          <div
            key={option.value}
            onClick={handleClick}
            className={`${
              modifiers.active ? 'bg-blue20' : 'bg-white'
            } px-2 text-xs text-center text-grey100 rounded transition-colors cursor-pointer mb-px py-3 hover:bg-blue10`}
          >
            {option.text}
          </div>
        );
      }}
      className={className}
    >
      <Button
        view="outlined"
        className={clsx('w-44 flex justify-between', disabled && 'opacity-50')}
      >
        <span className="text-sm whitespace-nowrap font-medium text-gray80 capitalize">
          {selectedValue}
        </span>
        <Icon
          icon={IconNames.CALENDAR}
          className="fill-current text-gray50"
          iconSize={16}
        />
      </Button>
    </Select>
  );
};

export default CustomSelectYear;
