import { useState, useEffect, forwardRef, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { debounce } from 'lodash';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';
import Skeleton from 'react-loading-skeleton';
import TextField from 'components/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSelectedRows,
  selectAllRows,
  toggleSelectedRow,
} from 'redux/features/ui';

const FLEX_ALIGNMENTS = {
  left: 'start',
  center: 'center',
  right: 'end',
};

const BUTTON_BASE_CLASSNAME =
  'h-10 px-3 py-2 my-1 text-white rounded focus:outline-none focus:ring-transparent';

const CustomDropDown = ({
  filter,
  selectedValue = '',
  setFilterBy = null,
  onChangeFilter = null,
}) => {
  return (
    <Select
      activeItem={selectedValue}
      filterable={false}
      popoverProps={{
        minimal: true,
        popoverClassName: 'shadow rounded',
        boundary: 'scrollParent',
      }}
      onItemSelect={(item) => {
        if (setFilterBy) {
          setFilterBy((prev) => {
            if (onChangeFilter) {
              onChangeFilter({
                ...prev,
                [`${filter.name}`]: item,
              });
            }
            return {
              ...prev,
              [`${filter.name}`]: item,
            };
          });
        }
      }}
      itemListRenderer={({ items, renderItem }) => {
        return (
          <div className="rounded max-h-64 overflow-y-auto p-2 bg-white">
            <ul>{items.map(renderItem)}</ul>
          </div>
        );
      }}
      items={[
        {
          text: `All ${filter.name}`,
          value: '',
        },
        ...filter.options,
      ]}
      itemRenderer={(option, { handleClick, modifiers }) => {
        return (
          <li
            key={option.value}
            onClick={handleClick}
            className={`${
              modifiers.active ? 'bg-blue20' : 'bg-white'
            } px-5 text-xs text-center text-grey100 rounded transition-colors cursor-pointer mb-px py-2 hover:bg-blue10`}
          >
            {option.text}
          </li>
        );
      }}
    >
      <button
        className={`bg-blue50 flex items-center justify-between ml-3 ${BUTTON_BASE_CLASSNAME}`}
      >
        <span className="text-sm whitespace-nowrap font-medium text-white capitalize mr-2">
          {(selectedValue && selectedValue.text) || filter.name}
        </span>
        <Icon
          icon={IconNames.CHEVRON_DOWN}
          className="fill-current text-white"
          iconSize={16}
        />
      </button>
    </Select>
  );
};
const CustomCellBody = ({
  value: initialValue,
  row,
  column: { customCell: CustomCell, ...columnData },
  state,
}) => {
  if (!CustomCell) {
    return <>{initialValue}</>;
  }
  return (
    <CustomCell
      value={initialValue}
      row={row}
      column={columnData}
      state={state}
    />
  );
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

function CustomTable({
  disableSearch = false,
  disabledCheckbox = false,
  disablePagination = false,
  downloadCSV,
  isLoading = false,
  dataTable = [],
  columnsTable = [],
  initialFilterValue = {},
  filterTable = [],
  additionalButton = [],
  onChangeFilter = null,
  search = '',
  onInputSearch = null,
  showEmptyData: ShowEmptyData = null,
  page = 1,
  totalPage = 1,
  onChangePage,
  optionTotalRow = [10, 25, 50, 100],
  totalRow = 10,
  onChangeTotalRow = null,
  onRowClick = null,
  renderBulkActions = () => {},
  onChangeSort,
  defaultSortedColumn,
}) {
  const dispatch = useDispatch();
  const selectedRows = useSelector(({ ui }) => ui.table.selectedRows);
  const selectedRowsOriginal = useSelector(
    ({ ui }) => ui.table.selectedRowsOriginal
  );
  const [rowPerPage, setRowPerPage] = useState(parseInt(totalRow));
  const data = useMemo(() => dataTable || [], [dataTable]);
  const columns = useMemo(() => columnsTable, [columnsTable]);
  const sortedColumn = useMemo(
    () => (defaultSortedColumn ? [defaultSortedColumn] : []),
    [defaultSortedColumn]
  );
  const isServerSideSorting = typeof onChangeSort === 'function';
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        initialState: {
          sortBy: sortedColumn,
        },
        columns,
        data,
        defaultColumn: {
          Cell: CustomCellBody,
        },
        autoResetSortBy: false,
        autoResetSelectedRows: false,
        manualSortBy: isServerSideSorting,
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) =>
          !disabledCheckbox
            ? [
                {
                  id: 'selection',
                  Header: ({ data }) => (
                    <div>
                      <IndeterminateCheckbox
                        onChange={() => {
                          if (selectedRows.length === data.length) {
                            dispatch(resetSelectedRows());
                          } else {
                            dispatch(selectAllRows(data));
                          }
                        }}
                        checked={selectedRows.length === data.length}
                        className="cursor-pointer appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent"
                      />
                    </div>
                  ),
                  Cell: ({ row }) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <IndeterminateCheckbox
                        onChange={() => {
                          dispatch(toggleSelectedRow(row.original));
                        }}
                        checked={selectedRows.includes(row.original.id)}
                        className="cursor-pointer appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent"
                      />
                    </div>
                  ),
                },
                ...columns,
              ]
            : columns
        );
      }
    );
  const [keySearch, setKeySearch] = useState(search);
  const [filterBy, setFilterBy] = useState(
    filterTable.length > 0
      ? filterTable.reduce((avaibleFilter, filter) => {
          let selectedFilter = null;
          if (initialFilterValue[`${filter.name}`] && filter.options) {
            const dataSelect = filter.options.filter(
              (v) => v.value === initialFilterValue[`${filter.name}`]
            );
            if (dataSelect.length > 0) {
              selectedFilter = dataSelect[0];
            }
          }
          return {
            ...avaibleFilter,
            [`${filter.name}`]: selectedFilter,
          };
        }, {})
      : {}
  );

  const debouncedSearchHandler = useRef(
    onInputSearch && debounce(onInputSearch, 300)
  ).current;
  const handleSearch = (e) => {
    setKeySearch(e.target.value);
    if (debouncedSearchHandler) {
      debouncedSearchHandler(e.target.value);
    }
  };

  useEffect(
    () => () => {
      dispatch(resetSelectedRows());
    },
    [dispatch]
  );

  return (
    <div className="w-full mb-5 pb-2 overflow-x-hidden shadow rounded bg-white">
      <div className="w-full overflow-hidden">
        {!disableSearch && (
          <div className="flex p-2 min-width-min items-center">
            <div className="relative w-full h-10 my-1 flex-grow">
              <TextField
                type="search"
                value={keySearch}
                onChange={handleSearch}
                className="w-full h-full pl-12 rounded-sm border-grey40 focus:outline-none focus:ring-transparent"
                placeholder="Search"
              />
            </div>
            {selectedRows.length < 2 ? (
              <>
                {filterTable.length > 0 &&
                  filterTable.map((filter, idx) => (
                    <CustomDropDown
                      key={`filter-${idx}`}
                      filter={filter}
                      selectedValue={filterBy[filter.name]}
                      setFilterBy={setFilterBy}
                      onChangeFilter={(changeData) =>
                        onChangeFilter && onChangeFilter(changeData)
                      }
                    />
                  ))}
                {additionalButton.length > 0 &&
                  additionalButton.map((Additional, index) => (
                    <Additional
                      key={`additional-btn-${index}`}
                      selectedRows={selectedRows.map((row) => row.original)}
                      baseClassName={BUTTON_BASE_CLASSNAME}
                    />
                  ))}
                {downloadCSV}
              </>
            ) : (
              <div className="ml-9 flex items-center">
                <span className="whitespace-nowrap mr-4">
                  {selectedRows.length} selected items
                </span>
                {renderBulkActions(selectedRows, selectedRowsOriginal)}
              </div>
            )}
          </div>
        )}

        <div className="w-full overflow-auto">
          <table className="min-w-full" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="bg-grey0 px-2 h-10"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      onClick={() => {
                        if (column.sortable !== false) {
                          column.toggleSortBy();
                          if (isServerSideSorting) {
                            let sortData;
                            if (!column.isSorted) {
                              sortData = {
                                sort: column.id,
                                order: 'asc',
                              };
                            } else if (column.isSortedDesc) {
                              sortData = {
                                sort: null,
                                order: null,
                              };
                            } else {
                              sortData = { sort: column.id, order: 'desc' };
                            }
                            onChangeSort(sortData);
                          }
                        }
                      }}
                      className={clsx(
                        'min-w-min px-3 py-2 text-left tracking-wide text-xs font-semibold uppercase cursor-pointer',
                        column.isSorted && 'bg-grey5'
                      )}
                    >
                      <div
                        className={clsx(
                          'flex items-center w-full',
                          column.alignment &&
                            `justify-${FLEX_ALIGNMENTS[column.alignment]}`
                        )}
                      >
                        <span className={clsx('text-grey60 block')}>
                          {column.render('Header')}
                        </span>
                        {column.sortable !== false && column.canSort && (
                          <div className="flex flex-col ml-2">
                            <Icon
                              icon={IconNames.CARET_UP}
                              iconSize={14}
                              className={clsx(
                                'fill-current -mb-2',
                                !column.isSorted || column.isSortedDesc
                                  ? 'text-grey50'
                                  : 'text-blue50'
                              )}
                            />
                            <Icon
                              icon={IconNames.CARET_DOWN}
                              iconSize={14}
                              className={clsx(
                                'fill-current',
                                !column.isSorted || !column.isSortedDesc
                                  ? 'text-grey50'
                                  : 'text-blue50'
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {isLoading &&
                [...new Array(10)].map((_, indexRow) => (
                  <tr key={indexRow}>
                    {[...new Array(columns.length + 1)].map(
                      (_, indexColumn) => {
                        return (
                          <td
                            key={indexColumn}
                            className="min p-3 text-grey90 text-sm font-normal"
                          >
                            <Skeleton />
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
              {!isLoading &&
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              onRowClick
                                ? onRowClick(row.original, cell.column)
                                : null
                            }
                            className={clsx(
                              'min-w-min p-3 text-grey90 text-sm font-normal',
                              onRowClick && 'cursor-pointer'
                            )}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              {!isLoading &&
                !(rows.length > 0) &&
                (ShowEmptyData ? (
                  <ShowEmptyData />
                ) : (
                  <tr>
                    <td
                      colSpan={columnsTable.length + 1}
                      className={`py-4 text-center text-grey70 text-sm font-medium`}
                    >
                      No Data
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {!disablePagination && (
          <div className="flex justify-end items-center px-3">
            <Select
              filterable={false}
              activeItem={rowPerPage}
              popoverProps={{
                minimal: true,
                popoverClassName: 'shadow rounded',
                boundary: 'scrollParent',
              }}
              className="text-grey80"
              onItemSelect={(item) => {
                setRowPerPage(item);
                if (onChangeTotalRow) {
                  onChangeTotalRow(item);
                }
              }}
              itemListRenderer={({ items, renderItem }) => {
                return (
                  <div className="w-full rounded overflow-y-auto p-2 bg-white">
                    <ul>{items.map(renderItem)}</ul>
                  </div>
                );
              }}
              items={optionTotalRow}
              itemRenderer={(option, { handleClick, modifiers }) => {
                return (
                  <li
                    key={option}
                    onClick={handleClick}
                    className={`${
                      modifiers.active ? 'bg-grey20' : 'bg-white'
                    } px-5 text-xs text-center text-grey100 rounded transition-colors cursor-pointer mb-px py-2 hover:bg-grey10`}
                  >
                    {`${option} Rows`}
                  </li>
                );
              }}
            >
              <button className="bg-transparent flex items-center justify-between min-w-min h-10 mx-2 px-3 py-2 my-1 rounded focus:outline-none focus:ring-transparent">
                <span className="text-xs font-medium capitalize mr-2">
                  {`Show ${rowPerPage} Rows`}
                </span>
                <Icon icon={IconNames.CHEVRON_DOWN} iconSize={16} />
              </button>
            </Select>
            <div>
              <button
                className={clsx(
                  'bg-transparent min-w-min h-10 mx-1 px-1  focus:outline-none focus:ring-transparent'
                )}
                onClick={() => {
                  if (page !== 1) {
                    if (onChangePage) {
                      onChangePage(page - 1);
                    }
                  }
                }}
              >
                <Icon
                  className={clsx(
                    'fill-current',
                    page === 1 ? 'text-grey30' : 'text-grey90'
                  )}
                  icon={IconNames.CHEVRON_LEFT}
                  iconSize={16}
                />
              </button>
              <button
                className={clsx(
                  'bg-transparent min-w-min h-10 mx-1 px-1  focus:outline-none focus:ring-transparent'
                )}
                onClick={() => {
                  if (page < totalPage) {
                    if (onChangePage) {
                      onChangePage(page + 1);
                    }
                  }
                }}
              >
                <Icon
                  className={clsx(
                    'fill-current',
                    page < totalPage ? 'text-grey90' : 'text-grey30'
                  )}
                  icon={IconNames.CHEVRON_RIGHT}
                  iconSize={16}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomTable;
