import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthLayout from '../../layouts/AuthLayout';
import { getUserProfile, verifyEmail } from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import { createErrorMessageSelector } from '../../redux/api/error';
import Button from 'components/Button';
import { push } from 'connected-react-router';

function VerifyEmail(props) {
  const dispatch = useDispatch();
  const urlParams = useSelector((state) => state.router.location.query);
  const error = useSelector((state) =>
    createErrorMessageSelector([verifyEmail.type])(state)
  );
  const loading = useSelector((state) =>
    createLoadingSelector([verifyEmail.type])(state)
  );

  useEffect(() => {
    const { oobCode: actionCode } = urlParams;
    dispatch(verifyEmail(actionCode));
  }, [urlParams, dispatch]);

  return (
    <AuthLayout>
      <div className="w-full flex flex-col">
        <header className="text-3xl font-semibold text-center">
          Verify Email
        </header>
        <div className={`text-center italic mt-12`}>
          {loading ? (
            <p>Verifying....</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="flex flex-col items-center">
              <p className="mb-2">Your email is successfully verified.</p>
              <Button
                color="primary"
                onClick={async () => {
                  await dispatch(getUserProfile());
                  dispatch(push('/u'));
                }}
              >
                Go To Homepage
              </Button>
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
}

export default VerifyEmail;
