import moment from 'moment';

function pad(num) {
  return ('0' + num).slice(-2);
}

function hhmmss(secs) {
  let minutes = Math.floor(secs / 60);
  secs = secs % 60;
  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

function getDueDays(expireAt) {
  return moment(expireAt || '').diff(moment(Date.now()).startOf('day'), 'days');
}
export { pad, hhmmss, getDueDays };
