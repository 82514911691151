import ConfirmationDialog from 'components/ConfirmationDialog';

export default function DialogPublishVariable(props) {
  return (
    <ConfirmationDialog
      isOpen={props.isOpen}
      mode={props.mode}
      isLoading={props.isLoading}
      title={props.title}
      captions={{
        confirm: props.confirm,
      }}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
    >
      {props.text}
    </ConfirmationDialog>
  );
}
