import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';

export const COLOR_VARIANTS_ENABLED = {
  filled: {
    default: `border-grey50 bg-grey50 text-white hover:text-white`,
    primary: `border-blue50 bg-blue50 text-white hover:text-white`,
    danger: `border-red50 bg-red50 text-white hover:text-white`,
    success: `border-green50 bg-green50 text-white hover:text-white`,
  },
  outlined: {
    default: `border-grey50 bg-white text-grey50 hover:text-grey50`,
    primary: `border-blue50 bg-white text-blue50 hover:text-blue50`,
    danger: `border-red50 bg-white text-red50 hover:text-red50`,
    success: `border-green50 bg-white text-green50 hover:text-green50`,
  },
};
export const COLOR_VARIANTS_DISABLED = {
  filled: {
    default: `border-grey30 bg-grey30 text-white hover:text-white`,
    primary: `border-blue30 bg-blue30 text-white hover:text-white`,
    danger: `border-red30 bg-red30 text-white hover:text-white`,
    success: `border-green30 bg-green30 text-white hover:text-white`,
  },
  outlined: {
    default: `border-grey30 bg-white text-grey30 hover:text-grey30`,
    primary: `border-blue30 bg-white text-blue30 hover:text-blue30`,
    danger: `border-red30 bg-white text-red30 hover:text-red30`,
    success: `border-green30 bg-white text-green30 hover:text-green30`,
  },
};

export const SIZE_VARIANTS = {
  small: 'px-3 py-2 text-xs font-bold',
  normal: 'px-4 py-3 text-xs font-bold',
  medium: 'p-4 text-sm font-semibold',
};

const Button = forwardRef((props, ref) => {
  const colorVariants = props.disabled
    ? COLOR_VARIANTS_DISABLED
    : COLOR_VARIANTS_ENABLED;
  return (
    <button
      ref={ref}
      type={props.type}
      name={props.name}
      className={`${
        props.className
      } flex items-center justify-center whitespace-nowrap rounded border ${
        SIZE_VARIANTS[props.size]
      } ${colorVariants[props.view][props.color]} ${
        props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={props.type === 'button' ? props.onClick : null}
      disabled={props.disabled}
    >
      {props.isLoading && (
        <Spinner
          className={`inline h-4 w-4 fill-current ${
            props.view === 'filled' ? 'text-grey20' : 'text-grey40'
          } mr-2`}
        />
      )}
      {props.children}
    </button>
  );
});

export default Button;

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']).isRequired,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'medium']).isRequired,
  view: PropTypes.oneOf(['filled', 'outlined']).isRequired,
  color: PropTypes.oneOf(['default', 'primary', 'success', 'danger'])
    .isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  type: 'button',
  size: 'normal',
  view: 'filled',
  color: 'default',
  onClick: () => {},
  isLoading: false,
  disabled: false,
  className: '',
};
