import { useState, useEffect, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { showToastSuccess, showToastError } from 'layouts/DashboardLayout';
import { uniq, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import StringUtils from 'utils/String';
import SelectInstitution from 'Pages/SetupAccount/SelectInstitution';
import { createLoadingSelector } from '../../../redux/api/loading';
import {
  uploadStudentIdCard,
  updateUserData,
  getInstitutions,
  getUserProfile,
  addInstitution,
} from 'redux/features/auth';
import Button from 'components/Button';
import { actionSuccess } from '../../../redux/utils/actionCreator';
import UserWaiting from 'assets/svg/user_subscription_waiting.svg';

const MAX_FILE_SIZE = 5000;

const RegexNumber = /^[A-Za-z0-9]*$/;
const schema = Yup.object().shape({
  nim: Yup.string()
    .required('Student Identification Number is required')
    .matches(
      RegexNumber,
      'Student Identification Number should be a number and alphanumeric'
    )
    .min(5, 'Student Identification Number should be minimum 5 character'),
  institution: Yup.string().required('Institution is required'),
  new_institution: Yup.string().when('institution', {
    is: 'Others',
    then: Yup.string().required(),
  }),
});

export default function AccountType() {
  const loading = useSelector((state) =>
    createLoadingSelector([uploadStudentIdCard.type, updateUserData.type])(
      state
    )
  );
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const [institutions, setInstitutions] = useState([]);
  const [institutionSearch, setInstitutionSearch] = useState('');
  const [studentId, setStudentId] = useState({ url: null, file: null });
  const [formError, setFormError] = useState({ field: '', message: '' });
  const uploadStudentIdRef = useRef();
  const { handleSubmit, register, setValue, watch, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const openFileStudentId = () => {
    uploadStudentIdRef.current?.click();
  };
  const institutionErrorMessage = errors?.institution?.message;
  const nimErrorMessage = errors?.nim?.message;
  const loadingInstitutions = useSelector(
    createLoadingSelector([getInstitutions.type])
  );
  const handleSubmitApplication = (e) => {
    e.preventDefault();
    if (studentId.url !== null) {
      handleSubmit(onSubmitApplyStudent)();
    } else
      setFormError({
        field: 'studentId',
        message: 'Please provide your student ID.',
      });
  };
  const onSubmitApplyStudent = async (data) => {
    try {
      const submitData = {
        id: userDetail.id,
        type: 'student',
        cardIdUrl: '',
        institution:
          data.institution === 'Others'
            ? data.new_institution
            : data.institution,
        nim: data.nim,
        submissionDate: new Date(),
      };
      const formUploadImage = new FormData();
      formUploadImage.set('file', studentId.file);
      const { payload } = await dispatch(uploadStudentIdCard(formUploadImage));
      if (payload) submitData.cardIdUrl = payload.publicUrl;
      const actionDispatced = await dispatch(updateUserData(submitData));
      if (actionDispatced.type === `${updateUserData.type}_SUCCESS`) {
        // Refetch data to get new type status
        await dispatch(getUserProfile());
        showToastSuccess('Your student account application has been submitted');
      }
      if (data.institution === 'Others')
        dispatch(addInstitution(data.new_institution));
    } catch (err) {
      showToastError('Your student account application failed to be submitted');
    }
  };
  const userType = StringUtils.capitalizeFirstLetter(userDetail.type);
  const handleChangeStudentId = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      if (e.target.files[0].size / 1000 < MAX_FILE_SIZE) {
        reader.addEventListener('load', function (event) {
          setStudentId({ url: event.target.result, file: e.target.files[0] });
        });
        reader.readAsDataURL(e.target.files[0]);
        if (formError.field === 'studentId')
          setFormError({ field: '', message: '' });
      } else {
        handleRemoveStudentId();
        setFormError({
          field: 'studentId',
          message: 'Maximum file size exceeded',
        });
      }
    }
  };
  const handleRemoveStudentId = () => {
    if (uploadStudentIdRef.current) {
      uploadStudentIdRef.current.value = null;
    }
    setStudentId({ url: null, file: null });
  };

  useEffect(() => {
    if (institutionSearch) {
      dispatch(getInstitutions(institutionSearch)).then((response) => {
        if (response.type === actionSuccess(getInstitutions.type)) {
          const institutionNames = response.payload.map(
            (institution) => institution.nama
          );
          setInstitutions((prev) =>
            uniq([...prev, ...institutionNames, 'Others']).sort()
          );
        }
      });
    }
  }, [dispatch, institutionSearch]);

  return (
    <div className="w-full">
      {userType.toLowerCase() === 'student' &&
        userDetail.profile.statusApproval === 'Awaiting' && (
          <div className={'justify-center'}>
            <img src={UserWaiting} className={'mx-auto'} alt={'user_waiting'} />
            <p className="text-base text-grey50 text-center">
              Please wait, your student account application is currently being
              reviewed. Your current account type is General
            </p>
          </div>
        )}
      {userType.toLowerCase() === 'student' &&
        userDetail.profile.statusApproval === 'Accepted' && (
          <>
            <div className={'mb-5'}>
              <p className="text-base text-grey50 mb-6">
                Your account is already a Student account. You can get a
                discount off the normal price.
              </p>
              <p className="mb-2 text-sm text-grey50">Current Account Type</p>
              <input
                className={'bg-grey5 rounded border-none'}
                disabled
                type={'text'}
                value={userType}
              />
            </div>
          </>
        )}
      {userType.toLowerCase() === 'general' && (
        <>
          <div className={'mb-5'}>
            <p className="mb-2 text-sm text-grey50">Current Account Type</p>
            <input
              className={'bg-grey5 rounded border-none'}
              disabled
              type={'text'}
              value={userType}
            />
          </div>
          <p className="mb-2 text-base text-grey50">
            With the student account you can get a discount off the normal
            price.
            <br />
            You can apply for a student account here.
          </p>
          <form onSubmit={handleSubmitApplication}>
            <div className="flex flex-col mb-1 w-3/5">
              <label htmlFor="institution" className="text-grey50 text-sm mb-1">
                Institution<span className="text-red50">*</span>
              </label>
              <SelectInstitution
                loading={loadingInstitutions}
                register={register}
                items={institutions}
                onSearch={debounce((input) => setInstitutionSearch(input), 500)}
                onItemSelected={(item) => setValue('institution', item)}
              />
              {institutionErrorMessage && (
                <p className="text-xs text-red50 mt-px pt-1">
                  {institutionErrorMessage}
                </p>
              )}
            </div>
            {watch('institution') === 'Others' && (
              <div className="relative flex items-center mt-1.5 w-3/5">
                <input
                  type="text"
                  name="new_institution"
                  ref={register}
                  className="border border-transparent text-sm rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
                />
                {errors.new_institution && (
                  <span className="absolute right-0 mr-2">
                    {errors.new_institution ? (
                      <Icon
                        icon={IconNames.ERROR}
                        iconSize={20}
                        className={`fill-current text-grey50`}
                      />
                    ) : (
                      <Icon
                        icon={IconNames.TICK}
                        iconSize={20}
                        className={`fill-current text-green50`}
                      />
                    )}
                  </span>
                )}
              </div>
            )}
            <span className="block text-xs text-grey40 mb-2">
              Choose Others if your university is not on the list
            </span>
            <div className={'mb-5 w-3/5'}>
              <p className="text-grey50 text-sm mb-2">
                Student Identification Number
                <span className="text-red50">*</span>
              </p>
              <input
                ref={register}
                id={'NIM'}
                name={'nim'}
                className="border border-blue50 rounded w-full p-2 px-3 focus:ring-grey40 focus:outline-none focus:border-blue50"
              />
              {nimErrorMessage && (
                <p className="text-xs text-red50 mt-px pt-1">
                  {nimErrorMessage}
                </p>
              )}
            </div>
            <div className="mb-3 w-3/5">
              <label htmlFor="studentId" className="text-grey50 text-sm mb-1">
                Student ID card (JPG / PNG and max. 5 MB)
              </label>
              {formError.field === 'studentId' && (
                <p className="text-xs text-red50 mb-px">{formError.message}</p>
              )}
              <div className="relative flex flex-col items-center justify-end rounded-md bg-grey5 p-1 h-40 w-full">
                {studentId.url && (
                  <img
                    src={studentId.url}
                    alt="student ID"
                    className="h-16 w-16 rounded absolute mx-auto top-6"
                  />
                )}
                <input
                  type="file"
                  name="studentId"
                  ref={uploadStudentIdRef}
                  accept="image/*"
                  onChange={handleChangeStudentId}
                  className="hidden"
                />
                <Button
                  color="danger"
                  size="small"
                  className="absolute top-2 right-2"
                >
                  <Icon
                    icon={IconNames.CROSS}
                    iconSize={14}
                    onClick={handleRemoveStudentId}
                    className="fill-current text-white"
                  />
                </Button>
                <Button
                  view="outlined"
                  color="primary"
                  onClick={openFileStudentId}
                  className="mb-2"
                >
                  Upload student ID card
                </Button>
              </div>
            </div>
            <Button
              color="primary"
              onClick={handleSubmitApplication}
              type="submit"
              className="mt-6"
              isLoading={loading}
            >
              Apply Student Account
            </Button>
          </form>
        </>
      )}
    </div>
  );
}
