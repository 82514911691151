import Dialog from 'components/Dialog';
import Checkbox from './Checkbox';

function DocumentSectionDialog(props) {
  const renderSections = () => {
    return (
      <div className="flex flex-wrap">
        {props.data.map((section) => (
          <div key={section.id} className="w-full mb-2">
            <Checkbox label={section.name} checked={true} disabled={true} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Dialog
      header="More Document Sections"
      isOpen={props.isOpen}
      onClose={props.onClose}
      dismissButton
    >
      <div className="flex flex-col h-full w-full px-4">
        <div className="h-56 w-full overflow-y-auto">{renderSections()}</div>
      </div>
    </Dialog>
  );
}

export default DocumentSectionDialog;
