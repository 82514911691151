import ConfirmationDialog from 'components/ConfirmationDialog';

const ModalDeleteDiscount = ({
  visible,
  onCancel,
  onConfirm,
  loading,
  onClose,
}) => {
  return (
    <ConfirmationDialog
      mode="danger"
      title="Delete Discount"
      isOpen={visible}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isLoading={loading}
      captions={{ confirm: 'Delete' }}
    >
      Are you sure you want to delete this discount?
    </ConfirmationDialog>
  );
};

export default ModalDeleteDiscount;
