import { useHistory } from 'react-router-dom';
import PaymentSuccess from 'assets/svg/payment-success.svg';
import Button from 'components/Button';

export default function PaymentComplete() {
  const history = useHistory();
  return (
    <div className={'w-full flex flex-col items-center justify-center my-10'}>
      <img src={PaymentSuccess} alt={'payment_success'} />
      <div className={'text-center flex flex-col'} style={{ width: '430px' }}>
        <div className={'text-grey100 text-2xl font-bold'}>
          Your order is complete
        </div>
        <div className={'text-xl text-grey50 pt-2 pb-4'}>
          You will be receiving a confirmation email with order details
        </div>
        <Button
          color="primary"
          size="medium"
          onClick={() => {
            history.push('/u');
          }}
        >
          Explore Our Service
        </Button>
      </div>
    </div>
  );
}
