import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SelectField = forwardRef((props, ref) => {
  return (
    <select
      ref={ref}
      name={props.name}
      className={`${props.className} w-full rounded-sm border border-grey40 focus:border-blue50 focus:outline-none focus:ring-transparent`}
    >
      {props.options.map((option, index) => (
        <option key={`option-${index}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
});

SelectField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default SelectField;
