import { useSelector } from 'react-redux';
import { updateNotes } from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';
import ConfirmationDialog from 'components/ConfirmationDialog';

function DeleteNoteDialog(props) {
  const loading = useSelector(createLoadingSelector([updateNotes.type]));
  const handleDelete = () => {
    props.onSubmit();
  };

  return (
    <ConfirmationDialog
      title="Delete Note"
      isOpen={props.isOpen}
      onCancel={props.onClose}
      onConfirm={handleDelete}
      isLoading={loading}
      mode="danger"
      captions={{
        confirm: 'Delete',
      }}
    >
      Are you sure you want to delete this note?
    </ConfirmationDialog>
  );
}

export default DeleteNoteDialog;
