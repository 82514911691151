import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import OUTPUT_UNITS from './data/output_units.json';
import MONGODB_OPERATORS from './data/mongodb_operator_suggestions.json';
import {
  createVariableTransformation,
  getCategories,
  getClassifications,
  getVariableSuggestions,
  runTransformationExample,
  getVariableRefId,
} from 'redux/features/variableManagement';
import { createLoadingSelector } from 'redux/api/loading';
import { actionSuccess } from 'redux/utils/actionCreator';
import clsx from 'clsx';
import { showToastSuccess } from 'layouts/DashboardLayout';
import { showToastError } from 'layouts/DashboardLayout';

export default function AddVariableDialog(props) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [queryResult, setQueryResult] = useState('');
  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [transformError, setTransformError] = useState(false);
  const { categories, classifications } = useSelector(
    (state) => state.variableManagement
  );
  const suggestions = useSelector(
    ({ variableManagement }) => variableManagement.suggestions
  );
  const loadingCreateVariable = useSelector(
    createLoadingSelector([createVariableTransformation.type])
  );
  const runTransformationExampleLoading = useSelector(
    createLoadingSelector([runTransformationExample.type])
  );
  const [customPrice, setCustomPrice] = useState(0);
  const clasificationVariable = useMemo(
    () => [
      ...classifications,
      { basePrice: customPrice, id: 0, name: 'Custom' },
    ],
    [classifications, customPrice]
  );
  const { register, handleSubmit, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      output: '',
      label: 'Environment',
      isPublished: false,
    },
  });
  const resetForm = () => {
    reset();
    setQuery('');
  };
  const onSubmit = async (data) => {
    const refIds = await runTransformation(query);
    if (refIds?.length > 0 || query === '') {
      const { type, payload } = await dispatch(
        createVariableTransformation({
          ...data,
          refIds: query === '' ? [] : refIds,
          transformation: query || null,
          ...(+data.classification_id === 0 ? { classification_id: null } : {}),
          price: getBasePriceValue(),
        })
      );
      if (type === actionSuccess(createVariableTransformation.type)) {
        setIsAlreadyExists(false);
        props.onClose();
        props.onSuccess();
        showToastSuccess('Your variable was created successfully');
        resetForm();
      } else {
        if (payload.message === 'Variable already exist') {
          setIsAlreadyExists(true);
        } else {
          showToastError('An error occured');
        }
      }
    } else {
      showToastError('An error occured');
    }
  };
  const selectedClassId = watch(
    'classification_id',
    clasificationVariable.length ? clasificationVariable[0].id : ''
  );
  const runTransformation = useCallback(
    (query) => {
      async function _run(query) {
        if (!query) return;
        const regex = /"variableName"\s*:\s*"([a-zA-Z]+)"/g;
        const matchResults = [...query.matchAll(regex)];
        const { type, payload } = await dispatch(
          runTransformationExample(query)
        );
        if (matchResults.length > 0) {
          const variableNames = [];
          const results = [];
          for (let match of matchResults) {
            if (!variableNames.includes(match[1])) {
              const result = await dispatch(getVariableRefId(match[1]));
              results.push(result);
              variableNames.push(match[1]);
            }
          }
          const allSuccess = results.every(
            (result) => result.type === actionSuccess(getVariableRefId.type)
          );
          if (
            type === actionSuccess(runTransformationExample.type) &&
            results.length > 0 &&
            allSuccess &&
            variableNames.length === results.length
          ) {
            const refIds = results.map((result) => result.payload);
            setTransformError(false);
            if (typeof payload === 'string') {
              setQueryResult(payload.replaceAll(';', '\n'));
            } else {
              setQueryResult(payload);
            }
            return refIds;
          } else {
            setQueryResult('');
            setTransformError(true);
          }
        } else {
          setTransformError(true);
        }
      }
      return _run(query);
    },
    [dispatch]
  );
  const getBasePriceValue = () => {
    const price = clasificationVariable.find(
      (c) => c.id === +selectedClassId
    )?.basePrice;
    return price || '';
  };

  useEffect(() => {
    if (props.isOpen && Object.keys(suggestions).length === 0) {
      dispatch(getVariableSuggestions());
    }
  }, [dispatch, props.isOpen, suggestions]);

  useEffect(() => {
    if (props.isOpen) {
      if (classifications.length === 0) dispatch(getClassifications());
      if (categories.length === 0) dispatch(getCategories());
    }
  }, [dispatch, props.isOpen, classifications, categories]);

  return (
    <Dialog
      isOpen={props.isOpen}
      header={'Add New Variable'}
      onClose={props.onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full px-4 pb-2">
          <div className="flex flex-col">
            <div>
              <label htmlFor="name" className="text-grey50">
                Variable Name
              </label>
              <input
                ref={register}
                type="text"
                name="name"
                className={clsx(
                  'border px-3 py-4 rounded w-full mt-1',
                  isAlreadyExists ? 'border-red50' : 'border-grey30'
                )}
                placeholder="Enter your variable name"
                required
              />
              {isAlreadyExists && (
                <span className="text-xs text-red50">
                  Sorry, the variable already exists
                </span>
              )}
            </div>
            <div className="mt-5">
              <label htmlFor="description" className="text-grey50">
                Variable Description
              </label>
              <input
                ref={register}
                type="text"
                name="description"
                className="border border-grey30 px-3 py-4 rounded w-full mt-1"
                placeholder="Enter your variable description"
                required
              />
            </div>
            <div className="mt-5">
              <label htmlFor="transformation" className="text-grey50">
                Variable Transformation
              </label>
              <TextInput
                value={query}
                onChange={setQuery}
                trigger={['/', '', '.', '$']}
                changeOnSelect={(trigger, slug) => {
                  if (trigger === '.') return `${trigger}${slug}`;
                  if (trigger === '') return `"${slug}"`;
                  if (trigger === '$') return `"${trigger}${slug}"`;
                  if (trigger === '/' && slug === 'variableName')
                    return `"${slug}"`;
                  return slug;
                }}
                minChars={2}
                disableMinChars={['.', '/', '$']}
                disableSpacerOn={['.', '/', '', '$']}
                maxOptions={0}
                options={{
                  '/': [
                    'find',
                    'findOne',
                    'aggregate',
                    'count',
                    'variableName',
                  ],
                  '': Object.keys(suggestions),
                  '.': suggestions,
                  $: MONGODB_OPERATORS,
                }}
                hierarchicalStringResolver={(str, matchStart) => {
                  const suggestedStr = str
                    .slice(str.lastIndexOf('$data', matchStart), matchStart)
                    .replace(/"|,/g, '');
                  const slicedPropStr = str.slice(0, matchStart);
                  let variableName = '';
                  if (slicedPropStr.includes('variableName')) {
                    const regex = /"variableName":\s*"([a-zA-Z]+)"/;
                    const matchWord = slicedPropStr.match(regex);
                    // get matched string from group (index 1 of the result) if there is match
                    variableName = Array.isArray(matchWord) ? matchWord[1] : '';
                  }
                  return suggestedStr.replace('$data', variableName);
                }}
                rows={3}
                className="border border-grey30 px-3 py-4 rounded w-full mt-1 resize-y"
                placeholder="Enter your variable transformation"
              />
            </div>
            <div className="mt-3 flex items-center justify-between">
              <textarea
                type="text"
                name="outputExample"
                value={
                  runTransformationExampleLoading
                    ? 'Running...'
                    : !transformError
                    ? queryResult
                    : 'Incorrect Transformation Query'
                }
                className={clsx(
                  transformError && !runTransformationExampleLoading
                    ? 'border-red30 bg-red10 text-red50'
                    : 'border-grey30 bg-grey10',
                  'border h-11 flex-grow mr-3 rounded resize-y'
                )}
                placeholder=""
                readOnly
              />
              <Button
                color="success"
                disabled={runTransformationExampleLoading}
                onClick={() => runTransformation(query)}
              >
                Run
              </Button>
            </div>
            <div className="mt-5">
              <label htmlFor="output" className="text-grey50">
                Output unit
              </label>
              <select
                ref={register}
                name="output"
                className="mt-1 border border-grey30 py-4 w-full rounded"
              >
                {OUTPUT_UNITS.map((unit, index) => (
                  <option
                    key={`output-unit-${index + 1}`}
                    value={unit === '-' ? '' : unit}
                  >
                    {unit}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center mt-5">
              <input
                ref={register}
                type="checkbox"
                name="isPublished"
                className="border text-teal2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-2"
              />
              <label
                htmlFor="isPublished"
                className="block text-grey100 text-sm"
              >
                Publish variable in the dashboard
              </label>
            </div>
            <div className="mt-5">
              <label htmlFor="label" className="text-grey50">
                Label
              </label>
              <select
                ref={register}
                name="label"
                className="mt-1 border border-grey30 py-4 w-full rounded"
                required
              >
                <option>Environment</option>
                <option>Social</option>
                <option>Governance</option>
                <option>Finance</option>
                <option>Others</option>
              </select>
            </div>
            <div className="mt-5">
              <label htmlFor="category_id" className="text-grey50">
                Category
              </label>
              <select
                ref={register}
                name="category_id"
                className="mt-1 border border-grey30 py-4 w-full rounded"
                defaultChecked={categories.length ? categories[0].id : null}
                required
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-5">
              <label htmlFor="classification_id" className="text-grey50">
                Data Classification
              </label>
              <select
                ref={register}
                name="classification_id"
                className="mt-1 border border-grey30 py-4 w-full rounded"
                defaultChecked={
                  clasificationVariable.length
                    ? clasificationVariable[0].id
                    : null
                }
                required
              >
                {clasificationVariable.map((classification) => (
                  <option key={classification.id} value={classification.id}>
                    {classification.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-5">
              <label htmlFor="basePrice" className="text-grey50">
                Base Price
              </label>
              <input
                type="text"
                name="basePrice"
                className={clsx(
                  'border border-grey30 px-3 py-4 rounded w-full mt-1',
                  +selectedClassId !== 0 && 'bg-grey10 '
                )}
                value={getBasePriceValue()}
                readOnly={+selectedClassId !== 0}
                onChange={(e) => {
                  if (+selectedClassId === 0) {
                    const price = +e?.target?.value;
                    if (!Number.isNaN(price))
                      setCustomPrice(+(e?.target?.value || 0));
                  }
                }}
                required
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-4 flex items-center justify-end">
          <Button
            view="outlined"
            color="danger"
            onClick={props.onClose}
            className="mr-4"
            disabled={loadingCreateVariable}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" loading={loadingCreateVariable}>
            Add Variable
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
