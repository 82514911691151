import { useState, useEffect } from 'react';
import BCA from 'assets/svg/bank_bca.svg';
import Countdown from '../Countdown';
import { Tooltip, Position } from '@blueprintjs/core';
import ActionButtons from './ActionButtons';

export default function BCAVa({
  invoiceNumber,
  vaNumber,
  price,
  dueDate,
  cancel,
  loadingCancel,
  setShowDetailInvoice,
  differenceInSecond,
}) {
  const [isOpen, setOpen] = useState(false);
  const maxWidthPhone = {
    maxWidth: '480px',
  };

  useEffect(() => {
    let timerId;
    if (isOpen) {
      timerId = setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [isOpen]);

  return (
    <div>
      <div className={'text-center'}>
        <div className={'text-xl'}>Complete payment in</div>
        <Countdown differenceInSecond={differenceInSecond} />
        <div className={'text-xl text-gray50 font-normal pb-2'}>
          Payment due date
        </div>
        <div className={'text-xl text-grey100'}>{dueDate}</div>
      </div>
      <div
        className={'mt-10 px-4 pb-4 rounded border mb-4'}
        style={maxWidthPhone}
      >
        <div className={'flex justify-between py-6'}>
          <div className={'text-xl font-medium'}>BCA Virtual Account</div>
          <div>
            <img src={BCA} className={'w-14'} alt={'bca'} />
          </div>
        </div>
        <div className={'flex justify-between py-6'}>
          <div>
            <div className={'text-sm text-gray50'}>Virtual Account Number</div>
            <Tooltip
              content="Copied!"
              isOpen={isOpen}
              position={Position.RIGHT}
            >
              <div>{vaNumber}</div>
            </Tooltip>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(vaNumber);
                setOpen(true);
              }}
            >
              Copy
            </button>
          </div>
        </div>
        <div className={'flex justify-between'}>
          <div>
            <div className={'text-sm text-gray50'}>Total Payment</div>
            <div className={'text-xl text-red50 font-medium'}>{price}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                setShowDetailInvoice(true);
              }}
            >
              View Detail
            </button>
          </div>
        </div>
      </div>

      <ActionButtons
        invoiceNumber={invoiceNumber}
        cancel={cancel}
        loadingCancel={loadingCancel}
      />

      <div style={maxWidthPhone}>
        <div className={'text-xl font-medium py-4'}>Payment Method</div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>ATM BCA</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              On the main menu, choose Other Transaction
            </li>
            <li className={'text-gray50 text-sm'}>Choose Transfer</li>
            <li className={'text-gray50 text-sm'}>
              Choose Transfer To BCA Virtual Account
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter your Payment Code (11 digits code) and press Correct
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter the full amount to be paid and press Correct
            </li>
            <li className={'text-gray50 text-sm'}>
              Your payment details will appear on the payment confirmation page.
              If the information is correct press Yes
            </li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>m-BCA (BCA Mobile)</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              Log in to your BCA Mobile app
            </li>
            <li className={'text-gray50 text-sm'}>
              Choose m-BCA, then input your m-BCA access code
            </li>
            <li className={'text-gray50 text-sm'}>
              Choose m-Transfer, then choose BCA Virtual Account
            </li>
            <li className={'text-gray50 text-sm'}>
              Input Virtual Account Number or choose an existing account from
              Daftar Transfer
            </li>
            <li className={'text-gray50 text-sm'}>Input the payable amount</li>
            <li className={'text-gray50 text-sm'}>Input your m-BCA pin</li>
            <li className={'text-gray50 text-sm'}>
              Payment is finished. Save the notification as your payment receipt
            </li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>Klik BCA</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>Choose Menu Fund Transfer</li>
            <li className={'text-gray50 text-sm'}>
              Choose Transfer To BCA Virtual Account
            </li>
            <li className={'text-gray50 text-sm'}>
              Input BCA Virtual Account Number or Choose from Transfer list and
              click Continue
            </li>
            <li className={'text-gray50 text-sm'}>
              Amount to be paid, account number and Merchant name will appear on
              the payment confirmation page, if the information is right click
              Continue
            </li>
            <li className={'text-gray50 text-sm'}>
              Get your BCA token and input KEYBCA Response APPLI 1 and click
              Submit
            </li>
            <li className={'text-gray50 text-sm'}>Your Transaction is Done</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
