import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Popover, Position, Collapse, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { debounce } from 'lodash';
import { ToastContainer } from 'react-toastify';
import FullLayout from '../../layouts/FullLayout';
import DocumentSectionDialog from './DocumentSectionDialog';
import CompaniesDialog from './CompaniesDialog';
import KeywordAdvancedPopover from './KeywordAdvancedPopover';
import KeywordBasicPopover from './KeywordBasicPopover';
import SaveQueryDialog from './SaveQueryDialog';
import DownloadQueryDialog from './DownloadQueryDialog';
import Checkbox from './Checkbox';
import Switch from 'components/Switch';
import YearDropdown from './YearDropdown';
import DocumentList from './DocumentList';
import DocumentTable from './DocumentTable';
import AdvancedKeywordBar from './AdvancedKeywordBar';
import InputLostDialog from './InputLostDialog';
import Spinner from 'components/Spinner';
import TooltipInfo from './TooltipInfo';
import FreetrialInformation from '../../layouts/DashboardLayout/FreetrialInformation';
import { userHasActiveFreeTrial } from '../../redux/features/auth';
import noResultImage from '../../assets/images/empty-search-result.svg';
import defaultUserProfile from '../../assets/images/userProfileDefault.svg';
import saveIcon from '../../assets/svg/icons/save-filled.svg';
import folderIcon from '../../assets/svg/icons/folder-open.svg';
import downloadIcon from '../../assets/svg/icons/cloud-download.svg';
import String from './../../utils/String';
import {
  convertFieldsToItems,
  convertSymbolToFields,
} from '../../utils/searchKeyword';
import {
  SEARCH_MODE,
  SEARCH_TOOLS,
  VIEW_MODE,
  toggleSearchMode,
  toggleSearchTools,
  searchDocumentGroup,
  getSearchDetail,
  selectedResultSelector,
  getSearchContent,
  changeSelectedPageIndex,
  storeSearchKeywords,
  changeYearRange,
  getCompanyList,
  getSectionList,
  changeSelectedCompanies,
  changeSelectedSections,
  changeSelectedSectionNames,
  getPeriodRange,
  toggleWholeSections,
  resetSearchDetail,
} from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';
import PATH_URL from '../../routers/path';
import UserMenu from '../../components/UserMenu';
import useVisible from '../../hooks/useVisible';

const searchLoadingSelector = createLoadingSelector([searchDocumentGroup.type]);
const searchDetailLoadingSelector = createLoadingSelector([
  getSearchDetail.type,
]);
const searchContentLoadingSelector = createLoadingSelector([
  getSearchContent.type,
]);

function SearchDetail() {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const { ref, isVisible, setIsVisible } = useVisible();
  const [collapsedMenus, setCollapsedMenus] = useState([
    'yearRange',
    'esgTopic',
    'documentSection',
    'companies',
    'location',
  ]);
  const [modalIsOpen, setModalIsOpen] = useState({
    documentSection: false,
    companies: false,
    addKeyword: false,
    saveQuery: false,
    downloadQuery: false,
  });
  const {
    searchMode,
    activeTools: searchTools,
    viewMode,
    keywords,
    searchResult,
    filter,
  } = useSelector((state) => state.textAnalytics);
  const [confirmLostInputOpen, setConfirmLostInputOpen] = useState(false);
  const authData = useSelector((state) => state.auth);
  const selectedResult = useSelector(selectedResultSelector);
  const searchIsLoading = useSelector(searchLoadingSelector);
  const searchDetailIsLoading = useSelector(searchDetailLoadingSelector);
  const searchContentIsLoading = useSelector(searchContentLoadingSelector);
  const yearRangeIsLoading = useSelector(
    createLoadingSelector([getPeriodRange.type])
  );
  const sectionsIsLoading = useSelector(
    createLoadingSelector([getSectionList.type])
  );
  const companiesIsLoading = useSelector(
    createLoadingSelector([getCompanyList.type])
  );
  const freeTrialActive = useSelector(userHasActiveFreeTrial);
  const [isAdvancedPopoverOpen, setIsAdvancedPopoverOpen] = useState(false);
  const [isBasicPopoverOpen, setIsBasicPopoverOpen] = useState(false);
  const handleCollapseSidebar = (menu) => {
    if (collapsedMenus.includes(menu))
      setCollapsedMenus(
        collapsedMenus.filter((collapsed) => collapsed !== menu)
      );
    else setCollapsedMenus(collapsedMenus.concat(menu));
  };
  const isCollapsed = (menu) => collapsedMenus.includes(menu);
  const handleOpenModal = (name) =>
    setModalIsOpen({ ...modalIsOpen, [name]: true });
  const handleCloseModal = (name) =>
    setModalIsOpen({ ...modalIsOpen, [name]: false });
  const handleToggleSearchTools = (toolName) =>
    dispatch(toggleSearchTools(toolName));
  const handleNextPageContent = async () => {
    if (selectedResult) {
      const { id, pageData, pages, selectedPageIndex } = selectedResult;
      if (selectedPageIndex < pages.length - 1) {
        const pageNumber = pages[selectedPageIndex + 1];
        if (!pageData[pageNumber]) {
          await dispatch(getSearchContent(id, pageNumber));
        }
        dispatch(
          changeSelectedPageIndex({ id, pageIndex: selectedPageIndex + 1 })
        );
      }
    }
  };
  const handlePrevPageContent = () => {
    if (selectedResult) {
      const { id, selectedPageIndex } = selectedResult;
      if (selectedPageIndex > 0)
        dispatch(
          changeSelectedPageIndex({ id, pageIndex: selectedPageIndex - 1 })
        );
    }
  };
  const renderSearchContent = () => {
    if (selectedResult) {
      const { pageData, pages, selectedPageIndex } = selectedResult;
      if (pageData && pages && pageData[pages[selectedPageIndex]]) {
        const pageNumber = pages[selectedPageIndex];
        const contents = pageData[pageNumber].map((paragraph, index) =>
          searchTools.includes(SEARCH_TOOLS.HIGHLIGHT) ? (
            <String.Highlight
              key={`paragraph-${index}`}
              str={paragraph.sentences}
              search={paragraph.matchSentences}
              keyword={keywords.items}
            />
          ) : (
            <p key={`paragraph-${index}`}>{paragraph.sentences}</p>
          )
        );
        return [
          <span
            key={`page_result_${pageNumber}`}
            className="font-medium italic mb-4 block"
          >
            Page: {pageNumber}
          </span>,
          ...contents,
        ];
      }
    }
  };
  const handleRemoveCompanyFilter = (ticker) => {
    const updateSelected = filter.companies.selected.filter(
      (selected) => selected.ticker !== ticker
    );
    dispatch(changeSelectedCompanies(updateSelected));
    handleApplyFilter();
  };
  const toggleSectionFilter = (sectionId, sectionName) => {
    const { selected, selectedNames } = filter.documentSections;
    if (selected.includes(sectionId)) {
      const updatedSections = selected.filter(
        (selectedSectionId) => selectedSectionId !== sectionId
      );
      const updatedSectionNames = Object.keys(selectedNames).reduce(
        (acc, id) => {
          if (id !== sectionId) acc = { ...acc, [id]: selectedNames[id] };
          return acc;
        },
        {}
      );
      dispatch(changeSelectedSections(updatedSections));
      dispatch(changeSelectedSectionNames(updatedSectionNames));
    } else {
      dispatch(changeSelectedSections(selected.concat(sectionId)));
      dispatch(
        changeSelectedSectionNames({
          ...selectedNames,
          [sectionId]: sectionName,
        })
      );
    }
    handleApplyFilter();
  };
  const handleApplyFilter = () => {
    dispatch(searchDocumentGroup(true));
  };
  const handleChangeSearchMode = () => {
    dispatch(toggleSearchMode());
    dispatch(
      storeSearchKeywords({
        basic: '',
        advanced: [{ operator: '', keyword: '' }],
      })
    );
    setConfirmLostInputOpen(false);
  };

  useEffect(() => {
    const symbols = atob(urlParams.keywords);
    const advanced = convertSymbolToFields(symbols);
    dispatch(
      storeSearchKeywords({
        symbols,
        basic: advanced[0].keyword,
        advanced,
        items: convertFieldsToItems(advanced),
      })
    );
    dispatch(searchDocumentGroup(true));
  }, [urlParams.keywords, dispatch]);

  useEffect(() => {
    dispatch(getCompanyList());
    dispatch(getSectionList());
    dispatch(getPeriodRange());
    return () => {
      dispatch(resetSearchDetail());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      localStorage.setItem('keywords', btoa(keywords.symbols));
    };
  }, [keywords.symbols]);

  return (
    <FullLayout>
      {freeTrialActive && <FreetrialInformation isAdmin={false} />}
      <div
        className="flex w-full"
        style={{
          height: freeTrialActive ? 'calc(100% - 3rem)' : '100%',
        }}
      >
        <div className="w-64 h-full border-r border-grey5 flex flex-col flex-shrink-0">
          <div className="h-14 w-full pl-7 flex items-center border-b border-grey5 flex-shrink-0">
            <button
              onClick={() => dispatch(push(PATH_URL.HOME))}
              className="text-xs text-grey80 font-medium flex items-center"
            >
              <Icon
                icon={IconNames.ARROW_LEFT}
                iconSize={20}
                className="fill-current text-grey70 mr-2"
              />
              <span>Back to home</span>
            </button>
          </div>
          {/* Sidebar */}
          <div className="overflow-y-auto flex-grow pb-4">
            <div className="h-auto w-full border-b border-grey5 flex flex-col items-center">
              <div
                onClick={() => handleCollapseSidebar('yearRange')}
                className="h-10 w-full px-4 flex justify-center items-center cursor-pointer"
              >
                <div className="flex w-full justify-between">
                  <h5 className="text-xs tracking-wider font-semibold">
                    YEAR RANGE
                  </h5>
                  <Icon
                    icon={IconNames.CHEVRON_DOWN}
                    iconSize={16}
                    className={`fill-current text-grey70 transition-transform transform ${
                      isCollapsed('yearRange') ? 'rotate-180' : 'rotate-0'
                    }`}
                  />
                </div>
              </div>
              <Collapse isOpen={isCollapsed('yearRange')} className="w-full">
                <div className="w-full relative px-4 flex justify-between items-center mb-2">
                  <label
                    htmlFor="dateFrom"
                    className="text-xs text-grey50 w-12"
                  >
                    FROM
                  </label>
                  <YearDropdown
                    options={filter.yearRange.options}
                    selected={
                      !yearRangeIsLoading ? filter.yearRange.from : 'Loading...'
                    }
                    onChange={(selected) => {
                      const range =
                        selected > filter.yearRange.to
                          ? { from: selected, to: selected }
                          : { ...filter.yearRange, from: selected };
                      dispatch(changeYearRange(range));
                      handleApplyFilter();
                    }}
                  />
                </div>
                <div className="w-full relative px-4 pb-2 flex justify-between items-center">
                  <label htmlFor="dateTo" className="text-xs text-grey50 w-12">
                    TO
                  </label>
                  <YearDropdown
                    options={filter.yearRange.options.filter(
                      (year) => year >= filter.yearRange.from
                    )}
                    selected={
                      !yearRangeIsLoading ? filter.yearRange.to : 'Loading...'
                    }
                    onChange={(selected) => {
                      dispatch(
                        changeYearRange({
                          ...filter.yearRange,
                          to: selected,
                        })
                      );
                      handleApplyFilter();
                    }}
                  />
                </div>
              </Collapse>
            </div>
            {/* <div className="h-auto w-full flex flex-col items-start"> */}
            <div className="h-auto w-full hidden">
              <div
                onClick={() => handleCollapseSidebar('esgTopic')}
                className="flex h-10 px-4 w-full justify-between items-center flex-shrink-0 cursor-pointer"
              >
                <h5 className="text-xs tracking-wider font-semibold">
                  ESG TOPIC
                </h5>
                <Icon
                  icon={IconNames.CHEVRON_DOWN}
                  iconSize={16}
                  className={`fill-current text-grey70 transition-transform transform ${
                    isCollapsed('documentSection') ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </div>
              <Collapse isOpen={isCollapsed('esgTopic')} className="w-full">
                <div className="w-full px-4 h-auto flex flex-col bg-grey5">
                  <Checkbox label="Environmental" checked={true} />
                  <Checkbox label="Social" />
                  <Checkbox label="Governance" />
                </div>
              </Collapse>
            </div>
            <div className="h-auto w-full flex flex-col items-start">
              <div
                onClick={() => handleCollapseSidebar('documentSection')}
                className="flex h-10 px-4 w-full justify-between items-center flex-shrink-0 cursor-pointer"
              >
                <h5 className="text-xs tracking-wider font-semibold">
                  DOCUMENT SECTION
                </h5>
                <Icon
                  icon={IconNames.CHEVRON_DOWN}
                  iconSize={16}
                  className={`fill-current text-grey70 transition-transform transform ${
                    isCollapsed('documentSection') ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </div>
              <Collapse
                isOpen={isCollapsed('documentSection')}
                className="w-full"
              >
                <div
                  className={clsx(
                    'w-full px-4 h-auto flex flex-col',
                    !sectionsIsLoading && 'bg-grey5'
                  )}
                >
                  {sectionsIsLoading && (
                    <span className="text-xs text-grey60">Loading...</span>
                  )}
                  {!sectionsIsLoading && (
                    <>
                      <Checkbox
                        label="Whole Documents"
                        checked={filter.documentSections.isWhole}
                        onChange={() => {
                          dispatch(toggleWholeSections());
                          handleApplyFilter();
                        }}
                      />
                      {filter.documentSections.options
                        .slice(0, 4)
                        .map((section) => (
                          <Checkbox
                            key={section.id}
                            label={section.name}
                            checked={
                              filter.documentSections.isWhole
                                ? true
                                : filter.documentSections.selected.includes(
                                    section.id
                                  )
                            }
                            onChange={() =>
                              toggleSectionFilter(section.id, section.name)
                            }
                            disabled={filter.documentSections.isWhole}
                          />
                        ))}
                    </>
                  )}
                </div>
                {!sectionsIsLoading && (
                  <button
                    onClick={() => handleOpenModal('documentSection')}
                    className="text-xs font-semibold ml-4 mt-3 mb-2 text-orange2 tracking-wider"
                  >
                    MORE
                  </button>
                )}
              </Collapse>
            </div>
            <div className="h-auto w-full flex flex-col items-start">
              <div className="h-10 w-full px-4 flex justify-center items-center flex-shrink-0 cursor-pointer">
                <div
                  onClick={() => handleCollapseSidebar('companies')}
                  className="flex w-full justify-between"
                >
                  <h5 className="text-xs tracking-wider font-semibold">
                    COMPANIES
                  </h5>
                  <Icon
                    icon={IconNames.CHEVRON_DOWN}
                    iconSize={16}
                    className={`fill-current text-grey70 transition-transform transform ${
                      isCollapsed('companies') ? 'rotate-180' : 'rotate-0'
                    }`}
                  />
                </div>
              </div>
              <Collapse isOpen={isCollapsed('companies')} className="w-full">
                <div className="w-full px-4 h-auto flex flex-wrap items-start">
                  {filter.companies.selected.length === 0 &&
                    companiesIsLoading && (
                      <span className="text-xs text-grey60">Loading...</span>
                    )}
                  {filter.companies.selected.length === 0 &&
                  !companiesIsLoading ? (
                    <span className="text-xs text-grey60 italic">
                      No selected companies
                    </span>
                  ) : (
                    filter.companies.selected
                      .slice(0, 12)
                      .map((company, idx) => (
                        <div
                          key={idx}
                          className="flex w-16 justify-between items-center rounded bg-teal1 py-2 px-1 mr-1 mb-1"
                        >
                          <span className="text-xs font-bold text-teal2">
                            {company.ticker}
                          </span>
                          <button
                            onClick={() =>
                              handleRemoveCompanyFilter(company.ticker)
                            }
                          >
                            <Icon
                              icon={IconNames.CROSS}
                              iconSize={14}
                              className="fill-current text-grey30"
                            />
                          </button>
                        </div>
                      ))
                  )}
                </div>
                {!companiesIsLoading && (
                  <button
                    onClick={() => handleOpenModal('companies')}
                    className="text-xs font-semibold ml-4 mt-3 mb-2 text-orange2 tracking-wider"
                  >
                    MORE
                  </button>
                )}
              </Collapse>
            </div>
            {/* <div className="h-auto w-full flex flex-col flex-shrink-0 cursor-pointer"> */}
            <div className="h-auto w-full hidden">
              <div
                onClick={() => handleCollapseSidebar('location')}
                className="h-10 w-full px-4 flex justify-center items-center"
              >
                <div className="flex w-full justify-between">
                  <h5 className="text-xs tracking-wider font-semibold">
                    LOCATION
                  </h5>
                  <Icon
                    icon={IconNames.CHEVRON_DOWN}
                    iconSize={16}
                    className={`fill-current text-grey70 transition-transform transform ${
                      isCollapsed('location') ? 'rotate-180' : 'rotate-0'
                    }`}
                  />
                </div>
              </div>
              <Collapse isOpen={isCollapsed('location')} className="w-full">
                <div className="w-full px-4 h-auto flex flex-col bg-grey5">
                  <Checkbox label="Table Mode" />
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="flex-grow flex flex-col overflow-hidden">
          <div className="h-14 w-full flex justify-between border-b border-grey5 flex-shrink-0 ">
            {/* Search keywords */}
            <div className="pl-4 h-full flex items-center">
              {searchMode === SEARCH_MODE.ADVANCED ? (
                <Popover
                  isOpen={isAdvancedPopoverOpen}
                  position={Position.BOTTOM_LEFT}
                  minimal={true}
                  targetTagName="div"
                  className="w-full"
                  targetClassName="w-full"
                  popoverClassName="w-full shadow-none"
                >
                  <AdvancedKeywordBar
                    isOpen={isAdvancedPopoverOpen}
                    onTogglePopover={() =>
                      setIsAdvancedPopoverOpen(!isAdvancedPopoverOpen)
                    }
                  />
                  {/* Add keyword popover */}
                  <KeywordAdvancedPopover
                    onClose={() => setIsAdvancedPopoverOpen(false)}
                  />
                </Popover>
              ) : (
                // Add keyword basic mode popover
                <KeywordBasicPopover
                  isOpen={isBasicPopoverOpen}
                  onOpen={() => setIsBasicPopoverOpen(true)}
                  onClose={() => setIsBasicPopoverOpen(false)}
                />
              )}
            </div>
            {/* Search Menu & Advanced mode */}
            <div className="h-full relative flex justify-end items-center mr-4 pr-0">
              <div className="flex">
                <span className="text-sm font-bold text-blue90 mr-2">
                  Advanced
                </span>
                <Switch
                  checked={searchMode === SEARCH_MODE.ADVANCED}
                  onChange={() => setConfirmLostInputOpen(true)}
                />
              </div>
              <div className="flex items-center ml-7 mr-2">
                <Tooltip
                  openOnTargetFocus={false}
                  minimal={true}
                  content={
                    <div>
                      <p className="text-xs text-white">Save Query</p>
                    </div>
                  }
                  position={Position.BOTTOM}
                  targetTagName="div"
                  targetClassName="flex items-center"
                >
                  <button
                    onClick={() => {
                      handleOpenModal('saveQuery');
                    }}
                    className="mr-7"
                  >
                    <img src={saveIcon} className="h-7 w-7" alt="save query" />
                  </button>
                </Tooltip>
                <Tooltip
                  openOnTargetFocus={false}
                  minimal={true}
                  content={
                    <div>
                      <p className="text-xs text-white">Download Query</p>
                    </div>
                  }
                  position={Position.BOTTOM}
                  targetTagName="div"
                  targetClassName="flex items-center"
                >
                  <button
                    onClick={() => handleOpenModal('downloadQuery')}
                    className="mr-7"
                  >
                    <img
                      src={downloadIcon}
                      className="h-6 w-6"
                      alt="download query"
                    />
                  </button>
                </Tooltip>
                <Tooltip
                  openOnTargetFocus={false}
                  minimal={true}
                  content={
                    <div>
                      <p className="text-xs text-white">
                        Saved Query List & Add Note
                      </p>
                    </div>
                  }
                  position={Position.BOTTOM}
                  targetTagName="div"
                  targetClassName="flex items-center"
                >
                  <button
                    onClick={() => dispatch(push(PATH_URL.SAVED_QUERIES))}
                  >
                    <img
                      src={folderIcon}
                      className="h-6 w-6"
                      alt="download query"
                    />
                  </button>
                </Tooltip>
              </div>
              <Popover
                isOpen={isVisible}
                position={Position.BOTTOM_RIGHT}
                minimal={true}
                targetTagName="div"
                className="w-min-content"
                popoverClassName="shadow-none bg-transparent"
              >
                <div
                  onClick={() => setIsVisible((prev) => !prev)}
                  className="w-7 h-7 rounded-full ml-4 overflow-hidden cursor-pointer"
                >
                  <img
                    src={authData.photoUrl || defaultUserProfile}
                    alt="user profile"
                    className="h-full w-full"
                  />
                </div>
                <UserMenu
                  containerRef={ref}
                  setIsVisible={setIsVisible}
                  isAdmin={authData.role === 'admin'}
                />
              </Popover>
            </div>
          </div>
          {/* Conditional rendering to display result or empty result */}
          {searchIsLoading ? (
            <div
              style={{ height: 'calc(100% - 3rem)' }}
              className="w-full border-b border-grey5 flex items-center justify-center"
            >
              <Spinner className="w-20 h-20 fill-current text-blue30" />
            </div>
          ) : searchResult.documents.length > 0 ? (
            <div
              style={{ height: 'calc(100% - 3rem)' }}
              className="w-full border-b border-grey5 flex"
            >
              {/* Search result */}
              <div
                className={clsx(
                  viewMode === VIEW_MODE.LIST ? 'w-1/2' : 'w-full',
                  'h-full border-r border-grey5 flex flex-col'
                )}
              >
                {viewMode === VIEW_MODE.LIST && <DocumentList />}
                {viewMode === VIEW_MODE.TABLE && <DocumentTable />}
              </div>
              {viewMode === VIEW_MODE.LIST && (
                <div className="w-1/2 h-full border-grey5 flex flex-col">
                  {/* Search result in document */}
                  <div
                    style={{
                      height: searchTools.includes(SEARCH_TOOLS.STATISTIC)
                        ? 'calc(100% - 14rem)'
                        : 'calc(100% - 1.5rem)',
                    }}
                    className={clsx(
                      'w-full',
                      searchTools.includes(SEARCH_TOOLS.STATISTIC) &&
                        'border-b border-grey5'
                    )}
                  >
                    <div className="w-full px-8 h-full">
                      <div className="w-full h-12 flex justify-between items-center">
                        <div>
                          <button
                            onClick={() =>
                              handleToggleSearchTools(SEARCH_TOOLS.HIGHLIGHT)
                            }
                            className="mr-2"
                          >
                            <Icon
                              icon={IconNames.HIGHLIGHT}
                              iconSize={16}
                              className={clsx(
                                'fill-current',
                                searchTools.includes(SEARCH_TOOLS.HIGHLIGHT)
                                  ? 'text-blue50'
                                  : 'text-grey20'
                              )}
                            />
                          </button>
                          <button
                            onClick={() =>
                              handleToggleSearchTools(SEARCH_TOOLS.STATISTIC)
                            }
                            className="mr-2"
                          >
                            <Icon
                              icon={IconNames.TIMELINE_AREA_CHART}
                              iconSize={16}
                              className={clsx(
                                'fill-current',
                                searchTools.includes(SEARCH_TOOLS.STATISTIC)
                                  ? 'text-blue50'
                                  : 'text-grey20'
                              )}
                            />
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={handlePrevPageContent}
                            className="mr-2"
                          >
                            <Icon
                              icon={IconNames.CHEVRON_LEFT}
                              iconSize={16}
                              className={clsx(
                                'fill-current',
                                selectedResult &&
                                  selectedResult.selectedPageIndex > 0
                                  ? 'text-grey40'
                                  : 'text-grey20'
                              )}
                            />
                          </button>
                          <button onClick={handleNextPageContent}>
                            <Icon
                              icon={IconNames.CHEVRON_RIGHT}
                              iconSize={16}
                              className={clsx(
                                'fill-current',
                                selectedResult &&
                                  selectedResult.pages &&
                                  selectedResult.selectedPageIndex <
                                    selectedResult.pages.length - 1
                                  ? 'text-grey40'
                                  : 'text-grey20'
                              )}
                            />
                          </button>
                        </div>
                      </div>
                      <div
                        style={{ height: 'calc(100% - 4rem) ' }}
                        className="w-full mt-2 text-xs leading-relaxed overflow-y-auto"
                      >
                        {!searchContentIsLoading && !searchDetailIsLoading ? (
                          renderSearchContent()
                        ) : (
                          <Skeleton count={15} />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Additional document data related to search */}
                  {searchTools.includes(SEARCH_TOOLS.STATISTIC) &&
                    selectedResult && (
                      <div className="h-56 w-full border-grey5">
                        <div className="h-12 w-full border-b border-grey5 flex">
                          <h1 className="ml-8 my-auto items-center text-grey70 font-medium text-base">
                            Word distributions
                          </h1>
                        </div>
                        <div className="w-full px-8 mt-4">
                          {!searchDetailIsLoading ? (
                            <ul className="w-full">
                              <li className="flex justify-between">
                                <h3 className="text-sm text-grey70 font-medium tracking-wide mb-2.5">
                                  Total keywords
                                  <TooltipInfo text="Total occurence of all keywords searched." />
                                </h3>
                                <h3 className="text-sm text-blue80 font-semibold">
                                  {selectedResult.stats?.total_keywords}
                                </h3>
                              </li>
                              <li className="flex justify-between">
                                <h3 className="text-sm text-grey70 font-medium tracking-wide mb-2.5">
                                  Total words
                                  <TooltipInfo text="Total occurrence of all words in report or sections" />
                                </h3>
                                <h3 className="text-sm text-blue80 font-semibold">
                                  {selectedResult.stats?.total_words}
                                </h3>
                              </li>
                              <li className="flex justify-between">
                                <h3 className="text-sm text-grey70 font-medium tracking-wide mb-2.5">
                                  Total words without numerics
                                  <TooltipInfo text="Total occurrence of words in report or sections without numeric word type" />
                                </h3>
                                <h3 className="text-sm text-blue80 font-semibold">
                                  {
                                    selectedResult.stats
                                      ?.total_words_non_numeric
                                  }
                                </h3>
                              </li>
                              <li className="flex justify-between">
                                <h3 className="text-sm text-grey70 font-medium tracking-wide mb-2.5">
                                  All sentences
                                  <TooltipInfo text="Total occurrence of sentences in report or sections" />
                                </h3>
                                <h3 className="text-sm text-blue80 font-semibold">
                                  {selectedResult.stats?.all_sentences}
                                </h3>
                              </li>
                              <li className="flex justify-between">
                                <h3 className="text-sm text-grey70 font-medium tracking-wide mb-2.5">
                                  Sentences including keywords
                                  <TooltipInfo text="Total occurrence of sentences which contains keywords" />
                                </h3>
                                <h3 className="text-sm text-blue80 font-semibold">
                                  {selectedResult.stats?.including_keywords}
                                </h3>
                              </li>
                            </ul>
                          ) : (
                            // Word distribution loading skeleton
                            <ul className="w-full">
                              <li className="flex justify-between mb-2">
                                <Skeleton width={150} />
                                <Skeleton width={30} />
                              </li>
                              <li className="flex justify-between mb-2">
                                <Skeleton width={130} />
                                <Skeleton width={30} />
                              </li>
                              <li className="flex justify-between mb-2">
                                <Skeleton width={190} />
                                <Skeleton width={30} />
                              </li>
                              <li className="flex justify-between mb-2">
                                <Skeleton width={140} />
                                <Skeleton width={30} />
                              </li>
                              <li className="flex justify-between mb-2">
                                <Skeleton width={200} />
                                <Skeleton width={30} />
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{ height: 'calc(100% - 3rem)' }}
              className="w-full flex flex-col items-center justify-center"
            >
              <img src={noResultImage} alt="no result" />
              <h2 className="font-bold text-lg text-grey100 mt-4">
                No result found
              </h2>
              <p className="text-base text-grey50 mt-1">
                We can’t find any item matching your search
              </p>
            </div>
          )}
        </div>
      </div>
      {/* More sections dialog */}
      <DocumentSectionDialog
        isOpen={modalIsOpen.documentSection}
        onClose={() => handleCloseModal('documentSection')}
        onSearch={debounce((search) => dispatch(getSectionList(search)), 300)}
        onApply={handleApplyFilter}
      />
      {/* More companies dialog */}
      <CompaniesDialog
        isOpen={modalIsOpen.companies}
        onClose={() => handleCloseModal('companies')}
        onApply={handleApplyFilter}
      />
      {/* Save query dialog */}
      <SaveQueryDialog
        isOpen={modalIsOpen.saveQuery}
        onClose={() => handleCloseModal('saveQuery')}
      />
      {/* Download query dialog */}
      <DownloadQueryDialog
        isOpen={modalIsOpen.downloadQuery}
        onClose={() => handleCloseModal('downloadQuery')}
      />
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        bodyClassName="p-1 my-1"
      />
      <InputLostDialog
        mode={searchMode}
        isOpen={confirmLostInputOpen}
        onClose={() => setConfirmLostInputOpen(false)}
        onConfirm={handleChangeSearchMode}
      />
    </FullLayout>
  );
}

export default SearchDetail;
