import React from 'react';
import clsx from 'clsx';

function FreeTrialInformation({ isAdmin = false }) {
  return (
    <div
      className={clsx(
        'h-12 bg-blue100 text-white text-sm flex justify-center items-center z-10 pl-2 pr-2'
      )}
    >
      <p className="font-medium md:text-base text-center xs:text-xs sm:text-sm">
        You are currently using free trial mode. It is restricted only to 6
        dummy datas. You can subscribe to access all the data.
      </p>
    </div>
  );
}

export default FreeTrialInformation;
