import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { IconNames } from '@blueprintjs/icons';
import { push } from 'connected-react-router';
import { uniq, debounce } from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import SelectInstitution from './SelectInstitution';
import PATH_URL from '../../routers/path';
import {
  addInstitution,
  getInstitutions,
  updateUserData,
  uploadProfilePict,
  uploadStudentIdCard,
} from '../../redux/features/auth';
import { actionSuccess } from '../../redux/utils/actionCreator';
import { createLoadingSelector } from '../../redux/api/loading';
import { createErrorMessageSelector } from '../../redux/api/error';

// 5 MB
const MAX_FILE_SIZE = 5000;

const schema = yup.object().shape({
  type: yup.string(),
  nim: yup.string().when('type', {
    is: 'student',
    then: yup
      .string()
      .min(5, 'Student Identification Number must at least 5 characters')
      .matches(
        /^[A-Za-z0-9]*$/,
        'Student Identification Number must using alphanumeric characters'
      )
      .required('Student Identification Number is required'),
  }),
  new_institution: yup.string().when('institution', {
    is: 'Others',
    then: yup.string().required(),
  }),
});

function PersonalDetail(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: 'general',
      institution: '',
      nim: '',
    },
    resolver: yupResolver(schema),
  });
  const accountType = watch('type');
  const [profilePict, setProfilePict] = useState({ url: null, file: null });
  const [studentId, setStudentId] = useState({ url: null, file: null });
  const [formError, setFormError] = useState({ field: '', message: '' });
  const [institutionSearch, setInstitutionSearch] = useState('');
  const [institutions, setInstitutions] = useState([]);
  const uploadProfileRef = useRef();
  const uploadStudentIdRef = useRef();
  const firebaseData = useSelector((state) => state.firebase.auth);
  const authData = useSelector((state) => state.auth);
  const loading = useSelector((state) =>
    createLoadingSelector([
      updateUserData.type,
      uploadProfilePict.type,
      uploadStudentIdCard.type,
    ])(state)
  );
  const loadingInstitutions = useSelector(
    createLoadingSelector([getInstitutions.type])
  );
  const error = useSelector((state) =>
    createErrorMessageSelector([updateUserData.type])(state)
  );
  const openFileProfile = () => {
    uploadProfileRef.current?.click();
  };
  const openFileStudentId = () => {
    uploadStudentIdRef.current?.click();
  };
  const handleChangeProfile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', function (event) {
        setProfilePict({ url: event.target.result, file: e.target.files[0] });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleRemoveProfilePict = () => {
    if (uploadProfileRef.current) {
      uploadProfileRef.current.value = null;
    }
    setProfilePict({ url: null, file: null });
  };
  const handleChangeStudentId = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      if (e.target.files[0].size / 1000 < MAX_FILE_SIZE) {
        reader.addEventListener('load', function (event) {
          setStudentId({ url: event.target.result, file: e.target.files[0] });
        });
        reader.readAsDataURL(e.target.files[0]);
        if (formError.field === 'studentId')
          setFormError({ field: '', message: '' });
      } else {
        handleRemoveStudentId();
        setFormError({
          field: 'studentId',
          message: 'Maximum file size exceeded',
        });
      }
    }
  };
  const handleRemoveStudentId = () => {
    if (uploadStudentIdRef.current) {
      uploadStudentIdRef.current.value = null;
    }
    setStudentId({ url: null, file: null });
  };
  const handleNextStep = () => {
    handleSubmit(onSubmit)();
  };
  const validate = (formData) => {
    const { type, institution } = formData;
    if (type === 'student') {
      if (studentId.url === null)
        setFormError({
          field: 'studentId',
          message: 'Please provide your student ID.',
        });
      else if (institution === '')
        setFormError({
          field: 'institution',
          message: 'Institution is required.',
        });
      return studentId.url !== null && institution;
    }
    return true;
  };
  const onSubmit = async (data) => {
    if (validate(data)) {
      const { type, nim, institution, new_institution } = data;
      const submitData = {
        id: authData.id,
        type,
        photoUrl: profilePict.url,
      };
      const formData = new FormData();
      formData.set('file', null);
      if (
        !(profilePict.url && profilePict.url.includes('https')) &&
        profilePict.file
      ) {
        formData.set('file', profilePict.file);
        const { payload } = await dispatch(uploadProfilePict(formData));
        if (payload) submitData.photoUrl = payload.publicUrl;
        else return;
      }
      if (type === 'student') {
        formData.set('file', studentId.file);
        const { payload } = await dispatch(uploadStudentIdCard(formData));
        if (payload) submitData.cardIdUrl = payload.publicUrl;
        else return;
        submitData.nim = nim;
        submitData.submissionDate = new Date();
        if (institution === 'Others') {
          submitData.institution = new_institution;
          dispatch(addInstitution(new_institution));
        } else {
          submitData.institution = institution;
        }
      }
      const actionDispatced = await dispatch(updateUserData(submitData));
      if (actionDispatced.type === `${updateUserData.type}_SUCCESS`) {
        props.nextPage();
      }
    }
  };
  const handleSkip = () => {
    const currentItem = localStorage.getItem('personalSetup');
    localStorage.setItem(
      'personalSetup',
      currentItem
        ? currentItem.split(',').concat(authData.email)
        : [authData.email]
    );
    dispatch(push(PATH_URL.HOME));
  };

  useEffect(() => {
    setProfilePict({
      url: authData.photoUrl || firebaseData.photoURL,
      file: null,
    });
  }, [setProfilePict, firebaseData, authData]);

  useEffect(() => {
    if (institutionSearch) {
      dispatch(getInstitutions(institutionSearch)).then((response) => {
        if (response.type === actionSuccess(getInstitutions.type)) {
          const institutionNames = response.payload.map(
            (institution) => institution.nama
          );
          setInstitutions((prev) =>
            uniq([...prev, ...institutionNames, 'Others']).sort()
          );
        }
      });
    }
  }, [dispatch, institutionSearch]);

  return (
    <form>
      {error && (
        <p className="text-sm bg-red20 text-red60 py-1 px-2 rounded-sm mb-1">
          {error}
        </p>
      )}
      <h1 className="font-semibold text-3xl">Your Personal Detail</h1>
      <p className="text-sm text-grey70 my-2">
        You're in good hands. We'll help you collect, analyze, and report ESG
        Data for your needs.
      </p>
      <div>
        <label htmlFor="profilePict" className="text-grey50 text-sm mb-1">
          Profile picture
        </label>
        <div className="flex items-center">
          <div className="w-16 h-16 rounded bg-grey5 overflow-hidden mr-3">
            {profilePict.url && (
              <img src={profilePict.url} alt="profile" className="w-16 h-16" />
            )}
          </div>
          <input
            type="file"
            name="profilePict"
            ref={uploadProfileRef}
            accept=".jpg,.jpeg,.png"
            className="hidden"
            onChange={handleChangeProfile}
          />
          <Button
            view="outlined"
            color="primary"
            onClick={openFileProfile}
            className="mr-3"
          >
            Upload new picture
          </Button>
          <Button color="danger" onClick={handleRemoveProfilePict}>
            Remove
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center text-sm text-grey100 mt-6">
        <select
          name="type"
          ref={register}
          defaultValue="general"
          className="rounded-md border-0 w-full bg-grey5 focus:border-0 focus:outline-none focus:ring-transparent"
        >
          <option value="general">General</option>
          <option value="student">Student</option>
        </select>
      </div>
      <div className="bg-yellow0 text-sm text-center text-grey100 mt-2 py-2 px-4 rounded-lg">
        {accountType === 'student'
          ? 'For student in Bachelor / Master / Doctoral degree which will get special 30% discount using voucher code: STUDENT30'
          : 'For lecturer or professional researcher'}
      </div>
      {accountType === 'student' && (
        <>
          <div className="mt-3">
            <label htmlFor="studentId" className="text-grey50 text-sm mb-1">
              Student ID card (JPG / PNG and max. 5 MB)
            </label>
            {formError.field === 'studentId' && (
              <p className="text-xs text-red50 mb-px">{formError.message}</p>
            )}
            <div className="relative flex flex-col items-center justify-end rounded-md bg-grey5 p-1 h-40 w-full">
              {studentId.url && (
                <img
                  src={studentId.url}
                  alt="student ID"
                  className="h-16 w-16 rounded absolute mx-auto top-6"
                />
              )}
              <input
                type="file"
                name="studentId"
                ref={uploadStudentIdRef}
                accept="image/*"
                onChange={handleChangeStudentId}
                className="hidden"
              />
              <Button
                color="danger"
                size="small"
                className="absolute top-2 right-2"
              >
                <Icon
                  icon={IconNames.CROSS}
                  iconSize={14}
                  onClick={handleRemoveStudentId}
                  className="fill-current text-white"
                />
              </Button>
              <Button
                view="outlined"
                color="primary"
                onClick={openFileStudentId}
                className="mb-2"
              >
                Upload student ID card
              </Button>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <label htmlFor="nim" className="text-grey60 text-sm mb-1">
              Student Identification Number<span className="text-red50">*</span>
            </label>
            <div className="relative flex items-center">
              <input
                type="text"
                name="nim"
                ref={register}
                className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
              />
              {(errors.nim || dirtyFields.nim) && (
                <span className="absolute right-0 mr-2">
                  {errors.nim ? (
                    <Icon
                      icon={IconNames.ERROR}
                      iconSize={20}
                      className={`fill-current text-grey50`}
                    />
                  ) : (
                    <Icon
                      icon={IconNames.TICK}
                      iconSize={20}
                      className={`fill-current text-green50`}
                    />
                  )}
                </span>
              )}
            </div>
            {errors.nim && (
              <p className="text-xs text-red50 mt-px">{errors.nim.message}</p>
            )}
          </div>
          <div className="flex flex-col mt-2">
            <label htmlFor="institution" className="text-grey60 text-sm mb-1">
              Institution<span className="text-red50">*</span>
            </label>
            <SelectInstitution
              loading={loadingInstitutions}
              register={register}
              items={institutions}
              onSearch={debounce((input) => setInstitutionSearch(input), 500)}
              onItemSelected={(item) => setValue('institution', item)}
            />
            {formError.field === 'institution' && (
              <p className="text-xs text-red50 mt-px">{formError.message}</p>
            )}
          </div>
          {watch('institution') === 'Others' && (
            <div className="relative flex items-center mt-1.5">
              <input
                type="text"
                name="new_institution"
                ref={register}
                className="border border-transparent text-sm rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
              />
              {(errors.new_institution || dirtyFields.new_institution) && (
                <span className="absolute right-0 mr-2">
                  {errors.new_institution ? (
                    <Icon
                      icon={IconNames.ERROR}
                      iconSize={20}
                      className={`fill-current text-grey50`}
                    />
                  ) : (
                    <Icon
                      icon={IconNames.TICK}
                      iconSize={20}
                      className={`fill-current text-green50`}
                    />
                  )}
                </span>
              )}
            </div>
          )}
          <span className="text-xs text-grey40">
            Choose Others if your university is not on the list
          </span>
        </>
      )}
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={handleSkip}
          className="text-sm text-blue50 hover:underline"
        >
          Skip for now
        </button>
        <Button color="primary" onClick={handleNextStep} isLoading={loading}>
          Next Step
        </Button>
      </div>
    </form>
  );
}

export default PersonalDetail;
