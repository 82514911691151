import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { getPayment } from 'redux/features/userPayment';
import { useState } from 'react';
import DialogCancelOrder from '../DialogCancelOrder';

export default function ActionButtons(props) {
  const [showDialogCancel, setShowDialogCancel] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className={'flex justify-between'}>
      <Button
        view="outlined"
        size="medium"
        onClick={() => setShowDialogCancel(true)}
        className="w-1/2"
        disabled={props.loadingCancel}
        isLoading={props.loadingCancel}
      >
        Cancel Order
      </Button>
      <Button
        color="primary"
        size="medium"
        onClick={() => {
          dispatch(getPayment(props.invoiceNumber, 1000));
        }}
        className="w-1/2 ml-4"
      >
        Check My Payment
      </Button>
      <DialogCancelOrder
        isOpen={showDialogCancel}
        isLoading={props.loadingCancel}
        onClose={() => !props.loadingCancel && setShowDialogCancel(false)}
        onConfirm={async () => {
          await props.cancel();
          setShowDialogCancel(false);
        }}
      />
    </div>
  );
}
