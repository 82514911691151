import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { updateNotes } from 'redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';

function RenameColumnDialog(props) {
  const [columnName, setColumnName] = useState(props.columnName || '');
  const handleChangeColumnName = (e) =>
    e.target.value.length <= 50 ? setColumnName(e.target.value) : null;
  const loading = useSelector(createLoadingSelector([updateNotes.type]));
  const handleSave = (e) => {
    e.preventDefault();
    if (columnName) {
      props.onSubmit(columnName);
    }
  };

  useEffect(() => {
    if (props.columnName) setColumnName(props.columnName);
  }, [props.columnName]);

  return (
    <Dialog
      header="Rename Column"
      size="small"
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
        setColumnName('');
      }}
    >
      <div className="w-full px-6">
        <form onSubmit={handleSave}>
          <label htmlFor="columnName" className="text-sm text-grey50">
            Name your column:
          </label>
          <input
            type="text"
            name="columnName"
            value={columnName}
            onChange={handleChangeColumnName}
            className="w-full text-grey100 text-xs h-12 rounded border-grey10 mt-1"
            autoFocus={true}
          />
          <span className="text-xs text-grey50 float-right mt-px">
            {columnName.length} / 50
          </span>
        </form>
      </div>
      <div className="w-full mt-4 px-6 flex items-center justify-end">
        <Button
          view="outlined"
          color="danger"
          size="small"
          onClick={props.onClose}
          className="w-20 mr-4"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={handleSave}
          isLoading={loading}
          className="w-20"
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}

export default RenameColumnDialog;
