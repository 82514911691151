import {
  createAsyncAction,
  actionSuccess,
  createAction,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import ENDPOINTS from '../urls';

const PRE = 'discountManagement';

export const getListDiscount = createAsyncAction(
  `${PRE}/GET_LIST_DISCOUNT`,
  (params) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_LIST_DISCOUNT`,
      'get',
      ENDPOINTS.DISCOUNT,
      params
    );
  }
);

export const getDetailDiscount = createAsyncAction(
  `${PRE}/GET_DETAIL_DISCOUNT`,
  (id) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_DETAIL_DISCOUNT`,
      'get',
      `${ENDPOINTS.DISCOUNT}/${id}`
    );
  }
);

export const createDiscount = createAsyncAction(
  `${PRE}/CREATE_DISCOUNT`,
  (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/CREATE_DISCOUNT`,
        'post',
        ENDPOINTS.DISCOUNT,
        data,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const updateDiscount = createAsyncAction(
  `${PRE}/UPDATE_DISCOUNT`,
  (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/UPDATE_DISCOUNT`,
        'put',
        `${ENDPOINTS.DISCOUNT}/${data.id}`,
        data,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const deleteDiscount = createAsyncAction(
  `${PRE}/DELETE_DISCOUNT`,
  (id, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/DELETE_DISCOUNT`,
        'delete',
        `${ENDPOINTS.DISCOUNT}/${id}`,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const downloadDiscountList = createAsyncAction(
  `${PRE}/DOWNLOAD_DISCOUNT_LIST`,
  (onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/DOWNLOAD_DISCOUNT_LIST`,
        'post',
        `${ENDPOINTS.DISCOUNT_DOWNLOAD}`,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const getDiscountUsageList = createAsyncAction(
  `${PRE}/GET_DISCOUNT_USAGE_LIST`,
  (id, params) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      `${PRE}/GET_DISCOUNT_USAGE_LIST`,
      'get',
      `${ENDPOINTS.DISCOUNT_USAGE}/${id}`,
      params
    );
  }
);

export const downloadDiscountUsageList = createAsyncAction(
  `${PRE}/DOWNLOAD_DISCOUNT_USAGE_LIST`,
  (id, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/DOWNLOAD_DISCOUNT_USAGE_LIST`,
        'post',
        `${ENDPOINTS.DISCOUNT_DOWNLOAD}/${id}`,
        null,
        'application/json',
        onSuccess,
        onError
      );
    }
);

export const changeFilter = createAction(`${PRE}/CHANGE_FILTER`);
export const changePageData = createAction(`${PRE}/CHANGE_PAGE_DATA`);
export const setSelected = createAction(`${PRE}/SET_SELECTED`);
export const changeUsageListPageData = createAction(
  `${PRE}/CHANGE_USAGE_LIST_PAGE_DATA`
);

export const toggleVisibleDiscount = createAsyncAction(
  `${PRE}/TOGGLE_VISIBLE_DISCOUNT`,
  (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
      return await apiRequest(
        dispatch,
        `${PRE}/TOGGLE_VISIBLE_DISCOUNT`,
        'post',
        `${ENDPOINTS.DISCOUNT}/visibility/${data.id}`,
        data,
        'application/json',
        onSuccess,
        onError
      );
    }
);
const initialState = {
  filter: {
    q: '',
    base: null,
    type: null,
  },
  pageData: {
    totalItems: 0,
    totalPages: 0,
    limit: 10,
    currentPageNumber: 1,
    currentPageSize: 0,
  },
  list: [],
  selected: {
    id: null,
    name: '',
    description: '',
    discountBase: '',
    type: '',
    eligibility: '',
    isLimitedPerUser: null,
    limit: 0,
    value: '',
    startDate: '',
    endDate: '',
    totalUsed: 0,
  },
  usage: {
    pageData: {
      totalItems: 0,
      totalPages: 0,
      limit: 10,
      currentPageNumber: 1,
      currentPageSize: 0,
    },
    list: [],
  },
};

const discountManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(getListDiscount.type): {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        pageData,
        list,
      };
    }
    case changeFilter.type: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
        pageData: {
          ...state.pageData,
          currentPageNumber: 1,
        },
      };
    }
    case changePageData.type: {
      return {
        ...state,
        pageData: {
          ...state.pageData,
          ...action.payload,
        },
      };
    }
    case actionSuccess(getDetailDiscount.type):
    case setSelected.type: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case actionSuccess(getDiscountUsageList.type): {
      const { list, ...pageData } = action.payload;
      return {
        ...state,
        usage: {
          ...state.usage,
          pageData,
          list,
        },
      };
    }
    case changeUsageListPageData.type: {
      return {
        ...state,
        usage: {
          ...state.usage,
          pageData: {
            ...state.pageData,
            ...action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default discountManagementReducer;
