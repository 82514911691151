import { useEffect } from 'react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createLoadingSelector } from 'redux/api/loading';
import {
  checkMidtransStatus,
  getPayment,
  resetSelectedOrder,
} from 'redux/features/userPayment';
import Loading from './Loading';
import WaitingPayment from './Waiting';
import CompletePayment from './Complete';
import CanceledPayment from './Canceled';
import Failed from './Failed';

export default function PaymentQD() {
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentQD = useSelector(
    ({ userPayment }) => userPayment.quantitative.selected
  );
  const loading = useSelector(
    createLoadingSelector([getPayment.type, checkMidtransStatus.type])
  );
  const renderPage = () => {
    const { paymentStatus, orderStatus } = paymentQD;
    if (orderStatus === 'canceled' || paymentStatus === 'expired')
      return <CanceledPayment />;
    if (paymentStatus === 'unpaid') return <WaitingPayment />;
    if (paymentStatus === 'paid') return <CompletePayment />;
    return <Failed />;
  };

  useEffect(() => {
    if (location.search.includes('order_id')) {
      const paramsObj = qs.parse(location.search.slice(1));
      const { order_id: invoiceNumber } = paramsObj;
      dispatch(getPayment(invoiceNumber, 1000));
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    return () => dispatch(resetSelectedOrder());
  }, [dispatch]);

  return loading ? <Loading /> : renderPage();
}
