import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { getFirebase } from 'react-redux-firebase';
import rootReducer from '../features/index';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import history from '../../routers/history';
const { REACT_APP_API_STAGE } = process.env;

// const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// enable trace. DISABLE IT ON PRODUCTION

const optionsDev = { trace: true, traceLimit: 25 };
const optionsStaging = {};
const options = REACT_APP_API_STAGE === 'staging' ? optionsStaging : optionsDev;

const composeEnhancers = composeWithDevTools(options);

function configureStore() {
  const store = createStore(
    rootReducer(history),
    REACT_APP_API_STAGE === 'production'
      ? applyMiddleware(
          routerMiddleware(history),
          thunk.withExtraArgument(getFirebase)
        )
      : composeEnhancers(
          applyMiddleware(
            routerMiddleware(history),
            thunk.withExtraArgument(getFirebase)
          )
        )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../features', () => {
      const nextRootReducer = require('../features').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export default configureStore;
