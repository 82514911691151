import { useEffect, useState } from 'react';
import { hhmmss } from 'utils/date';

export default function Countdown({ differenceInSecond }) {
  const [timeLeft, setTimeLeft] = useState(differenceInSecond);
  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const timeLeftForView = hhmmss(timeLeft);

  return (
    <div className={'text-4xl py-4 text-red50 font-bold'}>
      {timeLeft > 0 ? timeLeftForView : hhmmss(0)}
    </div>
  );
}
