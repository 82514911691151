import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import {
  parseGroupedKeywords,
  validateGroupedKeywords,
  convertFieldsToItems,
  convertSymbolToFields,
  convertGroupToSymbol,
} from '../../utils/searchKeyword';
import {
  storeSearchKeywords,
  searchDocumentGroup,
} from '../../redux/features/textAnalytics';

function AdvancedSearch(props) {
  const dispatch = useDispatch();
  const [maxError, setMaxError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      andKeywords: '',
      exactKeywords: '',
      orKeywords: '',
      notKeywords: '',
    },
  });
  const handleReset = (fieldName) => setValue(fieldName, '');
  const onSubmit = (data) => {
    const valid = validateGroupedKeywords(data);
    if (valid.filter((isValid) => isValid).length === 4) {
      const parsed = parseGroupedKeywords(data);
      const wordsCount = parsed.reduce((count, keywords) => {
        const countWords = keywords.length;
        count = count + countWords;
        return count;
      }, 0);
      if (wordsCount > 0 && wordsCount <= 5) {
        const keywordsData = {
          andKeywords: parsed[0],
          exactKeywords: parsed[1],
          orKeywords: parsed[2],
          notKeywords: parsed[3],
        };
        if (maxError !== '') setMaxError(false);
        const keywordSymbols = convertGroupToSymbol(keywordsData);
        const advancedKeywords = convertSymbolToFields(keywordSymbols);
        const searchKeywords = {
          symbols: keywordSymbols,
          basic: advancedKeywords[0].keyword,
          advanced: advancedKeywords,
          items: convertFieldsToItems(advancedKeywords),
        };
        dispatch(storeSearchKeywords(searchKeywords));
        dispatch(searchDocumentGroup());
      } else {
        if (wordsCount > 5) setMaxError(true);
      }
    } else {
      clearErrors();
      const fields = Object.keys(data);
      valid.forEach((isValid, index) => {
        if (!isValid)
          setError(fields[index], {
            type: 'maxLength',
            message: '* Unmatched quotes',
          });
      });
    }
  };

  return (
    <div className="md:w-2/3 my-10">
      <header className="w-full text-center font-bold text-2xl text-grey100">
        Advanced Search
      </header>
      <p className="w-full text-center text-sm text-grey50 mt-2">
        Find sentences in documents
      </p>
      {maxError && (
        <p className="py-2 w-full bg-red10 text-red60 text-center rounded mt-3 mb-2">
          The number of keywords you entered is more than 5
        </p>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-8 mb-4">
        <div className="w-full">
          <label className="text-sm text-grey100">
            Type the important keywords. Example result:
            <span className="font-mono text-grey50">
              {' '}
              tri-colour rat terrier
            </span>
          </label>
          <div className="w-full relative flex items-center">
            <input
              type="text"
              name="andKeywords"
              ref={register}
              placeholder="Enter your keywords (separate with commas)"
              autoComplete="off"
              className="h-12 w-full bg-white border border-grey30 rounded mt-px pr-10 focus:outline-none focus:border-blue50 focus:ring-transparent"
            />
            {watch('andKeywords') && (
              <button
                type="button"
                onClick={() => handleReset('andKeywords')}
                className="absolute right-4 -mt-px"
              >
                <Icon
                  icon={IconNames.CROSS}
                  iconSize={14}
                  className="fill-current text-grey70"
                />
              </button>
            )}
          </div>
          {errors.andKeywords?.message && (
            <p className="w-full text-left text-sm text-red60 font-medium mt-px">
              {errors.andKeywords.message}
            </p>
          )}
        </div>
        <div className="w-full mt-5">
          <label className="text-sm text-grey100">
            Put exact keyword or phrase in quotes. Example result:
            <span className="font-mono text-grey50"> "rat terrier"</span>
          </label>
          <div className="w-full relative flex items-center">
            <input
              type="text"
              name="exactKeywords"
              ref={register}
              placeholder="Enter your keywords or phrase"
              autoComplete="off"
              className="h-12 w-full bg-white border border-grey30 rounded mt-px pr-10 focus:outline-none focus:border-blue50 focus:ring-transparent"
            />
            {watch('exactKeywords') && (
              <button
                type="button"
                onClick={() => handleReset('exactKeywords')}
                className="absolute right-4 -mt-px"
              >
                <Icon
                  icon={IconNames.CROSS}
                  iconSize={14}
                  className="fill-current text-grey70"
                />
              </button>
            )}
          </div>
          {errors.exactKeywords?.message && (
            <p className="w-full text-left text-sm text-red60 font-medium mt-px">
              {errors.exactKeywords.message}
            </p>
          )}
        </div>
        <div className="w-full mt-5">
          <label className="text-sm text-grey100">
            With any of these keywords. Example result:
            <span className="font-mono text-grey50">
              {' '}
              miniature OR standard
            </span>
          </label>
          <div className="w-full relative flex items-center">
            <input
              type="text"
              name="orKeywords"
              ref={register}
              placeholder="Enter your keywords (separate with commas)"
              autoComplete="off"
              className="h-12 w-full bg-white border border-grey30 rounded mt-px pr-10 focus:outline-none focus:border-blue50 focus:ring-transparent"
            />
            {watch('orKeywords') && (
              <button
                type="button"
                onClick={() => handleReset('orKeywords')}
                className="absolute right-4 -mt-px"
              >
                <Icon
                  icon={IconNames.CROSS}
                  iconSize={14}
                  className="fill-current text-grey70"
                />
              </button>
            )}
          </div>
          {errors.orKeywords?.message && (
            <p className="w-full text-left text-sm text-red60 font-medium mt-px">
              {errors.orKeywords.message}
            </p>
          )}
        </div>
        <div className="w-full mt-5">
          <label className="text-sm text-grey100">
            Without the keywords. Example result:
            <span className="font-mono text-grey50">
              {' '}
              -rodent -"Jack Russell"
            </span>
          </label>
          <div className="w-full relative flex items-center">
            <input
              type="text"
              name="notKeywords"
              ref={register}
              placeholder="Enter your keywords (separate with commas)"
              autoComplete="off"
              className="h-12 w-full bg-white border border-grey30 rounded mt-px focus:outline-none focus:border-blue50 focus:ring-transparent"
            />
            {watch('notKeywords') && (
              <button
                type="button"
                onClick={() => handleReset('notKeywords')}
                className="absolute right-4 -mt-px"
              >
                <Icon
                  icon={IconNames.CROSS}
                  iconSize={14}
                  className="fill-current text-grey70"
                />
              </button>
            )}
          </div>
          {errors.notKeywords?.message && (
            <p className="w-full text-left text-sm text-red60 font-medium mt-px">
              {errors.notKeywords.message}
            </p>
          )}
        </div>
        <Button
          type="submit"
          color="primary"
          size="medium"
          className="w-full mt-4"
        >
          Advanced Search
        </Button>
      </form>
      <button
        type="button"
        onClick={() => {
          let isEmpty = true;
          const data = watch();
          const fields = Object.keys(data);
          for (let i = 0; i < fields.length; i++) {
            const currentField = fields[i];
            if (data[currentField].length > 0) {
              isEmpty = false;
              break;
            }
          }
          props.setChangeMode(isEmpty);
        }}
        className="text-blue50 font-medium text-base mt-2 mx-auto block"
      >
        Back to basic search
      </button>
    </div>
  );
}

export default AdvancedSearch;
