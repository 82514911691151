import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';
import * as yup from 'yup';
import Button from 'components/Button';
import PATH_URL from '../../routers/path';
import { forgotPassword } from '../../redux/features/auth';
import { ALERT_TYPE, displayAlert } from '../../redux/features/ui';
import { createLoadingSelector } from '../../redux/api/loading';
import TextField from '../../components/TextField';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const loading = useSelector((state) =>
    createLoadingSelector([forgotPassword.type])(state)
  );

  const onSubmit = async (data) => {
    const { email } = data;
    const isSuccess = await dispatch(forgotPassword(email));
    if (isSuccess) {
      dispatch(push(PATH_URL.LOGIN));
      dispatch(
        displayAlert({
          type: ALERT_TYPE.SUCCESS,
          message:
            'Instructions to reset your password will be sent to you. Please check your email',
        })
      );
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Link
        to={PATH_URL.LOGIN}
        className="border border-grey10 rounded-full w-9 h-9 mb-7 flex items-center justify-center"
      >
        <Icon
          icon={IconNames.CHEVRON_LEFT}
          iconSize={20}
          className={`fill-current text-blue100 -ml-px`}
        />
      </Link>
      <header className="text-3xl font-semibold">Forgot Your Password</header>
      <p className="text-sm text-grey70 my-2">
        Please enter the email address you use when creating your account, we'll
        send you instruction to reset your password.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-6">
          <TextField
            type="email"
            name="email"
            ref={register}
            defaultBorder={false}
            label="Email Address"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
            nameIconRight={
              (errors.email && IconNames.ERROR) ||
              (dirtyFields.email && IconNames.TICK)
            }
          />
          <p className="text-xs text-red50 mt-px">{errors.email?.message}</p>
        </div>
        <Button
          type="submit"
          color="primary"
          disabled={loading}
          isLoading={loading}
          className="w-full mt-6"
        >
          Send Reset Verification
        </Button>
        <p className="text-sm text-center text-grey40 mt-2.5">
          Don't have an account?
          <Link
            to={PATH_URL.REGISTER}
            className="text-blue50 font-bold ml-1 hover:underline"
          >
            Click here
          </Link>
        </p>
      </form>
    </div>
  );
}

export default ForgotPassword;
