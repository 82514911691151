import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Position } from '@blueprintjs/core';
import { push } from 'connected-react-router';
import clsx from 'clsx';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import FullLayout from '../../layouts/FullLayout';
import DocumentTable from './DocumentTable';
import SentenceTable from './SentenceTable';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import DownloadQueryDialog from './DownloadQueryDialog';
import FreetrialInformation from '../../layouts/DashboardLayout/FreetrialInformation';
import { userHasActiveFreeTrial } from '../../redux/features/auth';
import noSavedQueriesImage from '../../assets/images/empty-saved-queries.svg';
import defaultUserProfile from '../../assets/images/userProfileDefault.svg';
import {
  changeSelectedSavedQuery,
  getDecompressedSavedQuery,
  decodeSentenceData,
  getSavedQueries,
  selectedQuerySelector,
  resetDecompressedSavedQueryList,
  resetDecodedDocumentList,
  resetSavedQueryList,
} from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';
import PATH_URL from '../../routers/path';
import UserMenu from '../../components/UserMenu';
import Sidebar from './Sidebar';
import useVisible from '../../hooks/useVisible';
import logo from '../../assets/images/esg-dataset-logo.png';

function SavedQueries() {
  const dispatch = useDispatch();
  const { ref, isVisible, setIsVisible } = useVisible();
  const { savedQueries } = useSelector((state) => state.textAnalytics);
  const authData = useSelector((state) => state.auth);
  const freeTrialActive = useSelector(userHasActiveFreeTrial);
  const queryDataIsLoading = useSelector(
    createLoadingSelector([
      getSavedQueries.type,
      getDecompressedSavedQuery.type,
      decodeSentenceData.type,
    ])
  );
  const selectedQuery = useSelector(selectedQuerySelector);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const handleSelectDocument = (documentData) => {
    dispatch(decodeSentenceData(savedQueries.selected, documentData));
  };

  useEffect(() => {
    if (savedQueries.selected === null && savedQueries.data.length) {
      const firstQuery = savedQueries.data[0];
      dispatch(changeSelectedSavedQuery(firstQuery.id));
      dispatch(getDecompressedSavedQuery(firstQuery.id));
    }
  }, [dispatch, savedQueries.selected, savedQueries.data]);

  useEffect(() => {
    dispatch(getSavedQueries());
    return () => {
      dispatch(changeSelectedSavedQuery(null));
      dispatch(resetSavedQueryList());
      dispatch(resetDecompressedSavedQueryList());
      dispatch(resetDecodedDocumentList());
    };
  }, [dispatch]);

  useEffect(() => {
    setTablePageIndex(0);
  }, [savedQueries.selected]);

  return (
    <FullLayout>
      {freeTrialActive && <FreetrialInformation isAdmin={false} />}
      <div
        className="flex w-full"
        style={{
          height: freeTrialActive ? 'calc(100% - 3rem)' : '100%',
        }}
      >
        {savedQueries.data.length > 0 && (
          <div className="w-64 h-full border-r border-grey5 flex flex-col flex-shrink-0">
            <div className="h-14 w-full flex items-center justify-center border-b border-grey5 flex-shrink-0">
              <Link
                to={PATH_URL.HOME}
                className="flex items-center text-base font-bold text-blue-cesgs hover:no-underline"
              >
                <img src={logo} alt="cesgs logo" className="h-10 w-30" />
              </Link>
            </div>
            {/* Sidebar */}
            <Sidebar setDownloadDialogOpen={setDownloadDialogOpen} />
          </div>
        )}
        <div className="flex-grow flex flex-col overflow-hidden">
          <div className="h-14 w-full flex border-b border-grey5 flex-shrink-0 ">
            <div className="w-1/2 h-full relative flex items-center ml-4 pr-0">
              {savedQueries.data.length === 0 && (
                <div className="w-full flex items-center">
                  <Link
                    to={PATH_URL.HOME}
                    className="text-base font-bold text-blue-cesgs"
                  >
                    <img
                      src={logo}
                      alt="cesgs logo"
                      className="h-10 w-30 mr-2"
                    />
                  </Link>
                </div>
              )}
              {selectedQuery && (
                <h1 className="text-base font-medium text-grey100">
                  {selectedQuery.name}
                </h1>
              )}
            </div>
            <div className="w-1/2 h-full relative flex justify-end items-center mr-4 pr-0">
              {savedQueries.data.length > 0 && (
                <>
                  <span className="text-grey50 text-xs font-normal">
                    {`${
                      selectedQuery && selectedQuery.updatedAt
                        ? 'Last edit'
                        : 'Query saved'
                    } on `}
                    {selectedQuery &&
                      moment(
                        selectedQuery.updatedAt || selectedQuery.createdAt
                      ).format('D MMMM yyyy')}
                  </span>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setDownloadDialogOpen(true)}
                    className="w-28 ml-4"
                  >
                    Download
                  </Button>
                </>
              )}
              <Popover
                isOpen={isVisible}
                position={Position.BOTTOM_RIGHT}
                minimal={true}
                targetTagName="div"
                className="w-min-content"
                popoverClassName="shadow-none bg-transparent"
              >
                <div
                  onClick={() => setIsVisible((prev) => !prev)}
                  className="w-7 h-7 rounded-full ml-4 overflow-hidden cursor-pointer"
                >
                  <img
                    src={authData.photoUrl || defaultUserProfile}
                    alt="user profile"
                    className="h-full w-full"
                  />
                </div>
                <UserMenu
                  containerRef={ref}
                  setIsVisible={setIsVisible}
                  isAdmin={authData.role === 'admin'}
                />
              </Popover>
            </div>
          </div>
          {/* Conditional rendering to display result or empty result */}
          {queryDataIsLoading ? (
            <div
              style={{ height: 'calc(100% - 3rem)' }}
              className="w-full border-b border-grey5 flex items-center justify-center"
            >
              <Spinner className="w-20 h-20 fill-current text-blue30" />
            </div>
          ) : savedQueries.data.length > 0 ? (
            <div
              style={{ height: 'calc(100% - 3rem)' }}
              className="w-full border-b border-grey5 flex"
            >
              {/* Search result */}
              <div
                className={clsx(
                  'w-full h-full border-r border-grey5 flex flex-col'
                )}
              >
                {savedQueries.selected && !savedQueries.selectedDocument && (
                  <DocumentTable
                    setSelectedRow={handleSelectDocument}
                    tablePageIndex={tablePageIndex}
                    setTablePageIndex={setTablePageIndex}
                  />
                )}
                {savedQueries.selected && savedQueries.selectedDocument && (
                  <SentenceTable />
                )}
              </div>
            </div>
          ) : (
            <div
              style={{ height: 'calc(100% - 3rem)' }}
              className="w-full flex flex-col items-center justify-center"
            >
              <img src={noSavedQueriesImage} alt="no saved queries" />
              <h2 className="font-bold text-lg text-grey100 mt-4">
                No saved queries
              </h2>
              <p className="text-base text-grey50 mt-1">
                Start searching and save your search query
              </p>
              <Button
                color="success"
                size="small"
                onClick={() => dispatch(push(PATH_URL.HOME))}
                className="mt-3"
              >
                Back to home screen
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* Download query dialog */}
      <DownloadQueryDialog
        isOpen={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
      />
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        bodyClassName="p-1 my-1"
      />
    </FullLayout>
  );
}

export default SavedQueries;
