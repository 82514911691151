import { useMemo } from 'react';
import clsx from 'clsx';
import CustomTable from 'components/CustomTable';
import userProfileDefault from 'assets/images/userProfileDefault.svg';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  changeSortSubscribers,
  downloadSubscriberListCSV,
} from 'redux/features/userManagement';
import { showToastError } from 'layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'redux/api/loading';

function UserSubscription({
  page,
  totalPages,
  onChangePage,
  data,
  loading,
  initialSearch,
  onInputSearch,
  onChangeTotalRow,
  onFilterChange,
  sortBy,
}) {
  const dispatch = useDispatch();
  const loadingCSV = useSelector(
    createLoadingSelector([downloadSubscriberListCSV.type])
  );
  const columns = [
    {
      id: 'profile.fullName',
      Header: 'USER',
      accessor: 'user',
      sortType: useMemo(
        () => (rowA, rowB) => {
          const nameA = rowA.original.user;
          const nameB = rowB.original.user;
          return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1;
        },
        []
      ),
      customCell: ({ value }) => {
        const valueAsArray = value.split(',');
        const fullName = valueAsArray[0];
        const email = valueAsArray[1];
        const image = valueAsArray[2] || userProfileDefault;
        return (
          <div className={'flex '}>
            <div>
              <img
                src={image}
                className={'rounded-full w-12 h-12'}
                alt={'rounded_image'}
              />
            </div>
            <div className={'flex flex-col justify-center pl-4'}>
              <div className={'text-base font-medium'}>{fullName}</div>
              <div className={'text-sm text-grey50'}>{email}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'usersubscriptions.startAt',
      Header: 'START SUBSCRIPTION',
      accessor: 'startPeriod',
      sortType: 'datetime',
      customCell: ({ value }) => (
        <>
          {value && value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-'}
        </>
      ),
    },
    {
      id: 'usersubscriptions.expireAt',
      Header: 'SUBSCRIPTION ENDED',
      accessor: 'endPeriod',
      sortType: 'datetime',
      customCell: ({ value }) => (
        <>
          {value && value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-'}
        </>
      ),
    },
    {
      Header: 'REMAINING TIME',
      accessor: 'remainingTime',
      sortable: false,
      customCell: ({ value }) => {
        let isWarned = value.includes('ago');
        const signWarning = ['second', 'minute', 'hour', 'day'];
        while (signWarning.length > 0 && !isWarned) {
          const current = signWarning.pop();
          if (current === 'day' && value.includes(current)) {
            const numFromText = value.match(/\d+/);
            const daysRemain = numFromText ? +numFromText[0] : 0;
            isWarned = daysRemain <= 3;
          } else isWarned = value.includes(current);
        }
        let text = isWarned ? `Expired ${value}` : `Will expired ${value}`;
        return (
          <div className={clsx('flex', isWarned && 'text-red50')}>{text}</div>
        );
      },
    },
  ];
  const defaultSort = sortBy.sort
    ? { id: sortBy.sort, desc: sortBy.order === 'desc' }
    : null;

  return (
    <>
      <CustomTable
        page={page}
        totalPage={totalPages}
        onChangePage={onChangePage}
        onChangeTotalRow={onChangeTotalRow}
        search={initialSearch}
        onInputSearch={onInputSearch}
        onChangeFilter={onFilterChange}
        defaultSortedColumn={defaultSort}
        onChangeSort={(sortData) => {
          dispatch(changeSortSubscribers(sortData));
        }}
        isLoading={loading}
        dataTable={data}
        columnsTable={columns}
        additionalButton={[
          ({ baseClassName }) => (
            <button
              className={clsx(
                `${baseClassName} flex items-center ml-3`,
                loadingCSV ? 'bg-green30' : 'bg-green50'
              )}
              onClick={() => {
                dispatch(
                  downloadSubscriberListCSV(
                    (url) => {
                      const anchor = document.createElement('a');
                      anchor.setAttribute('href', url);
                      anchor.click();
                    },
                    () => {
                      showToastError('An error occurred');
                    }
                  )
                );
              }}
              disabled={loadingCSV}
            >
              <span className="whitespace-nowrap">
                {!loadingCSV ? 'Download CSV' : 'Please Wait...'}
              </span>
              <Icon
                iconSize={18}
                icon={IconNames.CLOUD_DOWNLOAD}
                className="fill-current text-white ml-2"
              />
            </button>
          ),
        ]}
      />
    </>
  );
}

export default UserSubscription;
