import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from 'components/ProgressBar';
import { useForm } from 'react-hook-form';
import { showToastError } from 'layouts/DashboardLayout';
import { createLoadingSelector } from 'redux/api/loading';
import {
  importTransformationResult,
  resetProgress,
} from 'redux/features/variableManagement';
import { showToastSuccess } from 'layouts/DashboardLayout';

export default function DialogImportData({ isOpen, onClose, onSuccess }) {
  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState('init');
  const [filename, setFilename] = useState('');
  const [data, setData] = useState(null);
  const selectedVariable = useSelector(
    ({ variableManagement }) => variableManagement.selected
  );
  const { visible: visibleProgress, progress } = useSelector(
    ({ variableManagement }) => variableManagement.upload
  );
  const loading = useSelector(
    createLoadingSelector([importTransformationResult.type])
  );
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      mode: 'replace',
    },
  });
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: '.xlsx',
    noClick: true,
    noKeyboard: true,
    maxSize: 5000000, // 5 mil. bytes => 5 MB
    multiple: false,
    onDropAccepted: (files) => {
      if (files.length > 0 && !loading) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const file = files[0];
          setData(file);
          setFilename(file.name);
        };
        reader.readAsDataURL(files[0]);
      }
    },
    onDropRejected: (files) => {
      const message = files[0].errors[0].message;
      showToastError(message);
    },
  });
  const handleCancelOption = () => {
    reset();
    onClose();
  };
  const onSubmit = async (form) => {
    const formData = new FormData();
    formData.set('file', data);
    formData.set('mode', form.mode);
    setUploadStatus('process');
    await dispatch(
      importTransformationResult(
        selectedVariable.id,
        formData,
        () => {
          showToastSuccess('XLSX upload successful');
          setUploadStatus('init');
          onClose();
          onSuccess();
          setData(null);
          setFilename('');
        },
        () => {
          showToastError('Upload XLSX failed');
          setUploadStatus('error');
        }
      )
    );
    dispatch(resetProgress());
  };

  return (
    <>
      <Dialog isOpen={isOpen} header="Upload data from XLSX" onClose={onClose}>
        <div className="w-full px-4 flex flex-col">
          <div
            {...getRootProps({
              className:
                'h-24 flex-grow flex justify-center items-center my-1 rounded border-2 border-dashed border-blue30 focus:outline-none',
            })}
          >
            <input {...getInputProps()} />
            <div
              className={clsx(
                'flex items-center',
                visibleProgress && 'w-full mx-3'
              )}
            >
              {['init', 'error'].includes(uploadStatus) && filename && (
                <>
                  <p className="text-sm text-blue50">
                    {filename}
                    <button
                      onClick={() => {
                        setData(null);
                        setFilename('');
                      }}
                    >
                      <Icon
                        icon={IconNames.TRASH}
                        iconSize={14}
                        className="fill-current text-red50 ml-1"
                      />
                    </button>
                  </p>
                </>
              )}
              {['init', 'error'].includes(uploadStatus) && !filename && (
                <>
                  <Icon
                    icon={IconNames.CIRCLE_ARROW_UP}
                    iconSize={14}
                    className="fill-current text-grey50 mr-1"
                  />
                  <p className="text-sm text-grey50">
                    Drag and Drop XLSX file here or
                    <span
                      onClick={open}
                      className="text-blue60 underline ml-1 cursor-pointer"
                    >
                      choose file
                    </span>
                  </p>
                </>
              )}
              {uploadStatus === 'process' && (
                <div className="w-full">
                  <p className="text-center text-grey50">{filename}</p>
                  <ProgressBar percentage={progress} />
                </div>
              )}
              {uploadStatus === 'done' && (
                <span className="text-blue50">
                  {filename}
                  <Icon
                    icon={IconNames.TICK_CIRCLE}
                    iconSize={16}
                    className="fill-current text-green50 ml-1"
                  />
                </span>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-col gap-y-5 mt-5">
              <p>
                Do you want to replace existing values with new values or just
                fill in the N/A values?
              </p>
              <label htmlFor="mode" className="flex items-center ml-2.5">
                <input
                  ref={register}
                  type="radio"
                  name="mode"
                  value="replace"
                  className="mr-4 h-5 w-5"
                />
                <span className="-mt-px">Replace existing values</span>
              </label>
              <label htmlFor="mode" className="flex items-center ml-2.5">
                <input
                  ref={register}
                  type="radio"
                  name="mode"
                  value="fill"
                  className="mr-4 h-5 w-5"
                />
                <span className="-mt-px">Just fill in the N/A values</span>
              </label>
              <div className="flex justify-end gap-x-3">
                <Button
                  view="outlined"
                  color="danger"
                  onClick={handleCancelOption}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={!filename}>
                  Upload
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}
