import { Dialog, Classes } from '@blueprintjs/core';
import clsx from 'clsx';
export default function DialogReasonRejected({
  setShowDialog,
  showDialog,
  rejectedReasonOption,
  setSelectedRejectReason,
  selectedRejectReason,
  setNoteRejectReason,
  noteRejectReason,
  rejectStudentStatus,
  ...props
}) {
  return (
    <Dialog isOpen={showDialog} className="w-80 h-66 bg-white rounded ">
      <div className={clsx(Classes.DIALOG_BODY, 'px-5 py-3 m-0')}>
        <h6 className="font-semibold text-grey90 mb-5">
          Reject Student Account Application
        </h6>
        <div className="w-full mb-5">
          {rejectedReasonOption.map((value, index) => (
            <label className="flex items-center mb-5" key={index}>
              <input
                type="radio"
                name="reject-student"
                className="mr-4 cursor-pointer"
                checked={value === selectedRejectReason}
                onChange={() => {
                  setNoteRejectReason('');
                  setSelectedRejectReason(value);
                }}
              />
              <p className="text-xs text-grey90 font-medium">{value}</p>
            </label>
          ))}
          <label className="flex items-center">
            <input
              type="radio"
              name="reject-student"
              className="mr-4 cursor-pointer"
              checked={'' === selectedRejectReason}
              onChange={() => {
                setSelectedRejectReason('');
                setNoteRejectReason('');
              }}
            />
            <p className="text-xs text-grey90 font-medium">Other</p>
          </label>
          {'' === selectedRejectReason && (
            <>
              <textarea
                name="reason"
                placeholder="Note"
                rows="5"
                value={noteRejectReason}
                className={`h-12 w-full text-sm bg-white border ${
                  !noteRejectReason
                    ? 'border-red70 focus:border-red50'
                    : 'border-grey30 focus:border-blue50'
                } rounded-tl rounded-bl mt-2 focus:outline-none focus:ring-transparent`}
                onChange={(e) => setNoteRejectReason(e.target.value)}
              />
              {!noteRejectReason && (
                <p className="text-red70 ml-2 text-xs">
                  Please enter your reason
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex items-center justify-end">
          <button
            className="border-2 border-red50 py-1 px-3 mx-1 shadow rounded text-center text-red50 text-sm font-medium focus:outline-none focus:ring-transparent"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            Cancel
          </button>
          <button
            className="border-2 border-blue50 bg-blue50 py-1 px-3 mx-1 shadow rounded text-center text-white text-sm font-medium focus:outline-none focus:ring-transparent disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={!selectedRejectReason && !noteRejectReason}
            onClick={() => {
              if (selectedRejectReason || noteRejectReason) {
                rejectStudentStatus();
              }
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
}
