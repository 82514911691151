import ConfirmationDialog from 'components/ConfirmationDialog';
import { useHistory } from 'react-router-dom';
import PATH_URL from 'routers/path';
export default function DialogEndFreetrial({
  setShowDialog,
  showDialog,
  ...props
}) {
  const history = useHistory();
  return (
    <ConfirmationDialog
      title="Free Trial Ends"
      isOpen={showDialog}
      onCancel={() => {
        setShowDialog(false);
      }}
      onConfirm={() => {
        history.push(PATH_URL.USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC);
      }}
      captions={{ confirm: 'Subscribe' }}
    >
      Your 7-day free trial has ended. Please subscribe to use all features
    </ConfirmationDialog>
  );
}
