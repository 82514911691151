import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { upperFirst } from 'lodash';
import Spinner from 'components/Spinner';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import ResultTopBar from './ResultTopBar';
import AdvancedKeywordBar from './AdvancedKeywordBar';
import KeywordBasic from './KeywordBasic';
import Switch from './Switch';
import DocumentSectionDialog from './DocumentSectionDialog';
import CompaniesDialog from './CompaniesDialog';
import Checkbox from './Checkbox';
import notesIcon from '../../assets/svg/notes.svg';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  getSearchDetail,
  selectedQuerySelector,
} from '../../redux/features/textAnalytics';

function reportType(str) {
  return str
    .split('_')
    .map((word) => upperFirst(word))
    .join(' ');
}

const DEFAULT_PAGE_SIZE = 100;

function DocumentTable(props) {
  const tableContainerRef = useRef(null);
  const isLoading = useSelector(createLoadingSelector([getSearchDetail.type]));
  const selectedQuery = useSelector(selectedQuerySelector);
  const [queryViewMode, setQueryViewMode] = useState('basic');
  const [tableHovered, setTableHovered] = useState(false);
  const [localVisibleCols, setLocalVisibleCols] = useState([
    'company.ticker',
    'year',
  ]);
  const [data, setData] = useState([]);
  const [moreSectionsOpen, setMoreSectionsOpen] = useState(false);
  const [moreCompaniesOpen, setMoreCompaniesOpen] = useState(false);
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: 'Ticker',
        accessor: 'company.ticker',
      },
      {
        Header: 'SIC',
        accessor: 'company.sic',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Document Type',
        accessor: (row) => reportType(row.type),
      },
      // {
      //   Header: 'Section',
      //   accessor: 'section',
      // },
      {
        Header: 'Total Keywords',
        accessor: (row) => {
          if (row.stats) {
            const keywords = row.stats.detail_keywords
              ? Object.keys(row.stats.detail_keywords).map((keyword) => {
                  return (
                    <li
                      key={keyword}
                    >{`${keyword} (${row.stats.detail_keywords[keyword]})`}</li>
                  );
                })
              : [];

            return keywords.length ? (
              <ul>
                {[
                  <li key="total_keywords" className="font-medium">
                    Total: {row.stats.total_keywords}
                  </li>,
                  ...keywords,
                ]}
              </ul>
            ) : null;
          }
          return '';
        },
      },
      {
        Header: 'Total Words',
        accessor: 'stats.total_words',
      },
      {
        Header: 'Total Words without Numeric',
        accessor: 'stats.total_words_non_numeric',
      },
      {
        Header: 'All Sentences',
        accessor: 'stats.all_sentences',
      },
      {
        Header: 'Sentences including Keywords',
        accessor: 'stats.including_keywords',
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    visibleColumns,
    setHiddenColumns,
    toggleHideAllColumns,
    page,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageIndex: props.tablePageIndex,
      },
    },
    useSortBy,
    usePagination
  );

  const allColumnsSelected = visibleColumns.length === allColumns.length;

  useEffect(() => {
    if (selectedQuery) setData(selectedQuery.documents);
  }, [selectedQuery]);

  useEffect(() => {
    if (visibleColumns.length < allColumns.length)
      setLocalVisibleCols(visibleColumns.map((col) => col.id));
  }, [visibleColumns, allColumns]);

  return (
    <>
      <div
        className={clsx(
          'w-full flex flex-col justify-between py-4 px-6 border-b border-grey5',
          showMoreFilter ? 'h-60' : 'h-36'
        )}
      >
        <div className="flex w-full">
          <div className="flex w-full items-center">
            <span className="block text-grey50 text-xs mr-3">Query:</span>
            <div className="max-w-4/5">
              {queryViewMode === 'advanced' ? (
                <AdvancedKeywordBar keywords={selectedQuery.keywords} />
              ) : (
                <KeywordBasic keywords={selectedQuery.keywords} />
              )}
            </div>
            <div className="flex items-center ml-4">
              <span className="text-sm font-bold text-blue90 mr-2">
                Advanced
              </span>
              <Switch
                checked={queryViewMode === 'advanced'}
                onChange={() =>
                  setQueryViewMode((prev) =>
                    prev === 'advanced' ? 'basic' : 'advanced'
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex items-center">
            <span className="block text-grey50 text-xs mr-2">Year Range:</span>
            <div>
              <span className="text-sm font-bold py-2 px-4 rounded bg-yellow5 text-yellow70">
                {selectedQuery.yearRange[0]}
              </span>
              <Icon
                icon={IconNames.ARROW_RIGHT}
                iconSize={14}
                className="fill-current ltext-grey70 mx-3"
              />
              <span className="text-sm font-bold py-2 px-4 rounded bg-yellow5 text-yellow70">
                {selectedQuery.yearRange[1]}
              </span>
            </div>
          </div>
        </div>
        {showMoreFilter && (
          <>
            <div className="flex">
              <div className="flex items-center">
                <span className="block text-grey50 text-xs mr-2">
                  Document Sections:
                </span>
                <div>
                  {selectedQuery.documentSection.length === 0 && (
                    <span className="text-sm font-bold py-2 px-4 rounded bg-grey5 text-grey70">
                      Whole documents
                    </span>
                  )}
                  {selectedQuery.documentSection.length > 0 &&
                    selectedQuery.documentSection.slice(0, 3).map((section) => (
                      <span
                        key={section.id}
                        className="text-sm font-bold py-2 px-4 rounded bg-grey5 text-grey70 mr-2"
                      >
                        {section.length > 20
                          ? `${section.name.slice(0, 20)}...`
                          : section.name}
                      </span>
                    ))}
                  {selectedQuery.documentSection.length > 0 &&
                    selectedQuery.documentSection.length > 3 && (
                      <button
                        onClick={() => setMoreSectionsOpen(true)}
                        className="text-sm font-bold ml-4 text-orange2 tracking-wider"
                      >
                        More
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex items-center">
                <span className="block text-grey50 text-xs mr-2">
                  Companies:
                </span>
                <div>
                  {selectedQuery.companies.length === 0 && (
                    <span className="text-sm font-bold py-2 px-4 rounded bg-teal1 text-teal2 mr-2">
                      All Companies
                    </span>
                  )}
                  {selectedQuery.companies.length > 0 &&
                    selectedQuery.companies.slice(0, 10).map((company) => (
                      <span
                        key={company.id}
                        className="text-sm font-bold py-2 px-4 rounded bg-teal1 text-teal2 mr-2"
                      >
                        {company.ticker}
                      </span>
                    ))}
                  {selectedQuery.companies.length > 10 && (
                    <button
                      onClick={() => setMoreCompaniesOpen(true)}
                      className="text-sm font-bold ml-4 text-orange2 tracking-wider"
                    >
                      More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-full flex justify-center">
          <button
            onClick={() => setShowMoreFilter((prev) => !prev)}
            className="text-sm text-blue50 font-medium flex items-center"
          >
            <Icon
              icon={IconNames.CHEVRON_UP}
              iconSize={20}
              className={clsx(
                'fill-current text-blue50 transition-transform transform mr-2',
                showMoreFilter ? 'rotate-0' : 'rotate-180'
              )}
            />
            <span>{`Show ${showMoreFilter ? 'Less' : 'More'} Filter`}</span>
          </button>
        </div>
      </div>
      <div
        className="w-full overflow-x-hidden"
        style={{
          height: showMoreFilter ? 'calc(100% - 15rem)' : 'calc(100% - 9rem)',
        }}
      >
        <ResultTopBar
          pageData={{
            pageIndex,
            pageSize,
            canNextPage,
            canPreviousPage,
            nextPage: () => {
              props.setTablePageIndex((prev) => prev + 1);
              nextPage();
            },
            previousPage: () => {
              props.setTablePageIndex((prev) => prev - 1);
              previousPage();
            },
          }}
          columnToggler={
            <Popover
              position={Position.TOP_LEFT}
              minimal={true}
              popoverClassName="w-60 shadow-md p-3 bg-white rounded-md"
            >
              <button className="mb-1 mr-5">
                <Icon
                  icon={IconNames.COLUMN_LAYOUT}
                  iconSize={16}
                  className={clsx('fill-current text-grey50')}
                />
              </button>
              <div className="w-full">
                <header className="font-medium text-base text-grey100">
                  Show Columns
                </header>
                <div className="flex flex-col flex-wrap mt-2 -ml-1">
                  <Checkbox
                    label="All Columns"
                    checked={allColumnsSelected}
                    onChange={() => {
                      if (allColumnsSelected) {
                        setHiddenColumns(
                          allColumns
                            .filter((col) => !localVisibleCols.includes(col.id))
                            .map((col) => col.id)
                        );
                      } else {
                        toggleHideAllColumns();
                      }
                    }}
                  />
                  {allColumns
                    .filter(
                      (col) => !['company.ticker', 'year'].includes(col.id)
                    )
                    .map((col) => (
                      <div key={col.id} className="w-full">
                        <Checkbox
                          label={col.Header}
                          {...col.getToggleHiddenProps()}
                          disabled={visibleColumns.length === allColumns.length}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Popover>
          }
        />
        {!isLoading ? (
          <div
            ref={tableContainerRef}
            className={clsx(
              'overflow-y-auto',
              tableHovered ? 'overflow-x-auto' : 'overflow-x-hidden'
            )}
            style={{ maxHeight: 'calc(100% - 3.5rem)' }}
            onMouseOver={() => setTableHovered(true)}
            onMouseLeave={() => setTableHovered(false)}
          >
            <table
              {...getTableProps()}
              className="min-w-full"
              onMouseOver={() => setTableHovered(true)}
              onMouseLeave={() => setTableHovered(false)}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        onClick={(e) => {
                          column
                            .getHeaderProps(column.getSortByToggleProps())
                            .onClick(e);
                        }}
                        className={clsx(
                          'h-14 px-2 select-none text-grey50 text-left text-xs font-medium whitespace-nowrap border-b-2 border-grey40 cursor-pointer',
                          column.isSorted ? 'bg-blue0' : 'bg-white'
                        )}
                      >
                        <div className="h-full flex justify-between items-center">
                          {column.render('Header')}
                          <div className="ml-2">
                            <button
                              onClick={(e) => {
                                column
                                  .getHeaderProps(column.getSortByToggleProps())
                                  .onClick(e);
                              }}
                              className="flex flex-col items-center"
                            >
                              <Icon
                                icon={IconNames.CARET_UP}
                                iconSize={12}
                                className={clsx(
                                  'fill-current',
                                  column.isSorted && !column.isSortedDesc
                                    ? 'text-blue50'
                                    : 'text-grey20'
                                )}
                              />
                              <Icon
                                icon={IconNames.CARET_DOWN}
                                iconSize={12}
                                className={clsx(
                                  'fill-current -mt-1.5',
                                  column.isSorted && column.isSortedDesc
                                    ? 'text-blue50'
                                    : 'text-grey20'
                                )}
                              />
                            </button>
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        const document = row.original;
                        props.setSelectedRow({
                          id: document.id,
                          index: row.index,
                          data: document.data.body.data,
                        });
                      }}
                      className="bg-white cursor-pointer hover:bg-blue0 group"
                    >
                      {row.cells.map((cell, index) => {
                        const {
                          column: { isSorted },
                        } = cell;
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={clsx(
                              'px-2 py-3 text-grey100 text-xs font-normal',
                              isSorted && 'bg-blue0'
                            )}
                          >
                            <div className="flex">
                              {cell.render('Cell')}
                              {index === 0 && (
                                <img
                                  src={notesIcon}
                                  alt="add notes"
                                  className="fill-current ml-1 -mt-px text-blue50 h-4 w-4 invisible group-hover:visible"
                                />
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full flex justify-center h-full items-center">
            <Spinner className="w-20 h-20 fill-current text-blue30" />
          </div>
        )}
      </div>
      <DocumentSectionDialog
        data={selectedQuery.documentSection}
        isOpen={moreSectionsOpen}
        onClose={() => setMoreSectionsOpen(false)}
      />
      <CompaniesDialog
        data={selectedQuery.companies}
        isOpen={moreCompaniesOpen}
        onClose={() => setMoreCompaniesOpen(false)}
      />
    </>
  );
}

export default DocumentTable;
