import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { errorReducer } from '../api/error';
import { loadingReducer } from '../api/loading';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth';
import userManagementReducer from './userManagement';
import uiReducer from './ui';
import textAnalyticsReducer from './textAnalytics';
import voucherManagementReducer from './voucherManagement';
import userPaymentReducer from './userPayment';
import variableManagementReducer from './variableManagement';
import userSelectVariableReducer from './userSelectVariable';
import discountManagementReducer from './discountManagement';
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    auth: authReducer,
    userManagement: userManagementReducer,
    userPayment: userPaymentReducer,
    ui: uiReducer,
    textAnalytics: textAnalyticsReducer,
    loading: loadingReducer,
    error: errorReducer,
    voucherManagement: voucherManagementReducer,
    variableManagement: variableManagementReducer,
    userSelectVariable: userSelectVariableReducer,
    discountManagement: discountManagementReducer,
  });

export default rootReducer;
