import { useSelector } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import { selectedQuerySelector } from '../../redux/features/textAnalytics';

function ResultTopBar(props) {
  const selectedQuery = useSelector(selectedQuerySelector);
  const { pageData } = props;

  const renderTablePageNav = () => {
    const { documents } = selectedQuery;
    const start = pageData.pageIndex * pageData.pageSize + 1;
    const maxRowPage = (pageData.pageIndex + 1) * pageData.pageSize;
    const label = `${start} - ${
      maxRowPage < documents.length ? maxRowPage : documents.length
    } of ${documents.length}`;
    return (
      <div className="flex">
        <span className="text-sm text-grey50">{label}</span>
        <div className="ml-3">
          <button
            onClick={() => pageData.canPreviousPage && pageData.previousPage()}
          >
            <Icon
              icon={IconNames.CHEVRON_LEFT}
              iconSize={18}
              className={clsx(
                'fill-current',
                pageData.canPreviousPage ? 'text-grey50' : 'text-grey20'
              )}
            />
          </button>
          <button
            onClick={() => pageData.canNextPage && pageData.nextPage()}
            className="ml-2"
          >
            <Icon
              icon={IconNames.CHEVRON_RIGHT}
              iconSize={18}
              className={clsx(
                'fill-current',
                pageData.canNextPage ? 'text-grey50' : 'text-grey20'
              )}
            />
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className={clsx('h-12 w-full flex items-center justify-end')}>
      <span className={clsx('block text-xs text-blue100 mr-4')}>
        {renderTablePageNav()}
      </span>
      {props.columnToggler}
    </div>
  );
}

export default ResultTopBar;
