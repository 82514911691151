import { useState } from 'react';
import { Icon, Collapse, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import Price from './Price';
import { get } from 'lodash';

const CustomCollapseCategory = ({
  initialShow = false,
  dataCategory = {
    id: '',
    name: '',
    variables: [],
  },
  selectedData = [],
  onSelectAll = null,
  onSelectItem = null,
  className = '',
}) => {
  const [open, setOpen] = useState(!!initialShow);
  return (
    <div className={clsx('w-full', className)}>
      <div
        className={`flex justify-between items-center py-3 cursor-pointer`}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div
          className={clsx('flex items-center cursor-pointer')}
          onClick={(e) => {
            e.stopPropagation();
            return (
              onSelectAll &&
              onSelectAll(
                !(selectedData.length === dataCategory.variables.length)
              )
            );
          }}
        >
          <input
            type="checkbox"
            checked={
              selectedData.length > 0 &&
              selectedData.length === dataCategory.variables.length
            }
            onChange={() => {}}
            className="appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent mr-2 cursor-pointer"
          />
          <p className={`text-grey100 font-medium text-base`}>
            {dataCategory.name}
          </p>
        </div>
        <Icon
          iconSize={16}
          icon={open ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          className="fill-current text-grey50 ml-2"
        />
      </div>
      <Collapse isOpen={open}>
        <div className="pl-5">
          {dataCategory.variables.map((variableCat) => (
            <div
              key={`${dataCategory.name}-${variableCat.id}`}
              className={clsx('flex justify-between items-center mb-3')}
            >
              <div className="flex items-center mr-4">
                <div
                  className={clsx('flex items-center cursor-pointer')}
                  onClick={() =>
                    onSelectItem &&
                    onSelectItem(
                      !selectedData.map((v) => v.id).includes(variableCat.id),
                      variableCat
                    )
                  }
                >
                  <input
                    type="checkbox"
                    checked={selectedData
                      .map((v) => v.id)
                      .includes(variableCat.id)}
                    onChange={() => {}}
                    className="appearance-none border-2 border-grey50 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-transparent mr-2 cursor-pointer"
                  />
                  <Tooltip
                    openOnTargetFocus={false}
                    content={
                      <p className="text-xs text-white max-w-md">
                        {variableCat.description}
                      </p>
                    }
                    position={Position.TOP_RIGHT}
                    className="mt-px"
                  >
                    <p className="text-sm text-grey100">{variableCat.name}</p>
                  </Tooltip>
                </div>
              </div>
              <Price
                type={
                  variableCat.classification && variableCat.classification.name
                }
                value={get(
                  variableCat,
                  'classification.price',
                  variableCat.price
                )}
              />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CustomCollapseCategory;
