import { useHistory } from 'react-router-dom';
import PaymentSuccess from 'assets/svg/payment-success.svg';
import Button from 'components/Button';
import PATH_URL from 'routers/path';

export default function PaymentComplete() {
  const history = useHistory();

  return (
    <div className={'w-full flex flex-col items-center mt-8'}>
      <img src={PaymentSuccess} alt="Payment Success" />
      <div
        className={'text-center flex flex-col items-center'}
        style={{ width: '430px' }}
      >
        <div className={'text-grey100 text-2xl font-bold'}>
          Your order is complete!
        </div>
        <div className={'text-xl text-grey50 pt-2 pb-4'}>
          You can check your transaction status and download your data in the
          Billing History
        </div>
        <Button
          color="primary"
          onClick={() => {
            history.push({
              pathname: PATH_URL.USER_PROFILE,
              search: '?billing=qd',
            });
          }}
        >
          Got to Billing History
        </Button>
      </div>
    </div>
  );
}
