import { useState } from 'react';
import moment from 'moment';
import { cancelOrder, getPayment } from 'redux/features/userPayment';
import { createLoadingSelector } from 'redux/api/loading';
import BCAVa from './VA/bca';
import BRIVa from './VA/bri';
import BNIVa from './VA/bni';
import MandiriVa from './VA/mandiri';
import General from './General';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'utils/currencyFormatter';
import DialogDetailInvoiceQD from './DialogDetailInvoiceQD';
import { showToastError } from 'layouts/DashboardLayout';

const getPaymentMethodClue = (origin, string) => {
  return origin ? origin.indexOf(string) > -1 : false;
};

export default function PaymentAwaiting() {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const orderData = useSelector(
    ({ userPayment }) => userPayment.quantitative.selected
  );
  const dueDateAsT = moment
    .tz(orderData.dueDate, 'Asia/Bangkok')
    .format('YYYY-MM-DDTHH:mm:ss');
  const dateNow = moment().format('YYYY-MM-DDTHH:mm:ss');
  const differenceInSecond = moment(dueDateAsT).diff(dateNow, 'seconds');
  const loading = useSelector((state) =>
    createLoadingSelector([getPayment.type])(state)
  );
  const [showDetailInvoice, setShowDetailInvoice] = useState(false);
  const paymentMethod = orderData.payment.paymentMethod
    ? orderData.payment.paymentMethod.toLowerCase()
    : 'general';
  const dueDate =
    orderData &&
    `${moment
      .tz(orderData.dueDate, 'Asia/Bangkok')
      .format('DD MMM YYYY, HH:mm')} WIB`;
  const snapToken = orderData.payment?.token;
  const netPrice = orderData && currencyFormatter.format(orderData.netPrice);
  const vaNumber = orderData.payment.vaNumber;
  const isBCA = getPaymentMethodClue(paymentMethod, 'bca');
  const isBri = getPaymentMethodClue(paymentMethod, 'bri');
  const isBni = getPaymentMethodClue(paymentMethod, 'bni');
  const isMandiri = getPaymentMethodClue(paymentMethod, 'mandiri');
  const isGeneral = [isBCA, isBri, isBni, isMandiri].every((c) => c === false);
  const loadingCancel = useSelector(createLoadingSelector([cancelOrder.type]));
  const cancel = async () => {
    await dispatch(
      cancelOrder(
        orderData.invoiceNumber,
        () => {
          showToastError('Your order has been canceled');
          dispatch(getPayment(orderData.invoiceNumber, 1000));
        },
        () => {
          showToastError('Failed cancel order');
        }
      )
    );
  };

  return (
    <div className={'w-full flex flex-column items-center flex-col py-14'}>
      {!loading && isBCA && (
        <BCAVa
          invoiceNumber={orderData.invoiceNumber}
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancel}
          loadingCancel={loadingCancel}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isBri && (
        <BRIVa
          invoiceNumber={orderData.invoiceNumber}
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancel}
          loadingCancel={loadingCancel}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isBni && (
        <BNIVa
          invoiceNumber={orderData.invoiceNumber}
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancel}
          loadingCancel={loadingCancel}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isMandiri && (
        <MandiriVa
          invoiceNumber={orderData.invoiceNumber}
          differenceInSecond={differenceInSecond}
          vaNumber={vaNumber}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancel}
          loadingCancel={loadingCancel}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      {!loading && isGeneral && (
        <General
          snapToken={snapToken}
          invoiceNumber={orderData.invoiceNumber}
          differenceInSecond={differenceInSecond}
          dueDate={dueDate}
          price={netPrice}
          cancel={cancel}
          loadingCancel={loadingCancel}
          setShowDetailInvoice={setShowDetailInvoice}
        />
      )}
      <DialogDetailInvoiceQD
        email={userDetail.email}
        showDialog={showDetailInvoice}
        detailInvoice={orderData}
        setShowDialog={setShowDetailInvoice}
      />
    </div>
  );
}
