import { Icon, Dialog } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment-timezone';
import { currencyFormatter } from '../../utils/currencyFormatter';
export default function ModalDetailInvoice({
  showDialog,
  detailInvoice,
  setShowDialog,
}) {
  const dataWithLabel = [
    {
      key: 'Name',
      value:
        detailInvoice &&
        detailInvoice.user &&
        detailInvoice.user.profile &&
        detailInvoice.user.profile.fullName,
    },
    {
      key: 'Email',
      value: detailInvoice && detailInvoice.user && detailInvoice.user.email,
    },
    {
      key: 'Payment Method',
      value: detailInvoice && detailInvoice.paymentMethod,
    },
    {
      key: 'Discount',
      value:
        detailInvoice && detailInvoice.discount && detailInvoice.discount.name,
    },
    { key: 'Order Status', value: detailInvoice && detailInvoice.orderStatus },
  ];
  return (
    <Dialog
      className="w-5/6 h-3/5 px-5 py-5 bg-white rounded shadow"
      isOpen={showDialog}
    >
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center">
            <span className="text-sm font-medium text-grey100 mr-2">
              {detailInvoice.invoiceNumber}
            </span>
            <span className="text-sm text-grey50">
              {`/ ${moment
                .tz(detailInvoice && detailInvoice.createdAt, 'Asia/Bangkok')
                .format('DD MMM YYYY, HH:mm')} WIB`}
            </span>
          </div>
          <Icon
            icon={IconNames.CROSS}
            iconSize={20}
            onClick={() =>
              setShowDialog({
                show: false,
                data: {},
              })
            }
            className="fill-current text-grey70 cursor-pointer"
          />
        </div>
        <div className="flex w-full mb-5">
          {dataWithLabel.map((data, index) => (
            <div key={`dummy-${index}`} className="mr-10 mb-6">
              <p className="text-sm font-normal text-gray50 mb-2">{data.key}</p>
              <p className="text-sm text-grey100 font-medium">{data.value}</p>
            </div>
          ))}
        </div>
        <div className="w-full shadow">
          <table className="w-full">
            <thead>
              <tr
                style={{
                  backgroundColor: '#f7f7fa',
                }}
                className="p-2"
              >
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium">
                  #
                </td>
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium">
                  Description
                </td>
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                  Amount
                </td>
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                  SubTotal
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-5 py-3 text-grey90 text-xs font-normal">1</td>
                <td className="w-6/12 md:w-8/12 px-5 py-3 text-grey90 text-xs font-normal">
                  {`${
                    detailInvoice &&
                    detailInvoice.subscription &&
                    detailInvoice.subscription.option
                  } Subscription`}
                </td>
                <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                  {currencyFormatter.format(detailInvoice.grossPrice)}
                </td>
                <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                  {currencyFormatter.format(detailInvoice.grossPrice)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="3"
                  className="px-5 py-3 text-grey90 text-xs  font-normal text-right"
                >
                  {detailInvoice.discount && detailInvoice.discount.name}
                </td>
                <td className="px-5 py-3 text-grey90 text-xs  font-normal text-right">
                  {detailInvoice &&
                    detailInvoice.discount &&
                    `- ${currencyFormatter.format(
                      detailInvoice.discount.type === 'amount'
                        ? detailInvoice.discountValue
                        : detailInvoice.grossPrice *
                            detailInvoice.discountValue *
                            0.01
                    )}`}
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: '#f7f7fa',
                }}
                className="p-2"
              >
                <td
                  colSpan="3"
                  className="px-5 py-3 text-grey100 text-sm  font-semibold text-right"
                >
                  Total
                </td>
                <td className="px-5 py-3 text-grey100 text-sm font-semibold text-right">
                  {currencyFormatter.format(detailInvoice.netPrice)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  );
}
