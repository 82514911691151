import { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Checkbox = forwardRef((props, ref) => {
  return (
    <label className={`inline-flex items-center cursor-pointer`}>
      <input
        ref={ref}
        type="checkbox"
        name={props.name}
        className={clsx(
          'form-checkbox h-5 w-5 rounded-sm cursor-pointer focus:ring-transparent',
          props.disabled === true ? 'text-grey30' : 'text-blue50'
        )}
        onChange={props.onChange || function () {}}
        onClick={props.onClick || (() => {})}
        checked={props.checked}
        disabled={props.disabled || false}
        value={props.value}
      />
      <span
        className={clsx(
          props.className,
          'ml-2',
          props.disabled === true ? 'text-grey30' : 'text-grey100'
        )}
      >
        {props.label}
      </span>
    </label>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default Checkbox;
