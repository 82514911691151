import { useState } from 'react';
import Dialog from 'components/Dialog';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Button from 'components/Button';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'utils/currencyFormatter';
import { updateInvoiceStatus } from 'redux/features/userPayment';
import { createLoadingSelector } from 'redux/api/loading';
import { showToastError } from 'layouts/DashboardLayout';
import { getUserInvoices } from 'redux/features/userManagement';

export default function ModalDetailInvoice({
  showDialog,
  detailInvoice,
  setShowDialog,
  email,
}) {
  const dispatch = useDispatch();
  const loadingUpdateInvoices = useSelector((state) =>
    createLoadingSelector([updateInvoiceStatus.type])(state)
  );
  const [showDialogCancel, setShowDialogCancel] = useState(false);
  const dataWithLabel = [
    {
      key: 'Email',
      value: email,
    },
    {
      key: 'Payment Method',
      value: detailInvoice && detailInvoice.paymentMethod,
    },

    {
      key: 'Virtual Account Number',
      value: (detailInvoice && detailInvoice.vaNumber) || '-',
    },
    ...(detailInvoice.statusPaid
      ? [
          {
            key: 'Discount',
            value:
              (detailInvoice &&
                detailInvoice.discount &&
                detailInvoice.discount.name) ||
              '-',
          },
        ]
      : []),
    { key: 'Order Status', value: detailInvoice && detailInvoice.orderStatus },
  ];
  const handleCancelInvoices = () => {
    dispatch(
      updateInvoiceStatus(
        {
          id: `${detailInvoice.id}`,
          orderStatus: 'cancelled',
          paymentStatus: 'unpaid',
        },
        () => {
          dispatch(
            getUserInvoices(detailInvoice.user && detailInvoice.user.id)
          );
          setShowDialogCancel(false);
          showToastError('Your order has been cancelled');
        },
        () => {
          showToastError('Failed to cancel order');
        }
      )
    );
  };
  return (
    <Dialog
      header={`Invoice ${detailInvoice.invoiceNumber}`}
      size="large"
      onClose={() => {
        setShowDialog(false);
      }}
      className="w-5/6 h-3/5 px-5 py-5 bg-white rounded shadow"
      isOpen={showDialog}
      dismissButton
    >
      <div className="w-full px-4">
        <div className="flex items-start justify-between mb-5">
          <div>
            <p className="text-sm text-grey50">
              {`Created on ${moment
                .tz(detailInvoice && detailInvoice.createdAt, 'Asia/Bangkok')
                .format('DD MMM YYYY, HH:mm')} WIB`}
            </p>
          </div>
        </div>
        {detailInvoice && detailInvoice.orderStatus === 'processing' && (
          <p className="h-10 mb-5 bg-red10 text-red60 text-sm flex justify-center items-center">
            {`Pay before  ${moment
              .tz(detailInvoice && detailInvoice.dueDate, 'Asia/Bangkok')
              .format('DD MMM YYYY, HH:mm')} WIB`}
          </p>
        )}
        <div className="flex justify-between items-center w-full mb-5">
          <div className="flex flex-wrap">
            {dataWithLabel.map((data, index) => (
              <div key={`dummy-${index}`} className="mr-10 mb-6">
                <p className="text-sm font-normal text-gray50 mb-2">
                  {data.key}
                </p>
                <p className="text-sm text-grey100 font-medium">{data.value}</p>
              </div>
            ))}
          </div>
          {detailInvoice && detailInvoice.orderStatus === 'processing' && (
            <Button
              view="outlined"
              size="small"
              onClick={() => setShowDialogCancel(true)}
            >
              Cancel Order
            </Button>
          )}
        </div>
        <div className="w-full shadow">
          <table className="w-full">
            <thead>
              <tr
                style={{
                  backgroundColor: '#f7f7fa',
                }}
                className="p-2"
              >
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium">
                  #
                </td>
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium">
                  Description
                </td>
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                  Amount
                </td>
                <td className="px-5 py-3 text-grey70 text-xs uppercase font-medium text-right">
                  SubTotal
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-5 py-3 text-grey90 text-xs font-normal">1</td>
                <td className="w-6/12 md:w-8/12 px-5 py-3 text-grey90 text-xs font-normal capitalize">
                  {`${
                    detailInvoice &&
                    detailInvoice.subscription &&
                    detailInvoice.subscription.option
                  } Subscription`}
                </td>
                <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                  {currencyFormatter.format(detailInvoice.grossPrice)}
                </td>
                <td className="px-5 py-3 text-grey90 text-xs font-normal text-right">
                  {currencyFormatter.format(detailInvoice.grossPrice)}
                </td>
              </tr>
              {detailInvoice.discountId && (
                <tr>
                  <td
                    colSpan="3"
                    className="px-5 py-3 text-grey90 text-xs  font-normal text-right"
                  >
                    {detailInvoice.discount && detailInvoice.discount.name}
                  </td>
                  <td className="px-5 py-3 text-grey90 text-xs  font-normal text-right">
                    {detailInvoice && detailInvoice.discountValue}
                  </td>
                </tr>
              )}
              <tr
                style={{
                  backgroundColor: '#f7f7fa',
                }}
                className="p-2"
              >
                <td
                  colSpan="3"
                  className="px-5 py-3 text-grey100 text-sm  font-semibold text-right"
                >
                  Total
                </td>
                <td className="px-5 py-3 text-grey100 text-sm font-semibold text-right">
                  {currencyFormatter.format(detailInvoice.netPrice)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {detailInvoice && detailInvoice.orderStatus === 'processing' && (
        <ConfirmationDialog
          title="Cancel Order"
          size="small"
          mode="danger"
          isOpen={showDialogCancel}
          onCancel={() => !loadingUpdateInvoices && setShowDialogCancel(false)}
          onConfirm={handleCancelInvoices}
          isLoading={loadingUpdateInvoices}
          captions={{ cancel: 'No', confirm: 'Yes' }}
        >
          Are you sure you want to cancel this order?
        </ConfirmationDialog>
      )}
    </Dialog>
  );
}
