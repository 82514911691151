import PropTypes from 'prop-types';

export default function Tab({ options, onChange, active }) {
  if (options.length > 0) {
    const style = { minWidth: '150px' };
    return (
      <ul className="flex bg-white pt-4">
        {options.map((value, index) => {
          const { label } = value;
          const itemClass = ['mr-6 pb-4 text-center cursor-pointer'];
          if (active === index) {
            itemClass.push('border-b-4');
            itemClass.push('border-blue50');
          }
          const itemClassAsString = itemClass.join(' ');

          let textClass = ['text-grey50'];
          if (active === index) {
            textClass = ['text-black'];
          }
          textClass.push('hover:text-black');
          textClass.push('cursor-pointer');
          const textClassAsString = textClass.join(' ');
          return (
            <li
              style={style}
              key={index}
              className={itemClassAsString}
              onClick={() => {
                onChange(index);
              }}
            >
              <span className={textClassAsString}>{label}</span>
            </li>
          );
        })}
      </ul>
    );
  }
  return null;
}

Tab.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
};

Tab.defaultProps = {
  options: [],
  onChange: () => {},
  active: -1,
};
