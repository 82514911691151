import React from 'react';
import mainLogo from '../../assets/images/esg-dataset-logo.png';
import splashLoading from '../../assets/svg/splash-loading.svg';

export default function SplashScreen() {
  return (
    <div className="h-screen w-screen">
      <div className="h-full w-full flex flex-col justify-center items-center">
        <img src={mainLogo} alt="main logo" className="h-20 w-18" />
        <img src={splashLoading} alt="splash loading" className="h-14 mt-2" />
      </div>
    </div>
  );
}
