import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicSearch from './BasicSearch';
import AdvancedSearch from './AdvancedSearch';
import InputLostDialog from './InputLostDialog';
import {
  SEARCH_MODE,
  toggleSearchMode,
  resetSearchFilter,
} from '../../redux/features/textAnalytics';

function SearchMain() {
  const dispatch = useDispatch();
  const searchMode = useSelector(
    ({ textAnalytics }) => textAnalytics.searchMode
  );
  const handleChangeSearchMode = () => {
    dispatch(toggleSearchMode());
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const handleChangeMode = (isEmpty) => {
    if (!isEmpty) setOpen(true);
    else dispatch(toggleSearchMode());
  };

  useEffect(() => {
    return () => {
      dispatch(resetSearchFilter());
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <div className="w-full h-full flex items-center justify-center flex-grow">
          {searchMode === SEARCH_MODE.BASIC ? (
            <BasicSearch setChangeMode={handleChangeMode} />
          ) : (
            <AdvancedSearch setChangeMode={handleChangeMode} />
          )}
        </div>
      </div>
      <InputLostDialog
        mode={searchMode}
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={handleChangeSearchMode}
      />
    </>
  );
}

export default SearchMain;
