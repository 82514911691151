import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from 'components/Button';
import { resetPassword } from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import { createErrorMessageSelector } from '../../redux/api/error';
import TextField from '../../components/TextField';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/i, 'Password must contain at least one letter')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Confirmation password not match'),
});

function ResetPassword(props) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState({ type: '', text: '' });
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const urlParams = useSelector((state) => state.router.location.query);
  const loading = useSelector((state) =>
    createLoadingSelector([resetPassword.type])(state)
  );
  const error = useSelector((state) =>
    createErrorMessageSelector([resetPassword.type])(state)
  );

  const onSubmit = async (data) => {
    const { oobCode: actionCode } = urlParams;
    const { password, confirmPassword } = data;
    const isSuccess = await dispatch(
      resetPassword(actionCode, password, confirmPassword)
    );
    if (isSuccess)
      setMessage({
        type: 'success',
        text: "Successfully reset your account password. You'll be redirected to login page.",
      });
    else setMessage({ type: 'error', text: error });
  };

  return (
    <div className="w-full flex flex-col">
      <header className="text-3xl font-semibold">Reset Password</header>
      <p className="text-sm text-grey70 my-2">
        Please enter your new password.
      </p>
      {message.type && (
        <p
          className={`text-sm ${
            message.type === 'error' ? 'bg-red50' : 'bg-green50'
          } text-white p-1 rounded-sm`}
        >
          {message.text}
        </p>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-6">
          <TextField
            type="password"
            name="password"
            ref={register}
            defaultBorder={false}
            label="New Password"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
          />
          <p className="text-xs text-red50 mt-px">{errors.password?.message}</p>
        </div>
        <div className="flex flex-col mt-4">
          <TextField
            type="password"
            name="confirmPassword"
            ref={register}
            defaultBorder={false}
            label="Re-enter Password"
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
          />
          <p className="text-xs text-red50 mt-px">
            {errors.confirmPassword?.message}
          </p>
        </div>
        <Button
          type="submit"
          color="primary"
          disabled={loading}
          isLoading={loading}
          className="w-full mt-6"
        >
          Reset Password
        </Button>
      </form>
    </div>
  );
}

export default ResetPassword;
