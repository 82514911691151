import { push } from 'connected-react-router';
import {
  actionSuccess,
  createAction,
  createAsyncAction,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import { findById, updateById } from '../../utils/array';
import PATH_URL from '../../routers/path';
import ENDPOINTS from '../urls';
import { cloneDeep, set, update, uniq } from 'lodash';

const PRE = 'textAnalytics';

export const toggleSearchMode = createAction(`${PRE}/TOGGLE_SEARCH_MODE`);
export const storeSearchKeywords = createAction(`${PRE}/STORE_SEARCH_KEYWORDS`);
export const toggleSearchTools = createAction(`${PRE}/TOGGLE_SEARCH_TOOLS`);
export const changeResultViewMode = createAction(
  `${PRE}/CHANGE_RESULT_VIEW_MODE`
);
export const changeSelectedDocument = createAction(
  `${PRE}/CHANGE_SELECTED_DOCUMENT`
);
export const setSearchDetailLoaded = createAction(
  `${PRE}/SET_SEARCH_DETAIL_LOADED`
);
export const resetSearchDetail = createAction(`${PRE}/RESET_SEARCH_DETAIL`);
export const setSearchDocumentDetail = createAction(
  `${PRE}/SET_SEARCH_DOCUMENT_DETAIL`
);
export const setSearchDocumentContent = createAction(
  `${PRE}/SET_SEARCH_DOCUMENT_CONTENT`
);
export const changeSelectedPageIndex = createAction(
  `${PRE}/CHANGE_SELECTED_PAGE_INDEX`
);
export const changeTablePage = createAction(`${PRE}/CHANGE_TABLE_PAGE`);
export const searchDocumentGroup = createAsyncAction(
  `${PRE}/SEARCH_DOCUMENT_GROUP`,
  (applyFilter = false) => async (dispatch, getState) => {
    const {
      viewMode,
      keywords: { symbols, advanced },
      filter,
    } = getState().textAnalytics;
    dispatch(resetSearchDetail());
    dispatch(push(PATH_URL.SEARCH_DETAIL.replace(':keywords', btoa(symbols))));
    const data = {
      keywords: advanced,
    };
    if (applyFilter) {
      const { yearRange, documentSections, companies } = filter;
      data.filters = {
        year: [yearRange.from, yearRange.to],
        sections: documentSections.isWhole ? [] : documentSections.selected,
        companies: companies.selected.map((company) => company.name),
      };
    }
    return await apiRequest(
      dispatch,
      `${PRE}/SEARCH_DOCUMENT_GROUP`,
      'post',
      ENDPOINTS.SEARCH_DOCUMENT_GROUP,
      data,
      'application/json',
      (data) => {
        const { result } = data;
        if (result.length > 0) {
          if (viewMode === VIEW_MODE.LIST) {
            dispatch(getSearchDetail(result[0].id));
          } else {
            result.forEach((document) => {
              dispatch(getSearchDetail(document.id, true));
            });
          }
        }
      }
    );
  }
);
export const getSearchDetail = createAsyncAction(
  `${PRE}/GET_SEARCH_DETAIL`,
  (id, batch = false) => async (dispatch, getState) => {
    const {
      searchResult: { documents, loaded },
      keywords: { advanced: advancedKeywords },
    } = getState().textAnalytics;
    if (!batch) {
      dispatch(changeSelectedDocument(id));
    } else if (documents.length) {
      dispatch(changeSelectedDocument(documents[0].id));
    }
    if (!loaded.includes(id)) {
      dispatch({ type: `${PRE}/GET_SEARCH_DETAIL_REQUEST` });
      Promise.all([
        apiRequest(
          dispatch,
          `${PRE}/GET_SEARCH_DOCUMENT_STATS`,
          'post',
          ENDPOINTS.SEARCH_DOCUMENT_STATS,
          { documentId: id, keywords: advancedKeywords }
        ),
        apiRequest(
          dispatch,
          `${PRE}/GET_SEARCH_DOCUMENT_PAGES`,
          'post',
          ENDPOINTS.SEARCH_DOCUMENT_PAGES,
          { documentId: id, keywords: advancedKeywords }
        ),
      ])
        .then(async (responses) => {
          const [{ payload: stats }, { payload: pages }] = responses;
          dispatch(setSearchDetailLoaded(id));
          dispatch(
            setSearchDocumentDetail({
              id,
              data: { stats, pages: pages.available_pages },
            })
          );
          const page = pages.available_pages[0];
          const result = await dispatch(getSearchContent(id, page));
          if (result.type === actionSuccess(getSearchContent.type))
            dispatch({ type: `${PRE}/GET_SEARCH_DETAIL_SUCCESS` });
          else
            dispatch({
              type: `${PRE}/GET_SEARCH_DETAIL_FAILURE`,
              payload: result.payload,
            });
        })
        .catch(() =>
          dispatch({
            type: `${PRE}/GET_SEARCH_DETAIL_FAILURE`,
            payload: { message: 'Problem getting detail search data ' },
          })
        );
    }
  }
);
export const getSearchContent = createAsyncAction(
  `${PRE}/GET_SEARCH_DOCUMENT_CONTENTS`,
  (documentId, page) => async (dispatch, getState) => {
    const {
      keywords: { advanced: advancedKeywords },
    } = getState().textAnalytics;
    return apiRequest(
      dispatch,
      `${PRE}/GET_SEARCH_DOCUMENT_CONTENTS`,
      'post',
      ENDPOINTS.SEARCH_DOCUMENT_CONTENTS,
      { documentId, keywords: advancedKeywords, pages: page },
      'application/json',
      (data) => {
        const selectedResult = selectedResultSelector(getState());
        const { pages_number, results } = data;
        dispatch(
          setSearchDocumentContent({
            documentId,
            data: {
              ...selectedResult.pageData,
              [pages_number]: results,
            },
          })
        );
      }
    );
  }
);
export const changeYearRange = createAction(`${PRE}/CHANGE_YEAR_RANGE_FILTER`);
export const getCompanyList = createAsyncAction(
  `${PRE}/GET_COMPANY_LIST`,
  (groupBy) => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_COMPANY_LIST`,
      'get',
      ENDPOINTS.GET_COMPANY_LIST,
      { groups: groupBy === 'None' ? null : groupBy }
    );
  }
);
export const getFilteredCompanyList = createAsyncAction(
  `${PRE}/GET_FILTERED_COMPANY_LIST`,
  (keywords) => async (dispatch, getState) => {
    const { groupBy } = getState().textAnalytics.filter.companies;
    return apiRequest(
      dispatch,
      `${PRE}/GET_FILTERED_COMPANY_LIST`,
      'get',
      ENDPOINTS.GET_COMPANY_LIST,
      {
        groups: groupBy === 'None' ? null : groupBy,
        keywords: keywords === '' ? null : keywords,
      }
    );
  }
);
export const changeCompanyGroup = createAction(`${PRE}/CHANGE_COMPANY_GROUP`);
export const changeSelectedCompanies = createAction(
  `${PRE}/CHANGE_SELECTED_COMPANIES`
);
export const getSectionList = createAsyncAction(
  `${PRE}/GET_SECTION_LIST`,
  () => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_SECTION_LIST`,
      'get',
      ENDPOINTS.GET_SECTION_LIST
    );
  }
);
export const getFilteredSectionList = createAsyncAction(
  `${PRE}/GET_FILTERED_SECTION_LIST`,
  (keywords) => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_FILTERED_SECTION_LIST`,
      'get',
      ENDPOINTS.GET_SECTION_LIST,
      { keywords: keywords === '' ? null : keywords }
    );
  }
);
export const changeSelectedSections = createAction(
  `${PRE}/CHANGE_SELECTED_SECTIONS`
);
export const changeSelectedSectionNames = createAction(
  `${PRE}/CHANGE_SELECTED_SECTION_NAMES`
);
export const toggleWholeSections = createAction(`${PRE}/TOGGLE_WHOLE_SECTIONS`);
export const getPeriodRange = createAsyncAction(
  `${PRE}/GET_PERIOD_RANGE`,
  () => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_PERIOD_RANGE`,
      'get',
      ENDPOINTS.GET_PERIOD_RANGE
    );
  }
);
export const resetSearchFilter = createAction(`${PRE}/RESET_SEARCH_FILTER`);

export const saveQuery = createAsyncAction(
  `${PRE}/SAVE_QUERY`,
  (queryName, onSuccess) => async (dispatch, getState) => {
    const { keywords, filter, searchResult } = getState().textAnalytics;
    const saveQueryData = {
      name: queryName,
      keywords: keywords.advanced,
      yearRange: [filter.yearRange.from, filter.yearRange.to],
      documentSection: filter.documentSections.isWhole
        ? []
        : filter.documentSections.selected,
      companies: filter.companies.selected.map((company) => company.name),
      documents: searchResult.documents,
    };
    return apiRequest(
      dispatch,
      `${PRE}/SAVE_QUERY`,
      'post',
      ENDPOINTS.SAVED_QUERY,
      saveQueryData,
      'application/json',
      onSuccess
    );
  }
);
export const changeSelectedSavedQuery = createAction(
  `${PRE}/CHANGE_SELECTED_SAVED_QUERY`
);
export const getSavedQueries = createAsyncAction(
  `${PRE}/LIST_SAVED_QUERY`,
  () => async (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/LIST_SAVED_QUERY`,
      'get',
      ENDPOINTS.SAVED_QUERY
    );
  }
);
export const getDecompressedSavedQuery = createAsyncAction(
  `${PRE}/GET_DECOMPRESSED_SAVED_QUERY`,
  (savedQueryId) => async (dispatch, getState) => {
    const {
      data: currentQueries,
      decompressed,
    } = getState().textAnalytics.savedQueries;
    if (!decompressed.includes(savedQueryId)) {
      const { type: actionType, payload } = await apiRequest(
        dispatch,
        `${PRE}/GET_DECOMPRESSED_SAVED_QUERY`,
        'get',
        `${ENDPOINTS.SAVED_QUERY}/${savedQueryId}`
      );
      if (actionSuccess(actionType)) {
        const result = payload[0];
        const updatedQueries = updateById(currentQueries, savedQueryId, {
          ...result,
        });
        dispatch(updateSavedQueryList(updatedQueries));
        dispatch(updateDecompressedSavedQueryList(savedQueryId));
      }
    }
  }
);
export const decodeSentenceData = createAsyncAction(
  `${PRE}/DECODE_SENTENCE_DATA`,
  (queryId, document) => async (dispatch, getState) => {
    const {
      data: currentQueries,
      decoded,
    } = getState().textAnalytics.savedQueries;
    const id = `${queryId}-${document.id}`;
    if (!decoded.includes(id)) {
      return apiRequest(
        dispatch,
        `${PRE}/DECODE_SENTENCE_DATA`,
        'post',
        ENDPOINTS.DECODE_SAVED_QUERY,
        { type: 'Buffer', data: document.data },
        'application/json',
        (response) => {
          const updatedQueries = currentQueries.map((query) => {
            if (query.id === queryId) {
              const copyQuery = cloneDeep(query);
              update(
                copyQuery,
                `documents[${document.index}].data`,
                (prev) => ({
                  ...prev,
                  body: response,
                })
              );
              return copyQuery;
            }
            return query;
          });
          dispatch(updateSavedQueryList(updatedQueries));
          dispatch(updateDecodedDocumentList(id));
          dispatch(changeSelectedQueryDocument(document.id));
          dispatch(changeSelectedQueryDocumentIndex(document.index));
        }
      );
    }
    dispatch(changeSelectedQueryDocument(document.id));
    dispatch(changeSelectedQueryDocumentIndex(document.index));
  }
);
export const renameSavedQuery = createAsyncAction(
  `${PRE}/RENAME_SAVED_QUERY`,
  (id, name, onSuccess) => async (dispatch, getState) => {
    return apiRequest(
      dispatch,
      `${PRE}/RENAME_SAVED_QUERY`,
      'put',
      ENDPOINTS.SAVED_QUERY,
      {
        id,
        name,
      },
      'application/json',
      (response) => {
        const { updatedAt } = response;
        const queries = getState().textAnalytics.savedQueries.data;
        const updatedQueries = queries.map((query) => {
          if (query.id === id)
            return { ...query, name, updatedAt: new Date(updatedAt) };
          return query;
        });
        dispatch(updateSavedQueryList(updatedQueries));
        onSuccess();
      }
    );
  }
);
export const deleteSavedQuery = createAsyncAction(
  `${PRE}/DELETE_SAVED_QUERY`,
  (id, onSuccess) => async (dispatch, getState) => {
    return apiRequest(
      dispatch,
      `${PRE}/DELETE_SAVED_QUERY`,
      'delete',
      `${ENDPOINTS.SAVED_QUERY}/${id}`,
      null,
      'application/json',
      () => {
        const currentQueries = getState().textAnalytics.savedQueries.data;
        const updatedQueries = currentQueries.filter(
          (query) => query.id !== id
        );
        if (updatedQueries.length > 0)
          dispatch(changeSelectedSavedQuery(updatedQueries[0].id));
        else dispatch(changeSelectedSavedQuery(null));
        dispatch(updateSavedQueryList(updatedQueries));
        onSuccess();
      }
    );
  }
);
export const changeSelectedQueryDocument = createAction(
  `${PRE}/CHANGE_SELECTED_QUERY_DOCUMENT`
);
export const changeSelectedQueryDocumentIndex = createAction(
  `${PRE}/CHANGE_SELECTED_QUERY_DOCUMENT_INDEX`
);
const updateSavedQueryList = createAction(`${PRE}/UPDATE_SAVED_QUERIES_LIST`);
const updateDecompressedSavedQueryList = createAction(
  `${PRE}/UPDATE_DECOMPRESSED_SAVED_QUERIES_LIST`
);
const updateDecodedDocumentList = createAction(
  `${PRE}/UPDATE_DECODED_DOCUMENT_LIST`
);
export const getReportStats = createAsyncAction(
  `${PRE}/GET_REPORT_STATS`,
  (queryId) => (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_REPORT_STATS`,
      'get',
      ENDPOINTS.GET_REPORT_STATS,
      { id: queryId },
      'application/json'
    );
  }
);
export const getReportSentencesXlsx = createAsyncAction(
  `${PRE}/GET_REPORT_SENTENCES_XLSX`,
  (queryId) => (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_REPORT_SENTENCES_XLSX`,
      'get',
      ENDPOINTS.GET_REPORT_SENTENCES_XLSX,
      { id: queryId },
      'application/json'
    );
  }
);
export const getReportSentencesPdf = createAsyncAction(
  `${PRE}/GET_REPORT_SENTENCES_PDF`,
  (queryId) => (dispatch) => {
    return apiRequest(
      dispatch,
      `${PRE}/GET_REPORT_SENTENCES_PDF`,
      'get',
      ENDPOINTS.GET_REPORT_SENTENCES_PDF,
      { id: queryId },
      'application/json'
    );
  }
);
export const getDirectReportXlsx = createAsyncAction(
  `${PRE}/GET_DIRECT_REPORT_XLSX`,
  () => (dispatch, getState) => {
    const { keywords, filter, searchResult } = getState().textAnalytics;
    const data = {
      keywords: keywords.advanced,
      filters: {
        year: [filter.yearRange.from, filter.yearRange.to],
        sections: filter.documentSections.selected,
        companies: filter.companies.selected.map((company) => company.name),
      },
      documents: searchResult.documents,
    };
    return apiRequest(
      dispatch,
      `${PRE}/GET_DIRECT_REPORT_XLSX`,
      'post',
      ENDPOINTS.GET_DIRECT_REPORT_XLSX,
      data,
      'application/json'
    );
  }
);
export const getDirectReportPdf = createAsyncAction(
  `${PRE}/GET_DIRECT_REPORT_PDF`,
  () => (dispatch, getState) => {
    const { keywords, filter, searchResult } = getState().textAnalytics;
    const data = {
      keywords: keywords.advanced,
      filters: {
        year: [filter.yearRange.from, filter.yearRange.to],
        sections: filter.documentSections.selected,
        companies: filter.companies.selected.map((company) => company.name),
      },
      documents: searchResult.documents,
    };
    return apiRequest(
      dispatch,
      `${PRE}/GET_DIRECT_REPORT_PDF`,
      'post',
      ENDPOINTS.GET_DIRECT_REPORT_PDF,
      data,
      'application/json'
    );
  }
);

export const resetSavedQueryList = createAction(
  `${PRE}/RESET_SAVED_QUERIES_LIST`
);
export const resetDecompressedSavedQueryList = createAction(
  `${PRE}/RESET_DECOMPRESSED_SAVED_QUERIES_LIST`
);
export const resetDecodedDocumentList = createAction(
  `${PRE}/RESET_DECODED_DOCUMENT_LIST`
);
export const addNotesColumn = (columnName) => (dispatch, getState) => {
  const {
    data: currentQueries,
    selected: queryId,
    selectedDocumentIndex,
  } = getState().textAnalytics.savedQueries;
  let updatedQueries = [];
  let payload = {};
  currentQueries.forEach((query) => {
    if (query.id === queryId) {
      const copyQuery = cloneDeep(query);
      const index = selectedDocumentIndex;
      update(copyQuery, `documents[${index}].data`, (prev) => ({
        ...prev,
        headers: prev.headers.concat(columnName),
      }));
      const currentDocument = copyQuery.documents[index];
      payload = {
        id: query.id,
        document: currentDocument.id,
        data: currentDocument.data.body,
        tableHeaders: currentDocument.data.headers,
      };
      updatedQueries.push(copyQuery);
    } else updatedQueries.push(query);
  });
  dispatch(updateSavedQueryList(updatedQueries));
  dispatch(updateNotes(payload, updatedQueries));
};
export const renameNotesColumn = (columnIndex, columnName, callback) => async (
  dispatch,
  getState
) => {
  const {
    selected: queryId,
    data: currentQueries,
    selectedDocumentIndex,
  } = getState().textAnalytics.savedQueries;
  let updatedQueries = [];
  let payload = {};
  currentQueries.forEach((query) => {
    if (query.id === queryId) {
      const copyQuery = cloneDeep(query);
      const index = selectedDocumentIndex;
      set(
        copyQuery,
        `documents[${index}].data.headers[${columnIndex - 1}]`,
        columnName
      );
      const currentDocument = copyQuery.documents[index];
      payload = {
        id: query.id,
        document: currentDocument.id,
        data: currentDocument.data.body,
        tableHeaders: currentDocument.data.headers,
      };
      updatedQueries.push(copyQuery);
    } else updatedQueries.push(query);
  });
  dispatch(updateNotes(payload, updatedQueries, callback));
};
export const removeNotesColumn = (columnIndex, callback) => async (
  dispatch,
  getState
) => {
  const {
    selected: queryId,
    data: currentQueries,
    selectedDocumentIndex,
  } = getState().textAnalytics.savedQueries;
  let updatedQueries = [];
  let payload = {};
  currentQueries.forEach((query) => {
    if (query.id === queryId) {
      const copyQuery = cloneDeep(query);
      const index = selectedDocumentIndex;
      update(copyQuery, `documents[${index}].data]`, (data) => {
        return {
          ...data,
          headers: data.headers.filter((h, idx) => idx !== columnIndex - 1),
          // Perf. flag
          body: data.body.map((sentence) => {
            const copySentence = Object.assign({}, sentence);
            if (copySentence.notes) {
              delete copySentence.notes[`notes${columnIndex}`];
              // shift notes
              copySentence.notes = Object.keys(copySentence.notes).reduce(
                (acc, key, i) => {
                  return {
                    ...acc,
                    [`notes${i + 1}`]: copySentence.notes[key],
                  };
                },
                {}
              );
            }
            return copySentence;
          }),
        };
      });
      const currentDocument = copyQuery.documents[index];
      payload = {
        id: query.id,
        document: currentDocument.id,
        data: currentDocument.data.body,
        tableHeaders: currentDocument.data.headers,
      };
      updatedQueries.push(copyQuery);
    } else updatedQueries.push(query);
  });
  dispatch(updateNotes(payload, updatedQueries, callback));
};
export const saveNote = (
  sentenceIndex,
  columnIndex,
  text,
  callback = () => {}
) => (dispatch, getState) => {
  if (text === '') {
    // remove note if text is empty
    dispatch(removeNote(sentenceIndex, columnIndex, callback));
    return;
  }
  const {
    data: currentQueries,
    selected: queryId,
    selectedDocumentIndex,
  } = getState().textAnalytics.savedQueries;
  let updatedQueries = [];
  let payload = {};
  currentQueries.forEach((query) => {
    if (query.id === queryId) {
      const copyQuery = cloneDeep(query);
      const index = selectedDocumentIndex;
      set(
        copyQuery,
        `documents[${index}].data.body[${sentenceIndex}].notes.notes${columnIndex}`,
        text
      );
      const currentDocument = copyQuery.documents[index];
      payload = {
        id: query.id,
        document: currentDocument.id,
        data: currentDocument.data.body,
        tableHeaders: currentDocument.data.headers,
      };
      updatedQueries.push(copyQuery);
    } else updatedQueries.push(query);
  });
  dispatch(updateNotes(payload, updatedQueries, callback));
};
export const removeNote = (sentenceIndex, columnIndex, callback = () => {}) => (
  dispatch,
  getState
) => {
  const {
    data: currentQueries,
    selected: queryId,
    selectedDocumentIndex,
  } = getState().textAnalytics.savedQueries;
  let updatedQueries = [];
  let payload = {};
  currentQueries.forEach((query) => {
    if (query.id === queryId) {
      const copyQuery = cloneDeep(query);
      const index = selectedDocumentIndex;
      update(
        copyQuery,
        `documents[${index}].data.body[${sentenceIndex}].notes`,
        (notes) => {
          const copyNotes = Object.assign({}, notes);
          delete copyNotes[`notes${columnIndex}`];
          return copyNotes;
        }
      );
      const currentDocument = copyQuery.documents[index];
      payload = {
        id: query.id,
        document: currentDocument.id,
        data: currentDocument.data.body,
        tableHeaders: currentDocument.data.headers,
      };
      updatedQueries.push(copyQuery);
    } else updatedQueries.push(query);
  });
  dispatch(updateNotes(payload, updatedQueries, callback));
};

export const updateNotes = createAsyncAction(
  `${PRE}/UPDATE_NOTES`,
  (payload, updatedQueries, callback = () => {}) => (dispatch) => {
    apiRequest(
      dispatch,
      `${PRE}/UPDATE_NOTES`,
      'post',
      `${ENDPOINTS.SAVED_QUERY_NOTES}`,
      payload,
      'application/json',
      (response) => {
        const { updatedAt } = response;
        const updatedDateQueries = updatedQueries.map((query) => {
          if (query.id === payload.id)
            return { ...query, updatedAt: new Date(updatedAt) };
          return query;
        });
        dispatch(updateSavedQueryList(updatedDateQueries));
        callback();
      }
    );
  }
);

export const selectSearchResult = ({ textAnalytics }) =>
  textAnalytics.searchResult;
export const selectedResultSelector = (state) => {
  const { documents, selected } = selectSearchResult(state);
  return findById(documents, selected);
};
export const selectedQuerySelector = (state) => {
  const { data, selected } = state.textAnalytics.savedQueries;
  return findById(data, selected);
};
export const selectedDocumentQuerySelector = (state) => {
  const {
    savedQueries: { selectedDocument },
  } = state.textAnalytics;
  const selectedQuery = selectedQuerySelector(state);
  if (selectedQuery && selectedDocument) {
    return findById(selectedQuery.documents, selectedDocument);
  }
  return null;
};

export const SEARCH_MODE = Object.freeze({
  BASIC: 'basic',
  ADVANCED: 'advanced',
});

export const COMPANIES_GROUP = Object.freeze({
  NONE: 'None',
  JASICA: 'JASICA',
  SIC: 'SIC',
});

export const SEARCH_TOOLS = Object.freeze({
  HIGHLIGHT: 'highlight',
  STATISTIC: 'statistic',
});

export const VIEW_MODE = Object.freeze({
  LIST: 'list',
  TABLE: 'table',
});

const initialState = {
  searchMode: SEARCH_MODE.BASIC,
  keywords: {
    symbols: '',
    basic: '',
    advanced: [{ operator: '', keyword: '' }],
    items: [],
  },
  activeTools: [SEARCH_TOOLS.HIGHLIGHT, SEARCH_TOOLS.STATISTIC],
  viewMode: VIEW_MODE.LIST,
  searchResult: {
    documents: [],
    loaded: [],
    selected: null,
    tablePage: 1,
  },
  filter: {
    yearRange: {
      from: null,
      to: null,
      options: [],
    },
    esgTopic: {
      selected: [],
    },
    documentSections: {
      selected: [],
      selectedNames: {},
      options: [],
      filtered: [],
      isWhole: true,
    },
    companies: {
      groupBy: COMPANIES_GROUP.NONE,
      selected: [],
      options: [],
      filtered: [],
    },
    location: {
      tableMode: false,
    },
  },
  savedQueries: {
    data: [],
    selected: null,
    queryTablePage: 1,
    decompressed: [],
    decoded: [],
    selectedDocument: null,
    selectedDocumentIndex: null,
  },
};

const textAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case toggleSearchMode.type:
      return {
        ...state,
        searchMode:
          state.searchMode === SEARCH_MODE.BASIC
            ? SEARCH_MODE.ADVANCED
            : SEARCH_MODE.BASIC,
      };
    case storeSearchKeywords.type:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          ...action.payload,
        },
      };
    case toggleSearchTools.type:
      return {
        ...state,
        activeTools: state.activeTools.includes(action.payload)
          ? state.activeTools.filter((tool) => tool !== action.payload)
          : state.activeTools.concat(action.payload),
      };
    case changeResultViewMode.type:
      return {
        ...state,
        viewMode: action.payload,
      };
    case changeSelectedDocument.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          selected: action.payload,
        },
      };
    case actionSuccess(searchDocumentGroup.type):
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          documents: action.payload.result,
        },
      };
    case setSearchDetailLoaded.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          loaded: uniq(state.searchResult.loaded.concat(action.payload)),
        },
      };
    case resetSearchDetail.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          loaded: initialState.searchResult.loaded,
          selected: initialState.searchResult.selected,
        },
      };
    case setSearchDocumentDetail.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          documents: updateById(
            state.searchResult.documents,
            action.payload.id,
            {
              ...action.payload.data,
              pageData: action.payload.data.pages.reduce((acc, page) => {
                acc = { ...acc, [page]: null };
                return acc;
              }, {}),
              selectedPageIndex: 0,
            }
          ),
        },
      };
    case setSearchDocumentContent.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          documents: updateById(
            state.searchResult.documents,
            action.payload.documentId,
            {
              pageData: action.payload.data,
            }
          ),
        },
      };
    case changeSelectedPageIndex.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          documents: updateById(
            state.searchResult.documents,
            action.payload.id,
            {
              selectedPageIndex: action.payload.pageIndex,
            }
          ),
        },
      };
    case changeTablePage.type:
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          tablePage: action.payload,
        },
      };
    case changeYearRange.type:
      return {
        ...state,
        filter: {
          ...state.filter,
          yearRange: {
            ...state.filter.yearRange,
            ...action.payload,
          },
        },
      };
    case actionSuccess(getCompanyList.type):
      return {
        ...state,
        filter: {
          ...state.filter,
          companies: {
            ...state.filter.companies,
            options: action.payload,
          },
        },
      };
    case actionSuccess(getFilteredCompanyList.type):
      return {
        ...state,
        filter: {
          ...state.filter,
          companies: {
            ...state.filter.companies,
            filtered: action.payload,
          },
        },
      };
    case changeCompanyGroup.type:
      return {
        ...state,
        filter: {
          ...state.filter,
          companies: {
            ...state.filter.companies,
            groupBy: action.payload,
          },
        },
      };
    case changeSelectedCompanies.type:
      return {
        ...state,
        filter: {
          ...state.filter,
          companies: {
            ...state.filter.companies,
            selected: action.payload,
          },
        },
      };
    case actionSuccess(getSectionList.type):
      return {
        ...state,
        filter: {
          ...state.filter,
          documentSections: {
            ...state.filter.documentSections,
            options: action.payload,
          },
        },
      };
    case actionSuccess(getFilteredSectionList.type):
      return {
        ...state,
        filter: {
          ...state.filter,
          documentSections: {
            ...state.filter.documentSections,
            filtered: action.payload,
          },
        },
      };
    case changeSelectedSections.type:
      return {
        ...state,
        filter: {
          ...state.filter,
          documentSections: {
            ...state.filter.documentSections,
            selected: action.payload,
          },
        },
      };
    case changeSelectedSectionNames.type:
      return {
        ...state,
        filter: {
          ...state.filter,
          documentSections: {
            ...state.filter.documentSections,
            selectedNames: action.payload,
          },
        },
      };
    case toggleWholeSections.type:
      return {
        ...state,
        filter: {
          ...state.filter,
          documentSections: {
            ...state.filter.documentSections,
            isWhole: !state.filter.documentSections.isWhole,
          },
        },
      };
    case actionSuccess(getPeriodRange.type): {
      const years = action.payload.filter((year) => year);
      const start = years[0];
      const max = years[years.length - 1];
      const options = Array(max - start + 1)
        .fill(start)
        .map((year, index) => year + index);
      return {
        ...state,
        filter: {
          ...state.filter,
          yearRange: {
            ...state.filter.yearRange,
            from: state.filter.yearRange.from || start,
            to: state.filter.yearRange.to || max,
            options,
          },
        },
      };
    }
    case actionSuccess(getSavedQueries.type):
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          data: action.payload,
        },
      };
    case changeSelectedSavedQuery.type:
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          selected: action.payload,
        },
      };
    case updateSavedQueryList.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          data: action.payload,
        },
      };
    }
    case resetSearchFilter.type:
      return {
        ...state,
        filter: initialState.filter,
      };
    case updateDecompressedSavedQueryList.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          decompressed: state.savedQueries.decompressed.concat(action.payload),
        },
      };
    }
    case resetSavedQueryList.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          data: [],
        },
      };
    }
    case resetDecompressedSavedQueryList.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          decompressed: [],
        },
      };
    }
    case changeSelectedQueryDocument.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          selectedDocument: action.payload,
        },
      };
    }
    case changeSelectedQueryDocumentIndex.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          selectedDocumentIndex: action.payload,
        },
      };
    }
    case updateDecodedDocumentList.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          decoded: state.savedQueries.decoded.concat(action.payload),
        },
      };
    }
    case resetDecodedDocumentList.type: {
      return {
        ...state,
        savedQueries: {
          ...state.savedQueries,
          decoded: [],
        },
      };
    }
    default:
      return state;
  }
};

export default textAnalyticsReducer;
