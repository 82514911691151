import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import GoldCrown from 'assets/svg/crown_gold.svg';
import SilverCrown from 'assets/svg/crown_silver.svg';
import BronzeCrown from 'assets/svg/crown_bronze.svg';
import MoneyVariableDetail from 'assets/svg/money_variable_detail.svg';
import CustomTable from 'components/CustomTable';
import DialogEditVariable from '../VariableList/EditVariable';
import moment from 'moment';
import { Classes, Icon, Popover, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Skeleton from 'react-loading-skeleton';
import {
  getListVariableDetail,
  getVariableDetailInformation,
  changeVariableDetailListPage,
  changeVariableDetailListFilter,
  runVariableIndexing,
  getVariableIndexingStats,
  resetVariableDetailListFilter,
  resetVariableDetailListPage,
  togglePublishVariable,
  nullifyTransformationResult,
  clearInitializedDocuments,
} from '../../redux/features/variableManagement';
import { createLoadingSelector } from 'redux/api/loading';
import { getPeriodRange } from 'redux/features/textAnalytics';
import {
  COLOR_VARIANTS_ENABLED,
  COLOR_VARIANTS_DISABLED,
} from 'components/Button';
import clsx from 'clsx';
import { showToastError } from 'layouts/DashboardLayout';
import Button from 'components/Button';
import DialogInitDocument from './DialogInitDocument';
import { range } from 'lodash';
import Switch from 'components/Switch';
import { actionSuccess } from 'redux/utils/actionCreator';
import DialogPublishVariable from 'Pages/VariableList/DialogPublishVariable';
import DownloadVariable from 'Pages/VariableList/DownloadVariable';
import DialogReportDocument from './DialogReportDocument';
import ConfirmationDialog from 'components/ConfirmationDialog';
import DialogImportData from './DialogImportData';

const CLASSIFICATION_ICONS = {
  Gold: GoldCrown,
  Silver: SilverCrown,
  Bronze: BronzeCrown,
};

function VariableDetail() {
  const paramsUrl = useParams();
  const dispatch = useDispatch();
  const { pageData, filter, contents } = useSelector(
    (state) => state.variableManagement.listVariableDetail
  );
  const selected = useSelector((state) => state.variableManagement.selected);
  const maxYear = useSelector(
    ({ textAnalytics }) => textAnalytics.filter.yearRange.to
  );
  const { indexingStats, isPublished } = selected;
  const loadingDetail = useSelector(
    createLoadingSelector([getVariableDetailInformation.type])
  );
  const loadingDetailList = useSelector(
    createLoadingSelector([getListVariableDetail.type])
  );
  const loadingRunIndexing = useSelector(
    createLoadingSelector([runVariableIndexing.type])
  );
  const loadingNullify = useSelector(
    createLoadingSelector([nullifyTransformationResult.type])
  );
  const loadingClearDocument = useSelector(
    createLoadingSelector([clearInitializedDocuments.type])
  );
  const [documentToRunIndexing, setDocumentToRunIndexing] = useState([]);
  const [visibleDialogEditVariable, setVisibleDialogEditVariable] =
    useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    mode: 'normal',
    title: '',
    text: '',
    isLoading: false,
    confirm: '',
  });
  const [visibleDownloadDialog, setVisibleDownloadDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [visibleReportDialog, setVisibleReportDialog] = useState(false);
  const [visibleNullifyDialog, setVisibleNullifyDialog] = useState(false);
  const [visibleImportDialog, setVisibleImportDialog] = useState(false);
  const [visibleClearDialog, setVisibleClearDialog] = useState(false);
  const typeErrorCode = useMemo(
    () => ({
      1: 'No annotation existed',

      2: 'Annotation status not reviewed',

      3: 'Error on normalization',

      4: 'Error on transformation',
    }),
    []
  );
  const formatIdDocument = (document) => {
    return `${document.ticker}_AR_${document.year}`;
  };
  const onRunIndexing = (documentIds = []) => {
    setDocumentToRunIndexing((prev) => [...prev, ...documentIds]);
    dispatch(
      runVariableIndexing(
        selected.id,
        documentIds,
        () => {
          setDocumentToRunIndexing((prev) => [
            ...prev.filter((id) => !documentIds.includes(id)),
          ]);
          dispatch(getVariableDetailInformation(paramsUrl.id));
          dispatch(getVariableIndexingStats(paramsUrl.id));
          dispatch(
            getListVariableDetail(paramsUrl.id, {
              page: pageData.currentPageNumber,
              limit: pageData.limit,
              ...filter,
            })
          );
        },
        () => {
          setDocumentToRunIndexing((prev) => [
            ...prev.filter((id) => !documentIds.includes(id)),
          ]);
          showToastError('An error occurred');
        }
      )
    );
  };

  const [visibleDialogInitDocument, setVisibleDialogInitDocument] =
    useState(false);
  const columnsIndexingInformation = [
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
      customCell: ({ value }) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      Header: 'VALUE',
      accessor: 'value',
      customCell: ({ value }) => {
        return <div>{value || '-'}</div>;
      },
    },
  ];
  const dataIndexingInformation = [
    {
      description: 'Total Document',
      value: indexingStats.totalDocument,
    },
    {
      description: 'Successful Indexing',
      value: indexingStats.successful,
    },
    {
      description: 'Indexing Queue',
      value: indexingStats.queued,
    },
    {
      description: 'Indexing Failed',
      value: indexingStats.failed,
    },
  ];
  const columnsIndexingVariable = [
    {
      Header: 'TICKER',
      accessor: 'ticker',
      customCell: ({ value, row }) => {
        return (
          <a
            href={`https://annotation.esgi.ai/d/spectate/${row.original.taskId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:no-underline focus:outline-none"
          >
            {value || '-'}
          </a>
        );
      },
    },
    {
      Header: 'COMPANY NAME',
      accessor: 'companyName',
      customCell: ({ value }) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      Header: 'YEAR',
      accessor: 'year',
      customCell: ({ value }) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      Header: 'VALUE',
      accessor: 'value',
      customCell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      customCell: ({ value }) => (
        <span
          className={clsx(
            'inline-block text-xs rounded-full py-0.5 px-1.5',
            value === 'Not Indexed' && 'bg-grey5 text-grey70',
            value === 'Done' && 'bg-green5 text-green70',
            value === 'Running' && 'bg-blue5 text-blue70',
            value === 'Queue' && 'bg-orange5 text-orange70',
            value === 'Failed' && 'bg-red5 text-red70',
            value === 'Reported' && 'bg-red70 text-white',
            value === 'Revised' && 'bg-orange70 text-orange5'
          )}
        >
          {value}
        </span>
      ),
    },
    {
      Header: 'E.C',
      accessor: 'errorCode',
      alignment: 'center',
      customCell: ({ value }) => {
        return (
          <div className="text-center">
            {value ? (
              <Tooltip
                openOnTargetFocus={false}
                content={
                  <p className="text-xs text-white max-w-md">
                    {typeErrorCode[`${value}`]}
                  </p>
                }
                position={Position.BOTTOM}
                className="mt-px"
              >
                <p className="text-red50 cursor-pointer">{value}</p>
              </Tooltip>
            ) : (
              <p className="cursor-pointer">-</p>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      sortable: false,
      customCell: ({ row }) => {
        const isSelected = documentToRunIndexing.includes(
          formatIdDocument(row.original)
        );
        const isDisabled =
          isSelected || isPublished || row.original.injectedData;
        const isDisabledReport =
          ['Reported', 'Revised'].includes(row.original.status) || isPublished;
        const optionClass = (disableCriteria) =>
          `block w-full p-2 text-xs font-medium text-left rounded-sm transition-colors ${
            disableCriteria ? 'cursor-not-allowed' : 'cursor-pointer'
          } hover:bg-grey0 hover:no-underline`;
        return (
          <Popover
            position={Position.BOTTOM_RIGHT}
            minimal={true}
            targetTagName="div"
            className="w-min-content"
            targetClassName="inline-block"
            popoverClassName="shadow-none bg-transparent"
          >
            <div className="cursor-pointer inline-block">
              <Icon
                icon={IconNames.MORE}
                iconSize={20}
                className="fill-curent text-grey50 hover:text-grey50"
              />
            </div>
            <div className="w-40 p-2 shadow rounded overflow-y-auto bg-white">
              <ul>
                <button
                  onClick={() => {
                    if (!isSelected) {
                      onRunIndexing([formatIdDocument(row.original)]);
                    }
                  }}
                  disabled={isDisabled}
                  className={`${optionClass(isDisabled)} ${
                    isDisabled ? 'text-grey30' : 'text-grey60'
                  }`}
                >
                  Run Indexing
                </button>
                <button
                  onClick={() => {
                    const { id, taskId, ticker, year } = row.original;
                    const { name: variableName, description } = selected;
                    setSelectedDocument({
                      id,
                      taskId,
                      ticker,
                      year,
                      variableName,
                      description,
                    });
                    setVisibleReportDialog(true);
                  }}
                  disabled={isDisabledReport}
                  className={`${optionClass(isDisabledReport)} text-red50`}
                >
                  Report
                </button>
              </ul>
            </div>
          </Popover>
        );
      },
    },
  ];
  const handleChangePage = (page) => {
    dispatch(changeVariableDetailListPage({ currentPageNumber: page }));
  };
  const handleChangeTotalRow = (selected) => {
    dispatch(
      changeVariableDetailListPage({ currentPageNumber: 1, limit: selected })
    );
  };
  const handleSearch = (searchTerm) => {
    dispatch(changeVariableDetailListFilter({ q: searchTerm }));
  };
  const handleChangeFilter = (filter) => {
    dispatch(
      changeVariableDetailListFilter(
        Object.keys(filter).reduce((allFilter, v) => {
          return {
            ...allFilter,
            [v]: filter[v] && filter[v].value,
          };
        }, {})
      )
    );
  };

  const onEditVariable = () => {
    setVisibleDialogEditVariable(true);
  };
  const onCloseDialogEditVariable = () => {
    setVisibleDialogEditVariable(false);
  };
  const disableRunIndexing =
    indexingStats.queued > 0 ||
    loadingRunIndexing ||
    isPublished ||
    selected.injectedData;
  const disableInitDocument = isPublished || selected.injectedData;
  const getButtonClassName = (disabledIndicator) =>
    `${
      disabledIndicator
        ? COLOR_VARIANTS_DISABLED['filled']['success']
        : COLOR_VARIANTS_ENABLED['filled']['success']
    } ${
      disabledIndicator ? 'cursor-not-allowed' : ''
    } whitespace-nowrap h-10 px-6 rounded ml-3`;
  const ButtonAdditionalVariableTable = (props) => {
    return (
      <div className="flex items-center">
        <button
          className={clsx(
            getButtonClassName(disableInitDocument),
            props && props.selectedRows.length > 0 && 'hidden'
          )}
          disabled={disableInitDocument}
          onClick={() => setVisibleDialogInitDocument(true)}
        >
          Init Documents
        </button>
        <button
          className={clsx(getButtonClassName(disableRunIndexing))}
          onClick={() =>
            onRunIndexing(props.selectedRows.map(formatIdDocument))
          }
          disabled={disableRunIndexing}
        >
          Run Indexing
          {loadingRunIndexing && '...'}
        </button>
        <button
          className={clsx(
            getButtonClassName(isPublished),
            props && props.selectedRows.length > 0 && 'hidden'
          )}
          onClick={onEditVariable}
          disabled={isPublished}
        >
          Edit Variable
        </button>
      </div>
    );
  };
  const filterTable = [
    {
      name: 'year',
      options: maxYear
        ? range(2010, maxYear + 1).map((year) => ({ text: year, value: year }))
        : [],
    },
    {
      name: 'status',
      options: [
        {
          text: 'Not Indexed',
          value: 1,
        },
        {
          text: 'Queue',
          value: 2,
        },
        {
          text: 'Running',
          value: 3,
        },
        {
          text: 'Done',
          value: 5,
        },
        {
          text: 'Failed',
          value: 4,
        },
        {
          text: 'Reported',
          value: 6,
        },
        {
          text: 'Revised',
          value: 7,
        },
      ],
    },
  ];
  const getPrice = useCallback(
    (dataVariable) =>
      dataVariable.classification
        ? dataVariable.classification.price
        : dataVariable.price || '-',
    []
  );

  useEffect(() => {
    dispatch(getPeriodRange());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getVariableDetailInformation(paramsUrl.id));
    dispatch(getVariableIndexingStats(paramsUrl.id));
  }, [dispatch, paramsUrl.id]);

  useEffect(() => {
    dispatch(
      getListVariableDetail(paramsUrl.id, {
        page: pageData.currentPageNumber,
        limit: pageData.limit,
        ...filter,
      })
    );
  }, [
    dispatch,
    paramsUrl.id,
    filter,
    pageData.currentPageNumber,
    pageData.limit,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetVariableDetailListFilter());
      dispatch(resetVariableDetailListPage());
    };
  }, [dispatch]);

  return (
    <div className="w-full">
      <div className="flex justify-between mb-2.5">
        <div className="flex items-center">
          {loadingDetail ? (
            <Skeleton circle height={24} width={24} />
          ) : selected.classification ? (
            <img
              src={CLASSIFICATION_ICONS[selected.classification.name]}
              alt={'CompanyVariableDetail'}
              className="h-6 w-6"
            />
          ) : (
            '-'
          )}
          <div className={'text-grey70 font-medium text-xl ml-4'}>
            {loadingDetail ? <Skeleton width={80} /> : selected.name}
          </div>
        </div>
        <div className="flex items-center">
          <span className="block text-grey70 mr-3 -mt-px">
            Publish this variable
          </span>
          <Switch
            checked={isPublished}
            onChange={() => {
              setConfirmationDialog((prev) => ({
                ...prev,
                isOpen: true,
                title: `${!isPublished ? 'Publish' : 'Unpublish'} Variable`,
                text: `Are you sure you want to ${
                  !isPublished ? 'publish' : 'unpublish'
                } this variable?`,
                mode: !isPublished ? 'normal' : 'danger',
                isLoading: false,
                confirm: !isPublished ? 'Publish' : 'Unpublish',
              }));
            }}
          />
          <Popover
            minimal
            position={Position.BOTTOM_RIGHT}
            popoverClassName="shadow-none border-none"
          >
            <Button
              color="success"
              className="flex justify-between items-center ml-4"
            >
              <span className="mr-2">Action</span>
              <Icon icon={IconNames.CARET_DOWN} />
            </Button>
            <div className="flex flex-col bg-white shadow rounded p-2 mt-1">
              {useMemo(
                () => [
                  {
                    caption: 'Nullify',
                    action: () => setVisibleNullifyDialog(true),
                  },
                  {
                    caption: 'Download variable',
                    action: () => setVisibleDownloadDialog(true),
                  },
                  {
                    caption: 'Upload data',
                    action: () => setVisibleImportDialog(true),
                  },
                  {
                    caption: 'Clear document',
                    color: 'danger',
                    action: () => setVisibleClearDialog(true),
                  },
                ],
                []
              ).map((menu, index) => {
                const colorStyle =
                  menu.color === 'danger' ? 'text-red50' : 'text-grey60';
                return (
                  <button
                    key={`menu-${index}`}
                    className={`${Classes.POPOVER_DISMISS} w-full px-2 py-1 text-left ${colorStyle} rounded font-medium bg-white transition-colors hover:bg-grey0`}
                    onClick={menu.action}
                  >
                    {menu.caption}
                  </button>
                );
              })}
            </div>
          </Popover>
        </div>
      </div>
      <div className={'text-base mb-2.5'}>
        {loadingDetail ? <Skeleton width={350} /> : selected.description}
      </div>
      <div className="flex flex-row mb-6">
        <div className="flex flex-row mr-6 items-center">
          <Icon
            icon={IconNames.TAG}
            iconSize={14}
            className="fill-current text-blue50"
          />
          <p className="text-sm	 text-gray50 ml-2.5">
            {loadingDetail ? <Skeleton width={60} /> : selected.label}
          </p>
        </div>
        <div className="flex flex-row mr-6 items-center">
          <Icon
            icon={IconNames.PROJECTS}
            iconSize={14}
            className="fill-current text-blue50"
          />
          <p className="text-sm	 text-gray50 ml-2">
            {loadingDetail ? (
              <Skeleton width={70} />
            ) : selected.category ? (
              selected.category.name
            ) : (
              '-'
            )}
          </p>
        </div>
        <div className="flex flex-row mr-6">
          <img src={MoneyVariableDetail} alt={'MoneyVariableDetail'} />
          <p className="text-sm text-gray50 ml-2">
            {loadingDetail ? (
              <Skeleton width={60} />
            ) : getPrice(selected) ? (
              `${getPrice(selected)}/data`
            ) : (
              '-'
            )}
          </p>
        </div>
        <div className="flex flex-row mr-6">
          <p className="text-sm text-gray50">
            Published:{' '}
            {loadingDetail ? (
              <Skeleton width={75} />
            ) : isPublished ? (
              'Yes'
            ) : (
              'No'
            )}
          </p>
        </div>
        <div className="flex flex-row">
          <p className="text-sm text-gray50">
            Last Indexing:{' '}
            {loadingDetail ? (
              <Skeleton width={75} />
            ) : selected.last_indexing ? (
              moment(selected.last_indexing).format('DD MMMM YYYY')
            ) : (
              '-'
            )}
          </p>
        </div>
      </div>
      <div className={'text-base mb-2.5 font-medium'}>
        Information of indexing job
      </div>
      <DialogEditVariable
        isOpen={visibleDialogEditVariable}
        onClose={onCloseDialogEditVariable}
        onSuccess={() => dispatch(getVariableDetailInformation(paramsUrl.id))}
      />
      <CustomTable
        disablePagination={true}
        disableSearch={true}
        disabledCheckbox={true}
        isLoading={loadingDetail}
        dataTable={dataIndexingInformation}
        columnsTable={columnsIndexingInformation}
      />
      <CustomTable
        additionalButton={[ButtonAdditionalVariableTable]}
        page={pageData.currentPageNumber}
        totalRow={pageData.limit}
        optionTotalRow={[10, 25, 50, 100, 500, 1000, 3000]}
        totalPage={pageData.totalPages}
        onChangePage={handleChangePage}
        onChangeTotalRow={handleChangeTotalRow}
        onInputSearch={handleSearch}
        onChangeFilter={handleChangeFilter}
        isLoading={loadingDetailList}
        dataTable={contents}
        columnsTable={columnsIndexingVariable}
        filterTable={filterTable}
        initialFilterValue={filter}
        renderBulkActions={(_, selectedRowsOriginal) => {
          return (
            <button
              className={clsx(getButtonClassName(disableRunIndexing))}
              onClick={() =>
                onRunIndexing(selectedRowsOriginal.map(formatIdDocument))
              }
              disabled={disableRunIndexing}
            >
              Run Indexing
              {loadingRunIndexing && '...'}
            </button>
          );
        }}
      />
      <DialogInitDocument
        isOpen={visibleDialogInitDocument}
        onClose={() => setVisibleDialogInitDocument(false)}
        onSuccess={() =>
          dispatch(
            getListVariableDetail(paramsUrl.id, {
              page: pageData.currentPageNumber,
              limit: pageData.limit,
              ...filter,
            })
          )
        }
      />
      <DialogPublishVariable
        {...confirmationDialog}
        captions={{
          confirm: confirmationDialog.confirm,
        }}
        onConfirm={async () => {
          setConfirmationDialog((prev) => ({ ...prev, isLoading: true }));
          const { type } = await dispatch(
            togglePublishVariable(selected.id, {
              status: !isPublished,
            })
          );
          if (type === actionSuccess(togglePublishVariable.type)) {
            dispatch(getVariableDetailInformation(paramsUrl.id));
            setConfirmationDialog({
              isOpen: false,
              mode: 'normal',
              title: '',
              text: '',
              isLoading: false,
              captions: {
                confirm: '',
              },
            });
          }
        }}
        onCancel={() =>
          setConfirmationDialog({
            isOpen: false,
            mode: 'normal',
            title: '',
            text: '',
            isLoading: false,
            captions: {
              confirm: '',
            },
          })
        }
      >
        {confirmationDialog.text}
      </DialogPublishVariable>
      <DownloadVariable
        isOpen={visibleDownloadDialog}
        selectedVariables={[+paramsUrl.id]}
        onClose={() => setVisibleDownloadDialog(false)}
      />
      <DialogReportDocument
        isOpen={visibleReportDialog}
        variableId={paramsUrl.id}
        selectedDocument={selectedDocument}
        onSuccess={() => {
          dispatch(
            getListVariableDetail(paramsUrl.id, {
              page: pageData.currentPageNumber,
              limit: pageData.limit,
              ...filter,
            })
          );
          showToastError('Document reported successfully');
          setVisibleReportDialog(false);
          setSelectedDocument(null);
        }}
        onError={() => {
          showToastError('Failed to report the document');
        }}
        onCancel={() => {
          setVisibleReportDialog(false);
          setSelectedDocument(null);
        }}
      />
      <ConfirmationDialog
        isOpen={visibleNullifyDialog}
        title="Nullify"
        captions={{ confirm: 'Nullify' }}
        isLoading={loadingNullify}
        onCancel={() => setVisibleNullifyDialog(false)}
        onConfirm={() => {
          dispatch(
            nullifyTransformationResult(
              selected.id,
              () => {
                setVisibleNullifyDialog(false);
                dispatch(
                  getListVariableDetail(paramsUrl.id, {
                    page: pageData.currentPageNumber,
                    limit: pageData.limit,
                    ...filter,
                  })
                );
              },
              () => showToastError('An error occurred')
            )
          );
        }}
      >
        All documents that have a value in the Error Code (E.C != null) will be
        converted to N/A
      </ConfirmationDialog>
      <DialogImportData
        isOpen={visibleImportDialog}
        onOpen={() => setVisibleImportDialog(true)}
        onClose={() => setVisibleImportDialog(false)}
        onSuccess={() => {
          dispatch(getVariableDetailInformation(paramsUrl.id));
          dispatch(getVariableIndexingStats(paramsUrl.id));
          dispatch(
            getListVariableDetail(paramsUrl.id, {
              page: pageData.currentPageNumber,
              limit: pageData.limit,
              ...filter,
            })
          );
        }}
      />
      <ConfirmationDialog
        mode="danger"
        isOpen={visibleClearDialog}
        title="Clear document"
        captions={{ confirm: 'Clear' }}
        isLoading={loadingClearDocument}
        onCancel={() => setVisibleClearDialog(false)}
        onConfirm={() => {
          dispatch(
            clearInitializedDocuments(
              selected.id,
              () => {
                setVisibleClearDialog(false);
                dispatch(
                  getListVariableDetail(paramsUrl.id, {
                    page: pageData.currentPageNumber,
                    limit: pageData.limit,
                    ...filter,
                  })
                );
              },
              () => showToastError('An error occurred')
            )
          );
        }}
      >
        Are you sure you want to clear all the documents on the "{selected.name}
        " variable?
      </ConfirmationDialog>
    </div>
  );
}

export default VariableDetail;
