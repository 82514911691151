import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useForm } from 'react-hook-form';
import {
  storeSearchKeywords,
  searchDocumentGroup,
} from '../../redux/features/textAnalytics';

import { findByName, removeByName } from '../../utils/array';
import { convertFieldsToItems } from 'utils/searchKeyword';
const storageKey = 'searchSuggestions';

function BasicSearch(props) {
  const node = useRef();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, setValue, errors } = useForm({
    defaultValues: {
      keywords: '',
    },
  });
  const [isFocused, setIsFocused] = useState(false);
  const [searchSuggestionState, setSearchSuggestionState] = useState([]);
  const handleInputSearchClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsFocused(false);
  };
  const handleResetSearch = () => setValue('keywords', '');
  const onSubmit = (data) => {
    const { keywords } = data;
    if (keywords) {
      const keywordsArr = keywords
        .split(/ (?=(?:(?:[^"]*"){2})*[^"]*$)/g)
        .map((word) => word.trim())
        .filter((word) => word.length > 0);
      const keywordSymbols = keywordsArr.join('/');
      const basicKeywords = keywordsArr.join(', ');
      const advancedKeywords = [{ operator: '', keyword: basicKeywords }];
      const searchKeywords = {
        symbols: keywordSymbols,
        basic: basicKeywords,
        advanced: advancedKeywords,
        items: convertFieldsToItems(advancedKeywords),
      };
      let keywordsAsString = keywords;
      const existingKey = localStorage.getItem(storageKey);
      if (existingKey) {
        const existingKeyAsArray = existingKey.split(',');
        if (existingKeyAsArray.length > 0) {
          let mergeKey = [keywordsAsString, ...existingKeyAsArray];
          const uniqueKey = [...new Set(mergeKey)];
          localStorage.setItem(storageKey, uniqueKey);
        }
      } else {
        localStorage.setItem(storageKey, keywords);
      }
      dispatch(storeSearchKeywords(searchKeywords));
      dispatch(searchDocumentGroup());
    }
  };
  const validateKeywords = (value) => {
    if (value) {
      // split keywords by space but not in quoted words
      return (
        value.split(/ (?=(?:(?:[^"]*"){2})*[^"]*$)/).length <= 15 ||
        'The number of keywords you entered is more than 15'
      );
    }
    return false;
  };

  const watchKeyword = watch('keywords');
  const watchKeywordAsArray = watchKeyword.split(' ');
  const watchKeywordAsArrayLength = watchKeywordAsArray.length;
  let stringForSearch = watchKeyword;
  if (watchKeywordAsArrayLength > 1) {
    stringForSearch = watchKeywordAsArray[watchKeywordAsArrayLength - 1];
  }
  const searchSuggestionResult = findByName(
    searchSuggestionState,
    stringForSearch
  );
  const searchSuggestionResultExist = searchSuggestionResult.length > 0;

  useEffect(() => {
    document.addEventListener('mousedown', handleInputSearchClick);
    return () => {
      document.removeEventListener('mousedown', handleInputSearchClick);
    };
  }, []);

  useEffect(() => {
    let searchSuggestionKey = localStorage.getItem(storageKey);
    if (searchSuggestionKey) {
      const existingKeyAsArray = searchSuggestionKey.split(',');
      setSearchSuggestionState(existingKeyAsArray);
    }
  }, []);

  return (
    <div className="md:w-2/3 mt-40 mb-20">
      <header className="w-full text-center font-bold text-2xl text-grey100">
        All you need is here & classified
      </header>
      <p className="w-full text-center text-sm text-grey50 mt-2">
        Search the keywords and identify its page location within Indonesian
        listed companies’ reports
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full text-center my-8"
      >
        <div className="relative flex items-center w-full">
          <div className="w-full relative flex items-center" ref={node}>
            <div className="w-full relative">
              <div className="w-full flex items-center">
                <span className="absolute left-5 rounded-md">
                  <Icon
                    icon={IconNames.SEARCH}
                    iconSize={16}
                    className="fill-current text-grey30 -mt-1"
                  />
                </span>
                <input
                  type="text"
                  name="keywords"
                  ref={register({ validate: validateKeywords })}
                  placeholder="Input keywords"
                  onFocus={() => setIsFocused(true)}
                  autoComplete="off"
                  className={`h-12 w-full bg-white border ${
                    errors.keywords?.message
                      ? 'border-red50 focus:border-red50'
                      : 'border-grey30 focus:border-blue50'
                  } rounded-tl rounded-bl pl-12 focus:outline-none focus:ring-transparent`}
                />
              </div>
              {/* Autocomplete suggestion - UNDONE */}
              {searchSuggestionResultExist && isFocused && (
                <div className="w-full absolute mt-1 shadow bg-white rounded-sm max-h-44 overflow-y-auto">
                  <ul className="w-full py-2">
                    {searchSuggestionResult.map((keyword) => {
                      return (
                        <li
                          key={keyword}
                          className="flex items-center py-2 px-5 my-1 text-left text-base text-grey50 cursor-pointer hover:bg-grey0"
                        >
                          <div className="flex justify-between w-full">
                            <div
                              className="flex-grow flex items-center"
                              onClick={() => {
                                setValue('keywords', keyword);
                                setIsFocused(false);
                              }}
                            >
                              <Icon
                                icon={IconNames.HISTORY}
                                iconSize={16}
                                className="fill-current text-grey30 mr-3"
                              />
                              <span>{keyword}</span>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                const filter = removeByName(
                                  searchSuggestionState,
                                  keyword
                                );
                                setSearchSuggestionState(filter);
                                localStorage.setItem(storageKey, filter);
                                setIsFocused(false);
                                setValue('keywords', '');
                              }}
                              className="-mt-1"
                            >
                              <Icon
                                icon={IconNames.CROSS}
                                iconSize={14}
                                className="fill-current text-grey50"
                              />
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            {watch('keywords') && (
              <button
                type="button"
                onClick={handleResetSearch}
                className="absolute right-5 -mt-1"
              >
                <Icon
                  icon={IconNames.CROSS}
                  iconSize={14}
                  className="fill-current text-grey70"
                />
              </button>
            )}
          </div>
          <button className="bg-blue50 h-12 px-5 text-white font-bold text-xs tracking-wider rounded-tr rounded-br">
            Search
          </button>
        </div>
        {errors.keywords?.message && (
          <p className="text-red60 text-base mt-2">{errors.keywords.message}</p>
        )}
        <button
          type="button"
          onClick={() => {
            const keywords = watch('keywords');
            props.setChangeMode(keywords === '');
          }}
          className="text-blue50 font-medium text-base mt-2 mx-auto"
        >
          Advanced search
        </button>
      </form>
      <div className="w-full h-10 items-center hidden">
        <h4 className="text-base font-medium text-grey70 mr-3">
          Most Popular Search
        </h4>
        <span className="rounded-full p-2 border-2 border-grey20 text-sm text-grey50 mr-2">
          environment
        </span>
        <span className="rounded-full p-2 border-2 border-grey20 text-sm text-grey50 mr-2">
          social
        </span>
        <span className="rounded-full p-2 border-2 border-grey20 text-sm text-grey50">
          company agreements
        </span>
      </div>
    </div>
  );
}

export default BasicSearch;
