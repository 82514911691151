import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { replace } from 'connected-react-router';
import SplashScreen from '../../Pages/SplashScreen';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { compose } from 'redux';
import PATH_URL from '../../routers/path';

const locationHelper = locationHelperBuilder({});

const UserIsAuthenticatedDefaults = {
  authenticatingSelector: ({ firebase: { auth, isInitializing }, loading }) =>
    !auth.isLoaded ||
    isInitializing === true ||
    loading['LOGIN'] ||
    loading['REGISTER'] ||
    loading['LOGOUT'],
  authenticatedSelector: ({ firebase: { auth }, loading }) =>
    auth.isLoaded &&
    !auth.isEmpty &&
    !loading['LOGIN'] &&
    !loading['REGISTER'] &&
    !loading['LOGOUT'],
  wrapperDisplayName: 'UserIsAuthenticated',
};

export const UserIsAuthenticated = connectedAuthWrapper(
  UserIsAuthenticatedDefaults
);

export const UserIsAuthenticatedRedir = connectedRouterRedirect({
  ...UserIsAuthenticatedDefaults,
  AuthenticatingComponent: SplashScreen,
  redirectPath: '/a/login',
});

const UserIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatingSelector: ({ firebase: { auth } }) => !auth.isLoaded,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty,
  wrapperDisplayName: 'UserIsNotAuthenticated',
};

export const UserIsNotAuthenticated = connectedAuthWrapper(
  UserIsNotAuthenticatedDefaults
);

export const UserIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...UserIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/u/',
  allowRedirectBack: false,
});

export const VerifiedEmailUserRedir = connectedRouterRedirect({
  wrapperDisplayName: 'VerifiedEmailUser',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.HOME;
  },
  authenticatedSelector: ({ firebase: { auth }, auth: userProfile }) =>
    userProfile.emailVerified,
  redirectAction: replace,
});

export const UnverifiedEmailUserRedir = connectedRouterRedirect({
  wrapperDisplayName: 'UnverifiedEmailUser',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.HOME;
  },
  authenticatedSelector: ({ firebase: { auth }, auth: userProfile }) =>
    !userProfile.emailVerified,
  redirectAction: replace,
});

export const UserIsAdminRedir = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAdmin',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    // const { blockStatus, emailVerified } = state.auth;
    // if (!emailVerified) return PATH_URL.UNVERIFIED_EMAIL;
    // if (blockStatus) return PATH_URL.WAITING_APPROVAL;
    return locationHelper.getRedirectQueryParam(ownProps) || '/';
  },
  authenticatedSelector: ({ firebase: { auth }, auth: userProfile }) =>
    userProfile.role === 'admin',
  redirectAction: replace,
});

export const UserApprovedRedir = connectedRouterRedirect({
  wrapperDisplayName: 'UserApproved',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return (
      locationHelper.getRedirectQueryParam(ownProps) ||
      PATH_URL.WAITING_APPROVAL
    );
  },
  authenticatedSelector: ({ firebase: { auth }, auth: userProfile }) =>
    !userProfile.blockStatus,
  redirectAction: replace,
});

export const UserWaitingApprovalRedir = connectedRouterRedirect({
  wrapperDisplayName: 'UserWaitingApproval',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.HOME;
  },
  authenticatedSelector: ({ firebase: { auth }, auth: userProfile }) =>
    userProfile.blockStatus,
  redirectAction: replace,
});

export const UserSetupProfileRedir = connectedRouterRedirect({
  wrapperDisplayName: 'UserSetupProfile',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.HOME;
  },
  authenticatedSelector: ({ auth }) => {
    const currentItem = localStorage.getItem('personalSetup') || [];
    return currentItem.length > 0
      ? currentItem.indexOf(auth.email) === -1
      : true;
  },
  redirectAction: replace,
});

export const UserSubscribeTextAnalyticsRedir = connectedRouterRedirect({
  wrapperDisplayName: 'UserSubscribeTextAnalytic',
  AuthenticatingComponent: SplashScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_URL.HOME;
  },
  authenticatedSelector: ({ auth }) => {
    const userData = auth;
    const subsActive = userData.usersubscriptions.filter(
      (data) =>
        data.subscription.brandCode === 'TA' &&
        new Date().getTime() < new Date(data.expireAt).getTime() &&
        data.status
    );
    return subsActive.length > 0;
  },
  redirectAction: replace,
});

export const UnverifiedEmailUser = compose(
  UserIsAuthenticatedRedir,
  UnverifiedEmailUserRedir
);

export const UserIsApproved = compose(
  UserIsAuthenticatedRedir,
  VerifiedEmailUserRedir,
  UserApprovedRedir
);

export const UserIsAdmin = compose(UserIsAuthenticatedRedir, UserIsAdminRedir);

export const UserWaitingApproval = compose(
  UserIsAuthenticatedRedir,
  UserWaitingApprovalRedir
);

export const UserSetupProfile = compose(
  UserIsAuthenticatedRedir,
  UserSetupProfileRedir
);

export const UserSubscribeTextAnalytics = compose(
  UserIsAuthenticatedRedir,
  VerifiedEmailUserRedir,
  UserSubscribeTextAnalyticsRedir
);
