import React from 'react';

function IconDatasets(props) {
  return (
    <svg
      width={80}
      height={80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.0007 30V43.3334C55.0007 46.3034 52.8007 48.4267 50.514 49.66C48.1207 50.9467 44.9907 51.6667 41.6673 51.6667C38.344 51.6667 35.214 50.9467 32.8207 49.66C30.534 48.4267 28.334 46.3 28.334 43.3334V30C28.334 27.03 30.534 24.9067 32.8207 23.6734C35.214 22.3867 38.344 21.6667 41.6673 21.6667C44.9907 21.6667 48.1207 22.3867 50.514 23.6734C52.8007 24.9067 55.0007 27.0334 55.0007 30ZM35.1907 28.0767C33.5607 28.9534 33.334 29.75 33.334 30C33.334 30.25 33.5607 31.0467 35.1907 31.9234C36.724 32.7467 39.0073 33.3334 41.6673 33.3334C44.3273 33.3334 46.614 32.7467 48.144 31.9234C49.774 31.0467 50.0007 30.25 50.0007 30C50.0007 29.75 49.774 28.9534 48.144 28.0767C46.6073 27.2534 44.3273 26.6667 41.6673 26.6667C39.0073 26.6667 36.7207 27.2534 35.1907 28.0767ZM50.0007 36.5867C47.684 37.71 44.7573 38.3334 41.6673 38.3334C38.5773 38.3334 35.6507 37.71 33.334 36.5867V43.3334C33.334 43.5834 33.5607 44.3767 35.1907 45.2567C36.724 46.08 39.0073 46.6667 41.6673 46.6667C44.3273 46.6667 46.614 46.08 48.144 45.2567C49.774 44.3767 50.0007 43.5834 50.0007 43.3334V36.5867Z"
        fill="current"
      />
      <path
        d="M13.334 15C13.334 12.7899 14.212 10.6703 15.7748 9.10746C17.3376 7.54466 19.4572 6.66669 21.6673 6.66669H60.0007C61.095 6.66669 62.1786 6.88224 63.1897 7.30102C64.2007 7.71981 65.1194 8.33364 65.8932 9.10746C66.667 9.88129 67.2809 10.7999 67.6996 11.811C68.1184 12.822 68.334 13.9057 68.334 15V62.5C68.334 63.1631 68.0706 63.799 67.6018 64.2678C67.1329 64.7366 66.497 65 65.834 65H18.334C18.334 65.8841 18.6852 66.7319 19.3103 67.357C19.9354 67.9822 20.7833 68.3334 21.6673 68.3334H65.834C66.497 68.3334 67.1329 68.5967 67.6018 69.0656C68.0706 69.5344 68.334 70.1703 68.334 70.8334C68.334 71.4964 68.0706 72.1323 67.6018 72.6011C67.1329 73.07 66.497 73.3334 65.834 73.3334H21.6673C19.4572 73.3334 17.3376 72.4554 15.7748 70.8926C14.212 69.3298 13.334 67.2102 13.334 65V15ZM18.334 60H63.334V15C63.334 14.116 62.9828 13.2681 62.3577 12.643C61.7326 12.0179 60.8847 11.6667 60.0007 11.6667H21.6673C20.7833 11.6667 19.9354 12.0179 19.3103 12.643C18.6852 13.2681 18.334 14.116 18.334 15V60Z"
        fill="current"
      />
    </svg>
  );
}

export default IconDatasets;
