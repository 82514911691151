import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useDropzone } from 'react-dropzone';
import { debounce } from 'lodash';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import Checkbox from './Checkbox';
import Spinner from 'components/Spinner';
import {
  COMPANIES_GROUP,
  changeCompanyGroup,
  getCompanyList,
  getFilteredCompanyList,
  changeSelectedCompanies,
} from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';
import clsx from 'clsx';

function CompaniesDialog(props) {
  const dispatch = useDispatch();
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: '.txt',
    noClick: true,
    noKeyboard: true,
    maxSize: 1024,
    multiple: false,
    onDropAccepted: (files) => {
      if (files.length > 0 && !loading) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target.result;
          const tickers = data.split('\n').filter((ticker) => ticker !== '');
          if (tickers.length) {
            const companyObj = companiesFilter.options.reduce(
              (acc, company) => {
                if (companiesFilter.groupBy === COMPANIES_GROUP.NONE)
                  acc = { ...acc, [company.ticker]: company };
                else
                  acc = {
                    ...acc,
                    ...company.data.reduce((wrap, c) => {
                      wrap = { ...wrap, [c.ticker]: c };
                      return wrap;
                    }, {}),
                  };
                return acc;
              },
              {}
            );
            const companyData = tickers
              .map((ticker) => companyObj[ticker])
              .filter((ticker) => ticker);
            setSelected(companyData);
          }
        };
        reader.readAsText(files[0]);
      }
    },
  });
  const { companies: companiesFilter } = useSelector(
    ({ textAnalytics }) => textAnalytics.filter
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(companiesFilter.selected);
  const [collapsedGroups, setCollapsedGroups] = useState([]);
  const loading = useSelector(
    createLoadingSelector([getCompanyList.type, getFilteredCompanyList.type])
  );
  const handleChangeGroup = (groupName) => {
    dispatch(changeCompanyGroup(groupName));
    dispatch(getCompanyList(groupName));
    if (searchTerm) dispatch(getFilteredCompanyList(searchTerm));
  };
  const handleCheckCompanies = (company, mode) => {
    let newSelected = [];
    if (mode === 'uncheck') {
      newSelected = selected.filter((selected) => selected.id !== company.id);
    } else newSelected = selected.concat(company);
    setSelected(newSelected);
  };
  const handleCheckGroup = (code, companies) => {
    const updateCompanies = selected
      .filter((company) => company[companiesFilter.groupBy] !== code)
      .concat(companies);
    setSelected(updateCompanies);
  };
  const handleUncheckGroup = (code) => {
    const updateCompanies = selected.filter(
      (company) => company[companiesFilter.groupBy] !== code
    );
    setSelected(updateCompanies);
  };
  const handleReset = () => {
    setSelected([]);
  };
  const applySearch = (searchTerm) => {
    dispatch(getFilteredCompanyList(searchTerm));
  };
  const handleSearch = useRef(debounce(applySearch, 300)).current;
  const handleChange = (e) => {
    const { value: searchInput } = e.target;
    setSearchTerm(searchInput);
    if (searchInput) handleSearch(searchInput);
  };
  const handleApply = () => {
    dispatch(changeSelectedCompanies(selected));
    props.onApply();
    props.onClose();
  };
  const renderCompanies = () => {
    const data = searchTerm
      ? companiesFilter.filtered
      : companiesFilter.options;
    if (data.length > 0)
      return (
        <div className="flex flex-wrap">
          {data.map((company) => {
            const checked = selected.find(
              (selectedCompany) => selectedCompany.ticker === company.ticker
            );
            return (
              <div key={company.id} className="w-1/3 mb-2">
                <Checkbox
                  label={company.ticker}
                  onChange={() =>
                    handleCheckCompanies(company, checked ? 'uncheck' : 'check')
                  }
                  checked={checked ? true : false}
                />
              </div>
            );
          })}
        </div>
      );
    else {
      return <span>No companies found</span>;
    }
  };
  const toggleCollapseGroup = (id) => {
    if (collapsedGroups.includes(id))
      setCollapsedGroups((prev) => prev.filter((prevId) => prevId !== id));
    else setCollapsedGroups((prev) => prev.concat(id));
  };
  const renderGroupedCompanies = () => {
    const data = searchTerm
      ? companiesFilter.filtered
      : companiesFilter.options;
    if (data.length > 0) {
      return data.map((list) => {
        const countSelected = list.data
          ? list.data.reduce((acc, company) => {
              if (selected.find((selected) => selected.id === company.id))
                acc += 1;
              return acc;
            }, 0)
          : 0;
        const groupChecked = list.data
          ? countSelected === list.data.length
          : false;
        const collapseId = list.groups
          ? `${companiesFilter.groupBy}-${list.groups.code}`
          : '';
        const isCollapsed = collapsedGroups.includes(collapseId);
        return (
          list.groups && (
            <div key={list.groups.code}>
              <div className="flex items-center mb-2">
                <Checkbox
                  label=""
                  checked={groupChecked}
                  onChange={() => {
                    if (!groupChecked)
                      handleCheckGroup(list.groups.code, list.data);
                    else handleUncheckGroup(list.groups.code);
                  }}
                />
                <div
                  onClick={() => toggleCollapseGroup(collapseId)}
                  className="w-full flex justify-between items-center cursor-pointer"
                >
                  <h5 className="text-sm text-grey100 font-medium -ml-1">
                    {companiesFilter.groupBy} {list.groups.code}{' '}
                    <span className="text-grey50 font-normal">
                      - {list.groups.classification}
                    </span>
                  </h5>
                  <Icon
                    icon={IconNames.CHEVRON_DOWN}
                    iconSize={24}
                    className={clsx(
                      'fill-current text-grey70 transition-transform transform duration-200',
                      isCollapsed ? 'rotate-180' : 'rotate-0'
                    )}
                  />
                </div>
              </div>
              <Collapse isOpen={isCollapsed}>
                <div className="flex flex-wrap">
                  {list.data &&
                    list.data.map((company) => {
                      const checked = selected.find(
                        (selectedCompany) =>
                          selectedCompany.ticker === company.ticker
                      );
                      return (
                        <div key={company.id} className="w-1/3 mb-2">
                          <Checkbox
                            label={company.ticker}
                            onChange={() => {
                              handleCheckCompanies(
                                company,
                                checked ? 'uncheck' : 'check'
                              );
                            }}
                            checked={checked ? true : false}
                          />
                        </div>
                      );
                    })}
                </div>
              </Collapse>
            </div>
          )
        );
      });
    } else {
      return <span>No companies found</span>;
    }
  };

  useEffect(() => {
    setSelected(companiesFilter.selected);
  }, [companiesFilter.selected]);

  return (
    <Dialog
      header="More Companies"
      isOpen={props.isOpen}
      onClose={props.onClose}
      dismissButton
    >
      <div className="flex flex-col h-full w-full px-4">
        <div className="pb-4 flex justify-between items-center">
          <div className="relative flex items-center w-2/3 flex-grow mr-2">
            <span className="absolute left-0 ml-2 rounded-md">
              <Icon
                icon={IconNames.SEARCH}
                iconSize={16}
                className="fill-current text-grey30"
              />
            </span>
            <input
              type="text"
              name="search"
              placeholder="Search companies"
              autoComplete="off"
              value={searchTerm}
              onChange={handleChange}
              className="h-10 w-full bg-grey0 border-0 rounded-md pl-10 focus:outline-none focus:ring-transparent"
            />
          </div>
          <div className="w-1/3 px-1 py-2 flex justify-end items-center">
            <div className="flex items-center -mr-2">
              <label className="text-xs text-grey50 mr-1">Group by</label>
              <select
                className="border-0 bg-transparent py-0 focus:border-transparent"
                onChange={({ target }) =>
                  !loading && handleChangeGroup(target.value)
                }
                value={companiesFilter.groupBy}
              >
                {Object.values(COMPANIES_GROUP).map((groupBy) => (
                  <option key={groupBy} value={groupBy}>
                    {groupBy}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="pt-1 pb-4 h-36 flex justify-end items-center">
          <div className="h-full w-full flex flex-col">
            <h3 className="text-sm text-grey50 font-bold tracking-wide">
              UPLOAD FILE
            </h3>
            <div
              {...getRootProps({
                className:
                  'flex-grow flex justify-center items-center my-1 rounded border-2 border-dashed border-blue30 focus:outline-none',
              })}
            >
              <input {...getInputProps()} />
              <div className="flex items-center">
                <Icon
                  icon={IconNames.CIRCLE_ARROW_UP}
                  iconSize={14}
                  className="fill-current text-grey50 mr-1"
                />
                <p className="text-sm text-grey50">
                  Drag and Drop file here or
                  <span
                    onClick={open}
                    className="text-blue60 underline ml-1 cursor-pointer"
                  >
                    choose file
                  </span>
                </p>
              </div>
            </div>
            <div className="w-full flex justify-between tracking-wide">
              <span className="text-xs text-grey40">
                Upload TXT file containing company codes, one code per line
              </span>
              <span className="text-xs text-grey40">Maximum size: 1KB</span>
            </div>
          </div>
        </div>
        <div className="h-48 w-full pb-4 pt-1 overflow-y-auto">
          {loading && (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          )}
          {!loading &&
            companiesFilter.groupBy === COMPANIES_GROUP.NONE &&
            renderCompanies()}
          {!loading &&
            companiesFilter.groupBy !== COMPANIES_GROUP.NONE &&
            renderGroupedCompanies()}
        </div>
        <div className="pt-4 flex justify-end items-center">
          <Button view="outlined" color="danger" onClick={handleReset}>
            Reset
          </Button>
          <Button color="primary" onClick={handleApply} className="ml-2">
            Apply
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default CompaniesDialog;
