import { Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export default function TooltipInfo(props) {
  return (
    <Tooltip
      openOnTargetFocus={false}
      minimal={true}
      content={
        <div>
          <p className="text-xs text-white">{props.text}</p>
        </div>
      }
      position={Position.TOP}
    >
      <Icon
        className="ml-2 mb-0.5 cursor-pointer"
        iconSize={12}
        icon={IconNames.INFO_SIGN}
      />
    </Tooltip>
  );
}
