import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'redux/api/loading';
import { createErrorMessageSelector } from 'redux/api/error';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Tab from 'components/Tab/index';
import Error from 'components/Error/index';
import TabPanel from 'components/Tab/Panel/index';
import UserList from './List';
import UserApproval from './Approval';
import UserSubscription from './Subscription';
import String from 'utils/String';
import {
  getUserSummary,
  getListUser,
  blockUser,
  changeUserRole,
  getListUserSubscriber,
  getListUserApproval,
  setSelectedTab,
  changePageDataUsers,
  changeFilterUsers,
  changePageDataSubscribers,
  changeFilterSubscribers,
  changePageDataApprovals,
  changeFilterApprovals,
} from '../../redux/features/userManagement';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PATH_URL from 'routers/path';
import { IconNames } from '@blueprintjs/icons';

const RenderCard = ({
  total,
  footerHeaderText,
  footerBodyText,
  icon,
  iconColor,
  loadingUserSummary,
}) => {
  return (
    <div className={'bg-white shadow rounded p-4 mr-4 flex-grow'}>
      {!loadingUserSummary ? (
        <>
          <div className={'flex justify-between'}>
            <div className={'text-2xl font-medium my-2'}>{total}</div>
            <div
              className={clsx(
                'h-10 w-10 rounded-full flex items-center justify-center',
                iconColor
              )}
            >
              <Icon
                iconSize={20}
                icon={icon}
                className="fill-current text-white"
              />
            </div>
          </div>
          <div className={'text-sm text-gray-700'}>{footerHeaderText}</div>
          <div>
            <span className={'text-sm text-green-500'}>{footerBodyText}</span>{' '}
            <span className={'text-gray-500 text-sm'}> vs Last Month</span>
          </div>
        </>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
};

const RenderDialogBlockUser = ({
  visible,
  onCancel,
  onConfirm,
  loading,
  onClose,
}) => {
  return (
    <ConfirmationDialog
      mode="danger"
      title="Block User"
      isOpen={visible}
      onClose={onClose}
      isLoading={loading}
      onConfirm={onConfirm}
      onCancel={onCancel}
      captions={{ confirm: 'Block' }}
    >
      Are you sure want to block this user ?
    </ConfirmationDialog>
  );
};

const RenderDialogChangeRole = ({
  visible,
  title,
  currentRole,
  onConfirm,
  onCancel,
  onSelect,
  loading,
  onClose,
}) => {
  const roles = [
    {
      name: 'Admin',
      text: 'Admin can adjust permission settings, billing information and update payment methods',
    },
    {
      name: 'User',
      text: 'A user only has access to the apps, but not the management platform',
    },
  ];

  return (
    <Dialog header={title} size="small" isOpen={visible} onClose={onClose}>
      <div className="px-6">
        <div className="w-full mb-5">
          {roles.map((role, index) => {
            return (
              <div
                key={index}
                className={clsx(
                  'cursor-pointer bg-white shadow rounded mb-4 py-4 pl-4 pr-1',
                  currentRole === role.name.toLowerCase() &&
                    'border border-blue50'
                )}
                onClick={() => {
                  onSelect(role.name.toLowerCase());
                }}
              >
                <p className="font-medium mb-1">{role.name}</p>
                <p className="text-sm text-gray-400">{role.text}</p>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-end">
          <Button
            view="outlined"
            color="danger"
            onClick={onCancel}
            disabled={loading}
            className="mr-4"
          >
            Cancel
          </Button>
          <Button color="primary" onClick={onConfirm} isLoading={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

function UserListPage() {
  const loadingListUser = useSelector(
    createLoadingSelector([getListUser.type])
  );
  const loadingUserSummary = useSelector(
    createLoadingSelector([getUserSummary.type])
  );

  const loadingUserSubscription = useSelector(
    createLoadingSelector([getListUserSubscriber.type])
  );

  const loadingStudentApproval = useSelector(
    createLoadingSelector([getListUserApproval.type])
  );

  const loadingBlockUser = useSelector(createLoadingSelector([blockUser.type]));
  const loadingChangeUserRole = useSelector(
    createLoadingSelector([changeUserRole.type])
  );
  const errorListUser = useSelector(
    createErrorMessageSelector([getListUser.type])
  );
  const errorUserSummary = useSelector(
    createErrorMessageSelector([getUserSummary.type])
  );
  const {
    list: users,
    filter: filterUsers,
    pageData: pageDataUsers,
    sortBy: sortUsers,
  } = useSelector((state) => state.userManagement.users);
  const {
    list: subscribers,
    filter: filterSubscribers,
    pageData: pageDataSubscribers,
    sortBy: sortSubscribers,
  } = useSelector((state) => state.userManagement.subscribers);
  const {
    list: approvals,
    filter: filterApprovals,
    pageData: pageDataApprovals,
    sortBy: sortApprovals,
  } = useSelector((state) => state.userManagement.approvals);
  const listUserSummary = useSelector(
    (state) => state.userManagement.listUserSummary
  );
  const dispatch = useDispatch();
  const location = useHistory();
  const selectedTab = useSelector(
    ({ userManagement }) => userManagement.selectedTab
  );
  const selectTab = (tabIndex) => dispatch(setSelectedTab(tabIndex));
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [visibleDropdownIndex, setVisibleDropdownIndex] = useState(null);
  const [showChangeRoleDialog, setShowChangeRoleDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [showBlockUserDialog, setBlockUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: '',
  });

  useEffect(() => {
    dispatch(getUserSummary());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab === 1) {
      dispatch(getListUserSubscriber());
    }
  }, [
    dispatch,
    selectedTab,
    filterSubscribers.q,
    pageDataSubscribers.currentPageNumber,
    pageDataSubscribers.limit,
    sortSubscribers.sort,
    sortSubscribers.order,
  ]);

  useEffect(() => {
    if (selectedTab === 2) {
      dispatch(getListUserApproval());
    }
  }, [
    dispatch,
    selectedTab,
    filterApprovals.q,
    pageDataApprovals.currentPageNumber,
    pageDataApprovals.limit,
    sortApprovals.sort,
    sortApprovals.order,
  ]);

  useEffect(() => {
    if (selectedTab === 0) {
      dispatch(getListUser());
    }
  }, [
    selectedTab,
    dispatch,
    filterUsers,
    pageDataUsers.currentPageNumber,
    pageDataUsers.limit,
    sortUsers.sort,
    sortUsers.order,
  ]);

  const optionsTab = [
    {
      label: 'User List',
    },
    {
      label: 'User Subscription',
    },
    {
      label: 'Student Approval',
    },
  ];

  const filterUserData = users.map((value) => {
    const {
      id,
      email,
      createdAt,
      photoUrl,
      role,
      lastSignin,
      type,
      blockStatus,
      profile: { fullName },
    } = value;
    const formatCreatedAt = createdAt ? new Date(createdAt) : new Date(0);
    const formatLastSignIn = lastSignin ? new Date(lastSignin) : new Date(0);
    const formatRole = String.capitalizeFirstLetter(role);
    const userArray = [fullName, email, photoUrl];
    const userAsString = userArray.join(',');

    let whichStatus = 'Active';
    if (blockStatus === true) {
      whichStatus = 'Blocked';
    }
    return {
      user: userAsString,
      createdAt: createdAt ? formatCreatedAt : '-',
      lastSignin: formatLastSignIn,
      role: formatRole,
      type,
      whichStatus,
      photoUrl,
      id,
    };
  });

  const filterUserSubscription = subscribers.map((value) => {
    const {
      photoUrl,
      usersubscriptions,
      profile: { fullName },
      email,
    } = value;
    const userArray = [fullName, email, photoUrl];
    const userAsString = userArray.join(',');
    const latestSubs = usersubscriptions[0];
    const formatStartPeriod = latestSubs.startAt
      ? new Date(latestSubs.startAt)
      : new Date(0);
    const formatEndPeriod = latestSubs.expireAt
      ? new Date(latestSubs.expireAt)
      : new Date(0);
    const remainingTime = moment(latestSubs.expireAt).format('MM-DD-YYYY');
    const remainingTimeFromNow = moment(remainingTime).fromNow();
    return {
      user: userAsString,
      startPeriod: formatStartPeriod,
      endPeriod: formatEndPeriod,
      fullName,
      email,
      remainingTime: remainingTimeFromNow,
    };
  });

  const filterUserApproval = approvals.map((value) => {
    const {
      id,
      photoUrl,
      profile: { fullName, institution, statusApproval, submissionDate },
      email,
    } = value;
    const userArray = [fullName, email, photoUrl];
    const submission = submissionDate ? new Date(submissionDate) : new Date(0);
    const userAsString = userArray.join(',');
    return {
      id: id,
      user: userAsString,
      status: statusApproval,
      institution: institution,
      submission: submission,
    };
  });

  const listUserSummaryExist = Object.keys(listUserSummary).length > 0;
  const general = listUserSummaryExist ? listUserSummary?.totalTypeGeneral : 0;
  const student = listUserSummaryExist ? listUserSummary?.totalTypeStudent : 0;
  const unverified = listUserSummaryExist
    ? listUserSummary.totalUsersUnVerified
    : 0;
  const active = listUserSummaryExist ? listUserSummary.totalUsersVerified : 0;
  const totalUsers = listUserSummaryExist ? listUserSummary.totalUsers : 0;

  if (errorListUser || errorUserSummary) {
    return <Error />;
  }

  return (
    <>
      <RenderDialogBlockUser
        onClose={() => {
          setBlockUserDialog(false);
        }}
        loading={loadingBlockUser}
        visible={showBlockUserDialog}
        onConfirm={() => {
          const payload = {
            id: selectedUserID.toString(),
            blockStatus: true,
          };
          dispatch(
            blockUser(
              payload,
              () => {
                showToastSuccess('User has been successfully blocked by you');
                setBlockUserDialog(false);
                dispatch(getListUser());
              },
              (error) => {
                showToastError(error.message);
                setBlockUserDialog(false);
              }
            )
          );
        }}
        onCancel={() => {
          setBlockUserDialog(false);
        }}
      />
      <RenderDialogChangeRole
        onClose={() => {
          setShowChangeRoleDialog(false);
        }}
        loading={loadingChangeUserRole}
        onSelect={(role) => {
          setSelectedRole(role);
        }}
        currentRole={selectedRole}
        visible={showChangeRoleDialog}
        title={`Change role for ${selectedUser.name}`}
        onConfirm={() => {
          const payload = {
            id: selectedUserID.toString(),
            role: selectedRole,
          };
          dispatch(
            changeUserRole(
              payload,
              () => {
                showToastSuccess('User role berhasil di ganti');
                setShowChangeRoleDialog(false);
                dispatch(getListUser());
              },
              (error) => {
                showToastError('URL tidak di temukan');
                setShowChangeRoleDialog(false);
              }
            )
          );
        }}
        onCancel={() => {
          setShowChangeRoleDialog(false);
        }}
      />
      <div className="w-full">
        <div className={'flex'}>
          <RenderCard
            loading={loadingUserSummary}
            total={totalUsers}
            footerHeaderText={'ALL USERS'}
            footerBodyText={'+24%'}
            icon={IconNames.PEOPLE}
            iconColor="bg-yellow50"
          />
          <RenderCard
            loading={loadingUserSummary}
            total={active}
            footerHeaderText={'ACTIVE'}
            footerBodyText={'+24%'}
            icon={IconNames.PERSON}
            iconColor="bg-green60"
          />
          <RenderCard
            loading={loadingUserSummary}
            total={unverified}
            footerHeaderText={'UNVERIFIED'}
            footerBodyText={'+24%'}
            icon={IconNames.USER}
            iconColor="bg-grey50"
          />
          <RenderCard
            loading={loadingUserSummary}
            total={general}
            footerHeaderText={'GENERAL'}
            footerBodyText={'+24%'}
            icon={IconNames.PERSON}
            iconColor="bg-blue40"
          />
          <RenderCard
            total={student}
            footerHeaderText={'STUDENT'}
            footerBodyText={'+24%'}
            icon={IconNames.LEARNING}
            iconColor="bg-blue80"
          />
        </div>
        <div className={'mt-8'}>
          <Tab active={selectedTab} onChange={selectTab} options={optionsTab} />
          <TabPanel value={selectedTab} index={0}>
            <UserList
              onChangePage={(page) => {
                dispatch(
                  changePageDataUsers({
                    currentPageNumber: page,
                  })
                );
              }}
              page={pageDataUsers.currentPageNumber}
              totalPages={pageDataUsers.totalPages}
              onChangeTotalRow={(limit) =>
                dispatch(
                  changePageDataUsers({
                    currentPageNumber: 1,
                    limit,
                  })
                )
              }
              initialSearch={filterUsers.q}
              onInputSearch={(value) => {
                dispatch(changeFilterUsers({ q: value }));
              }}
              loading={loadingListUser}
              onFilterChange={(filter) => {
                const { role, type } = filter;
                const query = {
                  role: role?.value || null,
                  type: type?.value || null,
                };
                dispatch(changeFilterUsers(query));
              }}
              data={filterUserData || []}
              visibleDropdownIndex={visibleDropdownIndex}
              setVisibleDropdownIndex={setVisibleDropdownIndex}
              onViewDetail={(userId) => {
                location.push(`${PATH_URL.USER_DETAIL.replace(':id', userId)}`);
              }}
              onChangeUserRole={(user) => {
                const name = user.user.split(',')[0];
                setSelectedUser({
                  ...selectedUser,
                  name,
                });
                setSelectedUserID(user.id);
                setSelectedRole(user.role.toLowerCase());
                setShowChangeRoleDialog(true);
              }}
              onBlockUser={(user) => {
                setSelectedUserID(user.id);
                setBlockUserDialog(true);
              }}
              sortBy={sortUsers}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <UserSubscription
              onChangePage={(page) => {
                dispatch(
                  changePageDataSubscribers({ currentPageNumber: page })
                );
              }}
              page={pageDataSubscribers.currentPageNumber}
              totalPages={pageDataSubscribers.totalPages}
              onChangeTotalRow={(limit) =>
                dispatch(
                  changePageDataSubscribers({ currentPageNumber: 1, limit })
                )
              }
              initialSearch={filterSubscribers.q}
              onInputSearch={(value) => {
                dispatch(changeFilterSubscribers({ q: value }));
              }}
              onFilterChange={(filter) => {
                const { role } = filter;
                const query = {
                  role: role?.value || null,
                };
                dispatch(changeFilterSubscribers(query));
              }}
              data={filterUserSubscription || []}
              loading={loadingUserSubscription}
              sortBy={sortSubscribers}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <UserApproval
              onChangePage={(page) => {
                dispatch(changePageDataApprovals({ currentPageNumber: page }));
              }}
              page={pageDataApprovals.currentPageNumber}
              totalPages={pageDataApprovals.totalPages}
              onChangeTotalRow={(limit) =>
                dispatch(
                  changePageDataApprovals({ currentPageNumber: 1, limit })
                )
              }
              initialSearch={filterApprovals.q}
              onInputSearch={(value) => {
                dispatch(changeFilterApprovals({ q: value }));
              }}
              loading={loadingStudentApproval}
              data={filterUserApproval || []}
              sortBy={sortApprovals}
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
}

export default UserListPage;
