import moment from 'moment';
import { Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { currencyFormatter } from 'utils/currencyFormatter';
import DiscountWhite from 'assets/svg/discount_white.svg';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import clsx from 'clsx';

export default function DiscountCard(props) {
  const history = useHistory();
  const [shown, setShown] = useState(false);
  const copyCode = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(props.code);
    setShown(true);
  };

  useEffect(() => {
    let timerId;
    if (shown) {
      timerId = setTimeout(() => {
        setShown(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [shown]);

  return (
    <div
      onClick={() => {
        if (props.url) history.push(props.url);
      }}
      className={clsx(
        props.url && 'cursor-pointer',
        'flex flex-col flex-shrink-0 rounded-md overflow-hidden border border-grey30 w-full h-full'
      )}
    >
      <div className="text-white bg-blue80 p-4 h-3/5">
        <div className="flex items-center justify-between">
          <div className="flex items-center font-medium text-base">
            <img alt="icon-dark" src={DiscountWhite} className="mr-3 h-6 w-6" />
            {props.code}
          </div>
          <Tooltip
            isOpen={shown}
            content="Voucher code copied"
            position={Position.TOP}
          >
            <button
              onClick={copyCode}
              className="py-1.5 px-3 bg-orange50 rounded-sm font-bold text-xs"
            >
              Copy
            </button>
          </Tooltip>
        </div>
        <p className="mt-3 text-xs">{props.description}</p>
      </div>
      <div className="flex items-center p-4 justify-between h-2/5">
        <div className="flex items-center">
          <Icon
            iconSize={24}
            icon={IconNames.TIME}
            className="fill-current text-grey70 mr-3"
          />
          <div className="flex flex-col">
            <span className="block text-grey50 text-xs">End Date</span>
            <span className="block text-grey100 text-xs">
              {moment(props.endDate, 'YYYY-MM-DD HH:mm:ss Z').format(
                'D MMM YYYY'
              )}
            </span>
          </div>
        </div>
        <div className="flex flex-col text-xs">
          {!props.minimumPurchase ? (
            <p>No minimum purchase</p>
          ) : (
            <>
              <span className="block text-grey50 text-xs">Min. purchase</span>
              <span className="block text-grey100 text-xs">
                {currencyFormatter.format(props.minimumPurchase)}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

DiscountCard.propTypes = {
  url: PropTypes.string,
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  minimumPurchase: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
