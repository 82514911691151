import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { reportTransformationResult } from 'redux/features/variableManagement';
import { createLoadingSelector } from 'redux/api/loading';

export default function DialogReportDocument({
  isOpen,
  variableId,
  selectedDocument,
  onSuccess,
  onError,
  onCancel,
}) {
  const dispatch = useDispatch();
  const { register, reset, watch } = useForm({
    defaultValues: {
      additionalNotes: '',
    },
  });
  const loading = useSelector(
    createLoadingSelector([reportTransformationResult.type])
  );
  const getSelectedDocument = () =>
    selectedDocument && `${selectedDocument.ticker} (${selectedDocument.year})`;

  useEffect(() => {
    if (!isOpen) reset({});
  }, [isOpen, reset]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title="Report document"
      mode="danger"
      isLoading={loading}
      onConfirm={() => {
        if (selectedDocument) {
          const { id, taskId, variableName, description } = selectedDocument;
          const additionalNotes = watch('additionalNotes');
          const notes = `Reported variable: ${variableName}\nDescription: ${description}${
            additionalNotes ? `\nNotes: ${additionalNotes}` : ''
          }`;
          dispatch(
            reportTransformationResult(
              id,
              taskId,
              parseInt(variableId),
              notes,
              onSuccess,
              onError
            )
          );
        }
      }}
      onCancel={onCancel}
      captions={{
        confirm: 'Report',
      }}
    >
      <div className="flex flex-col">
        <p>
          Are you sure want to report <b>{getSelectedDocument()}</b> document?
        </p>
        <textarea
          ref={register}
          name="additionalNotes"
          placeholder="Additional notes (optional)"
          className="h-20 w-full border text-sm border-grey30 rounded resize-y mt-5 focus:ring-0 focus:border-blue50"
        ></textarea>
      </div>
    </ConfirmationDialog>
  );
}
