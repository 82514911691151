import ConfirmationDialog from 'components/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'redux/api/loading';
import { deleteVariableTransformation } from 'redux/features/variableManagement';
import { actionSuccess } from 'redux/utils/actionCreator';
import { showToastError } from '../../layouts/DashboardLayout';

function DeleteVariableDialog(props) {
  const dispatch = useDispatch();
  const selected = useSelector(
    ({ variableManagement }) => variableManagement.selected
  );
  const loadingDelete = useSelector(
    createLoadingSelector([deleteVariableTransformation.type])
  );
  const handleDelete = async () => {
    const variableId = selected.id;
    const { type } = await dispatch(deleteVariableTransformation(variableId));
    if (type === actionSuccess(deleteVariableTransformation.type)) {
      showToastError('Your variable was deleted successfully');
      props.onClose();
      props.onSuccess();
    }
  };
  return (
    <ConfirmationDialog
      title={'Delete Variable'}
      mode="danger"
      isOpen={props.isOpen}
      onCancel={props.onClose}
      onConfirm={handleDelete}
      captions={{ confirm: 'Delete' }}
      isLoading={loadingDelete}
    >
      Are you sure you want to delete this variable?
    </ConfirmationDialog>
  );
}

export default DeleteVariableDialog;
