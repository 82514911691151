import clsx from 'clsx';
import { currencyFormatter } from 'utils/currencyFormatter';

const CLASSIFICATION_COLORS = {
  custom: 'bg-green5 text-green70',
  gold: 'bg-yellow5 text-yellow80',
  silver: 'bg-grey5 text-grey70',
  bronze: 'bg-orange5 text-orange90',
};
const Price = ({ type, value }) => (
  <span
    className={clsx(
      'px-3 py-2 rounded font-medium text-xs',
      CLASSIFICATION_COLORS[String(type).toLowerCase()] ||
        CLASSIFICATION_COLORS['custom']
    )}
  >
    {currencyFormatter.format(value)}/data
  </span>
);

export default Price;
