import React, { useState } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { Icon, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
export const Menu = (props) => {
  return <ul className="flex flex-col items-center">{props.children}</ul>;
};
export const MenuItem = ({
  text,
  iconNames = '',
  to = '',
  onClick = null,
  ...props
}) => {
  const history = useHistory();
  const [showChild, setShowChild] = useState(false);
  const locationPath = useSelector((state) => state.router.location.pathname);
  const active = to && matchPath(locationPath, { path: to }) !== null;
  return (
    <li className="w-full">
      <div
        className={`w-full cursor-pointer h-12 flex flex-col justify-center rounded-sm ${props.className}`}
        style={{ background: active ? '#566A9E' : 'transparent' }}
        onClick={onClick}
      >
        <div
          className={clsx(
            'w-full flex justify-between no-underline hover:no-underline hover:text-white',
            active ? 'text-white' : 'text-grey40'
          )}
          onClick={() => {
            if (to) history.push(to);
            else setShowChild((prev) => !prev);
          }}
        >
          <div className={clsx('flex items-center')}>
            {iconNames && (
              <Icon
                icon={iconNames}
                iconSize={16}
                className={clsx('fill-current mr-3 ')}
              />
            )}
            <span className={clsx('font-medium text-sm')}>{text}</span>
          </div>
          {props.children && (
            <Icon
              icon={IconNames.CHEVRON_RIGHT}
              iconSize={16}
              className={clsx(
                'mr-3 mt-0.5 fill-current transition-transform transform',
                active ? 'text-white' : 'text-grey40',
                showChild ? 'rotate-90' : 'rotate-0'
              )}
            />
          )}
        </div>
      </div>
      {props.children && (
        <div style={{ background: '#4A5A82' }}>
          <Collapse isOpen={showChild}>
            <ul>{props.children}</ul>
          </Collapse>
        </div>
      )}
    </li>
  );
};
