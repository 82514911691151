import ConfirmationDialog from 'components/ConfirmationDialog';

const ModalDeleteVoucher = ({
  visible,
  onCancel,
  onConfirm,
  loading,
  onClose,
}) => {
  return (
    <ConfirmationDialog
      mode="danger"
      title="Delete Voucher"
      isOpen={visible}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isLoading={loading}
      captions={{ confirm: 'Delete' }}
    >
      Are you sure you want to delete this voucher?
    </ConfirmationDialog>
  );
};

export default ModalDeleteVoucher;
