import AuthLayout from '../layouts/AuthLayout';
import AdminDashboardLayout from '../layouts/DashboardLayout/DashboardAdmin';
import UserDashboardLayout from '../layouts/DashboardLayout/DashboardUser';
import Login from '../Pages/Login';
import Register from '../Pages/Register';
import SetupAccount from '../Pages/SetupAccount';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import Home from '../Pages/Home';
import UserDetail from '../Pages/UserDetail';
import UserList from '../Pages/UserList';
import SearchMain from '../Pages/SearchMain';
import SearchDetail from '../Pages/SearchDetail';
import UserProfile from '../Pages/UserProfile';
import EditUserProfile from '../Pages/EditProfile';
import PaymentCheckout from '../Pages/Payment/Checkout/index';
import PaymentComplete from '../Pages/Payment/Complete/index';
import PaymentAwaiting from '../Pages/Payment/Waiting/index';
import VoucherList from '../Pages/VoucherList';
import SavedQueries from '../Pages/SavedQueries';
import VariableList from '../Pages/VariableList';
import VariableDetail from '../Pages/VariableDetail';
import SelectVariable from '../Pages/UserSelectVariable';
import PaymentQD from '../Pages/PaymentQD';
import DiscountList from '../Pages/DiscountList';
import DiscountDetail from '../Pages/DiscountDetail';
import UserVoucherDetail from '../Pages/UserVoucherDetail';
import UserVoucher from 'Pages/UserVoucher';
import {
  UserIsAdmin,
  UserSetupProfile,
  UserIsAuthenticated,
  UserIsAuthenticatedRedir,
  UserSubscribeTextAnalytics,
  VerifiedEmailUserRedir,
} from '../utils/Routes/AuthRoutingHOC';
import PATH_URL from './path';

const routes = {
  AdminDashboard: {
    Layout: AdminDashboardLayout,
    routes: [
      {
        path: PATH_URL.USER_LIST,
        component: UserIsAdmin(UserList),
        title: 'User List',
        icon: 'people',
      },
      {
        path: PATH_URL.USER_DETAIL,
        component: UserIsAdmin(UserDetail),
        title: 'Detail User',
        icon: 'people',
        redirectPath: PATH_URL.USER_LIST,
      },
      {
        path: PATH_URL.VOUCHER_LIST,
        component: UserIsAdmin(VoucherList),
        title: 'Voucher List',
        icon: 'tag',
      },
      {
        path: PATH_URL.VARIABLE_LIST,
        component: UserIsAdmin(VariableList),
        title: 'Variable',
        icon: 'variable',
      },
      {
        path: PATH_URL.VARIABLE_DETAIL,
        component: UserIsAdmin(VariableDetail),
        title: 'Variable',
        icon: 'variable',
        redirectPath: PATH_URL.VARIABLE_LIST,
      },
      {
        path: PATH_URL.DISCOUNT_LIST,
        component: UserIsAdmin(DiscountList),
        title: 'Discount QD',
        icon: 'tag',
      },
      {
        path: PATH_URL.DISCOUNT_DETAIL,
        component: UserIsAdmin(DiscountDetail),
        title: 'Discount QD',
        icon: 'tag',
        redirectPath: PATH_URL.DISCOUNT_LIST,
      },
    ],
  },
  UserDashboard: {
    Layout: UserDashboardLayout,
    routes: [
      {
        path: PATH_URL.HOME,
        component: UserIsAuthenticatedRedir(Home),
        title: 'Home',
        icon: 'home',
      },
      {
        path: PATH_URL.USER_PROFILE,
        component: UserIsAuthenticated(UserProfile),
        title: 'Profile',
      },
      {
        path: PATH_URL.EDIT_USER_PROFILE,
        component: UserIsAuthenticated(EditUserProfile),
        title: 'Edit Profile',
      },
      {
        path: PATH_URL.SEARCH_MAIN,
        component: UserSubscribeTextAnalytics(SearchMain),
        title: 'Search',
      },
      {
        path: PATH_URL.USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC,
        component: UserIsAuthenticated(PaymentCheckout),
        title: 'Payment Checkout',
      },
      {
        path: PATH_URL.USER_PAYMENT_COMPLETE_TEXT_ANALYTIC,
        component: UserIsAuthenticated(PaymentComplete),
        title: 'Payment Complete',
      },
      {
        path: PATH_URL.USER_PAYMENT_AWAITING,
        component: UserIsAuthenticated(PaymentAwaiting),
        title: 'Payment Awaiting',
      },
      {
        path: PATH_URL.SELECT_VARIABLE,
        component: UserIsAuthenticated(VerifiedEmailUserRedir(SelectVariable)),
        title: 'Select variable',
      },
      {
        path: PATH_URL.PAYMENT_QD,
        component: UserIsAuthenticated(PaymentQD),
        title: 'Payment QD',
      },
      {
        path: PATH_URL.USER_VOUCHER_LIST,
        component: UserIsAuthenticated(UserVoucher),
        title: 'All Available Voucher',
      },
      {
        path: PATH_URL.USER_VOUCHER_DETAIL,
        component: UserIsAuthenticated(UserVoucherDetail),
        title: 'Voucher Detail',
      },
    ],
  },
  FullView: {
    Layout: '',
    routes: [
      {
        path: PATH_URL.SETUP_ACCOUNT,
        component: UserSetupProfile(SetupAccount),
        title: 'Account Setup',
      },
      {
        path: PATH_URL.SEARCH_DETAIL,
        component: UserSubscribeTextAnalytics(SearchDetail),
        title: 'Search Detail',
      },
      {
        path: PATH_URL.SAVED_QUERIES,
        component: SavedQueries,
        title: 'Saved Queries',
      },
    ],
  },
  Auth: {
    Layout: AuthLayout,
    routes: [
      {
        path: PATH_URL.LOGIN,
        component: Login,
        title: 'Login',
      },
      {
        path: PATH_URL.REGISTER,
        component: Register,
        title: 'Register',
      },
      {
        path: PATH_URL.FORGOT_PASSWORD,
        component: ForgotPassword,
        title: 'Forgot Password',
      },
      {
        path: PATH_URL.RESET_PASSWORD,
        component: ResetPassword,
        title: 'Reset Password',
      },
    ],
  },
};

export default routes;
