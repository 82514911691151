import { useState, useEffect } from 'react';
import PaymentLoading from 'assets/svg/payment_loading.svg';

export default function Loading() {
  const [tick, setTick] = useState(0);
  const renderDots = () => {
    let dots = '';
    for (let i = 0; i < tick; i++) dots += '.';
    return dots;
  };

  useEffect(() => {
    let mounted = true;
    function updateTick() {
      return setTimeout(() => {
        if (mounted) {
          setTick((prev) => (prev === 3 ? 0 : prev + 1));
          updateTick();
        }
      }, 500);
    }
    const timer = updateTick();
    return () => {
      mounted = false;
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center">
      <img src={PaymentLoading} alt="loading" className="h-80 w-80 mt-9" />
      <h4 className="text-2xl font-bold text-grey100">
        Wait a minute{renderDots()}
      </h4>
      <span className="text-xl text-grey50 mt-3">
        Payment is being processed
      </span>
    </div>
  );
}
