import { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconAnalytics from 'components/IconAnalytics';
import IconDatasets from 'components/IconDatasets';
import IconBagHandle from 'components/IconBagHandle';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PATH_URL from 'routers/path';
import DialogFreetrial from './DialogFreetrial';
import DialogEndFreetrial from './DialogEndFreetrial';
import { getDueDays } from '../../utils/date';
import {
  userHasActiveFreeTrial,
  userSubsActiveTextAnalytics,
  isTrialUserAllowed,
} from 'redux/features/auth';
import { Icon, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Button from 'components/Button';
import IconClasification from 'components/IconClasification';
import DiscountDark from 'assets/svg/discount_black.svg';
import DiscountCard from 'components/DiscountCard';
import { getAvailableQDVoucher } from 'redux/features/userSelectVariable';

function Home(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const userLastInvoice = useSelector(
    (state) => state.userManagement.userLastInvoice
  );
  const subsActive = useSelector(userSubsActiveTextAnalytics);
  const hasFreetrial = useSelector(userHasActiveFreeTrial);
  const userAllowToTrial = useSelector(isTrialUserAllowed);
  const availableVouchers = useSelector(
    ({ userSelectVariable }) => userSelectVariable.availableQDVoucher
  );
  const unpaid =
    userLastInvoice.orderStatus === 'processing' &&
    new Date().getTime() < new Date(userLastInvoice.dueDate || '');
  const [showDialogFreetrial, setShowDialogFreetrial] = useState(false);
  const [showDialogEndFreetrial, setShowDialogEndFreetrial] = useState(true);
  const history = useHistory();
  const featureActive = useMemo(() => ({ ta: true, qd: true }), []);
  const quickActionsFeature = useMemo(
    () => [
      {
        title: 'Quantitative Data',
        active: featureActive.qd,
        iconComponent: (
          <IconDatasets className="h-7 w-7 fill-current text-white" />
        ),
        features: [
          {
            iconName: IconNames.SEARCH,
            title: 'Buy Data',
            onClick: () => {
              history.push(PATH_URL.SELECT_VARIABLE);
            },
            disabled: !userData.emailVerified || !featureActive.qd,
          },
          {
            iconName: IconNames.BOOK,
            title: 'View Data Catalog',
            onClick: () => {
              window.open(
                'https://airtable.com/shr3U8e8VH0zmtOEg/tblICvhVcGCptzTq0'
              );
            },
            disabled: !userData.emailVerified || !featureActive.qd,
          },
          {
            iconName: IconNames.CLOUD_DOWNLOAD,
            title: 'Billing History & Download Result',
            onClick: () => {
              history.push(`${PATH_URL.USER_PROFILE}?billing=qd`);
            },
            disabled: !userData.emailVerified || !featureActive.qd,
          },
        ],
      },
      {
        title: 'Text Analytics',
        active: featureActive.ta,
        iconComponent: (
          <IconAnalytics className="h-7 w-7 fill-current text-white" />
        ),
        features: [
          {
            iconName: IconNames.SEARCH,
            title: 'Search New Query',
            onClick: () => {
              history.push(PATH_URL.SEARCH_MAIN);
            },
            disabled: (!subsActive && !hasFreetrial) || !featureActive.ta,
          },
          {
            iconName: IconNames.FOLDER_OPEN,
            title: ' Saved Query List & Add Note',
            onClick: () => {
              history.push(PATH_URL.SAVED_QUERIES);
            },
            disabled: !featureActive.ta,
          },
        ],
      },
    ],
    [featureActive, userData, subsActive, history, hasFreetrial]
  );
  const [activePanel, setActivePanel] = useState([0]);
  const popularVariables = useSelector((state) => state.auth.popularVariables);

  useEffect(() => {
    dispatch(getAvailableQDVoucher());
  }, [dispatch]);

  return (
    <div className="w-full pt-10 pb-9 flex justify-center bg-white">
      <div className="w-11/12 md:w-9/12 lg:w-8/12 xl:max-w-4xl">
        <h1 className="text-grey70 text-2xl font-bold mb-4">Our Products</h1>
        <div className="w-full flex mb-4 justify-between gap-x-4">
          {/* Feature Quantitative Dataset */}
          <div className="w-1/2 flex-grow">
            <div
              className={clsx(
                'rounded-xl flex flex-col justify-start items-center bg-white py-8 px-6 mb-5 shadow-lg border border-grey10 text-grey30',
                userData.emailVerified ? 'border-blue50' : 'border-grey10'
              )}
            >
              <div className={clsx('m-0 mb-5')}>
                <IconDatasets
                  fill={userData.emailVerified ? '#4F91FF' : '#E1E1E3'}
                />
              </div>
              <p
                className={clsx(
                  'w-full max-w-xl text-center mb-3',
                  userData.emailVerified ? 'text-grey70' : 'text-grey30'
                )}
              >
                <span className="block font-semibold text-lg">
                  Quantitative Data
                </span>
                <span className="block font-medium text-base">
                  Start from Rp 400/data
                </span>
                <span className="block font-normal text-sm mb-3">
                  Get 30% discount for student account
                </span>
                <span className="block font-normal text-sm mb-3">
                  A quantitative database is one of the comprehensive datasets
                  that provide all data
                </span>
              </p>

              <a
                href="https://www.esgi.ai/dataset/"
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(
                  'font-normal text-xs text-center mb-3 text-blue50 hover:no-underline'
                )}
              >
                See products details
              </a>
              <div className="mt-2 flex flex-row items-start justify-between">
                {featureActive.qd ? (
                  <Button
                    size="normal"
                    color={userData.emailVerified ? 'primary' : 'default'}
                    disabled={!userData.emailVerified}
                    onClick={() => {
                      history.push(PATH_URL.SELECT_VARIABLE);
                    }}
                  >
                    Buy Dataset
                  </Button>
                ) : (
                  <Button size="normal" color="default" disabled={true}>
                    Coming Soon
                  </Button>
                )}
              </div>
            </div>
          </div>

          {/* Feature Text Analytic */}
          <div className="w-1/2 flex-grow">
            <div
              className={clsx(
                'rounded-xl flex flex-col justify-start items-center bg-white py-8 px-6 mb-5 shadow-lg border',
                userData.emailVerified ? 'border-blue50' : 'border-grey10'
              )}
            >
              <div className={clsx('m-0 mb-5')}>
                <IconAnalytics
                  fill={userData.emailVerified ? '#4F91FF' : '#E1E1E3'}
                  disabled={!userData.emailVerified}
                />
              </div>
              <p
                className={clsx(
                  'w-full max-w-xl text-center mb-3',
                  userData.emailVerified ? 'text-grey70' : 'text-grey30'
                )}
              >
                <span className="block font-semibold text-lg">
                  Text Analytics
                </span>
                <span className="block font-medium text-base">
                  Start from Rp 500.000/mo
                </span>
                <span className="block font-normal text-sm mb-3">
                  Get 30% discount for student account
                </span>
                <span className="block font-normal text-sm mb-3">
                  Text analytics is perfectly fit for the user interested in
                  content analysis
                </span>
              </p>
              <a
                href="https://www.esgi.ai/"
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(
                  'font-normal text-xs text-center mb-3 text-blue50 hover:no-underline'
                )}
              >
                See plan &amp; pricing details
              </a>
              {userData.emailVerified &&
                subsActive &&
                subsActive.subscription &&
                !(subsActive.subscription.option === 'trial') && (
                  <div className="mt-1">
                    <p className="font-normal text-xs text-center text-grey100">
                      {`Active until ${moment(subsActive.expireAt).format(
                        'DD MMM YYYY'
                      )} `}
                    </p>
                    {subsActive.expireAt &&
                      getDueDays(subsActive.expireAt) <= 3 && (
                        <p className="text-red50 text-xs text-center">{`(Will expire in ${getDueDays(
                          subsActive.expireAt
                        )} days)`}</p>
                      )}
                  </div>
                )}
              {featureActive.ta ? (
                <div className="mt-2 flex flex-row items-start justify-between">
                  {userData.emailVerified &&
                    !unpaid &&
                    !subsActive &&
                    !hasFreetrial &&
                    userAllowToTrial && (
                      <Button
                        view="outlined"
                        size="normal"
                        className="mr-2"
                        onClick={() => setShowDialogFreetrial(true)}
                      >
                        Free Trial
                      </Button>
                    )}
                  {(!userData.emailVerified ||
                    (!unpaid && !subsActive) ||
                    (subsActive &&
                      subsActive.subscription &&
                      subsActive.subscription.option === 'trial')) && (
                    <Button
                      size="normal"
                      color={userData.emailVerified ? 'primary' : 'default'}
                      disabled={!userData.emailVerified || !featureActive.ta}
                      onClick={() => {
                        if (featureActive.ta)
                          history.push(
                            PATH_URL.USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC
                          );
                      }}
                    >
                      Subscribe
                    </Button>
                  )}
                </div>
              ) : (
                <div className="mt-2 flex flex-row items-start justify-between">
                  <Button size="normal" color="default" disabled={true}>
                    Coming Soon
                  </Button>
                </div>
              )}

              {userData.emailVerified && subsActive && (
                <Link
                  to={PATH_URL.SEARCH_MAIN}
                  className={clsx(
                    'font-normal text-sm text-center text-blue50 hover:no-underline',
                    subsActive.subscription?.option === 'trial' && 'mt-3'
                  )}
                >
                  Access your apps
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="flex items-center mb-8">
            <Icon
              iconSize={24}
              icon={IconNames.OFFLINE}
              className="fill-current text-grey70 mr-3"
            />
            <h1 className="text-grey70 text-2xl font-bold">Quick Actions</h1>
          </div>

          {quickActionsFeature
            .filter((v) => v.active)
            .map((action, indexQuickActions) => (
              <div
                key={`quick-actions-${indexQuickActions}`}
                className="w-full pb-5 mb-7 "
              >
                <div
                  className="w-full flex justify-between items-center mb-2 rounded cursor-pointer"
                  onClick={() => {
                    if (activePanel.includes(indexQuickActions)) {
                      setActivePanel((prev) => [
                        ...prev.filter((v) => v !== indexQuickActions),
                      ]);
                    } else {
                      setActivePanel((prev) => [...prev, indexQuickActions]);
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div className="w-11 h-11 bg-teal50 rounded mr-5 flex justify-center items-center shadow">
                      {action.iconComponent}
                    </div>
                    <h1 className="text-grey70 text-lg font-bold">
                      {action.title}
                    </h1>
                  </div>
                  <Icon
                    icon={
                      activePanel.includes(indexQuickActions)
                        ? IconNames.CHEVRON_UP
                        : IconNames.CHEVRON_DOWN
                    }
                    className="fill-current text-grey70"
                    iconSize={25}
                  />
                </div>
                <Collapse isOpen={activePanel.includes(indexQuickActions)}>
                  <div className="w-full py-3 grid grid-cols-1 lg:grid-cols-3 gap-2">
                    {action.features.map((feature, indexFeature) => (
                      <div
                        key={`feature-${indexFeature}`}
                        className={clsx(
                          'w-full rounded-md px-3 py-5 border-2',
                          feature.disabled
                            ? 'cursor-not-allowed border-gray40'
                            : 'cursor-pointer border-teal30'
                        )}
                        onClick={() =>
                          !feature.disabled &&
                          feature.onClick &&
                          feature.onClick()
                        }
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <div
                              className={clsx(
                                'w-12 h-12 rounded-full flex justify-center items-center shadow flex-shrink-0 mr-3',
                                !feature.disabled ? 'bg-teal50' : 'bg-grey30'
                              )}
                            >
                              <Icon
                                icon={feature.iconName}
                                className="fill-current text-white"
                                iconSize={18}
                              />
                            </div>
                            <p
                              className={clsx(
                                'w-3/4 text-base font-medium text-left',
                                feature.disabled ? 'text-gray30' : 'text-gray80'
                              )}
                            >
                              {feature.title}
                            </p>
                          </div>
                          <Icon
                            icon={IconNames.ARROW_RIGHT}
                            className={clsx(
                              'fill-current',
                              feature.disabled ? 'text-gray30' : 'text-gray80'
                            )}
                            iconSize={18}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
        </div>
        {availableVouchers.length > 0 && (
          <div className="w-full mb-16">
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center">
                <img
                  alt="icon-dark"
                  src={DiscountDark}
                  className="mr-3 h-6 w-6"
                />
                <h1 className="text-grey70 text-2xl font-bold">
                  Available Voucher
                </h1>
              </div>
              {availableVouchers.length > 3 && (
                <Link
                  to={PATH_URL.USER_VOUCHER_LIST}
                  className="text-blue50 text-base hover:no-underline focus:outline-none"
                >
                  View all
                </Link>
              )}
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-2">
              {availableVouchers.slice(0, 3).map((voucher) => (
                <DiscountCard
                  key={voucher.name}
                  url={`${PATH_URL.USER_VOUCHER_LIST}/${voucher.id}`}
                  code={voucher.name}
                  description={voucher.description}
                  endDate={voucher.endDate}
                  minimumPurchase={voucher.minimumPurchase}
                />
              ))}
            </div>
          </div>
        )}
        {popularVariables.length > 0 && (
          <div className="w-full">
            <div className="flex items-center mb-8">
              <Icon
                iconSize={24}
                icon={IconNames.CHART}
                className="fill-current text-grey50 mr-3"
              />
              <h2 className="text-grey70 text-2xl font-bold">
                Popular Variables in Quantitative Data
              </h2>
            </div>
            <div className="w-full flex flex-wrap md:flex-nowrap">
              {popularVariables.map((v) => (
                <div
                  key={v.id}
                  className="w-60 mr-2 px-3 py-2 mb-2 border-2 border-gray-20 rounded-lg flex flex-col justify-between"
                >
                  <div className="mb-4">
                    <p className="text-gray80 text-base font-bold mb-2">
                      {v.name}
                    </p>
                    <p className="text-gray40 text-xs">{v.description}</p>
                  </div>
                  <div className="flex justify-end items-center mb-2">
                    <IconClasification
                      type={v.clasification.toLowerCase()}
                      className="mr-3"
                    />
                    <IconBagHandle
                      className={clsx('fill-current text-green50 mr-1 text-xs')}
                    />
                    <p className="text-xs">{v.totalCheckout}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <DialogFreetrial
        showDialog={showDialogFreetrial}
        setShowDialog={setShowDialogFreetrial}
      />
      {hasFreetrial &&
        moment.utc(hasFreetrial.expireAt).diff(moment.utc(), 'hours') === 0 && (
          <DialogEndFreetrial
            showDialog={showDialogEndFreetrial}
            setShowDialog={setShowDialogEndFreetrial}
          />
        )}
    </div>
  );
}

export default Home;
