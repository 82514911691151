import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import Button from 'components/Button';

export default function ConfirmationDialog(props) {
  return (
    <Dialog
      header={props.title}
      size="small"
      isOpen={props.isOpen}
      onClose={props.onCancel}
    >
      <div className="w-full px-6">
        {typeof props.children === 'string' ? (
          <p>{props.children}</p>
        ) : (
          props.children
        )}
      </div>
      <div className="w-full mt-4 px-6 flex items-center justify-end">
        <Button
          color={props.mode === 'normal' ? 'danger' : 'default'}
          view="outlined"
          disabled={props.isLoading}
          onClick={props.onCancel}
        >
          {props.captions.cancel || 'Cancel'}
        </Button>
        {props.onConfirm && (
          <Button
            color={props.mode === 'normal' ? 'primary' : 'danger'}
            isLoading={props.isLoading}
            onClick={props.onConfirm}
            className="ml-4"
          >
            {props.captions.confirm || 'Confirm'}
          </Button>
        )}
      </div>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  mode: PropTypes.oneOf(['normal', 'danger']),
  captions: PropTypes.shape({
    confirm: PropTypes.string,
    cancel: PropTypes.string,
  }),
};

ConfirmationDialog.defaultProps = {
  isLoading: false,
  mode: 'normal',
  captions: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
};
