import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  resendEmailVerification,
  userHasActiveFreeTrial,
  userSubsActiveTextAnalytics,
} from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import Spinner from 'components/Spinner';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PATH_URL from '../../routers/path';
import { getDueDays } from '../../utils/date';
function ProfileInformation({
  isAdmin = false,
  typeInformation = '',
  ...props
}) {
  const dispatch = useDispatch();
  const userLastInvoice = useSelector(
    (state) => state.userManagement.userLastInvoice
  );
  const loadingResend = useSelector((state) =>
    createLoadingSelector([resendEmailVerification.type])(state)
  );
  const freeTrialActive = useSelector(userHasActiveFreeTrial);
  const subsActive = useSelector(userSubsActiveTextAnalytics);
  return (
    <div
      className={clsx(
        'h-10 bg-red10 text-red60 text-sm flex justify-center items-center z-10',
        isAdmin && 'pl-56',
        freeTrialActive && 'top-28'
      )}
    >
      {loadingResend && (
        <div className="mr-3">
          <Spinner className=" h-6 w-6 fill-current text-red60" />
        </div>
      )}
      {typeInformation === 'unVerified' && (
        <div>
          <span className="mr-1">
            Your account is not verified. Please verify your account
          </span>
          <span
            className="cursor-pointer text-blue60"
            onClick={() => dispatch(resendEmailVerification())}
          >
            Resend verification
          </span>
        </div>
      )}
      {typeInformation === 'unPaid' &&
        new Date().getTime() < new Date(userLastInvoice.dueDate) && (
          <div>
            <span className="mr-1">
              {`You have a bill that must be paid immediately before ${moment(
                userLastInvoice.dueDate
              ).format('DD MMM YYYY, HH:mm')}`}
            </span>
            <Link
              to={PATH_URL.USER_PAYMENT_AWAITING}
              className="cursor-pointer text-blue60"
            >
              Pay now
            </Link>
          </div>
        )}
      {typeInformation === 'expired' && (
        <div>
          <span className="mr-1">
            {`Your plan will expire on ${moment(
              (subsActive && subsActive.expireAt) || ''
            ).format('DD MMM YYYY')} (${getDueDays(
              subsActive.expireAt
            )} more days).`}
          </span>

          <Link
            className="cursor-pointer text-blue60 hover:no-underline"
            to={PATH_URL.USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC}
          >
            Renew now
          </Link>
        </div>
      )}
    </div>
  );
}

export default ProfileInformation;
