import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import Switch from 'components/Switch';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import { createLoadingSelector } from '../../redux/api/loading';
import {
  changeUsageListPageData,
  downloadDiscountUsageList,
  getDetailDiscount,
  getDiscountUsageList,
  toggleVisibleDiscount,
} from '../../redux/features/discountManagement';
import EditDiscountDialog from '../DiscountList/EditDiscount';
import ToggleVisibleDiscount from 'Pages/DiscountList/ToggleVisibleDiscount ';
import { showToastError, showToastSuccess } from 'layouts/DashboardLayout';
import Skeleton from 'react-loading-skeleton';
import { upperFirst } from 'lodash';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import clsx from 'clsx';

function DiscountDetailPage() {
  const _isMounted = useRef(true);
  const paramsUrl = useParams();
  const downloadAnchorRef = useRef(null);
  const dispatch = useDispatch();
  const {
    usage: { list: usages, pageData },
    selected,
  } = useSelector((state) => state.discountManagement);
  const loadingDetail = useSelector(
    createLoadingSelector([getDetailDiscount.type])
  );
  const loadingGetListDiscount = useSelector(
    createLoadingSelector([getDiscountUsageList.type])
  );
  const loadingDownloadDiscount = useSelector(
    createLoadingSelector([downloadDiscountUsageList.type])
  );
  const loadingToggleVisibleDiscount = useSelector(
    createLoadingSelector([toggleVisibleDiscount.type])
  );
  const [showDialogEdit, setShowDialogEdit] = useState(false);
  const getColumns = () => {
    let baseColumns = [
      {
        Header: 'Date',
        accessor: 'paidOn',
        sortType: 'datetime',
        customCell: ({ value }) => {
          return value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-';
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Total Payment',
        alignment: 'right',
        accessor: 'netPrice',
        customCell: ({ value }) => (
          <span className="block text-right">
            {currencyFormatter.format(value)}
          </span>
        ),
      },
    ];
    if (selected.discountBase === 'referral')
      return baseColumns.concat({
        id: 'comission',
        Header: 'Comission Earned',
        alignment: 'right',
        accessor: 'netPrice',
        customCell: ({ value, row }) => (
          <span className="block text-right">
            {currencyFormatter.format(
              row.original.paidOn.getTime() !== 0 ? value * 0.1 : 0
            )}
          </span>
        ),
      });
    return baseColumns;
  };
  const [showDialogToggleVisible, setShowDialogToggleVisible] = useState(false);
  const handleVisibleDiscount = useCallback(async () => {
    dispatch(
      toggleVisibleDiscount(
        {
          id: selected.id,
          isVisible: !selected.isVisible,
        },
        () => {
          showToastSuccess('Success toggle voucher visibility');
          setShowDialogToggleVisible(false);
          dispatch(getDetailDiscount(paramsUrl.id));
        },
        () => {
          showToastError('An error occurred');
          setShowDialogToggleVisible(false);
        }
      )
    );
  }, [dispatch, paramsUrl, selected]);
  useEffect(() => {
    if (selected.id === null) {
      dispatch(getDetailDiscount(paramsUrl.id));
    }
  }, [dispatch, paramsUrl.id, selected]);

  useEffect(() => {
    dispatch(
      getDiscountUsageList(paramsUrl.id, {
        page: pageData.currentPageNumber,
        limit: pageData.rowPerPage,
      })
    );
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch, paramsUrl.id, pageData.currentPageNumber, pageData.rowPerPage]);

  return (
    <>
      <div className="w-full p-2">
        <div className="w-full flex flex-wrap justify-between items-center mb-2">
          <h1 className="text-lg text-grey70 font-bold">
            {loadingDetail ? <Skeleton width={300} /> : selected.name}
          </h1>
          <div className="flex items-center">
            {selected.discountBase === 'voucher' && (
              <div className="flex items-center mr-4">
                <span
                  className={clsx(
                    'text-sm mr-2',
                    selected.isVisible ? 'text-gray70' : 'text-gray30 '
                  )}
                >
                  Show voucher
                </span>
                <Switch
                  checked={selected.isVisible}
                  onChange={() => setShowDialogToggleVisible(true)}
                />
              </div>
            )}
            <Button
              color="success"
              onClick={() =>
                dispatch(
                  downloadDiscountUsageList(
                    selected.id,
                    (link) => {
                      const downloadAnchor = downloadAnchorRef.current;
                      downloadAnchor.setAttribute('href', link);
                      downloadAnchor.click();
                    },
                    () => showToastError('An error occurred')
                  )
                )
              }
              className="mr-3"
              disabled={loadingDownloadDiscount}
            >
              {loadingDownloadDiscount ? (
                'Please Wait...'
              ) : (
                <span className="mr-1">
                  Download
                  <Icon
                    iconSize={15}
                    icon={IconNames.CLOUD_DOWNLOAD}
                    className="fill-current text-white ml-2"
                  />
                </span>
              )}
            </Button>
            <Button
              color="success"
              onClick={() => {
                setShowDialogEdit(true);
              }}
            >
              <span className="mr-1">Edit Discount</span>
              <Icon
                icon={IconNames.TAG}
                iconSize={15}
                className="fill-current text-white"
              />
            </Button>
          </div>
        </div>
        <div className="w-full mb-5">
          <p>
            {loadingDetail ? <Skeleton width={300} /> : selected.description}
          </p>
          <div className="flex mt-3">
            <div className="flex mr-6 items-center">
              <Icon
                icon={IconNames.TAG}
                iconSize={14}
                className="fill-current text-blue50"
              />
              <p className="text-sm	 text-gray50 ml-2.5">
                {loadingDetail ? (
                  <Skeleton width={60} />
                ) : (
                  upperFirst(selected.discountBase)
                )}
              </p>
            </div>
            <div className="flex mr-6 items-center">
              <Icon
                icon={IconNames.CALENDAR}
                iconSize={14}
                className="fill-current text-blue50"
              />
              <p className="text-sm	 text-gray50 ml-2.5">
                {loadingDetail ? (
                  <Skeleton width={60} />
                ) : (
                  `${moment(selected.startDate).format(
                    'D MMM YYYY'
                  )} - ${moment(selected.endDate).format('D MMM YYYY')}`
                )}
              </p>
            </div>
            {selected.discountBase === 'voucher' && (
              <div className="flex mr-6 items-center">
                <Icon
                  icon={IconNames.SHOPPING_CART}
                  iconSize={14}
                  className="fill-current text-blue50"
                />
                <p className="text-sm	 text-gray50 ml-2.5">
                  {loadingDetail ? (
                    <Skeleton width={60} />
                  ) : (
                    `Min. purchase ${currencyFormatter.format(
                      selected.minimumPurchase || 0
                    )}`
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        {selected.discountBase === 'voucher' && (
          <div className="my-2 pb-1">
            <p className="text-gray100 text-sm font-semibold mb-3">
              Terms and Condition
            </p>
            {loadingDetail ? (
              <Skeleton width="100%" height={200} />
            ) : (
              <div className={clsx('text-gray70 text-sm')}>
                {selected.termsConditions && (
                  <ol className="list-decimal list-inside block w-full">
                    {selected.termsConditions.split('\n').map((tnc, i) => (
                      <li key={i} className="text-xs text-gray70 mb-1">
                        {tnc}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            )}
          </div>
        )}

        <CustomTable
          disabledCheckbox
          disableSearch
          isLoading={loadingGetListDiscount}
          dataTable={usages.map((v) => ({
            ...v,
            paidOn: new Date(v.paidOn),
          }))}
          columnsTable={getColumns()}
          page={pageData.currentPageNumber}
          totalPage={pageData.totalPages}
          totalRow={pageData.limit}
          onChangeTotalRow={(row) => {
            dispatch(changeUsageListPageData({ limit: row }));
          }}
          onChangePage={(page) => {
            dispatch(changeUsageListPageData({ currentPageNumber: page }));
          }}
        />
        <a ref={downloadAnchorRef} href="_" download className="hidden">
          Download
        </a>
      </div>
      <EditDiscountDialog
        showDialog={showDialogEdit}
        setShowDialog={setShowDialogEdit}
        onSuccess={() => dispatch(getDetailDiscount(paramsUrl.id))}
      />
      <ToggleVisibleDiscount
        mode={!selected.isVisible ? 'normal' : 'danger'}
        visible={showDialogToggleVisible}
        onCancel={() => {
          setShowDialogToggleVisible(false);
        }}
        onConfirm={async () => {
          handleVisibleDiscount();
        }}
        loading={loadingToggleVisibleDiscount}
        onClose={() => {
          setShowDialogToggleVisible(false);
        }}
      />
    </>
  );
}

export default DiscountDetailPage;
