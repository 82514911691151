import Highlighter from 'react-highlight-words';
import tokenizer from 'sbd';
import { escapeRegExp, upperFirst } from 'lodash';

function RenderHighlighter({ text, keyword }) {
  return (
    <Highlighter
      className={'bg-grey10'}
      highlightClassName="w-full leading-relaxed overflow-y-auto"
      // to match word as a whole
      searchWords={keyword.map((word) => `\\b${escapeRegExp(word)}\\b`)}
      textToHighlight={text}
    />
  );
}

const String = {};
String.Highlight = ({ str, search, keyword }) => {
  const options = {
    abbreviations: ['No'],
  };
  const sentences = tokenizer.sentences(str, options);
  const composedSentences = sentences.reduce((acc, sentence, index) => {
    if (sentence.length) {
      if (search.includes(sentence))
        acc = acc.concat(
          <span key={`highlight-${index}`}>
            {index > 0 ? ' ' : ''}
            <RenderHighlighter text={`${sentence}`} keyword={keyword} />
          </span>
        );
      else {
        const space = index > 0 ? ' ' : '';
        acc = acc.concat(`${space}${sentence}`);
      }
    }
    return acc;
  }, []);
  return <p>{composedSentences}</p>;
};

String.capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

String.parsePaymentMethod = (paymentMethod) => {
  const trimmed = paymentMethod.trim();
  const OTHERS = {
    gopay: 'GoPay',
    shopeepay: 'ShopeePay',
    qris: 'QRIS',
    echannel: 'Bank Transfer Mandiri',
  };
  const BANK = {
    bca: 'BCA',
    bni: 'BNI',
    bri: 'BRI',
    permata: 'Permata',
  };
  if (trimmed.includes('bank_transfer')) {
    const bankName = trimmed.split(' ')[1];
    return `Bank Transfer ${BANK[bankName]}`;
  }
  if (trimmed.includes('credit_card')) return 'Credit Card';
  if (trimmed.includes('cstore')) {
    const storeName = trimmed.split(' ')[1];
    return upperFirst(storeName);
  }
  return OTHERS[trimmed];
};

export default String;
