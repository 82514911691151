import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import Button from 'components/Button';
import PATH_URL from '../../routers/path';
import { createLoadingSelector } from '../../redux/api/loading';
import { storeSurveyData } from '../../redux/features/auth';
import infoSources from './data/infoSources.json';
import purposes from './data/purposes.json';

function Survey() {
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    createLoadingSelector([storeSurveyData.type])(state)
  );
  const authData = useSelector((state) => state.auth);
  const handleNextStep = () => {
    handleSubmit(onSubmit)();
  };
  const onSubmit = async (data) => {
    const isSuccess = await dispatch(storeSurveyData(data));
    if (isSuccess) handleSkip();
  };
  const handleSkip = () => {
    const currentItem = localStorage.getItem('personalSetup');
    localStorage.setItem(
      'personalSetup',
      currentItem
        ? currentItem.split(',').concat(authData.email)
        : [authData.email]
    );
    dispatch(push(PATH_URL.HOME));
  };

  return (
    <form>
      <h1 className="font-semibold text-3xl">Tell us about what you need</h1>
      <p className="text-sm text-grey70 my-2">
        You're in good hands. We'll help you collect, analyze, and report ESG
        Data for your needs.
      </p>
      <div className="flex flex-col mt-3">
        <label htmlFor="hearFrom" className="text-grey60 text-sm mb-1">
          I heard ESGI from :
        </label>
        <select
          name="hearFrom"
          ref={register}
          defaultValue={infoSources[0]}
          className="rounded border-0 bg-grey5 focus:border-0 focus:outline-none focus:ring-transparent"
        >
          {infoSources.map((source, index) => (
            <option key={`source-${index + 1}`} value={source}>
              {source}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col mt-3">
        <label htmlFor="occupation" className="text-grey60 text-sm mb-1">
          What do you do?
        </label>
        <select
          name="occupation"
          ref={register}
          defaultValue="Working"
          className="rounded border-0 bg-grey5 focus:border-0 focus:outline-none focus:ring-transparent"
        >
          <option value="Working">Working</option>
          <option value="Research">Research</option>
        </select>
      </div>
      <div className="flex flex-col mt-3">
        <label htmlFor="need" className="text-grey60 text-sm mb-1">
          What do you need?
        </label>
        <select
          name="need"
          ref={register}
          defaultValue={purposes[0]}
          className="rounded border-0 bg-grey5 focus:border-0 focus:outline-none focus:ring-transparent"
        >
          {purposes.map((purpose, index) => (
            <option key={`purpose-${index + 1}`} value={purpose}>
              {purpose}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={handleSkip}
          className="text-sm text-blue50 hover:underline"
        >
          Skip for now
        </button>
        <Button color="primary" onClick={handleNextStep} isLoading={loading}>
          Next Step
        </Button>
      </div>
    </form>
  );
}

export default Survey;
