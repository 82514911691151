import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { IconNames } from '@blueprintjs/icons';
import { debounce } from 'lodash';
import Checkbox from './Checkbox';
import Spinner from 'components/Spinner';
import {
  changeSelectedSections,
  changeSelectedSectionNames,
  getFilteredSectionList,
  toggleWholeSections,
} from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';

function DocumentSectionDialog(props) {
  const dispatch = useDispatch();
  const { documentSections: sectionsFilter } = useSelector(
    ({ textAnalytics }) => textAnalytics.filter
  );
  const loading = useSelector(
    createLoadingSelector([getFilteredSectionList.type])
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(sectionsFilter.selected);
  const [selectedNames, setSelectedNames] = useState(
    sectionsFilter.selectedNames
  );
  const [isWhole, setIsWhole] = useState(sectionsFilter.isWhole);
  const toggleSelectedSections = (sectionId, sectionName) => {
    if (selected.includes(sectionId)) {
      const updatedSections = selected.filter(
        (selectedSectionId) => selectedSectionId !== sectionId
      );
      const updatedSectionNames = Object.keys(selectedNames).reduce(
        (acc, id) => {
          if (id !== sectionId) acc = { ...acc, [id]: selectedNames[id] };
          return acc;
        },
        {}
      );
      setSelected(updatedSections);
      setSelectedNames(updatedSectionNames);
    } else {
      setSelected(selected.concat(sectionId));
      setSelectedNames({
        ...selectedNames,
        [sectionId]: sectionName,
      });
    }
  };
  const handleReset = () => {
    setSelected([]);
  };
  const applySearch = (searchTerm) => {
    dispatch(getFilteredSectionList(searchTerm));
  };
  const handleSearch = useRef(debounce(applySearch, 300)).current;
  const handleChange = (e) => {
    const { value: searchInput } = e.target;
    setSearchTerm(searchInput);
    if (searchInput) handleSearch(searchInput);
  };
  const handleApply = () => {
    if (isWhole !== sectionsFilter.isWhole) dispatch(toggleWholeSections());
    dispatch(changeSelectedSections(selected));
    dispatch(changeSelectedSectionNames(selectedNames));
    props.onApply();
    props.onClose();
  };
  const renderSections = () => {
    const data = searchTerm ? sectionsFilter.filtered : sectionsFilter.options;
    if (data.length > 0) {
      return (
        <div className="flex flex-wrap">
          <Checkbox
            label="Whole Documents"
            checked={isWhole}
            onChange={() => setIsWhole(!isWhole)}
          />
          {data.map((section) => (
            <div key={section.id} className="w-full mb-2">
              <Checkbox
                label={section.name}
                checked={isWhole ? true : selected.includes(section.id)}
                onChange={() =>
                  toggleSelectedSections(section.id, section.name)
                }
                disabled={isWhole}
              />
            </div>
          ))}
        </div>
      );
    } else {
      return <div>No sections found</div>;
    }
  };

  useEffect(() => {
    setSelected(sectionsFilter.selected);
    setSelectedNames(sectionsFilter.selectedNames);
  }, [sectionsFilter.selected, sectionsFilter.selectedNames]);

  useEffect(() => {
    setIsWhole(sectionsFilter.isWhole);
  }, [sectionsFilter.isWhole]);

  return (
    <Dialog
      header="More Sections"
      isOpen={props.isOpen}
      onClose={props.onClose}
      dismissButton
    >
      <div className="flex flex-col h-full w-full px-4">
        <div className="pb-4 flex justify-between items-center">
          <div className="relative flex items-center w-full flex-grow">
            <span className="absolute left-0 ml-2 rounded-md">
              <Icon
                icon={IconNames.SEARCH}
                iconSize={16}
                className="fill-current text-grey30"
              />
            </span>
            <input
              type="text"
              name="search"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search sections"
              autoComplete="off"
              className="h-10 w-full bg-grey0 border-0 rounded-md pl-10 focus:outline-none focus:ring-transparent"
            />
          </div>
        </div>
        <div className="h-48 w-full overflow-y-auto">
          {loading && (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          )}
          {!loading && renderSections()}
        </div>
        <div className="pt-4 flex justify-end items-center">
          <Button view="outlined" color="danger" onClick={handleReset}>
            Reset
          </Button>
          <Button color="primary" onClick={handleApply} className="ml-2">
            Apply
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DocumentSectionDialog;
