import { Popover, Position } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import useVisible from '../../hooks/useVisible';
import userImage from '../../assets/images/userProfileDefault.svg';
import UserMenu from '../../components/UserMenu';
import { Link } from 'react-router-dom';
import PATH_URL from 'routers/path';
import clsx from 'clsx';
import { userHasActiveFreeTrial } from '../../redux/features/auth';
import { getDueDays } from '../../utils/date';
function DashboardMenu({ isAdmin = false, ...props }) {
  const { ref, isVisible, setIsVisible } = useVisible();
  const user = useSelector((state) => state.auth);
  const freeTrialActive = useSelector(userHasActiveFreeTrial);

  return (
    <>
      {freeTrialActive && freeTrialActive.expireAt && (
        <p className="block whitespace-nowrap text-sm text-grey70 mr-5">
          You have{' '}
          <span className="text-red50">{` ${getDueDays(
            freeTrialActive.expireAt || ''
          )}`}</span>{' '}
          days left for Text Analytics trial.{' '}
          <Link
            to={PATH_URL.USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC}
            className="text-blue50 hover:no-underline"
          >
            Subscribe
          </Link>
        </p>
      )}
      <Popover
        isOpen={isVisible}
        position={Position.BOTTOM_RIGHT}
        minimal={true}
        targetTagName="div"
        className="w-min-content"
        popoverClassName="shadow-none bg-transparent"
      >
        <div
          className={clsx(
            'flex items-center justify-end cursor-pointer',
            isAdmin && freeTrialActive && 'ml-8'
          )}
          onClick={() => setIsVisible((prev) => !prev)}
        >
          <div className="w-7 h-7 rounded-full overflow-hidden flex-shrink-0">
            <img
              src={user.photoUrl || userImage}
              alt="User profile"
              className="w-7 h-7"
            />
          </div>
        </div>
        <UserMenu
          containerRef={ref}
          isAdmin={isAdmin}
          setIsVisible={setIsVisible}
        />
      </Popover>
    </>
  );
}

export default DashboardMenu;
