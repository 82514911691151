const PATH_URL = {
  LOGIN: '/a/login',
  REGISTER: '/a/register',
  FORGOT_PASSWORD: '/a/forgot-password',
  RESET_PASSWORD: '/a/reset-password',
  SETUP_ACCOUNT: '/d/setup',
  HOME: '/u',
  ADMIN_DASHBOARD: '/m',
  EMAIL_ACTION: '/email',
  VERIFY_EMAIL: '/verify-email',
  SEARCH_MAIN: '/u/search',
  SEARCH_DETAIL: '/d/search-detail/:keywords',
  // will be replaced/removed later
  UNVERIFIED_EMAIL: '/not-found',
  WAITING_APPROVAL: '/not-found',
  USER_DETAIL: '/m/users/:id',
  USER_LIST: '/m/users',
  TERMS_CONDITIONS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  USER_PROFILE: '/u/profile',
  USER_PAYMENT: '/u/payment',
  USER_PAYMENT_CHECKOUT_TEXT_ANALYTIC: '/u/payment/text-analytic',
  USER_PAYMENT_COMPLETE_TEXT_ANALYTIC: '/u/payment/complete/text-analytic',
  USER_PAYMENT_AWAITING: '/u/payment/awaiting',
  EDIT_USER_PROFILE: '/u/profile/edit',
  USER_VOUCHER_DETAIL: '/u/vouchers/:id',
  VOUCHER_LIST: '/m/vouchers',
  SAVED_QUERIES: '/d/saved-queries',
  VARIABLE_DETAIL: '/m/variables/:id',
  VARIABLE_LIST: '/m/variables',
  SELECT_VARIABLE: '/u/select-variable',
  PAYMENT_QD: '/u/payment/quantitative',
  DISCOUNT_LIST: '/m/discounts',
  DISCOUNT_DETAIL: '/m/discounts/:id',
  USER_VOUCHER_LIST: '/u/vouchers',
};

export default PATH_URL;
