import { useDispatch } from 'react-redux';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  getReportStats,
  selectedQuerySelector,
  getReportSentencesXlsx,
  getReportSentencesPdf,
} from 'redux/features/textAnalytics';
import { convertFieldToSymbol } from 'utils/searchKeyword';
import { actionFailure } from 'redux/utils/actionCreator';
import { createLoadingSelector } from 'redux/api/loading';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function autofitWidth(worksheet, minWidth = 10) {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minWidth,
        cell.value ? cell.value.toString().length : 0
      );
    });
    column.width = maxColumnLength + 2;
  });
}

function addBorder(worksheet) {
  worksheet.eachRow(function (row, num) {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });
}

function DownloadQueryDialog(props) {
  const dispatch = useDispatch();
  const selectedQuery = useSelector(selectedQuerySelector);
  const user = useSelector((state) => state.auth);
  const loadingXlsx = useSelector(
    createLoadingSelector([getReportSentencesXlsx.type])
  );
  const loadingPdf = useSelector(
    createLoadingSelector([getReportSentencesPdf.type])
  );
  const handleDownloadXlsx = async () => {
    const { payload: statsPayload, type: statsActionType } = await dispatch(
      getReportStats(selectedQuery.id)
    );
    const { payload: sentencePayload, type: sentenceActionType } =
      await dispatch(getReportSentencesXlsx(selectedQuery.id));
    if (
      statsActionType === actionFailure(getReportStats.type) ||
      sentenceActionType === actionFailure(getReportSentencesXlsx.type)
    )
      return;
    if (selectedQuery) {
      const { keywords, yearRange, documentSection, companies } = selectedQuery;
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet('Summary Query');
      workbook.addWorksheet('Query Result Statistics');
      workbook.addWorksheet('Notes');
      let worksheet = workbook.getWorksheet(1);
      worksheet.columns = [
        { key: 'A', width: 10 },
        { key: 'B', width: 102 },
      ];
      worksheet.addRows([
        { A: 'Account', B: user.email },
        { A: 'Keywords', B: convertFieldToSymbol(keywords) },
        { A: 'Filter Period', B: `${yearRange[0]}-${yearRange[1]}` },
        {
          A: 'Filter Documents Section',
          B:
            documentSection.length > 0
              ? documentSection.map((section) => section.name).join(', ')
              : 'Whole documents',
        },
        {
          A: 'Filter Companies',
          B:
            companies.length > 0
              ? companies.map((company) => company.ticker).join(', ')
              : 'All companies',
        },
        { A: 'Download time', B: moment().format('D MMMM YYYY, HH:mm') },
      ]);
      addBorder(worksheet);
      autofitWidth(worksheet);
      worksheet = workbook.getWorksheet(2);
      const cellStyles = { alignment: { vertical: 'middle' } };
      worksheet.columns = [
        { key: 'ticker', header: 'Ticker', style: cellStyles },
        { key: 'sic', header: 'SIC', style: cellStyles },
        { key: 'year', header: 'Year', style: cellStyles },
        { key: 'documentType', header: 'Document Type', style: cellStyles },
        // { key: 'Section', header: 'Section' },
        {
          key: 'keywords',
          header: 'Total Keywords',
          style: { alignment: { wrapText: true } },
        },
        { key: 'totalWords', header: 'Total Words', style: cellStyles },
        {
          key: 'totalWordsNonNumeric',
          header: 'Total Words without numeric',
          style: cellStyles,
        },
        { key: 'allSentences', header: 'All Sentences', style: cellStyles },
        {
          key: 'includingKeywords',
          header: 'Sentences including keywords',
          style: cellStyles,
        },
      ];
      worksheet.addRows(statsPayload);
      addBorder(worksheet);
      autofitWidth(worksheet);
      worksheet = workbook.getWorksheet(3);
      const lastRow =
        sentencePayload.mergeString[
          sentencePayload.mergeString.length - 1
        ].split(':');
      const lastRowNum = lastRow[1].match(/\d+/)[0];
      worksheet.addConditionalFormatting({
        ref: `E1:G${lastRowNum}`,
        rules: [
          {
            type: 'expression',
            formulae: ['TRUE'],
            style: {
              border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              },
            },
          },
        ],
      });
      const styling = {
        alignment: { vertical: 'middle', horizontal: 'center' },
      };
      worksheet.columns = [
        {
          key: 'ticker',
          header: 'Ticker',
          style: styling,
        },
        {
          key: 'year',
          header: 'Year',
          style: styling,
        },
        {
          key: 'keywords',
          header: 'Total Keywords',
          width: 20,
          style: { alignment: { ...styling.alignment, wrapText: true } },
        },
        {
          key: 'sentences',
          header: 'Sentences',
          width: 70,
          style: {
            alignment: { vertical: 'middle', wrapText: true },
          },
        },
        {
          key: 'notes1',
          header: 'Notes 1',
          width: 50,
          style: {
            alignment: { vertical: 'middle', wrapText: true },
          },
        },
        {
          key: 'notes2',
          header: 'Notes 2',
          width: 50,
          style: {
            alignment: { vertical: 'middle', wrapText: true },
          },
        },
        {
          key: 'notes3',
          header: 'Notes 3',
          width: 50,
          style: {
            alignment: { vertical: 'middle', wrapText: true },
          },
        },
      ];
      worksheet.addRows(sentencePayload.data);
      addBorder(worksheet);
      sentencePayload.mergeString.forEach((mergeStr) =>
        worksheet.mergeCells(mergeStr)
      );
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(
        blob,
        `${selectedQuery.name.replace(' ', '_')}-${moment().format(
          'D-MMMM-YYYY-HH-mm'
        )}.xlsx`
      );
    }
  };
  const handleDownloadPDF = async () => {
    const { payload: statsPayload, type: statsActionType } = await dispatch(
      getReportStats(selectedQuery.id)
    );
    const { payload: sentencePayload, type: sentenceActionType } =
      await dispatch(getReportSentencesPdf(selectedQuery.id));
    if (
      statsActionType === actionFailure(getReportStats.type) ||
      sentenceActionType === actionFailure(getReportSentencesXlsx.type)
    )
      return;
    if (selectedQuery) {
      const { keywords, yearRange, documentSection, companies } = selectedQuery;
      const docDefinition = {
        content: [
          {
            text: 'Summary Query',
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          {
            table: {
              widths: [140, '*'],
              body: [
                ['Account', user.email],
                ['Keywords', convertFieldToSymbol(keywords)],
                ['Filter Period', `${yearRange[0]}-${yearRange[1]}`],
                [
                  'Filter Documents Section',
                  documentSection.length > 0
                    ? documentSection.map((section) => section.name).join(', ')
                    : 'Whole documents',
                ],
                [
                  'Filter Companies',
                  companies.length > 0
                    ? companies.map((company) => company.name).join(', ')
                    : 'All companies',
                ],
                ['Download time', moment().format('D MMMM YYYY, HH:mm')],
              ],
            },
          },
          {
            text: 'Query Result Statistics',
            fontSize: 18,
            bold: true,
            pageBreak: 'before',
            margin: [0, 0, 0, 10],
            pageOrientation: 'landscape',
          },
          {
            table: {
              body: [
                [
                  'Ticker',
                  'SIC',
                  'Year',
                  'Document Type',
                  // 'Section',
                  'Total Keywords',
                  'Total Words',
                  'Total Words without numeric',
                  'All Sentences',
                  'Sentences including keywords',
                ],
                ...statsPayload.map((stat) => [
                  stat.ticker,
                  stat.sic,
                  stat.year,
                  stat.documentType,
                  stat.keywords,
                  stat.totalWords,
                  stat.totalWordsNonNumeric,
                  stat.allSentences,
                  stat.includingKeywords,
                ]),
              ],
            },
          },
          {
            text: 'Notes',
            fontSize: 18,
            bold: true,
            pageBreak: 'before',
            margin: [0, 0, 0, 10],
          },
          {
            table: {
              widths: [80, 80, 90, 120, '*', '*', '*'],
              body: sentencePayload,
            },
          },
        ],
      };
      pdfMake
        .createPdf(docDefinition)
        .download(
          `${selectedQuery.name.replace(' ', '_')}-${moment().format(
            'D-MMMM-YYYY-HH-mm'
          )}.pdf`
        );
    }
  };

  return (
    <Dialog
      header="Download Option"
      size="small"
      isOpen={props.isOpen}
      onClose={props.onClose}
      dismissButton
    >
      <div className="w-full px-6 flex flex-col">
        <Button
          color="primary"
          onClick={handleDownloadXlsx}
          loading={loadingXlsx}
          className="mb-2"
        >
          Download XLSX
        </Button>
        <Button
          view="outlined"
          onClick={handleDownloadPDF}
          loading={loadingPdf}
          className="text-grey100"
        >
          Download PDF
        </Button>
      </div>
    </Dialog>
  );
}

export default DownloadQueryDialog;
