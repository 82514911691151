import React from 'react';

function IconAnalytics(props) {
  return (
    <svg
      width={80}
      height={80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.5368 9.99997C37.5701 10.0166 38.4901 10.6666 38.8468 11.64L50.7835 44.1933L46.9068 48.14L45.3468 43.89H26.6601L22.3335 55.0667C22.0939 55.6855 21.6183 56.1838 21.0113 56.452C20.4043 56.7202 19.7156 56.7362 19.0968 56.4966C18.478 56.2571 17.9796 55.7815 17.7115 55.1745C17.4433 54.5675 17.4272 53.8788 17.6668 53.26L22.6135 40.4833L22.6235 40.4566L34.1801 11.5666C34.3687 11.098 34.6948 10.6974 35.1155 10.4178C35.5362 10.1381 36.0317 9.99243 36.5368 9.99997ZM36.4001 19.4866L28.6368 38.8866H43.5135L36.4001 19.4866Z"
        fill="current"
      />
      <path
        d="M67.6852 41.8567L67.2552 41.5033L67.2452 41.4967C65.7937 40.4143 64.0013 39.8901 62.1954 40.0199C60.3894 40.1496 58.6904 40.9246 57.4085 42.2033L44.7318 55.1033C43.9452 55.9 43.3718 56.8667 43.0552 57.92L41.0985 64.4267C39.3264 64.7041 37.5214 64.6962 35.7518 64.4033C35.7334 64.4003 35.7159 64.3931 35.7007 64.3823C35.6854 64.3715 35.6728 64.3574 35.6639 64.341C35.6549 64.3246 35.6497 64.3064 35.6488 64.2878C35.6479 64.2691 35.6512 64.2505 35.6585 64.2333L35.7818 63.9667C36.0018 63.51 36.3818 62.6967 36.5085 61.86C36.5752 61.3933 36.5985 60.7467 36.3452 60.06C36.0837 59.3479 35.5876 58.746 34.9385 58.3533C33.8585 57.6867 32.5152 57.6367 31.3618 57.7033C30.207 57.7879 29.059 57.9483 27.9252 58.1833C26.3852 58.4833 24.9218 59.1733 23.4618 59.86C21.5418 60.7667 19.6285 61.6667 17.5585 61.6667C16.8152 61.6667 16.1018 61.5467 15.4318 61.32C15.0218 61.1833 14.5718 61.5167 14.6752 61.9367C14.8652 62.6967 15.2152 63.7267 15.9818 64.52C16.4469 65.0059 17.0387 65.3519 17.6903 65.5186C18.3419 65.6853 19.0272 65.6662 19.6685 65.4633C24.5152 64.0433 27.7352 63.27 29.8285 62.92C30.2685 62.8467 30.5952 63.2867 30.4618 63.7133C30.2518 64.38 29.9518 65.64 30.5752 66.9133C31.2485 68.3 32.5752 68.88 33.7218 69.1133C37.5318 69.9 40.8218 69.7967 44.8418 68.74C45.0192 68.7109 45.1941 68.6686 45.3652 68.6133L45.7552 68.49C46.3252 68.3233 46.9118 68.14 47.5185 67.9367C47.6093 67.9073 47.6983 67.8728 47.7852 67.8333L52.4085 66.3367C53.5352 65.97 54.5518 65.35 55.3652 64.52L68.0152 51.6533C70.6352 48.9833 70.6252 44.87 68.0752 42.2333L67.6852 41.8567Z"
        fill="current"
      />
    </svg>
  );
}

export default IconAnalytics;
