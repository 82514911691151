import { CSSTransition as ReactCSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

export default function CSSTransition(props) {
  const splitClassName = (str) => str.split(' ').filter((c) => c.length > 0);
  const onEnterClassName = splitClassName(props.onEnterClassName);
  const onEnteredClassName = splitClassName(props.onEnteredClassName);
  return (
    <ReactCSSTransition
      in={props.in}
      unmountOnExit
      timeout={props.timeout}
      onEnter={(node) => {
        node.classList.add(...onEnterClassName);
      }}
      onEntered={(node) => {
        node.classList.remove(...onEnterClassName);
        node.classList.add(...onEnteredClassName);
      }}
      onExit={(node) => {
        node.classList.remove(...onEnteredClassName);
        node.classList.add(...onEnterClassName);
      }}
    >
      {props.children}
    </ReactCSSTransition>
  );
}

CSSTransition.propTypes = {
  in: PropTypes.bool.isRequired,
  timeout: PropTypes.number.isRequired,
  onEnterClassName: PropTypes.string.isRequired,
  onEnteredClassName: PropTypes.string.isRequired,
};
