import { useMemo } from 'react';
import CustomTable from 'components/CustomTable';
import userProfileDefault from 'assets/images/userProfileDefault.svg';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PATH_URL from 'routers/path';
import {
  changeSortApprovals,
  downloadStudentApprovalListCSV,
} from 'redux/features/userManagement';
import { showToastError } from 'layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'redux/api/loading';
import clsx from 'clsx';

function UserApproval({
  page,
  totalPages,
  onChangePage,
  data,
  loading,
  initialSearch,
  onInputSearch,
  onChangeTotalRow,
  sortBy,
}) {
  const dispatch = useDispatch();
  const loadingCSV = useSelector(
    createLoadingSelector([downloadStudentApprovalListCSV.type])
  );
  const columns = [
    {
      id: 'profile.fullName',
      Header: 'USER',
      accessor: 'user',
      sortType: useMemo(
        () => (rowA, rowB) => {
          const nameA = rowA.original.user;
          const nameB = rowB.original.user;
          return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1;
        },
        []
      ),
      customCell: ({ value }) => {
        const valueAsArray = value.split(',');
        const fullName = valueAsArray[0];
        const email = valueAsArray[1];
        const image = valueAsArray[2] || userProfileDefault;
        return (
          <div className={'flex '}>
            <div>
              <img
                src={image}
                className={'rounded-full w-12 h-12'}
                alt={'rounded_image'}
              />
            </div>
            <div className={'flex flex-col justify-center pl-4'}>
              <div className={'text-base font-medium'}>{fullName}</div>
              <div className={'text-sm text-grey50'}>{email}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'profile.institution',
      Header: 'INSTITUTION',
      accessor: 'institution',
      customCell: ({ value }) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      id: 'profile.submissionDate',
      Header: 'SUBMISSION',
      accessor: 'submission',
      sortType: 'datetime',
      customCell: ({ value }) => (
        <>
          {value && value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-'}
        </>
      ),
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      sortable: false,
      alignment: 'center',
      customCell: ({ value }) => {
        return (
          <div
            className={
              'bg-yellow10 text-yellow50 text-center rounded-full py-0.5'
            }
          >
            {value}
          </div>
        );
      },
    },
  ];
  const defaultSort = sortBy.sort
    ? { id: sortBy.sort, desc: sortBy.order === 'desc' }
    : null;

  const location = useHistory();

  return (
    <>
      <CustomTable
        page={page}
        totalPage={totalPages}
        onChangePage={onChangePage}
        onChangeTotalRow={onChangeTotalRow}
        onRowClick={(e) => {
          location.push(`${PATH_URL.USER_LIST}/${e.id}`);
        }}
        search={initialSearch}
        onInputSearch={onInputSearch}
        isLoading={loading}
        dataTable={data}
        columnsTable={columns}
        defaultSortedColumn={defaultSort}
        onChangeSort={(sortData) => {
          dispatch(changeSortApprovals(sortData));
        }}
        additionalButton={[
          ({ baseClassName }) => (
            <button
              className={clsx(
                `${baseClassName} flex items-center ml-3`,
                loadingCSV ? 'bg-green30' : 'bg-green50'
              )}
              onClick={() => {
                dispatch(
                  downloadStudentApprovalListCSV(
                    (url) => {
                      const anchor = document.createElement('a');
                      anchor.setAttribute('href', url);
                      anchor.click();
                    },
                    () => {
                      showToastError('An error occurred');
                    }
                  )
                );
              }}
              disabled={loadingCSV}
            >
              <span className="whitespace-nowrap">
                {!loadingCSV ? 'Download CSV' : 'Please Wait...'}
              </span>
              <Icon
                iconSize={18}
                icon={IconNames.CLOUD_DOWNLOAD}
                className="fill-current text-white ml-2"
              />
            </button>
          ),
        ]}
      />
    </>
  );
}

export default UserApproval;
