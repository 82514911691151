import React from 'react';
import routes from '../routers/routes';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserIsAuthenticatedRedir } from '../utils/Routes/AuthRoutingHOC';

const generateRoutesComponents = (routes) =>
  routes.map((route) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

function UserDashboardRoutes(props) {
  const locationPath = useSelector((state) => state.router.location.pathname);
  const { UserDashboard } = routes;
  const currentRoutes = UserDashboard.routes;
  const routeSelector = currentRoutes.filter(
    (item) => item.path === locationPath
  );
  const title = routeSelector.length > 0 ? routeSelector[0].title : 'Dashboard';
  const routesComponents = generateRoutesComponents(currentRoutes);

  return (
    <UserDashboard.Layout title={title}>
      <Switch>
        {routesComponents}
        <Route>
          <Redirect to={currentRoutes[0].path} />
        </Route>
      </Switch>
    </UserDashboard.Layout>
  );
}

export default withRouter(UserIsAuthenticatedRedir(UserDashboardRoutes));
