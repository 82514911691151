export const createAction = (actionName) => {
  function _actionFn(payload) {
    return {
      type: actionName,
      payload,
    };
  }
  _actionFn.type = actionName;
  return _actionFn;
};

export const createAsyncAction = (actionName, fn) => {
  fn.type = actionName;
  return fn;
};

export const actionSuccess = (actionName) => `${actionName}_SUCCESS`;
export const actionFailure = (actionName) => `${actionName}_FAILURE`;
