import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ToastContainer, toast } from 'react-toastify';

function DashboardLayout(props) {
  return (
    <>
      {props.children}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
}

export default DashboardLayout;

export const showToastSuccess = (message, caption) => {
  toast(
    <div className="flex items-center">
      <Icon
        icon={IconNames.TICK_CIRCLE}
        className="fill-current text-green70 mr-3"
      />
      <div className="-mt-1">
        <p className={`text-md ${caption ? 'font-medium' : 'font-normal'}`}>
          {message}
        </p>
        {caption && <p className="text-sm font-normal">{caption}</p>}
      </div>
    </div>,
    {
      className: 'text-green100  bg-green10',
    }
  );
};
export const showToastError = (message, caption = null) => {
  toast(
    <div className="flex items-start">
      <Icon
        icon={IconNames.WARNING_SIGN}
        className="fill-current text-red70 mr-3"
      />
      <div className="-mt-1">
        <p className={`text-md ${caption ? 'font-medium' : 'font-normal'}`}>
          {message}
        </p>
        {caption && <p className="text-sm font-normal">{caption}</p>}
      </div>
    </div>,
    {
      className: 'text-red100 bg-red10',
    }
  );
};
