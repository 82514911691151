import {
  createAsyncAction,
  actionSuccess,
  actionFailure,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import ENDPOINTS from '../urls';

export const getListVoucher = createAsyncAction(
  'GET_LIST_VOUCHER',
  (params = {}) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_LIST_VOUCHER',
      'get',
      `${ENDPOINTS.VOUCHER_LIST}${
        params && Object.keys(params).length > 0
          ? `?${Object.keys(params)
              .filter((v) => !!params[v])
              .map((v) => `${v}=${params[v]}`)
              .join('&')}`
          : ''
      }`
    );
  }
);

export const createVoucher = createAsyncAction(
  'CREATE_VOUCHER',
  (data, onSuccess = null, onError = null) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'CREATE_VOUCHER',
      'post',
      ENDPOINTS.CREATE_VOUCHER,
      data,
      'application/json',
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  }
);
export const updateVoucherData = createAsyncAction(
  'UPDATE_VOUCHER_DATA',
  (data, onSuccess = null, onError = null) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'UPDATE_VOUCHER_DATA',
      'put',
      ENDPOINTS.CREATE_VOUCHER,
      data,
      'application/json',
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  }
);

export const deleteVoucher = createAsyncAction(
  'DELETE_VOUCHER',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'DELETE_VOUCHER',
      'delete',
      `${ENDPOINTS.DELETE_VOUCHER}/${data.id}`,
      null,
      'application/json'
    );
  }
);

const initialState = {
  listVoucher: {},
};

const voucherManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(getListVoucher.type):
      return {
        ...state,
        listVoucher: action.payload,
      };
    case actionFailure(getListVoucher.type): {
      return {
        ...state,
        listVoucher: {},
      };
    }

    default:
      return state;
  }
};

export default voucherManagementReducer;
