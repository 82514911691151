import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import history from './routers/history';
import { ConnectedRouter } from 'connected-react-router';
import {
  UnverifiedEmailUser,
  UserIsAuthenticatedRedir,
} from './utils/Routes/AuthRoutingHOC';
import AuthRoutes from './Routes/AuthRoutes';
import UserDashboardRoutes from './Routes/UserDashboardRoutes';
import AdminDashboardRoutes from './Routes/AdminDashboardRoutes';
import FullViewRoutes from './Routes/FullViewRoutes';
import EmailAction from './Routes/EmailAction';
import NotFound from './Pages/NotFound';
import PATH_URL from './routers/path';
import VerifyEmail from './Pages/VerifyEmail';
import { Helmet } from 'react-helmet';
import SplashScreen from './Pages/SplashScreen';
import TermsConditions from './Pages/TermsConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { register, getUserProfile, signOut } from './redux/features/auth';
import { getUserLastInvoices } from './redux/features/userManagement';
import { createLoadingSelector } from './redux/api/loading';
import { actionFailure } from './redux/utils/actionCreator';

const { REACT_APP_MIDTRANS_CLIENT_KEY, REACT_APP_API_STAGE } = process.env;

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const userProfile = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const loadingProfile = useSelector(
    createLoadingSelector([getUserProfile.type])
  );
  const loadingRegister = useSelector(createLoadingSelector([register.type]));

  useEffect(() => {
    if (!isLoaded(auth)) {
      setLoading(true);
    } else {
      if (!isEmpty(auth) && !userProfile.role) {
        dispatch(getUserProfile()).then((response) => {
          const { type: actionType, payload: profile } = response;
          const getProfileFailed =
            actionFailure(getUserProfile.type) === actionType;
          if ((getProfileFailed && !loadingRegister) || profile?.blockStatus) {
            dispatch(signOut());
          }
          if (response && response.payload && response.payload.id) {
            dispatch(getUserLastInvoices(response.payload.id));
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [loading, auth, userProfile, dispatch, loadingRegister]);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s - ESGI Intelligence App"
        defaultTitle="ESGI Intelligence App"
      >
        <meta name="description" content="ESGI intelligence app" />
        <script
          src={
            REACT_APP_API_STAGE === 'production'
              ? 'https://app.midtrans.com/snap/snap.js'
              : 'https://app.sandbox.midtrans.com/snap/snap.js'
          }
          data-client-key={REACT_APP_MIDTRANS_CLIENT_KEY}
        ></script>
      </Helmet>
      <ConnectedRouter history={history}>
        {loading || loadingProfile ? (
          <SplashScreen />
        ) : (
          <Switch>
            <Route exact path="/">
              <Redirect to="/u" />
            </Route>
            <Route path="/a/:path?">
              <AuthRoutes />
            </Route>
            <Route path="/u">
              <UserDashboardRoutes />
            </Route>
            <Route path="/m">
              <AdminDashboardRoutes />
            </Route>
            <Route
              path="/d"
              component={UserIsAuthenticatedRedir(FullViewRoutes)}
            />
            <Route exact path={PATH_URL.EMAIL_ACTION} component={EmailAction} />
            <Route
              exact
              path={PATH_URL.VERIFY_EMAIL}
              component={UnverifiedEmailUser(VerifyEmail)}
            />
            <Route
              exact
              path={PATH_URL.TERMS_CONDITIONS}
              component={TermsConditions}
            />
            <Route
              exact
              path={PATH_URL.PRIVACY_POLICY}
              component={PrivacyPolicy}
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </ConnectedRouter>
    </React.Fragment>
  );
}

export default App;
