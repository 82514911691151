import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';
import {
  useTable,
  useSortBy,
  useBlockLayout,
  usePagination,
} from 'react-table';
import clsx from 'clsx';
import Spinner from 'components/Spinner';
import Checkbox from './Checkbox';
import useVisible from '../../hooks/useVisible';
import RenameColumnDialog from './RenameColumnDialog';
import DeleteColumnDialog from './DeleteColumnDialog';
import DeleteNoteDialog from './DeleteNoteDialog';
import {
  addNotesColumn,
  saveNote,
  changeSelectedQueryDocument,
  selectedDocumentQuerySelector,
  renameNotesColumn,
  updateNotes,
  removeNotesColumn,
  removeNote,
  changeSelectedQueryDocumentIndex,
} from 'redux/features/textAnalytics';
import { createLoadingSelector } from 'redux/api/loading';

const DEFAULT_PAGE_SIZE = 10;

function SentenceTable(props) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const {
    ref: selectPageRef,
    isVisible: isRowOptionVisible,
    setIsVisible: setRowOptionIsVisible,
  } = useVisible();
  const {
    ref: columnMenuRef,
    isVisible: isColumnMenuVisible,
    setIsVisible: setColumnMenuIsVisible,
  } = useVisible();
  const currentDocument = useSelector(selectedDocumentQuerySelector);
  const loadingUpdateNotes = useSelector(
    createLoadingSelector([updateNotes.type])
  );
  const [noteText, setNoteText] = useState('');
  const [containerWidth, setContainerWidth] = useState(100);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNotesOnly, setShowNotesOnly] = useState(false);
  const [tableHovered, setTableHovered] = useState(false);
  const [colIndex, setColIndex] = useState(null);
  const [dialogOpen, setDialogOpen] = useState({
    renameColumn: false,
    deleteColumn: false,
    deleteNote: false,
  });
  const data = useMemo(() => {
    const rows = currentDocument.data.body;
    if (showNotesOnly)
      return Array.isArray(rows)
        ? rows.filter(
            (sentence) =>
              sentence.notes && Object.keys(sentence.notes).length > 0
          )
        : [];
    return Array.isArray(rows) ? rows : [];
  }, [currentDocument.data.body, showNotesOnly]);
  const noteColumns = useMemo(
    () =>
      currentDocument.data.headers.map((header, index) => ({
        Header: header,
        accessor: `notes.notes${index + 1}`,
        isEditable: true,
        width: 320,
      })),
    [currentDocument.data.headers]
  );
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedCell, setSelectedCell] = useState({ row: null, col: null });
  const [hoveredRow, setHoveredRow] = useState(null);
  const columns = useMemo(() => {
    let cols = [
      { Header: 'Sentences', accessor: 'fields.text', width: 640 },
      ...noteColumns,
    ];
    cols =
      noteColumns.length < 3
        ? cols.concat({
            Header: '',
            accessor: 'addNote',
            isButton: true,
            width: 50,
          })
        : cols;
    const totalColWidth = cols.reduce((acc, col) => (acc += col.width), 0);
    if (totalColWidth < containerWidth) {
      const colsLength = cols.length;
      const remainingWidth = containerWidth - totalColWidth;
      return cols.map((col, index) => {
        if (index === colsLength - 2) {
          return { ...col, width: col.width + remainingWidth };
        }
        return col;
      });
    }
    return cols;
  }, [noteColumns, containerWidth]);
  const {
    getTableProps,
    getTableBodyProps,
    // rows,
    prepareRow,
    page,
    headerGroups,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: DEFAULT_PAGE_SIZE },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    useBlockLayout,
    usePagination
  );
  const handleAddNoteColumn = () => {
    dispatch(addNotesColumn(`Notes ${noteColumns.length + 1}`));
  };
  const handleStoreNotes = () => {
    const { row: sentenceIndex, cell: columnIndex } = editingIndex;
    dispatch(
      saveNote(sentenceIndex, columnIndex, noteText, () => {
        setNoteText('');
        setEditingIndex(null);
      })
    );
  };
  const renderCell = (rowIndex, cellIndex, cell) => {
    if (!cell.column.isButton && cellIndex !== 0) {
      if (hoveredRow === rowIndex && editingIndex === null) {
        return (
          <div className="flex">
            <span className="mr-2">{cell.render('Cell')}</span>
            <div className="flex-shrink-0 mr-2">
              <button
                onClick={() => {
                  setEditingIndex({ row: rowIndex, cell: cellIndex });
                  if (cell.value) setNoteText(cell.value);
                }}
              >
                <Icon
                  icon={IconNames.ANNOTATION}
                  iconSize={16}
                  className={clsx('fill-current text-blue50')}
                />
              </button>
              {cell.value && (
                <button
                  className="ml-2"
                  onClick={() =>
                    handleOpenDeleteNoteDialog(rowIndex, cellIndex)
                  }
                >
                  <Icon
                    icon={IconNames.TRASH}
                    iconSize={16}
                    className={clsx('fill-current text-red50')}
                  />
                </button>
              )}
            </div>
          </div>
        );
      }
      if (
        editingIndex !== null &&
        editingIndex.row === rowIndex &&
        editingIndex.cell === cellIndex
      ) {
        return (
          <div className="flex flex-col">
            <textarea
              className="border border-grey30 rounded"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
            />
            <div className="flex justify-end mt-2">
              <button
                onClick={() => {
                  setEditingIndex(null);
                  setNoteText('');
                }}
                className="border border-red50 text-red50 w-24 py-3 font-bold text-xs rounded mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleStoreNotes}
                className="border border-blue50 text-white bg-blue50 w-24 py-3 font-bold text-xs rounded"
              >
                {loadingUpdateNotes ? (
                  <Spinner className="inline h-4 w-4 mx-auto fill-current text-grey20" />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>
        );
      }
    }
    return cell.render('Cell');
  };
  const getWidth = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const width = container.offsetWidth;
      setContainerWidth(width);
    }
  }, []);
  const handleOpenColumnMenu = (e, columnIndex) => {
    e.stopPropagation();
    setColIndex(columnIndex);
    setColumnMenuIsVisible(true);
  };
  const handleOpenDeleteColDialog = (e, columnIndex) => {
    e.stopPropagation();
    setDialogOpen((prev) => ({ ...prev, deleteColumn: true }));
    setColumnMenuIsVisible(false);
    setSelectedCell((prev) => ({ ...prev, col: columnIndex }));
  };
  const handleRemoveNoteColumn = () => {
    dispatch(
      removeNotesColumn(selectedCell.col, () => {
        setDialogOpen((prev) => ({ ...prev, deleteColumn: false }));
      })
    );
  };
  const handleOpenRenameDialog = (e, columnName) => {
    e.stopPropagation();
    setDialogOpen((prev) => ({ ...prev, renameColumn: true }));
    setColumnMenuIsVisible(false);
    setNoteText(columnName);
  };
  const handleSubmitRename = (columnName) => {
    dispatch(
      renameNotesColumn(colIndex, columnName, () => {
        setDialogOpen((prev) => ({ ...prev, renameColumn: false }));
        setNoteText('');
      })
    );
  };
  const handleOpenDeleteNoteDialog = (rowIndex, colIndex) => {
    setDialogOpen((prev) => ({ ...prev, deleteNote: true }));
    setSelectedCell({ row: rowIndex, col: colIndex });
  };
  const handleRemoveNote = () => {
    dispatch(
      removeNote(selectedCell.row, selectedCell.col, () => {
        setSelectedCell({ row: null, col: null });
        setDialogOpen((prev) => ({ ...prev, deleteNote: false }));
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(changeSelectedQueryDocument(null));
      dispatch(changeSelectedQueryDocumentIndex(null));
    };
  }, [dispatch]);

  useEffect(() => {
    getWidth();
    window.addEventListener('resize', getWidth, true);
    return () => {
      window.removeEventListener('resize', getWidth, true);
    };
  }, [getWidth]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      function checkScroll() {
        const scrollLeft = container.scrollLeft;
        setIsScrolled(scrollLeft > 0);
      }
      container.addEventListener('scroll', checkScroll, true);
      return () => {
        container.removeEventListener('scroll', checkScroll, true);
      };
    }
  }, [containerWidth]);

  return (
    <div className="h-full">
      <div className="w-full h-12 px-4 flex items-center justify-between">
        <button
          onClick={() => dispatch(changeSelectedQueryDocument(null))}
          className="text-base text-grey100 font-medium flex items-center"
        >
          <Icon
            icon={IconNames.ARROW_LEFT}
            iconSize={20}
            className="fill-current text-grey70 mr-3"
          />
          <span>
            {currentDocument.company.ticker} - {currentDocument.year}
          </span>
        </button>
        <div>
          <Checkbox
            label="Show sentences that have notes"
            checked={showNotesOnly}
            onChange={() => setShowNotesOnly((prev) => !prev)}
          />
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className={clsx(
          'overflow-y-auto',
          tableHovered ? 'overflow-x-auto' : 'overflow-x-hidden'
        )}
        onMouseOver={() => setTableHovered(true)}
        onMouseLeave={() => setTableHovered(false)}
        style={{
          maxHeight: 'calc(100% - 6.5rem)',
        }}
      >
        {/* table */}
        <div
          {...getTableProps()}
          ref={containerRef}
          className="min-w-full"
          onMouseOver={() => setTableHovered(true)}
          onMouseLeave={() => setTableHovered(false)}
        >
          {/* table heading */}
          <div className="min-w-full sticky top-0 z-10">
            {headerGroups.map((headerGroup) => (
              // row
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="border-b border-grey100"
              >
                {headerGroup.headers.map((column, index) => (
                  // cell
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.Header}
                    onClick={(e) => {
                      !column.isButton &&
                        editingIndex === null &&
                        column
                          .getHeaderProps(column.getSortByToggleProps())
                          .onClick(e);
                    }}
                    className={clsx(
                      'h-14 px-2 select-none text-grey50 text-xs font-medium whitespace-nowrap cursor-pointer',
                      index === 0 && containerWidth > 960 && 'sticky left-0',
                      column.isSorted ? 'bg-blue0' : 'bg-white',
                      column.isButton ? 'text-center' : 'text-left'
                    )}
                  >
                    {!column.isButton && (
                      <div className="h-full flex justify-between items-center">
                        <div className="flex items-center">
                          {column.render('Header')}
                          <div className="ml-2">
                            <button
                              onClick={(e) => {
                                column
                                  .getHeaderProps(column.getSortByToggleProps())
                                  .onClick(e);
                              }}
                              className="flex flex-col items-center"
                            >
                              <Icon
                                icon={IconNames.CARET_UP}
                                iconSize={12}
                                className={clsx(
                                  'fill-current',
                                  column.isSorted && !column.isSortedDesc
                                    ? 'text-blue50'
                                    : 'text-grey20'
                                )}
                              />
                              <Icon
                                icon={IconNames.CARET_DOWN}
                                iconSize={12}
                                className={clsx(
                                  'fill-current -mt-1.5',
                                  column.isSorted && column.isSortedDesc
                                    ? 'text-blue50'
                                    : 'text-grey20'
                                )}
                              />
                            </button>
                          </div>
                        </div>
                        {column.isEditable && (
                          <Popover
                            isOpen={isColumnMenuVisible && colIndex === index}
                            position={Position.BOTTOM_RIGHT}
                            minimal={true}
                            popoverClassName="shadow"
                          >
                            <button
                              onClick={(e) => handleOpenColumnMenu(e, index)}
                              className="pr-4"
                            >
                              <Icon
                                icon={IconNames.MORE}
                                iconSize={12}
                                className={clsx('fill-current text-grey30')}
                              />
                            </button>
                            <div
                              ref={columnMenuRef}
                              className="w-48 flex flex-col rounded shadow-lg bg-white overflow-hidden"
                            >
                              <button
                                onClick={(e) =>
                                  handleOpenRenameDialog(
                                    e,
                                    column.render('Header')
                                  )
                                }
                                className="w-full px-4 py-2 text-sm text-left font-medium text-grey60 hover:bg-grey0"
                              >
                                Rename column
                              </button>
                              <button
                                onClick={(e) =>
                                  handleOpenDeleteColDialog(e, index)
                                }
                                className="w-full px-4 py-2 text-sm text-left font-medium text-red50 hover:bg-grey0"
                              >
                                Delete column
                              </button>
                            </div>
                          </Popover>
                        )}
                      </div>
                    )}
                    {column.isButton && noteColumns.length < 3 && (
                      <div className="h-full flex justify-center items-center">
                        <button onClick={handleAddNoteColumn}>
                          <Icon
                            icon={IconNames.ADD}
                            iconSize={20}
                            className={clsx('fill-current text-blue50')}
                          />
                        </button>
                      </div>
                    )}
                    {index === 0 && containerWidth > 960 && isScrolled && (
                      <span className="block h-full w-1.5 absolute right-0 top-0 bg-gradient-to-r from-grey20"></span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          {/* table body */}
          <div {...getTableBodyProps()} className="relative">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                // row
                <div
                  {...row.getRowProps()}
                  className="cursor-pointer group transition-colors"
                  onMouseOver={() => setHoveredRow(row.index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {row.cells.map((cell, index) => {
                    const {
                      column: { isSorted },
                    } = cell;
                    return (
                      // cell
                      <div
                        {...cell.getCellProps()}
                        className={clsx(
                          'px-2 py-3 text-grey100 text-xs font-normal transition-colors group-hover:bg-blue0',
                          index === 0 &&
                            containerWidth > 960 &&
                            'sticky left-0',
                          isSorted && 'bg-blue0',
                          i % 2 === 0 && !isSorted ? 'bg-white' : 'bg-grey0'
                        )}
                      >
                        {renderCell(row.index, index, cell)}
                        {index === 0 && containerWidth > 960 && isScrolled && (
                          <span className="block h-full w-1.5 absolute right-0 top-0 bg-gradient-to-r from-grey20"></span>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full h-11 px-7 flex items-center justify-end">
        <div
          ref={selectPageRef}
          onClick={() => setRowOptionIsVisible(!isRowOptionVisible)}
          className="text-sm text-grey50 cursor-pointer select-none"
        >
          Show
          <Select
            activeItem={pageSize}
            filterable={false}
            onItemSelect={(item, e) => {
              e.stopPropagation();
              setRowOptionIsVisible(false);
              setPageSize(item);
            }}
            popoverProps={{
              isOpen: isRowOptionVisible,
              minimal: true,
              popoverClassName: 'shadow rounded',
              position: Position.BOTTOM,
            }}
            items={[10, 25]}
            itemListRenderer={({ items, renderItem, itemsParentRef }) => {
              return (
                <ul
                  ref={itemsParentRef}
                  className="w-20 rounded max-h-42 overflow-y-auto p-2 bg-white"
                >
                  {items.map(renderItem)}
                </ul>
              );
            }}
            itemRenderer={(rowCount, { handleClick, modifiers }) => {
              return (
                <li
                  key={rowCount}
                  onClick={handleClick}
                  className={`${
                    modifiers.active ? 'bg-lightgrey0' : 'bg-white'
                  } text-sm text-center text-grey100 rounded transition-colors hover:bg-lightgrey0 cursor-pointer mb-px py-2`}
                >
                  {rowCount}
                </li>
              );
            }}
          >
            <div>
              <span className="ml-1 cursor-pointer">{pageSize} rows</span>
              <button className="ml-2">
                <Icon
                  icon={IconNames.CARET_DOWN}
                  iconSize={18}
                  className={clsx(
                    'fill-current text-grey50 transition-transform transform -mt-1',
                    isRowOptionVisible ? 'rotate-180' : 'rotate-0'
                  )}
                />
              </button>
            </div>
          </Select>
        </div>
        <div className="ml-3">
          <button onClick={() => canPreviousPage && previousPage()}>
            <Icon
              icon={IconNames.CHEVRON_LEFT}
              iconSize={18}
              className={clsx(
                'fill-current',
                canPreviousPage ? 'text-grey50' : 'text-grey20'
              )}
            />
          </button>
          <button onClick={() => canNextPage && nextPage()} className="ml-2">
            <Icon
              icon={IconNames.CHEVRON_RIGHT}
              iconSize={18}
              className={clsx(
                'fill-current',
                canNextPage ? 'text-grey50' : 'text-grey20'
              )}
            />
          </button>
        </div>
      </div>
      <RenameColumnDialog
        columnName={noteText}
        isOpen={dialogOpen.renameColumn}
        onSubmit={handleSubmitRename}
        onClose={() => {
          setDialogOpen((prev) => ({ ...prev, renameColumn: false }));
          setNoteText('');
        }}
      />
      <DeleteColumnDialog
        isOpen={dialogOpen.deleteColumn}
        onSubmit={handleRemoveNoteColumn}
        onClose={() =>
          setDialogOpen((prev) => ({ ...prev, deleteColumn: false }))
        }
      />
      <DeleteNoteDialog
        isOpen={dialogOpen.deleteNote}
        onSubmit={handleRemoveNote}
        onClose={() => {
          setDialogOpen((prev) => ({ ...prev, deleteNote: false }));
        }}
      />
    </div>
  );
}

export default SentenceTable;
