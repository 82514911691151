import { toast } from 'react-toastify';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export const showToastError = (message, caption = null) => {
  toast(
    <div className="flex items-start">
      <Icon
        icon={IconNames.WARNING_SIGN}
        className="fill-current text-red70 mr-3"
      />
      <div className="-mt-1">
        <p className={`text-md ${caption ? 'font-medium' : 'font-normal'}`}>
          {message}
        </p>
        {caption && <p className="text-sm font-normal">{caption}</p>}
      </div>
    </div>,
    {
      className: `text-red100 bg-red10 flex justify-between items-center h-12 min-h-full`,
    }
  );
};

export const showToastSuccess = (message, caption) => {
  toast(
    <div className="flex items-center">
      <Icon
        icon={IconNames.TICK_CIRCLE}
        className="fill-current text-green70 mr-3"
      />
      <div>
        <p className={`text-md ${caption ? 'font-medium' : 'font-normal'}`}>
          {message}
        </p>
        {caption && <p className="text-sm font-normal">{caption}</p>}
      </div>
    </div>,
    {
      className: `text-green100 bg-green10 flex justify-between items-center h-12 min-h-full`,
    }
  );
};
