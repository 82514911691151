const URL = {};
const makeQueryString = (param) => {
  let query = '?';
  const paramLength = Object.keys(param).length;
  Object.keys(param).map((value, index) => {
    query += `${value}=${param[value]}`;
    if (index < paramLength - 1) {
      query += '&';
    }
    return true;
  });
  return query;
};

const queryString = (url, key) => {
  const query = new URLSearchParams(url);
  return query.get(key);
};

URL.makeQueryString = makeQueryString;
URL.queryString = queryString;
export default URL;
