import { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/Button';
import userPhoto from '../../assets/images/userProfileDefault.svg';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
import {
  getUserProfile,
  uploadProfilePict,
  updateUserData,
} from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import TextField from '../../components/TextField';

function PanelEditProfile() {
  const _isMounted = useRef(true);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.auth);
  const loading = useSelector((state) =>
    createLoadingSelector([
      getUserProfile.type,
      updateUserData.type,
      uploadProfilePict.type,
    ])(state)
  );
  const imageUploadRef = useRef(null);
  const [userPhotoUpload, setUserPhotoUpload] = useState();
  const schema = Yup.object().shape({
    fullName: Yup.string().required(),
    phoneNumber: Yup.string()
      .min(10, 'Invalid phone number')
      .matches(/^\d+$/, 'Phone number must not contain non numeric character')
      .required('Phone number is required'),
  });
  const defaultValues = useMemo(
    () => ({
      fullName: userDetail.profile && userDetail.profile.fullName,
      phoneNumber: userDetail.phoneNumber,
    }),
    [userDetail]
  );
  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const handleSelectImage = async (e) => {
    const imageFile = e.target.files[0];
    const type = imageFile.type.split('/')[1];
    if (['jpg', 'jpeg', 'png'].includes(type.toLowerCase())) {
      if (imageFile.size > 5 * 1024 * 1024) {
        showToastError('The size of the file must be less than 5MB');
      } else {
        setUserPhotoUpload(imageFile);
      }
    } else {
      showToastError('Please Upload File JPG or PNG');
    }
    imageUploadRef.current.value = '';
  };
  const onSubmit = async (data) => {
    let submitData = {
      ...data,
      id: userDetail.id,
      type: userDetail.type,
      cardIdUrl: userDetail.profile && userDetail.profile.cardIdUrl,
      institution: userDetail.profile && userDetail.profile.institution,
    };
    try {
      if (userPhotoUpload) {
        const formUploadImage = new FormData();
        formUploadImage.set('file', userPhotoUpload);
        const { payload } = await dispatch(uploadProfilePict(formUploadImage));
        if (payload) submitData.photoUrl = payload.publicUrl;
        else throw new Error('Failed when upload image');
      }
      const actionDispatced = await dispatch(updateUserData(submitData));
      if (actionDispatced.type === `${updateUserData.type}_SUCCESS`) {
        await dispatch(getUserProfile());
        showToastSuccess('Your profile has been updated successfully');
      } else {
        throw new Error('Failed when update profile');
      }
    } catch (err) {
      showToastError(err.message || 'Something wrong...');
    }
  };
  useEffect(() => {
    if (userDetail.id) {
      reset(defaultValues);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [userDetail, reset, defaultValues]);
  return (
    <div className="w-full">
      <p className="mb-5 text-base text-grey50">
        You can update your Profile at any time and it will automatically update
        anywhere your profile photo and name appear
      </p>
      <div className="w-full flex items-center justify-start mb-5">
        <div
          className="w-20 h-20 mr-5 rounded-full overflow-hidden bg-no-repeat bg-cover bg-center shadow"
          style={{
            backgroundImage: `url(${
              userPhotoUpload
                ? URL.createObjectURL(userPhotoUpload)
                : userDetail.photoUrl || userPhoto
            })`,
          }}
        />
        <div>
          <p className="text-sm text-grey50 mb-2">
            JPG or PNG. Max size of 5 Mb
          </p>
          <div className="flex">
            <Button
              color="primary"
              size="small"
              className="inline-block mr-2"
              onClick={() => {
                if (imageUploadRef.current) imageUploadRef.current.click();
              }}
            >
              Upload New Picture
            </Button>
            <Button
              color="danger"
              size="small"
              onClick={() => setUserPhotoUpload('')}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            id="photoUrl"
            type="file"
            style={{ display: 'none' }}
            onChange={handleSelectImage}
            ref={imageUploadRef}
          />
          <div className="w-full mb-3">
            <TextField
              id="fullName-input"
              type="text"
              name="fullName"
              ref={register}
              label="Full Name"
              defaultBorder={false}
              required
              className="border-blue50 rounded w-full p-2 px-3 focus:border-blue50"
            />
            <p className="text-xs text-red50 mt-px">
              {errors.fullName?.message}
            </p>
          </div>
          <div className="w-full mb-3">
            <TextField
              id="email-input"
              type="email"
              name="email"
              value={userDetail.email}
              disabled
              label="Email"
              required
              className="border-transparent rounded w-full bg-grey5 p-2 px-3"
            />
          </div>
          <div className="w-full mb-3">
            <TextField
              id="phoneNumber-input"
              type="text"
              name="phoneNumber"
              label="Phone Number"
              required
              ref={register}
              defaultBorder={false}
              className="border border-blue50 rounded w-full p-2 px-3 focus:border-blue50"
            />
            <p className="text-xs text-red50 mt-px">
              {errors.phoneNumber?.message}
            </p>
          </div>
          <Button
            type="submit"
            color="primary"
            className="mt-5"
            isLoading={loading}
          >
            Save Profile
          </Button>
        </form>
      </div>
    </div>
  );
}

export default PanelEditProfile;
