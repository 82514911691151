import { useState } from 'react';
import Countdown from './Countdown';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { getPayment } from 'redux/features/userPayment';
import DialogCancelOrder from './DialogCancelOrder';

export default function General({
  invoiceNumber,
  snapToken,
  price,
  dueDate,
  cancel,
  loadingCancel,
  setShowDetailInvoice,
  differenceInSecond,
}) {
  const dispatch = useDispatch();
  const [showDialogCancel, setShowDialogCancel] = useState(false);
  const recheckOrder = () => dispatch(getPayment(invoiceNumber, 1000));

  return (
    <div className="border w-2/5 p-6">
      <div className={'text-center'}>
        <div className={'text-xl'}>Complete payment in</div>
        <Countdown differenceInSecond={differenceInSecond} />
        <div className={'text-xl text-grey100'}>{dueDate}</div>
      </div>
      <div className={'mt-10 px-4 pb-4 rounded mb-4'}>
        <div className={'flex justify-between items-center'}>
          <div>
            <div className={'text-sm text-gray50'}>Total Payment</div>
            <div className={'text-xl text-red50 font-medium'}>{price}</div>
          </div>
          <div className={'text-blue50 cursor-pointer text-base font-medium'}>
            <button
              onClick={() => {
                setShowDetailInvoice(true);
              }}
            >
              View Invoice
            </button>
          </div>
        </div>
      </div>

      <div className={'flex justify-between'}>
        <Button
          view="outlined"
          size="medium"
          onClick={() => setShowDialogCancel(true)}
          className="w-1/2"
          disabled={loadingCancel}
          isLoading={loadingCancel}
        >
          Cancel Order
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={() => {
            if (snapToken) {
              /* global snap */
              snap.pay(snapToken, {
                onSuccess: recheckOrder,
                onPending: recheckOrder,
                onClose: recheckOrder,
                onError: recheckOrder,
              });
            }
          }}
          className="w-1/2 ml-4"
          disabled={!snapToken}
        >
          Payment Detail
        </Button>
      </div>
      <DialogCancelOrder
        isOpen={showDialogCancel}
        isLoading={loadingCancel}
        onClose={() => !loadingCancel && setShowDialogCancel(false)}
        onConfirm={async () => {
          await cancel();
          setShowDialogCancel(false);
        }}
      />
    </div>
  );
}
