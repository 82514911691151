import { useState } from 'react';
import {
  Icon,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ReactComponent as WhatsAppIcon } from 'assets/svg/whatsapp_icon.svg';
import clsx from 'clsx';
import CSSTransition from 'components/CSSTransition';

export default function HelpFloat() {
  const [hidden, setHidden] = useState(true);
  const baseFloatClassName =
    'flex items-center justify-center rounded-full bg-green50 hover:bg-green60 shadow-lg transition-colors cursor-pointer focus:outline-none';
  return (
    <div className="flex flex-col items-end fixed bottom-9 right-8">
      <CSSTransition
        in={!hidden}
        timeout={300}
        onEnterClassName="opacity-0 translate-x-8"
        onEnteredClassName="opacity-100 translate-x-0"
      >
        <div
          className={clsx(
            'flex items-center mr-1.5 mb-2 transform transition-all'
          )}
        >
          <Popover
            minimal
            interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
            content={
              <span className="px-4 py-1 rounded text-white text-xs bg-grey70 block">
                WhatsApp
              </span>
            }
            position={Position.LEFT}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/6281234826424?text=Halo, saya mendapat info dari website ESGI Dataset dan ingin bertanya lebih lanjut"
              className={`${baseFloatClassName} h-11 w-11 ml-2`}
            >
              <WhatsAppIcon
                className="fill-current text-white"
                height={21}
                width={21}
              />
            </a>
          </Popover>
        </div>
      </CSSTransition>
      <CSSTransition
        in={!hidden}
        timeout={200}
        onEnterClassName="opacity-0 translate-x-8"
        onEnteredClassName="opacity-100 translate-x-0"
      >
        <div
          className={clsx(
            'flex items-center mr-1.5 mb-2 transform transition-all'
          )}
        >
          <Popover
            minimal
            interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
            content={
              <span className="px-4 py-1 rounded text-white text-xs bg-grey70 block">
                Email
              </span>
            }
            position={Position.LEFT}
          >
            <a
              href="mailto:products@esgi.ai"
              className={`${baseFloatClassName} text-white hover:text-white h-11 w-11 ml-2`}
            >
              <Icon
                icon={IconNames.ENVELOPE}
                iconSize={21}
                className="fill-current text-white"
              />
            </a>
          </Popover>
        </div>
      </CSSTransition>
      <div className="flex items-center">
        <span className="px-4 py-1 rounded text-white text-xs bg-grey70 block">
          Ask a Question
        </span>
        <div
          className={clsx(
            baseFloatClassName,
            !hidden && 'bg-green60',
            'focus:outline-none h-14 w-14 ml-2'
          )}
          onClick={() => setHidden((prev) => !prev)}
        >
          <Icon
            icon={IconNames.HEADSET}
            iconSize={21}
            className="fill-current text-white"
          />
        </div>
      </div>
    </div>
  );
}
