import { useMemo } from 'react';
import CustomTable from 'components/CustomTable';
import String from 'utils/String';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import userProfileDefault from 'assets/images/userProfileDefault.svg';
import moment from 'moment';
import useVisible from '../../../hooks/useVisible';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeSortUsers,
  downloadUserListCSV,
} from 'redux/features/userManagement';
import { showToastError } from 'layouts/DashboardLayout';
import { createLoadingSelector } from 'redux/api/loading';
import clsx from 'clsx';

function UserList({
  loading,
  data,
  onViewDetail,
  onChangeUserRole,
  onBlockUser,
  setVisibleDropdownIndex,
  visibleDropdownIndex,
  onFilterChange,
  initialSearch,
  onInputSearch,
  onChangeTotalRow,
  totalPages,
  page,
  onChangePage,
  sortBy,
}) {
  const dispatch = useDispatch();
  const loadingCSV = useSelector(
    createLoadingSelector([downloadUserListCSV.type])
  );
  const { ref, isVisible, setIsVisible } = useVisible();
  const columns = [
    {
      id: 'profile.fullName',
      Header: 'USER',
      accessor: 'user',
      sortType: useMemo(
        () => (rowA, rowB) => {
          const nameA = rowA.original.user;
          const nameB = rowB.original.user;
          return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1;
        },
        []
      ),
      customCell: ({ value }) => {
        const valueAsArray = value.split(',');
        const fullName = valueAsArray[0];
        const email = valueAsArray[1];
        const image = valueAsArray[2] || userProfileDefault;
        return (
          <div className={'flex '}>
            <div>
              <img
                src={image}
                className={'rounded-full w-12 h-12'}
                alt={'rounded_image'}
              />
            </div>
            <div className={'flex flex-col justify-center pl-4'}>
              <div className={'text-base font-medium'}>{fullName}</div>
              <div className={'text-sm text-grey50'}>{email}</div>
            </div>
          </div>
        );
      },
    },
    {
      Header: 'CREATED',
      accessor: 'createdAt',
      sortType: 'datetime',
      customCell: ({ value }) => (
        <>
          {value && value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-'}
        </>
      ),
    },
    {
      Header: 'LAST SIGN IN',
      accessor: 'lastSignin',
      sortType: 'datetime',
      customCell: ({ value }) => (
        <>
          {value && value.getTime() !== 0
            ? moment(value).format('DD MMM YYYY')
            : '-'}
        </>
      ),
    },
    {
      Header: 'ROLE',
      accessor: 'role',
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      customCell: ({ value }) => {
        const upperCaseValue = String.capitalizeFirstLetter(value);
        if (value === 'general') {
          return (
            <div
              className={
                'bg-blue10 text-blue50 text-center p-1 rounded-full py-0.5'
              }
            >
              {upperCaseValue}
            </div>
          );
        }

        if (value === 'student') {
          return (
            <div
              className={
                'bg-yellow10 text-yellow50 text-center p-1 rounded-full py-0.5'
              }
            >
              {upperCaseValue}
            </div>
          );
        }
      },
    },
    {
      id: 'blockStatus',
      Header: 'STATUS',
      accessor: 'whichStatus',
      customCell: ({ value }) => {
        let className = '';
        if (value === 'Active') {
          className =
            'bg-green10 text-green50 text-center p-1 rounded-full py-0.5';
        }
        if (value === 'Unverified') {
          className =
            'bg-grey5 text-grey70 text-center p-1 rounded-full py-0.5';
        }
        if (value === 'Blocked') {
          className = 'bg-red10 text-red50 text-center p-1 rounded-full py-0.5';
        }
        return <div className={className}>{value}</div>;
      },
    },
    {
      Header: 'ACTION',
      accessor: 'id',
      disableSortBy: true,
      customCell: ({ index, row }) => {
        return (
          <Popover
            isOpen={isVisible && visibleDropdownIndex === row.index}
            position={Position.LEFT_TOP}
            minimal={true}
            targetTagName="div"
            className="w-min-content"
            targetClassName="inline-block"
            popoverClassName="shadow-none bg-transparent"
          >
            <div
              className="cursor-pointer inline-block"
              onClick={() => {
                if (isVisible && visibleDropdownIndex !== null) {
                  setIsVisible(false);
                  setVisibleDropdownIndex(null);
                } else {
                  setIsVisible(true);
                  setVisibleDropdownIndex(row.index);
                }
              }}
            >
              <Icon
                icon={IconNames.MORE}
                iconSize={20}
                className="fill-curent text-grey50 hover:text-grey50"
              />
            </div>
            <div
              className="w-40 p-2 mt-1 shadow top rounded overflow-y-auto bg-white"
              ref={ref}
            >
              <ul>
                {[
                  {
                    label: 'View detail',
                    onClick: () => {
                      onViewDetail(row.original.id);
                      setVisibleDropdownIndex(null);
                    },
                  },
                  {
                    label: 'Change user role',
                    onClick: () => {
                      onChangeUserRole(row.original);
                      setVisibleDropdownIndex(null);
                    },
                  },
                  {
                    label: 'Block User',
                    onClick: () => {
                      onBlockUser(row.original);
                      setVisibleDropdownIndex(null);
                    },
                  },
                ].map((menu, menuIndex) => (
                  <li key={`menu-${menuIndex}`}>
                    <button
                      className="block w-full pl-2 mb-1 text-xs font-medium text-left rounded-sm text-grey70 transition-colors cursor-pointer py-2 hover:bg-grey5 hover:no-underline"
                      onClick={menu.onClick}
                    >
                      {menu.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Popover>
        );
      },
    },
  ];

  const filterTable = [
    {
      name: 'role',
      options: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Admin',
          value: 'admin',
        },
      ],
    },
    {
      name: 'type',
      options: [
        {
          text: 'Student',
          value: 'student',
        },
        {
          text: 'General',
          value: 'general',
        },
      ],
    },
  ];
  const defaultSort = sortBy.sort
    ? { id: sortBy.sort, desc: sortBy.order === 'desc' }
    : null;

  return (
    <>
      <CustomTable
        onChangePage={onChangePage}
        page={page}
        totalPage={totalPages}
        onChangeTotalRow={onChangeTotalRow}
        search={initialSearch}
        onInputSearch={onInputSearch}
        onChangeFilter={onFilterChange}
        defaultSortedColumn={defaultSort}
        onChangeSort={(sortData) => {
          dispatch(changeSortUsers(sortData));
        }}
        isLoading={loading}
        dataTable={data}
        columnsTable={columns}
        filterTable={filterTable}
        additionalButton={[
          ({ baseClassName }) => (
            <button
              className={clsx(
                `${baseClassName} flex items-center ml-3`,
                loadingCSV ? 'bg-green30' : 'bg-green50'
              )}
              onClick={() => {
                dispatch(
                  downloadUserListCSV(
                    (url) => {
                      const anchor = document.createElement('a');
                      anchor.setAttribute('href', url);
                      anchor.click();
                    },
                    () => {
                      showToastError('An error occurred');
                    }
                  )
                );
              }}
              disabled={loadingCSV}
            >
              <span className="whitespace-nowrap">
                {!loadingCSV ? 'Download CSV' : 'Please Wait...'}
              </span>
              <Icon
                iconSize={18}
                icon={IconNames.CLOUD_DOWNLOAD}
                className="fill-current text-white ml-2"
              />
            </button>
          ),
        ]}
      />
    </>
  );
}

export default UserList;
