import firebase, { auth } from '../../firebase';
import { push } from 'connected-react-router';
import {
  createAction,
  createAsyncAction,
  actionSuccess,
} from '../utils/actionCreator';
import { apiRequest } from '../utils/apiRequest';
import PATH_URL from '../../routers/path';
import ENDPOINTS from '../urls';
import { ALERT_TYPE, displayAlert } from './ui';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
export const signIn = createAsyncAction(
  'LOGIN',
  (email, password, isRemembered) => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {
      let persistenceSetting = isRemembered
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION;
      await getFirebase().auth().setPersistence(persistenceSetting);
      const response = await getFirebase().login({ email, password });
      if (response.user) {
        const result = await dispatch(getUserProfile());
        if (result.type === 'GET_USER_PROFILE_SUCCESS') {
          const userData = result.payload;
          if (userData.blockStatus)
            throw new Error(
              'Unfortunately, you were unable to access your account while we were reviewing your account.'
            );
          else dispatch({ type: 'LOGIN_SUCCESS' });
        } else {
          throw new Error(result.payload || 'No local account found.');
        }
      }
    } catch (error) {
      dispatch(signOut());
      dispatch({ type: 'LOGIN_FAILURE', payload: error });
      return false;
    }
  }
);

export const signInGoogle = createAsyncAction(
  'LOGIN',
  () => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {
      const response = await getFirebase().login({
        provider: 'google',
        type: 'popup',
      });
      if (response.user) {
        const { type, payload: userData } = await dispatch(getUserProfile());
        if (type === 'GET_USER_PROFILE_SUCCESS') {
          if (userData.blockStatus)
            throw new Error(
              'Unfortunately, you were unable to access your account while we were reviewing your account.'
            );
          else dispatch({ type: 'LOGIN_SUCCESS' });
        } else {
          throw new Error('User not found, please register first.');
        }
      }
    } catch (error) {
      dispatch(signOut());
      dispatch({ type: 'LOGIN_FAILURE', payload: error });
      return false;
    }
  }
);

export const registerGoogle = createAsyncAction(
  'REGISTER',
  () => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'REGISTER_REQUEST' });
    try {
      const response = await getFirebase().login({
        provider: 'google',
        type: 'popup',
      });
      if (response.user) {
        const { uid, email, displayName: fullName, photoURL } = response.user;
        const data = {
          uid,
          photoUrl: photoURL,
          email,
          fullName,
        };
        const userProfileAction = await dispatch(getUserProfile());
        if (userProfileAction.type === 'GET_USER_PROFILE_SUCCESS') {
          dispatch({ type: 'REGISTER_SUCCESS' });
        } else {
          const { type } = await dispatch(storeUserData(data));
          if (type === 'STORE_USER_DATA_SUCCESS') {
            dispatch({ type: 'REGISTER_SUCCESS' });
            dispatch(push(PATH_URL.SETUP_ACCOUNT));
          } else {
            throw new Error('An error occured.');
          }
        }
      }
    } catch (error) {
      dispatch(signOut());
      dispatch({ type: 'REGISTER_FAILURE', payload: error });
      return false;
    }
  }
);

export const signOut = createAsyncAction(
  'LOGOUT',
  () => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'LOGOUT_REQUEST' });
    try {
      await getFirebase().logout();
      await dispatch(resetUserProfile());
      await dispatch({ type: 'LOGOUT_SUCCESS' });
    } catch (error) {
      await dispatch({ type: 'LOGOUT_FAILURE', payload: error });
    }
  }
);

export const register = createAsyncAction(
  'REGISTER',
  (userData) => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'REGISTER_REQUEST' });
    const { fullname, email, password, confirmPassword, phone } = userData;
    if (password === confirmPassword) {
      try {
        let persistenceSetting = firebase.auth.Auth.Persistence.LOCAL;
        await getFirebase().auth().setPersistence(persistenceSetting);
        const response = await getFirebase().createUser({ email, password });
        if (response.user) {
          const uid = response.user.uid;
          const data = {
            uid,
            email,
            fullName: fullname,
            phoneNumber: phone,
          };
          await response.user.sendEmailVerification();
          const { type, payload } = await dispatch(storeUserData(data));
          if (type === 'STORE_USER_DATA_SUCCESS') {
            dispatch({ type: 'REGISTER_SUCCESS' });
            dispatch(push(PATH_URL.SETUP_ACCOUNT));
          } else {
            const { statusCode } = payload;
            if (statusCode === 401) {
              throw new Error('User not found, please register first.');
            } else {
              throw new Error(payload);
            }
          }
        }
      } catch (error) {
        dispatch(signOut());
        dispatch({ type: 'REGISTER_FAILURE', payload: error });
        return false;
      }
    } else {
      dispatch({
        type: 'REGISTER_FAILURE',
        payload: { message: 'Password mismatch.' },
      });
      return false;
    }
  }
);

export const forgotPassword = createAsyncAction(
  'FORGOT_PASSWORD',
  (email) => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'FORGOT_PASSWORD_REQUEST' });
    try {
      // somehow using getFirebase() caused re-rendering issue
      // const response = await getFirebase().resetPassword(email);
      const response = await auth.sendPasswordResetEmail(email);
      dispatch({ type: 'FORGOT_PASSWORD_SUCCESS', payload: response });
      return true;
    } catch (error) {
      dispatch({ type: 'FORGOT_PASSWORD_FAILURE', payload: error });
      dispatch(
        displayAlert({ type: ALERT_TYPE.ERROR, message: error.message })
      );
      return false;
    }
  }
);

export const verifyEmail = createAsyncAction(
  'VERIFY_EMAIL',
  (actionCode) => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'VERIFY_EMAIL_REQUEST' });
    try {
      await getFirebase().auth().applyActionCode(actionCode);
      // force refresh current access token
      await getFirebase().auth().currentUser.getIdToken(true);
      dispatch({ type: 'VERIFY_EMAIL_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'VERIFY_EMAIL_FAILURE', payload: error });
    }
  }
);

export const resetPassword = createAsyncAction(
  'RESET_PASSWORD',
  (actionCode, newPassword, retypePassword) =>
    async (dispatch, getState, getFirebase) => {
      dispatch({ type: 'RESET_PASSWORD_REQUEST' });
      if (newPassword === retypePassword) {
        try {
          await getFirebase()
            .auth()
            .confirmPasswordReset(actionCode, newPassword);
          dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
          setTimeout(() => {
            dispatch(push(PATH_URL.LOGIN));
          }, 2000);
          return true;
        } catch (error) {
          dispatch({ type: 'RESET_PASSWORD_FAILURE', payload: error });
          return false;
        }
      } else
        dispatch({
          type: 'RESET_PASSWORD_FAILURE',
          payload: { message: 'Password mismatch.' },
        });
    }
);

const storeUserData = createAsyncAction(
  'STORE_USER_DATA',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'STORE_USER_DATA',
      'post',
      ENDPOINTS.AUTH,
      data
    );
  }
);

export const updateUserData = createAsyncAction(
  'UPDATE_USER_DATA',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'UPDATE_USER_DATA',
      'put',
      ENDPOINTS.AUTH,
      data
    );
  }
);

export const storeSurveyData = createAsyncAction(
  'STORE_SURVEY_DATA',
  (data) => async (dispatch, getState) => {
    const { id } = getState().auth;
    const { type: actionType } = await apiRequest(
      dispatch,
      'STORE_SURVEY_DATA',
      'post',
      ENDPOINTS.STORE_SURVEY_DATA,
      { id: id.toString(), ...data }
    );
    if (actionType === actionSuccess('STORE_SURVEY_DATA')) return true;
    return false;
  }
);

export const getUserProfile = createAsyncAction(
  'GET_USER_PROFILE',
  () => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_USER_PROFILE',
      'get',
      ENDPOINTS.AUTH
    );
  }
);

export const uploadProfilePict = createAsyncAction(
  'UPLOAD_PROFILE_PICT',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'UPLOAD_PROFILE_PICT',
      'post',
      ENDPOINTS.UPLOAD_PROFILE_PICT,
      data,
      'multipart/form-data'
    );
  }
);

export const uploadStudentIdCard = createAsyncAction(
  'UPLOAD_STUDENT_ID_CARD',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'UPLOAD_STUDENT_ID_CARD',
      'post',
      ENDPOINTS.UPLOAD_STUDENT_ID_CARD,
      data,
      'multipart/form-data'
    );
  }
);

export const getInstitutions = createAsyncAction(
  'GET_INSTITUTION_LIST',
  (name) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'GET_INSTITUTION_LIST',
      'get',
      `${ENDPOINTS.INSTITUTIONS}`,
      { nama: name }
    );
  }
);

export const addInstitution = createAsyncAction(
  'ADD_INSTITUTION',
  (name) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'ADD_INSTITUTION',
      'post',
      `${ENDPOINTS.INSTITUTIONS}`,
      { name }
    );
  }
);

const resetUserProfile = createAction('RESET_USER_PROFILE');

export const resendEmailVerification = createAsyncAction(
  'RESEND_EMAIL',
  () => async (dispatch, getState, getFirebase) => {
    dispatch({ type: 'RESEND_EMAIL_REQUEST' });
    try {
      await getFirebase().auth().currentUser.sendEmailVerification();
      showToastSuccess('Email verification has been send to your email');
      await dispatch({ type: 'RESEND_EMAIL_SUCCESS' });
    } catch (error) {
      showToastError('Something Wrong...');
      await dispatch({ type: 'RESEND_EMAIL_FAILURE', payload: error });
    }
  }
);

export const getUserInvoicesTA = createAsyncAction(
  'GET_USER_INVOICES_TA',
  (params = {}) =>
    async (dispatch, getState, getFirebase) =>
      await apiRequest(
        dispatch,
        'GET_USER_INVOICES_TA',
        'get',
        `${ENDPOINTS.USER_BILLINGS}`,
        params
      )
);

export const getUserInvoicesQD = createAsyncAction(
  'GET_USER_INVOICES_QD',
  (params = {}) =>
    async (dispatch) =>
      await apiRequest(
        dispatch,
        'GET_USER_INVOICES_QD',
        'get',
        `${ENDPOINTS.GET_USER_QD_INVOICES}`,
        params
      )
);
export const getUserInvoiceDetailQD = createAsyncAction(
  'GET_USER_INVOICE_DETAIL_QD',
  (id) => async (dispatch) =>
    await apiRequest(
      dispatch,
      'GET_USER_INVOICE_DETAIL_QD',
      'get',
      `${ENDPOINTS.GET_USER_QD_INVOICES}/${id}`
    )
);

export const freeTrialFeature = createAsyncAction(
  'FREE_TRIAL_FEATURE',
  (data) => async (dispatch) => {
    return await apiRequest(
      dispatch,
      'FREE_TRIAL_FEATURE',
      'post',
      ENDPOINTS.FREE_TRIAL,
      data
    );
  }
);
export const userSubsActiveTextAnalytics = (state) => {
  const subsActive = state.auth.usersubscriptions?.filter(
    (data) =>
      data.subscription &&
      data.subscription.brandCode === 'TA' &&
      data.status &&
      new Date().getTime() < new Date(data.expireAt).getTime()
  );
  return subsActive.length ? subsActive[0] : null;
};
export const userHasActiveFreeTrial = (state) => {
  const freeTrialSubs = state.auth.usersubscriptions.filter(
    (data) =>
      data.subscription &&
      data.subscription.brandCode === 'TA' &&
      data.subscription.option === 'trial' &&
      data.status &&
      new Date().getTime() < new Date(data.expireAt).getTime()
  );
  return freeTrialSubs.length ? freeTrialSubs[0] : null;
};

export const isTrialUserAllowed = (state) => {
  const isTrial = state.auth.usersubscriptions?.[0]?.isTrial;
  return isTrial === true || typeof isTrial === 'undefined';
};

const initialState = {
  blockStatus: null,
  role: '',
  invoices: {
    textAnalytics: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
      invoices: [],
      selected: null,
    },
    quantitative: {
      currentPageNumber: 1,
      currentPageSize: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 0,
      list: [],
      selected: {
        id: null,
        invoiceNumber: null,
        grossPrice: null,
        netPrice: null,
        paymentMethod: null,
        vaNumber: null,
        dueDate: null,
        discountValue: null,
        discountId: null,
        orderStatus: null,
        paymentStatus: null,
        createdAt: null,
        updatedAt: null,
        discount: null,
        orders_details: [],
      },
    },
  },
  popularVariables: [
    {
      id: 1,
      name: 'Firm Age',
      description:
        'Age of the company  (referto the date of the establishment of the company)',
      clasification: 'Gold',
      totalCheckout: 100,
    },
    {
      id: 2,
      name: 'Net Sales',
      description:
        'Gross sales minus freight costs, selling costs, and sales discounts',
      clasification: 'Gold',
      totalCheckout: 100,
    },
    {
      id: 3,
      name: 'Cost of Goods Sold',
      description: 'Costs incurred to produce a product',
      clasification: 'Gold',
      totalCheckout: 100,
    },
    {
      id: 4,
      name: 'Earnings/(Loss) Before ...',
      description:
        'Income before interest income, interest expense, income taxes, depreciation & amortization and ...',
      clasification: 'Gold',
      totalCheckout: 100,
    },
  ],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSuccess(storeUserData.type):
    case actionSuccess(getUserProfile.type):
      return {
        ...state,
        ...action.payload,
      };
    case resetUserProfile.type:
      return initialState;
    case actionSuccess(getUserInvoicesTA.type): {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          textAnalytics: {
            ...state.invoices.textAnalytics,
            ...action.payload,
          },
        },
      };
    }
    case actionSuccess(getUserInvoicesQD.type): {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          quantitative: {
            ...state.invoices.quantitative,
            ...action.payload,
          },
        },
      };
    }
    case actionSuccess(getUserInvoiceDetailQD.type): {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          quantitative: {
            ...state.invoices.quantitative,
            selected: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
