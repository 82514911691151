import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { showToastSuccess } from './Toast';
import { saveQuery } from '../../redux/features/textAnalytics';
import { createLoadingSelector } from '../../redux/api/loading';

function SaveQueryDialog(props) {
  const dispatch = useDispatch();
  const [queryName, setQueryName] = useState('');
  const handleChangeQueryName = (e) =>
    e.target.value.length <= 50 ? setQueryName(e.target.value) : null;
  const saveQueryLoading = useSelector(createLoadingSelector([saveQuery.type]));
  const handleSave = (e) => {
    e.preventDefault();
    if (queryName) {
      dispatch(
        saveQuery(queryName, () => {
          // on succes save query
          setQueryName('');
          props.onClose();
          showToastSuccess('Query has been saved successfully!');
        })
      );
    }
  };

  return (
    <Dialog
      header="Save Query"
      size="small"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <div className="w-full px-6">
        <form onSubmit={handleSave}>
          <label htmlFor="queryName" className="text-sm text-grey50">
            Name your query:
          </label>
          <input
            type="text"
            name="queryName"
            value={queryName}
            onChange={handleChangeQueryName}
            placeholder="Enter your query name here..."
            className="w-full text-grey100 text-xs h-12 rounded border-grey10 mt-1"
            autoFocus={true}
          />
          <span className="text-xs text-grey50 float-right mt-px">
            {queryName.length} / 50
          </span>
        </form>
      </div>
      <div className="w-full mt-4 px-6 flex items-center justify-end">
        <Button
          view="outlined"
          color="danger"
          size="small"
          onClick={props.onClose}
          className="w-20 mr-4"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={handleSave}
          disabled={saveQueryLoading}
          isLoading={saveQueryLoading}
          className="w-20"
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}

export default SaveQueryDialog;
