import { useEffect } from 'react';
import clsx from 'clsx';
import AuthLayout from '../../layouts/AuthLayout';

function Break() {
  return (
    <>
      <br />
      <br />
    </>
  );
}

export default function TermsConditions() {
  const headingClass = 'font-bold text-2xl text-grey100 text-center';
  const subHeadingClass = 'font-medium text-base text-grey100';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AuthLayout navOnly={true}>
      <div className="w-full py-9 px-24">
        <header>
          <h5 className={headingClass}>Terms & Conditions</h5>
          <h5 className={clsx(headingClass, 'mt-2')}>ESG Intelligence</h5>
        </header>
        <div className="w-full text-grey60 text-justify mt-12">
          Published and effective in use from 1 March 2021
          <Break />
          <span className={subHeadingClass}>TERMS AND CONDITIONS</span>
          <br />
          Terms and conditions of use apply to all access and use features and
          products that exist in the site Service ESG Intelligence under Center
          for Environmental, Social, and Government Studies (CESGS) which is
          further called as “ESG Intelligence”. All information and content are
          protected by copyright, trademark, and other proprietary laws.
          <Break />
          Please read this Agreement carefully. Users must read, understand,
          accept and agree to all terms and conditions contained in this
          Agreement before using the service and/or receive content contained
          therein.
          <Break />
          If the use of one of the services is on behalf of an entity, the User
          represents and guarantees that the User is authorized to accept and be
          responsible according to the provisions. By accessing or using our
          services, the user agrees to be bound by the terms and conditions.
          Users are welcome not to use this site if they do not agree with the
          applicable terms and conditions.
          <Break />
          We may change or update this Agreement at any time by posting the
          amended or updated Agreement on the site and the amended and updated
          terms and conditions will take effect immediately after the amended
          and updated terms and conditions are posted by ESG Intelligence, and
          the User is obliged to any time reading the new terms and conditions
          and is considered to have approved any changes or updates that if
          after included in the service of ESG Intelligence and Users continue
          using the service of ESG Intelligence.
          <Break />
          <span className={subHeadingClass}>USER ACCESS</span>
          <br />
          To access the features of the Service Center of Environmental, Social,
          and Government Dataset (Dataset CESGS), users are required to create
          an account first. In creating an account, the User agrees to provide
          correct information about himself and is responsible for all
          activities on the account.
          <Break />
          <span className={subHeadingClass}>COPYRIGHT AND CONTENT</span>
          <br />
          Data and information in this site are the property of the Center of
          Environmental, Social, and Government Studies (CESGS). The entire
          content on this site is the property of the company or unless
          otherwise specified. User agrees not to distribute the content for the
          following purposes:
          <ol className="list-decimal ml-4">
            <li>
              Sharing the negative impact content that is unlawful, defamatory,
              invasion of privacy or public interests, threats, violence,
              deception, or unpleasant behavior.{' '}
            </li>
            <li>
              Content violates or infringes one patent license/copyrights.{' '}
            </li>
            <li>Does not represent an affiliate and other entity or party. </li>
            <li>
              Conducting commercial activities using data or information,
              outside of transactions made with us. We strive to provide as
              accurate information as possible, objective and based on the
              current situation.
            </li>
          </ol>
          <br />
          <span className={subHeadingClass}>OBLIGATIONS</span>
          <br />
          Center of Environmental, Social, and Government Studies Dataset
          (Dataset CESGS) are not responsible for any damage, loss of
          information, information that is not compatible because of the
          consequences of losing data updates and other data.
          <Break />
          <span className={subHeadingClass}>EXTERNAL CONTENT</span>
          <br />
          Center of Environmental, Social, and Government Studies Dataset (CESGS
          dataset) does not monitor or have any control over third-party content
          if there is a change.
          <Break />
          <span className={subHeadingClass}>TERMS OF PAYMENT</span>
          <br />
          <ol className="list-decimal ml-4">
            <li>
              We work with trusted banking / payment service providers in
              providing various payment methods that can be used by users.
            </li>
            <li>
              Each user has the right to choose the payment method that has been
              provided by Us, in which users feel more comfortable and easier to
              transact with the site Center of Environmental, Social, and
              Government Studies Dataset (CESGS Dataset), where each payment
              transaction fees will be deferred to the user the.
            </li>
            <li>
              Each User is obliged to pay in full on bookings made within the
              period specified by us specifically for each payment method before
              we can process more top Users reservations. When users did not
              make payment within the time specified then we reserve the right
              stating that the order has been canceled by the user.
            </li>
          </ol>
        </div>
      </div>
    </AuthLayout>
  );
}
