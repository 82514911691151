import React from 'react';
import routes from '../routers/routes';
import { Switch, Route, Redirect } from 'react-router-dom';

const generateRoutesComponents = (routes) =>
  routes.map((route) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

export default function FullViewRoutes(props) {
  const { FullView } = routes;
  const currentRoutes = FullView.routes;
  const routesComponents = generateRoutesComponents(currentRoutes);

  return (
    <Switch location={props.location}>
      {routesComponents}
      <Route>
        <Redirect to="/u" />
      </Route>
    </Switch>
  );
}
