import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IconNames } from '@blueprintjs/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import googleLogo from '../../assets/images/google-logo.svg';
import Button from 'components/Button';
import PATH_URL from '../../routers/path';
import { signIn, signInGoogle } from '../../redux/features/auth';
import { createLoadingSelector } from '../../redux/api/loading';
import { createErrorMessageSelector } from '../../redux/api/error';
import TextField from '../../components/TextField';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

function Login(props) {
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    createLoadingSelector([signIn.type])(state)
  );
  const error = useSelector((state) =>
    createErrorMessageSelector([signIn.type])(state)
  );

  const onSubmit = async (data) => {
    const { email, password, remember } = data;
    await dispatch(signIn(email, password, remember));
  };

  const handleSignInGoogle = () => dispatch(signInGoogle());

  return (
    <div className="w-full flex flex-col">
      <header className="text-3xl font-semibold">Welcome back</header>
      {error && (
        <p className="text-sm bg-red50 text-white p-1 rounded-sm">{error}</p>
      )}
      <p className="text-sm text-grey70 my-2">Sign in to your account</p>
      <Button
        view="outlined"
        size="small"
        onClick={handleSignInGoogle}
        disabled={loading}
        isLoading={loading}
        className="w-full mt-4"
      >
        <div className="flex items-center justify-center">
          <img src={googleLogo} height={24} width={24} alt="Google logo" />
          <span className="ml-1">Sign in with Google</span>
        </div>
      </Button>
      <div className="flex items-center w-full mt-2">
        <hr className="border-t border-grey30 w-full" />
        <span className="block text-sm text-grey30 font-normal mx-4">OR</span>
        <hr className="border-t border-grey30 w-full" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-2">
          <TextField
            label="Email Address"
            type="email"
            name="email"
            ref={register}
            defaultBorder={false}
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
            nameIconRight={
              (errors.email && IconNames.ERROR) ||
              (dirtyFields.email && IconNames.TICK)
            }
          />
          <p className="text-xs text-red50 mt-px">{errors.email?.message}</p>
        </div>
        <div className="flex flex-col mt-2">
          <TextField
            label="Password"
            type={'password'}
            name="password"
            ref={register}
            defaultBorder={false}
            className="border border-transparent rounded-md w-full bg-grey5 p-2 focus:ring-grey40 focus:outline-none focus:border-transparent"
          />
          <p className="text-xs text-red50 mt-px">{errors.password?.message}</p>
        </div>
        <div className="flex justify-between items-center text-sm text-grey50 mt-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              name="remember"
              ref={register}
              className="border-2 rounded-sm border-grey50 cursor-pointer focus:ring-transparent mr-1"
            />
            <label htmlFor="remember">Remember me</label>
          </div>
          <Link to={PATH_URL.FORGOT_PASSWORD}>Forgot password?</Link>
        </div>
        <Button
          type="submit"
          color="primary"
          disabled={loading}
          isLoading={loading}
          className="w-full mt-3"
        >
          Sign In
        </Button>
        <p className="text-sm text-center text-grey40 mt-2.5">
          Don't have an account?
          <Link
            to={PATH_URL.REGISTER}
            className="text-blue50 font-bold ml-1 hover:underline"
          >
            Click here
          </Link>
        </p>
      </form>
    </div>
  );
}

export default Login;
