import axios from 'axios';
import { auth } from '../firebase';
const { REACT_APP_API_ENDPOINT, REACT_APP_API_STAGE } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  responseType: 'json',
});

export const useAuth = async () => {
  if (auth.currentUser) {
    return await auth.currentUser.getIdToken();
  }
  return null;
};

export const setupAxios = async () =>
  api.interceptors.request.use(
    async (config) => {
      let accessToken = await useAuth();
      if (REACT_APP_API_STAGE !== 'production') {
        console.log('accessToken: ', accessToken);
      }

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

export default api;
