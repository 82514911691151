import clsx from 'clsx';

function Checkbox(props) {
  return (
    <label className={`inline-flex items-center cursor-pointer py-2 px-1`}>
      <input
        type="checkbox"
        name={props.name}
        ref={props.ref}
        className={clsx(
          'form-checkbox h-5 w-5 rounded-sm cursor-pointer focus:ring-transparent',
          props.disabled === true ? 'text-grey30' : 'text-teal2'
        )}
        onChange={props.onChange || function () {}}
        checked={props.checked}
        disabled={props.disabled || false}
      />
      <span
        className={clsx(
          'ml-2 text-xs',
          props.disabled === true ? 'text-grey100' : 'text-blue100'
        )}
      >
        {props.label}
      </span>
    </label>
  );
}

export default Checkbox;
