import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { upperFirst } from 'lodash';
import clsx from 'clsx';
import { FixedSizeList as List } from 'react-window';
import ResultTopBar from './ResultTopBar';
import {
  selectSearchResult,
  getSearchDetail,
} from '../../redux/features/textAnalytics';
import documentImg from '../../assets/images/document.png';

function reportType(str) {
  return str
    .split('_')
    .map((word) => upperFirst(word))
    .join(' ');
}

function DocumentList() {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(200);
  const { documents, selected } = useSelector(selectSearchResult);
  const handleGetDetail = (id) => dispatch(getSearchDetail(id));
  const Item = ({ index, style }) => {
    const {
      id,
      company: { name, ticker, isin, sic },
      type,
      year,
    } = documents[index];
    return (
      <div
        key={id}
        className={clsx(
          selected === id ? 'bg-grey0' : '',
          'h-32 w-full border-b flex items-center flex-shrink-0 cursor-pointer'
        )}
        style={style}
        onClick={() => handleGetDetail(id)}
      >
        <div className="h-24 w-5/6 ml-7 flex">
          <div className="h-24 w-20">
            <img
              src={documentImg}
              alt="document result"
              className="h-full w-full"
            />
          </div>
          <div className="ml-4 flex flex-col justify-between">
            <div className="">
              <h4 className="font-medium text-sm -mt-1 -mb-2">{name}</h4>
              <span className="text-xs text-grey50">
                {reportType(type)} • {year}
              </span>
            </div>
            <div className="flex">
              <div className="flex flex-col h-full items-start">
                <span className="text-xs font-bold text-grey80 px-2 h-4 mb-1 rounded bg-orange1">
                  TICKER
                </span>
                <span className="text-xs font-bold text-grey80 px-2 h-4 mb-1 rounded bg-orange1">
                  ISIN
                </span>
                <span className="text-xs font-bold text-grey80 px-2 h-4 rounded bg-orange1">
                  SIC
                </span>
              </div>
              <div className="flex flex-col h-full items-start ml-1">
                <span className="text-xs px-2 h-4 mb-1">{ticker}</span>
                <span className="text-xs px-2 h-4 mb-1">{isin}</span>
                <span className="text-xs px-2 h-4">{sic}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    function updateHeight() {
      if (containerRef.current) {
        const height = containerRef.current.offsetHeight;
        setContainerHeight(height);
      }
    }
    updateHeight();
    window.addEventListener('resize', updateHeight, true);
    return () => {
      window.removeEventListener('resize', updateHeight, true);
    };
  }, []);

  return (
    <>
      <ResultTopBar />
      <div
        ref={containerRef}
        className="w-full h-full flex flex-col overflow-y-auto pb-2"
      >
        <List
          height={containerHeight}
          itemCount={documents.length}
          itemSize={128}
          width="100%"
        >
          {Item}
        </List>
      </div>
    </>
  );
}

export default DocumentList;
