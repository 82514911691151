import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { downloadVariableTransformation } from 'redux/features/variableManagement';
import { createLoadingSelector } from 'redux/api/loading';
import {
  getIDXICList,
  getSICList,
  getFilterPeriodRange,
  setSelectedYears,
  resetSelectedClassification,
  setSelectedClassification,
  addSelectedClassification,
  removeSelectedClassification,
  getTickerList,
} from 'redux/features/userSelectVariable';
import { Classes, Collapse, Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { debounce } from 'lodash';
import { showToastError } from 'layouts/DashboardLayout';
import { format } from 'date-fns';
import Checkbox from 'components/Checkbox';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';

export default function DownloadVariable(props) {
  const dispatch = useDispatch();
  const filterYear = useSelector(
    (state) => state.userSelectVariable.filterYear
  );
  const filterCompany = useSelector(
    (state) => state.userSelectVariable.filterCompany
  );
  const selectedClassification = useSelector(
    (state) => state.userSelectVariable.filterCompany.selected
  );
  const selectedClassificationCodes = Object.keys(selectedClassification);
  const loadingFilterData = useSelector(
    createLoadingSelector([
      getFilterPeriodRange.type,
      getSICList.type,
      getIDXICList.type,
    ])
  );
  const loadingDownload = useSelector(
    createLoadingSelector([downloadVariableTransformation.type])
  );
  const [searchClassification, setSearchClassification] = useState('');
  const [appliedSearchClassification, setAppliedSearchClassification] =
    useState('');
  const [collapsedSubs, setCollapsedSubs] = useState([]);
  const { register, handleSubmit, watch, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyCodeVariant: 'TICKER',
      industryClassification: 'all',
    },
  });
  const filterIndustryOptions = useMemo(
    () => ({
      all: 'All Sectors',
      SIC: 'SIC',
      'IDX-IC': 'IDX-IC',
      tickers: 'Ticker',
    }),
    []
  );
  const industryClassification = watch('industryClassification');
  const handleClose = () => {
    reset();
    dispatch(setSelectedYears([]));
    props.onClose();
  };
  const onSubmit = async () => {
    const industryCode =
      selectedClassificationCodes.length > 0
        ? selectedClassificationCodes.map((code) => {
            let prefix = '';
            if (industryClassification === 'SIC') prefix = 'SIC-';
            else if (industryClassification === 'IDX-IC') prefix = 'IDX_IC-';
            return `${prefix}${code}`;
          })
        : ['*'];
    const data = {
      companyCodeVariant: 'TICKER',
      companyCodes: industryCode,
      years:
        filterYear.selectedYears.length === 0
          ? filterYear.listYear
          : filterYear.selectedYears,
      variables: props.selectedVariables,
      fileNameOutput: `TRANSFORMATION_RESULTS_${format(
        new Date(),
        'dd_MMM_yyyy'
      )}`,
    };
    dispatch(
      downloadVariableTransformation(
        data,
        (url) => {
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.click();
        },
        () => showToastError('An error occurred')
      )
    );
  };
  const handleCheckClassification = (code, value) => {
    if (!selectedClassificationCodes.includes(`${code}`)) {
      dispatch(addSelectedClassification({ code, value }));
    } else {
      dispatch(removeSelectedClassification(code));
    }
  };
  // eslint-disable-next-line
  const applySearchStr = useCallback(
    debounce((value) => {
      setAppliedSearchClassification(value);
    }, 300),
    []
  );
  const handleSearchClassification = (event) => {
    const { value } = event.target;
    setSearchClassification(value);
    applySearchStr(value);
  };
  const applySearchClassification = (acc, classification) => {
    const regexp = new RegExp(appliedSearchClassification, 'i');
    if (appliedSearchClassification === '') return acc.concat(classification);
    if (industryClassification !== 'tickers') {
      if (
        regexp.test(classification.code) ||
        regexp.test(classification.sector)
      )
        return acc.concat(classification);
      else {
        const matchSub = classification.subsectors.filter(
          (sub) => regexp.test(sub.code) || regexp.test(sub.name)
        );
        if (matchSub.length > 0) {
          return acc.concat({
            ...classification,
            subsectors: matchSub,
          });
        }
        return acc;
      }
    } else {
      if (regexp.test(classification)) return acc.concat(classification);
      return acc;
    }
  };
  const getSelectedYearsLabel = () => {
    const { selectedYears } = filterYear;
    const totalSelected = selectedYears.length;
    if (totalSelected > 0)
      return `${totalSelected} year${totalSelected > 1 ? 's' : ''}`;
    return 'All years';
  };
  const getIndustryLabel = (filterIndustry) => {
    if (filterIndustry === 'SIC') return 'Industry';
    if (filterIndustry === 'IDX-IC') return 'Sector';
    return 'Companies';
  };
  const toggleCollapsedSubs = (id) => {
    if (collapsedSubs.includes(id)) {
      setCollapsedSubs(
        collapsedSubs.filter((collapsedId) => collapsedId !== id)
      );
    } else {
      setCollapsedSubs(collapsedSubs.concat(id));
    }
  };

  useEffect(() => {
    dispatch(resetSelectedClassification());
    setSearchClassification('');
    setAppliedSearchClassification('');
  }, [dispatch, industryClassification]);

  useEffect(() => {
    dispatch(getFilterPeriodRange());
    dispatch(getSICList());
    dispatch(getIDXICList());
    dispatch(getTickerList());
    return () => {
      dispatch(setSelectedClassification([]));
    };
  }, [dispatch]);

  return (
    <Dialog
      isOpen={props.isOpen}
      header={'Download option'}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full px-4 pb-2">
          <div className="flex flex-col">
            <div>
              <label htmlFor="yearRange" className="text-xs text-grey50">
                Select Year
              </label>
              <div className="flex w-full">
                <Popover
                  minimal
                  position={Position.BOTTOM_LEFT}
                  popoverClassName="w-full shadow-none"
                  targetClassName="w-full"
                  className="w-full"
                >
                  <div className="flex items-center justify-between border border-grey30 rounded bg-white p-3 cursor-pointer w-full">
                    <span>{getSelectedYearsLabel()}</span>
                    <Icon
                      icon={IconNames.CARET_DOWN}
                      iconSize={16}
                      className="fill-current text-grey100"
                    />
                  </div>
                  <div>
                    <div
                      className="rounded shadow-lg p-6 bg-white"
                      style={{
                        width: 542,
                      }}
                    >
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-grey100 font-medium">
                          Select Year
                        </h2>
                        <button
                          className="text-red50"
                          onClick={() => {
                            dispatch(setSelectedYears([]));
                          }}
                        >
                          Reset
                        </button>
                      </div>
                      <div
                        className="grid grid-cols-3 gap-x-4 gap-y-5 overflow-y-auto"
                        style={{ maxHeight: 252 }}
                      >
                        {!loadingFilterData ? (
                          filterYear.listYear.map((year) => {
                            return (
                              <Checkbox
                                key={year}
                                label={`${year}`}
                                onChange={() => {
                                  const { selectedYears: selected } =
                                    filterYear;
                                  const updatedYears = selected.includes(year)
                                    ? selected.filter(
                                        (selectedYear) => selectedYear !== year
                                      )
                                    : selected.concat(year);
                                  dispatch(setSelectedYears(updatedYears));
                                }}
                                checked={filterYear.selectedYears.includes(
                                  year
                                )}
                                className="font-medium"
                              />
                            );
                          })
                        ) : (
                          <>
                            <Skeleton width={50} height={20} />
                            <Skeleton width={50} height={20} />
                            <Skeleton width={50} height={20} />
                            <Skeleton width={50} height={20} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <div className="mt-5">
              <label
                htmlFor="industryClassification"
                className="text-xs text-grey50"
              >
                Select Industry Classification
              </label>
              <div className="flex w-full">
                <Popover
                  minimal
                  position={Position.BOTTOM_LEFT}
                  popoverClassName="w-full shadow-none"
                  targetClassName="w-full"
                  className="w-full"
                >
                  <div className="flex items-center justify-between border border-grey30 rounded bg-white p-3 cursor-pointer w-full">
                    <input
                      ref={register}
                      name="industryClassification"
                      type="hidden"
                    />
                    <span>{filterIndustryOptions[industryClassification]}</span>
                    <Icon
                      icon={IconNames.CARET_DOWN}
                      iconSize={16}
                      className="fill-current text-grey100"
                    />
                  </div>
                  <div>
                    <div
                      className="rounded shadow-lg bg-white"
                      style={{
                        width: 542,
                      }}
                    >
                      <div
                        className="flex flex-col overflow-y-auto"
                        style={{ maxHeight: 252 }}
                      >
                        {Object.keys(filterIndustryOptions).map((value) => (
                          <div
                            key={value}
                            onClick={() =>
                              setValue('industryClassification', value)
                            }
                            className={clsx(
                              'px-3 py-2 cursor-pointer hover:bg-grey5',
                              Classes.POPOVER_DISMISS,
                              industryClassification === value
                                ? 'bg-grey10'
                                : 'bg-white'
                            )}
                          >
                            {filterIndustryOptions[value]}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <div className="mt-5 flex flex-col">
              <label htmlFor="sector" className="text-xs text-grey50">
                Select Sector
              </label>
              <Popover
                minimal
                position={Position.BOTTOM_LEFT}
                popoverClassName="w-full shadow-none"
                targetClassName="w-full"
                className="w-full mr-5"
              >
                <div className="flex items-center justify-between overflow-hidden border border-grey30 rounded bg-white p-3 cursor-pointer w-full">
                  <span>
                    {industryClassification === 'tickers' &&
                      !loadingFilterData &&
                      `${selectedClassificationCodes.length || 'All'} compan${
                        selectedClassificationCodes.length !== 1 ? 'ies' : 'y'
                      }`}
                    {['all', 'IDX-IC', 'SIC'].includes(
                      industryClassification
                    ) &&
                      !loadingFilterData &&
                      `${selectedClassificationCodes.length || 'All'} sector${
                        selectedClassificationCodes.length !== 1 ? 's' : ''
                      }`}
                    {loadingFilterData && 'Loading...'}
                  </span>
                  <Icon
                    icon={IconNames.CARET_DOWN}
                    iconSize={16}
                    className="fill-current text-grey100"
                  />
                </div>
                {industryClassification !== 'all' && !loadingFilterData ? (
                  <div
                    style={{
                      width: 542,
                    }}
                  >
                    <div className="rounded shadow-lg p-6 bg-white w-full">
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-grey100 font-medium">
                          {getIndustryLabel(industryClassification)}
                        </h2>
                        <button
                          className="text-red50"
                          onClick={() => {
                            setSearchClassification('');
                            setAppliedSearchClassification('');
                            dispatch(resetSelectedClassification());
                          }}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="w-full relative mb-4">
                        <div className="w-full flex items-center">
                          <span className="absolute left-2 rounded-md">
                            <Icon
                              icon={IconNames.SEARCH}
                              iconSize={16}
                              className="fill-current text-grey30 mb-px"
                            />
                          </span>
                          <input
                            type="text"
                            name="keywords"
                            placeholder={`Search ${
                              industryClassification === 'tickers'
                                ? 'company'
                                : 'sector or sub-sector'
                            }`}
                            value={searchClassification}
                            onChange={handleSearchClassification}
                            autoComplete="off"
                            className={`w-full h-10 bg-white border border-grey30 focus:border-grey50
                   rounded-sm pl-8 focus:outline-none focus:ring-transparent`}
                          />
                        </div>
                      </div>
                      <div
                        className={clsx(
                          'w-full max-h-56 overflow-y-auto',
                          industryClassification !== 'tickers'
                            ? 'flex flex-col gap-y-4 '
                            : 'grid grid-cols-3 gap-4'
                        )}
                      >
                        {filterCompany[industryClassification]
                          ?.reduce(applySearchClassification, [])
                          .map((classification) => {
                            if (industryClassification !== 'tickers') {
                              const label = `${
                                industryClassification === 'SIC'
                                  ? `SIC ${classification.code} - `
                                  : ''
                              }${classification.sector}`;
                              const selectedParent =
                                selectedClassificationCodes.includes(
                                  `${classification.code}`
                                );
                              const subsectorData =
                                classification.subsectors.reduce(
                                  (acc, data) => {
                                    if (
                                      selectedClassificationCodes.find(
                                        (selected) =>
                                          selected === `${data.code}`
                                      )
                                    ) {
                                      acc = {
                                        ...acc,
                                        selected: acc.selected + 1,
                                      };
                                    }
                                    acc = {
                                      ...acc,
                                      codes: acc.codes.concat(`${data.code}`),
                                    };

                                    return acc;
                                  },
                                  { selected: 0, codes: [] }
                                );
                              return (
                                <div key={classification.sector}>
                                  <div
                                    onClick={() =>
                                      toggleCollapsedSubs(classification.code)
                                    }
                                    className="flex items-center justify-between cursor-pointer"
                                  >
                                    <Checkbox
                                      label={''}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={() => {
                                        if (
                                          !selectedParent &&
                                          subsectorData.selected > 0
                                        ) {
                                          const subCodes = subsectorData.codes;
                                          const filteredSubs =
                                            selectedClassificationCodes
                                              .filter(
                                                (selected) =>
                                                  !subCodes.includes(selected)
                                              )
                                              .reduce((acc, key) => {
                                                return {
                                                  ...acc,
                                                  [key]:
                                                    selectedClassification[key],
                                                };
                                              }, {});
                                          const { code, sector } =
                                            classification;
                                          dispatch(
                                            setSelectedClassification({
                                              ...filteredSubs,
                                              [code]: sector,
                                            })
                                          );
                                        } else {
                                          handleCheckClassification(
                                            classification.code,
                                            classification.sector
                                          );
                                        }
                                      }}
                                      checked={selectedParent}
                                      className="font-medium"
                                    />
                                    <div className="w-full flex items-center justify-between font-medium">
                                      <span>{label}</span>
                                      <Icon
                                        icon={
                                          !collapsedSubs.includes(
                                            classification.code
                                          )
                                            ? IconNames.CHEVRON_UP
                                            : IconNames.CHEVRON_DOWN
                                        }
                                        iconSize={18}
                                        className="fill-current text-grey70 mr-1"
                                      />
                                    </div>
                                  </div>
                                  <Collapse
                                    isOpen={
                                      !collapsedSubs.includes(
                                        classification.code
                                      )
                                    }
                                  >
                                    <div className="ml-7 flex flex-col mt-2 gap-y-3">
                                      {classification.subsectors.map(
                                        (subsector) => {
                                          const labelSub =
                                            industryClassification === 'SIC'
                                              ? `SIC ${subsector.code} - ${subsector.name}`
                                              : subsector.name;
                                          const selectedSub =
                                            selectedClassificationCodes.includes(
                                              `${subsector.code}`
                                            );
                                          return (
                                            <Checkbox
                                              key={`subsector-${subsector.code}`}
                                              label={labelSub}
                                              onChange={() => {
                                                const totalSub =
                                                  classification.subsectors
                                                    .length;
                                                if (
                                                  !selectedSub &&
                                                  subsectorData.selected ===
                                                    totalSub - 1
                                                ) {
                                                  // select parent
                                                  const subCodes =
                                                    subsectorData.codes;
                                                  const filteredSubs =
                                                    selectedClassificationCodes
                                                      .filter(
                                                        (selected) =>
                                                          !subCodes.includes(
                                                            selected
                                                          )
                                                      )
                                                      .reduce((acc, key) => {
                                                        return {
                                                          ...acc,
                                                          [key]:
                                                            selectedClassification[
                                                              key
                                                            ],
                                                        };
                                                      }, {});
                                                  const { code, sector } =
                                                    classification;
                                                  dispatch(
                                                    setSelectedClassification({
                                                      ...filteredSubs,
                                                      [code]: sector,
                                                    })
                                                  );
                                                } else {
                                                  handleCheckClassification(
                                                    subsector.code,
                                                    subsector.name
                                                  );
                                                }
                                              }}
                                              disabled={selectedParent}
                                              checked={
                                                selectedParent || selectedSub
                                              }
                                              className="font-medium"
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </Collapse>
                                </div>
                              );
                            } else {
                              return (
                                <Checkbox
                                  key={classification}
                                  label={classification}
                                  onChange={() => {
                                    handleCheckClassification(
                                      classification,
                                      classification
                                    );
                                  }}
                                  checked={selectedClassificationCodes.includes(
                                    classification
                                  )}
                                  className="font-medium"
                                />
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
              </Popover>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-4 flex items-center justify-end">
          <Button
            view="outlined"
            color="danger"
            onClick={handleClose}
            className="mr-4"
            disabled={loadingDownload}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" isLoading={loadingDownload}>
            Download
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
