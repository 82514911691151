import { useState } from 'react';
import PersonalDetail from './PersonalDetailForm';
import Survey from './SurveyForm';
import logo from '../../assets/images/cesgs-logo-only.png';
import { Link } from 'react-router-dom';
import PATH_URL from 'routers/path';

function SetupAccount() {
  const [page, setPage] = useState(1);
  return (
    <div className="h-auto w-auto my-10 mx-auto flex flex-col items-center justify-center">
      <header className="mb-6">
        <div className="flex items-center">
          <img src={logo} alt="cesgs logo" className="h-8 w-8 mr-1" />
          <h4 className="text-base font-bold text-blue80">ESG Intelligence</h4>
        </div>
      </header>
      <div
        style={{ width: 400 }}
        className="box-border shadow-md rounded bg-white p-7"
      >
        <div className="flex mb-4">
          <div
            className={`h-3 w-3 bg-blue50 ring-2 ${
              page === 1 ? 'ring-blue10' : 'ring-transparent'
            } rounded-full`}
          ></div>
          <div
            className={`h-3 w-3 ${
              page === 2
                ? 'bg-blue50 ring-2 ring-blue10'
                : 'bg-grey10 ring-2 ring-transparent'
            }  rounded-full ml-1`}
          ></div>
        </div>
        {page === 1 ? (
          <PersonalDetail nextPage={() => setPage(2)} />
        ) : (
          <Survey />
        )}
      </div>
      <footer className="">
        <p
          style={{ width: 250 }}
          className="text-xs text-grey50 leading-relaxed mt-4"
        >
          Need help? Contact us at{' '}
          <a
            href="mailto:info@cesgs.or.id"
            className="font-semibold text-grey50"
          >
            info@cesgs.or.id
          </a>{' '}
          &copy; 2021 ESG Intelligence.{' '}
          <Link
            to={PATH_URL.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-grey50"
          >
            Privacy
          </Link>{' '}
          and{' '}
          <Link
            to={PATH_URL.TERMS_CONDITIONS}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-grey50"
          >
            Legal
          </Link>
        </p>
      </footer>
    </div>
  );
}

export default SetupAccount;
