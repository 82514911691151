import { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'components/Select/index';
import Alert from 'components/Alert/index';
import Button from 'components/Button';
import { IconNames } from '@blueprintjs/icons';
import { showToastError, showToastSuccess } from 'layouts/DashboardLayout';
import Voucher from 'assets/svg/discout_with_percent.svg';
import { useForm } from 'react-hook-form';
import {
  checkDiscount,
  doPayment,
  paymentPlans,
} from 'redux/features/userManagement';
import { updateInvoiceStatus } from 'redux/features/userPayment';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'redux/api/loading';
import urlUtils from 'utils/url';
import {
  currencyFormatter,
  reverseFormatNumber,
} from 'utils/currencyFormatter';
import { Icon } from '@blueprintjs/core';
import { upperFirst } from 'lodash';

export default function PaymentCheckout() {
  const _mounted = useRef(false);
  const buttonApply = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let additionalParameterForSelect = {};
  const { search } = location;
  const [defaultValueSelect, setDefaultValueSelect] = useState('');
  const [selectedCommitment, setSelectedCommitment] = useState('');
  // State to store old sub total used when user apply for the second time not valid voucher.
  const subscription = urlUtils.queryString(search, 'subscription');
  const isMonthlySubscription = subscription === 'monthly';
  const isYearlySubscription = subscription === 'yearly';
  const [oldSubTotal, setOldSubTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(0);
  // Detect if select option has been changed
  const [selectChange, setSelectChange] = useState(false);
  const [voucherType, setVoucherType] = useState('');
  const [voucherValue, setVoucherValue] = useState('');
  let grandTotal = subTotal;
  if (selectChange && discountPrice > 0) {
    grandTotal -= discountPrice;
  }
  const [isVoucherValid, setIsVoucherValid] = useState(false);
  const [isVoucherSubmitted, setIsVoucherSubmitted] = useState(false);
  const authData = useSelector((state) => state.auth);
  const isStudent =
    authData.type === 'student' &&
    authData.profile.statusApproval === 'Accepted';

  const paymentPlansData = useSelector(
    (state) => state.userManagement.paymentPlans
  );
  const checkDiscountData = useSelector(
    (state) => state.userManagement.checkDiscount
  );
  const loadingPaymentPlans = useSelector((state) =>
    createLoadingSelector([paymentPlans.type])(state)
  );
  const userData = useSelector((state) => state.auth);

  const {
    register: registerVoucher,
    handleSubmit: handleSubmitVoucher,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const className = 'flex flex-col rounded shadow py-6 mr-16 px-4';
  const classNameRightOuter = 'pl-28';
  const rightStyle = {
    width: '445px',
    Height: ' 492px',
  };

  const getDiscount = (payload) => {
    return payload.type === 'amount'
      ? payload.value
      : (parseFloat(payload.value) * parseFloat(payload.factor)) / 100;
  };

  const checkVoucher = async (dataVoucher) => {
    const { voucher_code } = dataVoucher;
    if (voucher_code !== '') {
      const student = authData.type === 'student';
      const studentApproval = authData.profile.statusApproval.toLowerCase();
      const voucherCodeUpperCase = voucher_code.toUpperCase();
      const studentAwaiting = student && studentApproval === 'awaiting';
      const studentApproved = student && studentApproval !== 'awaiting';
      const { payload } = await dispatch(
        checkDiscount({
          name: voucherCodeUpperCase,
        })
      );
      if (payload.id) {
        // Awaiting student but apply for student type voucher. Reject it
        if (studentAwaiting) {
          if (payload.eligibility.toLowerCase() !== 'general') {
            showToastError(
              'Apply voucher tidak di perkenankan. Tipe voucher student'
            );
            return false;
          }
        }
        // Approved student but apply for general type voucher. Reject it
        if (studentApproved) {
          if (payload.eligibility.toLowerCase() === 'general') {
            showToastError(
              'Apply voucher tidak di perkenankan. Tipe voucher general'
            );
            return false;
          }
        }
        const getVoucherType = payload.type;
        const getVoucherValue = payload.value;
        setVoucherValue(getVoucherValue);
        setVoucherType(getVoucherType);
        // Apply if code not same
        setOldSubTotal(subTotal);
        const discountPrice =
          getVoucherType === 'amount'
            ? getVoucherValue
            : (parseFloat(getVoucherValue) * subTotal) / 100;
        setDiscountPrice(discountPrice);
        const afterDiscount = subTotal - discountPrice;
        setSubTotal(afterDiscount);
        setIsVoucherValid(true);
        setIsVoucherSubmitted(true);
      } else {
        setSubTotal(oldSubTotal);
        setIsVoucherValid(false);
        showToastError(payload.message);
      }
    }
  };

  const voucherSubmittedAndValid =
    isVoucherSubmitted === true && isVoucherValid === true;
  const voucherSubmittedAndNotValid =
    isVoucherSubmitted === true && isVoucherValid === false;
  let modifyPlans = [];

  useEffect(() => {
    const isMounted = _mounted.current;
    if (isStudent && !isMounted) {
      const studentVoucherCode = 'STUDENT30';
      setValue('voucher_code', studentVoucherCode);
      setTimeout(() => {
        buttonApply.current.click();
      }, 500);
      _mounted.current = true;
    }
  }, [isStudent, setValue]);

  useEffect(() => {
    const onLoad = async () => {
      await dispatch(paymentPlans());
    };
    onLoad();
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(paymentPlansData).length > 0) {
      let subscriptionID = paymentPlansData.plans[0].id;
      let text = paymentPlansData.plans[0].option;
      let price = paymentPlansData.plans[0].price;
      if (isMonthlySubscription) {
        const monthlyOption = paymentPlansData.plans.filter((value) => {
          const { option } = value;
          return option === 'monthly';
        });
        text = monthlyOption[0].option;
        price = monthlyOption[0].price;
        subscriptionID = monthlyOption[0].id;
      }

      if (isYearlySubscription) {
        const yearlyOption = paymentPlansData.plans.filter((value) => {
          const { option } = value;
          return option === 'yearly';
        });
        text = yearlyOption[0].option;
        price = yearlyOption[0].price;
        subscriptionID = yearlyOption[0].id;
      }
      setSelectedCommitment(upperFirst(text));
      setSubTotal(price);
      setOldSubTotal(price);
      setSubscriptionID(subscriptionID);
    }
  }, [paymentPlansData, search, isMonthlySubscription, isYearlySubscription]);

  const handlePayment = async () => {
    const payloadForSend = {
      userId: userData.id,
      subscriptionId: subscriptionID,
    };
    if (isVoucherValid) {
      payloadForSend.discountId = checkDiscountData.id;
    }
    const { payload } = await dispatch(doPayment(payloadForSend));
    if (payload) {
      /* global snap */
      snap.pay(payload.snapToken, {
        // Optional
        onSuccess(resultSuccess) {
          console.log('resultSuccess', resultSuccess);
        },
        // Optional
        async onPending(resultPending) {
          // Save to db and direct to page waiting
          history.push('/u/payment/complete/text-analytic');
        },
        async onClose() {
          dispatch(
            updateInvoiceStatus(
              {
                id: payload.invoiceId.toString(),
                orderStatus: 'cancelled',
                paymentStatus: 'unpaid',
              },
              () => {
                showToastSuccess('Your order has been cancelled');
              },
              () => {
                showToastError('Failed to cancel order');
              }
            )
          );
        },
        // Optional
        onError(result) {},
      });
    } else {
      showToastError('Ada kesalahan saat melakukan pembayaran');
    }
  };
  const discountPriceFormatted = `-${currencyFormatter.format(discountPrice)}`;
  let voucherText =
    'You automatically get a 30% discount because your account type is student';

  if (isVoucherValid === true) {
    voucherText = `You have successfully used the discount code ${checkDiscountData.name} and get a discount of ${discountPriceFormatted}`;
  }

  if (isVoucherValid === false) {
    voucherText =
      'You cannot use a discount code because it is no longer valid';
  }

  if (Object.keys(paymentPlansData).length > 0) {
    modifyPlans = paymentPlansData.plans
      .sort((planA, planB) => {
        // sort to make monthly subs always comes first
        if (planA.option === 'yearly' && planB.option === 'monthly') return 1;
        return -1;
      })
      .map((value) => {
        const { id, name, option, price } = value;
        return {
          value: id,
          label: `${name} - ${currencyFormatter.format(price)}/${option}`,
        };
      });

    if (isMonthlySubscription) {
      additionalParameterForSelect.defaultValue = `${modifyPlans[0].value}:${modifyPlans[0].label}`;
    }

    if (isYearlySubscription) {
      additionalParameterForSelect.defaultValue = `${modifyPlans[1].value}:${modifyPlans[1].label}`;
    }

    if (defaultValueSelect !== '') {
      additionalParameterForSelect.defaultValue = defaultValueSelect;
    }
  }

  const ResetVoucher = () => {
    setSubTotal(oldSubTotal);
    setIsVoucherSubmitted(false);
    setIsVoucherValid(false);
    setValue('voucher_code', '');
  };

  const disableSubmitVoucher =
    isVoucherSubmitted === true && isVoucherValid === true;

  return (
    <div className={'flex flex-row w-full justify-center my-14'}>
      <div>
        <div className={'text-2xl font-bold'}>Checkout</div>
        <div className={'mt-6'}>
          <div className={'text-grey50 text-base'}>
            You're using ESGI account
          </div>
          <div className={'text-grey100 text-base mt-2'}>{userData.email}</div>
        </div>
        <div className={'mt-6'}>
          <div className={'text-grey50 text-base'}>Your Order</div>
          <div className={'text-grey100 text-base mt-2'}>
            <div className={'text-grey100 text-base'}>Text Analytics</div>
          </div>
        </div>
        <div className={'mt-6'}>
          <div className={'text-grey50 text-base'}>Commitment</div>
          <div className={'text-grey100 text-base mt-2'}>
            <div>{selectedCommitment}</div>
          </div>
        </div>
      </div>
      <div className={classNameRightOuter}>
        <div className={'text-grey100 text-base mb-3'}>Your Order</div>
        <div className={className} style={rightStyle}>
          <div className={'text-xl font-medium text-grey100 mb-6'}>
            Text Analytic
          </div>
          <div className={'text-sm text-gray50 mb-6'}>
            Commitment
            <Select
              {...additionalParameterForSelect}
              loading={loadingPaymentPlans}
              onChange={(e) => {
                const value = e.target.value;
                setDefaultValueSelect(value);
                const splitColon = value.split(':');
                const splitDash = splitColon[1].split('-');
                const takePrice = splitDash[1].split('/');
                setSelectedCommitment(upperFirst(takePrice[1]));
                let reverseCurrency = parseFloat(
                  reverseFormatNumber(takePrice[0], 'id').replace('Rp', '')
                );
                // reset discount price if discount already applied before
                if (discountPrice !== 0) {
                  const discountAmount = getDiscount({
                    type: voucherType,
                    value: voucherValue,
                    factor: reverseCurrency,
                  });
                  setDiscountPrice(discountAmount);
                }
                setSubTotal(reverseCurrency);
                setOldSubTotal(reverseCurrency);
                setSubscriptionID(+splitColon[0]);
                setSelectChange(true);
              }}
              options={modifyPlans}
            />
          </div>
          <div className={'mb-6'}>
            <div className={'relative flex'}>
              <div className={'relative flex-grow flex items-center'}>
                <img
                  src={Voucher}
                  className={'absolute top-2.5 left-2.5'}
                  alt={'voucher-code'}
                />
                {disableSubmitVoucher && (
                  <button
                    onClick={ResetVoucher}
                    type="button"
                    className="absolute right-2.5"
                  >
                    <Icon
                      icon={IconNames.CROSS}
                      iconSize={16}
                      className="fill-current text-grey50"
                    />
                  </button>
                )}
                <input
                  placeholder={'Enter your voucher code here'}
                  disabled={disableSubmitVoucher}
                  type={'text'}
                  className={
                    'w-full pl-9 rounded-tl rounded-bl border border-blue50 focus:ring-0'
                  }
                  ref={registerVoucher}
                  name="voucher_code"
                />
              </div>
              <div>
                <button
                  ref={buttonApply}
                  disabled={disableSubmitVoucher}
                  className="bg-blue50 text-white font-bold py-2 px-4 h-full rounded-tr rounded-br"
                  onClick={handleSubmitVoucher(checkVoucher)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div>
            {voucherSubmittedAndValid && (
              <Alert text={voucherText} type={'success'} />
            )}
            {voucherSubmittedAndNotValid && (
              <Alert text={voucherText} type={'error'} />
            )}
          </div>
          <div style={{ border: '1px solid #E1E1E3' }} />
          <div className={'py-6'}>
            <div className={'flex flex-row justify-between'}>
              <div className={'text-base'}>Sub Total</div>
              <div className={'text-lg'}>
                {currencyFormatter.format(oldSubTotal)}
              </div>
            </div>
            {isVoucherValid && (
              <div className={'flex flex-row justify-between'}>
                <div className={'text-base'}>Discount</div>
                <div className={'text-lg text-red50'}>
                  {discountPriceFormatted}
                </div>
              </div>
            )}
          </div>
          <div style={{ border: '1px solid #E1E1E3' }} className={'mb-6'} />
          <div className={'flex flex-row justify-between'}>
            <div className={'text-lg font-medium'}>Total</div>
            <div className={'text-lg font-medium'}>
              {currencyFormatter.format(grandTotal)}
            </div>
          </div>
          <Button
            color="primary"
            size="medium"
            onClick={handlePayment}
            className="w-full mt-6"
          >
            Select Payment
          </Button>
        </div>
      </div>
    </div>
  );
}
