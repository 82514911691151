import { convertFieldToSymbol } from '../../utils/searchKeyword';

function AddKeywordBasicPopover(props) {
  const searchKeywords = convertFieldToSymbol(props.keywords);

  return (
    <div className="relative flex items-center max-w-132">
      <input
        type="text"
        value={searchKeywords}
        onChange={() => {}}
        className={`h-10 w-full text-sm text-grey50 bg-grey0 border border-grey10 rounded-sm focus:outline-none focus:ring-transparent`}
        readOnly={true}
      />
    </div>
  );
}

export default AddKeywordBasicPopover;
