import { useHistory } from 'react-router-dom';
import BNI from 'assets/svg/bank_bni.svg';
import Countdown from '../Countdown';
import PATH_URL from 'routers/path';
import Button from 'components/Button';

export default function BNIVa({
  vaNumber,
  price,
  dueDate,
  cancel,
  setShowDetailInvoice,
  differenceInSecond,
}) {
  const history = useHistory();
  const maxWidthPhone = {
    maxWidth: '480px',
  };
  return (
    <div>
      <div className={'text-center'}>
        <div className={'text-xl'}>Complete payment in</div>
        <Countdown differenceInSecond={differenceInSecond} />
        <div className={'text-xl text-gray50 font-normal pb-2'}>
          Payment due date
        </div>
        <div className={'text-xl text-grey100'}>{dueDate}</div>
      </div>
      <div
        className={'mt-10 px-4 pb-4 rounded border mb-4'}
        style={maxWidthPhone}
      >
        <div className={'flex justify-between py-6'}>
          <div className={'text-xl font-medium'}>BNI Virtual Account</div>
          <div>
            <img src={BNI} alt={'bni'} className={'w-14'} />
          </div>
        </div>
        <div className={'flex justify-between py-6'}>
          <div>
            <div className={'text-sm text-gray50'}>Virtual Account Number</div>
            <div>{vaNumber}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(vaNumber);
              }}
            >
              Copy
            </button>
          </div>
        </div>
        <div className={'flex justify-between'}>
          <div>
            <div className={'text-sm text-gray50'}>Total Payment</div>
            <div className={'text-xl text-red50 font-medium'}>{price}</div>
          </div>
          <div className={'text-blue50 cursor-pointer'}>
            <button
              onClick={() => {
                setShowDetailInvoice(true);
              }}
            >
              View Detail
            </button>
          </div>
        </div>
      </div>

      <div className={'flex justify-between'}>
        <Button
          view="outlined"
          size="medium"
          onClick={cancel}
          className="w-1/2"
        >
          Cancel Order
        </Button>
        <Button
          color="primary"
          size="medium"
          onClick={() => {
            history.push(PATH_URL.USER_PROFILE);
          }}
          className="w-1/2 ml-4"
        >
          View My Billing
        </Button>
      </div>

      <div style={maxWidthPhone}>
        <div className={'text-xl font-medium py-4'}>Payment Method</div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>ATM BNI</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              On the main menu, choose Other
            </li>
            <li className={'text-gray50 text-sm'}>Choose Transfer</li>
            <li className={'text-gray50 text-sm'}>Choose Savings Account</li>
            <li className={'text-gray50 text-sm'}>Choose To BNI Account</li>
            <li className={'text-gray50 text-sm'}>
              Enter the payment account number and press Yes
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter the full amount to be paid. If the amount entered is not the
              same as the invoiced amount, the transaction will be declined
            </li>
            <li className={'text-gray50 text-sm'}>
              Amount to be paid, account number, and merchant name will appear
              on the payment confirmation page. If the information is correct,
              press Yes
            </li>
            <li className={'text-gray50 text-sm'}>You are done</li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>Internet Banking</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              Go to https://ibank.bni.co.id and then click Login
            </li>
            <li className={'text-gray50 text-sm'}>
              Continue login with your User ID and Password
            </li>
            <li className={'text-gray50 text-sm'}>
              Click Transfer and then Add Favorite Account and choose Antar
              Rekening BNI
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter account name, account number, and email and then click
              Continue
            </li>
            <li className={'text-gray50 text-sm'}>
              Input the Authentification Code from your token and then click
              Continue
            </li>
            <li className={'text-gray50 text-sm'}>
              Back to main menu and select Transfer and then Transfer Antar
              Rekening BNI
            </li>
            <li className={'text-gray50 text-sm'}>
              Pick the account that you just created in the previous step as
              Rekening Tujuan and fill in the rest before clicking Continue
            </li>
            <li className={'text-gray50 text-sm'}>
              Check whether the details are correct, if they are, please input
              the Authentification Code and click Continue and you are done
            </li>
          </ul>
        </div>
        <div className={'mb-4'}>
          <div className={'text-base pb-2'}>Mobile Banking</div>
          <ul className="list-none md:list-decimal">
            <li className={'text-gray50 text-sm'}>
              Open the BNI Mobile Banking app and login
            </li>
            <li className={'text-gray50 text-sm'}>Choose menu Transfer</li>
            <li className={'text-gray50 text-sm'}>
              Choose menu Virtual Account Billing
            </li>
            <li className={'text-gray50 text-sm'}>
              Choose the bank account you want to use
            </li>
            <li className={'text-gray50 text-sm'}>
              Enter the 16 digits virtual account number
            </li>
            <li className={'text-gray50 text-sm'}>
              The billing information will appear on the payment validation page
            </li>
            <li className={'text-gray50 text-sm'}>
              If the information is correct, enter your password to proceed the
              payment
            </li>
            <li className={'text-gray50 text-sm'}>
              Your transaction will be processed
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
