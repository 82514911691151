import PropTypes from 'prop-types';

export default function Switch(props) {
  return (
    <div
      className={`transition relative flex items-center cursor-pointer`}
      onClick={props.onChange}
    >
      <span
        className={`transition transform ${
          props.checked ? 'translate-x-3 bg-blue60' : '-translate-x-0 bg-grey50'
        } absolute h-4 w-4 block rounded-full`}
      ></span>
      <span
        className={`h-3 w-7 block transition ${
          props.checked ? 'bg-blue10 ' : 'bg-grey30'
        } rounded-full`}
      ></span>
      <input
        type="checkbox"
        value={props.checked}
        onChange={props.onChange}
        defaultChecked={props.checked}
        className="hidden"
      />
    </div>
  );
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
