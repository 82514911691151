import firebase from 'firebase/app';
import 'firebase/auth';

const { REACT_APP_FIREBASE_CONFIG } = process.env;

const fbConfig = JSON.parse(REACT_APP_FIREBASE_CONFIG);

firebase.initializeApp(fbConfig);

export const rrfConfig = {
  // userProfile: "users"
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

export const auth = firebase.auth();

export default firebase;
