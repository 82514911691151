function findIndexById(array, object) {
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === object.id) {
      index = i;
      break;
    }
  }
  return index;
}

function insertItem(array, action) {
  return [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index),
  ];
}
function removeItem(array, object) {
  let index = findIndexById(array, object);
  return [...array.slice(0, index), ...array.slice(index + 1)];
}
function findById(array, id) {
  return array.find((item) => item.id === id) || null;
}
function removeById(array, id) {
  return array.filter((item) => item.id !== id);
}
function updateById(array, id, updateData) {
  return array.map((item) => {
    if (item.id === id) return { ...item, ...updateData };
    return item;
  });
}

function findByName(array, name) {
  return array.filter((item) => item.toLowerCase().indexOf(name) > -1);
}

function removeByName(array, item) {
  var index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

function groupBy(array, fieldName) {
  return array.reduce((acc, item) => {
    const fieldValue = item[fieldName];
    if (acc[fieldValue]) {
      acc[fieldValue] = acc[fieldValue].concat(item);
    } else {
      acc[fieldValue] = [item];
    }
    return acc;
  }, {});
}

export {
  insertItem,
  removeItem,
  findById,
  updateById,
  removeById,
  findByName,
  removeByName,
  groupBy,
};
