import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';

function YearDropdown(props) {
  return (
    <Select
      activeItem={props.selected}
      filterable={false}
      onItemSelect={props.onChange}
      popoverProps={{
        minimal: true,
        popoverClassName: 'shadow rounded',
      }}
      items={props.options}
      itemListRenderer={({ items, renderItem, itemsParentRef }) => {
        return (
          <ul
            ref={itemsParentRef}
            className="w-36 rounded max-h-40 overflow-y-auto p-2 bg-white"
          >
            {items.map(renderItem)}
          </ul>
        );
      }}
      itemRenderer={(year, { handleClick, modifiers }) => {
        return (
          <li
            key={year}
            onClick={handleClick}
            className={`${
              modifiers.active ? 'bg-lightgrey0' : 'bg-white'
            } text-sm text-center text-grey100 rounded transition-colors hover:bg-lightgrey0 cursor-pointer mb-px py-2`}
          >
            {year}
          </li>
        );
      }}
    >
      <div className="relative h-10 w-36 bg-grey0 border-0 rounded flex items-center">
        <h1 className="ml-3 text-xs">{props.selected}</h1>
        <span className="absolute right-0 mr-2 bg-grey0">
          <Icon
            icon={IconNames.CALENDAR}
            iconSize={16}
            className="fill-current text-blue40"
          />
        </span>
      </div>
    </Select>
  );
}

export default YearDropdown;
