import { useSelector } from 'react-redux';
import { createLoadingSelector } from '../../redux/api/loading';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { updateNotes } from 'redux/features/textAnalytics';

function DeleteColumnDialog(props) {
  const loading = useSelector(createLoadingSelector([updateNotes.type]));
  const handleDelete = props.onSubmit;

  return (
    <ConfirmationDialog
      title="Delete Column"
      isOpen={props.isOpen}
      onCancel={props.onClose}
      onConfirm={handleDelete}
      isLoading={loading}
      mode="danger"
      captions={{
        confirm: 'Delete',
      }}
    >
      <span>
        Are you sure you want to delete this column? <br />
        All notes in this column will be permanently deleted
      </span>
    </ConfirmationDialog>
  );
}

export default DeleteColumnDialog;
