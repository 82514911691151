import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { currencyFormatter } from '../../utils/currencyFormatter';
import CustomTable from '../../components/CustomTable';
import userPhoto from '../../assets/images/userProfileDefault.svg';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import clsx from 'clsx';
import moment from 'moment';
import {
  getDetailUser,
  getUserInvoices,
  updatedStudentStatus,
} from '../../redux/features/userManagement';
import { createLoadingSelector } from '../../redux/api/loading';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import {
  showToastSuccess,
  showToastError,
} from '../../layouts/DashboardLayout';
import DialogReasonRejected from './DialogReasonRejected';
import ModalDetailInvoice from './ModalDetailInvoice';
import Skeleton from 'react-loading-skeleton';

function UserDetailPage() {
  const _isMounted = useRef(true);
  const paramsPage = useParams();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userManagement.userDetail);
  const loadingStatusDetailUser = useSelector((state) =>
    createLoadingSelector([getDetailUser.type])(state)
  );
  const getDetailUserSuccess =
    !loadingStatusDetailUser && Object.keys(userDetail).length > 0;
  const loadingUpdateStatusStudent = useSelector((state) =>
    createLoadingSelector([updatedStudentStatus.type])(state)
  );
  const userInvoices = useSelector(
    (state) => state.userManagement.userInvoices
  );
  const loadingStatusUserInvoices = useSelector((state) =>
    createLoadingSelector([getUserInvoices.type])(state)
  );
  const [showDialogAccept, setShowDialogAccept] = useState(false);
  const rejectedReasonOption = [
    'Student ID photo is unclear / damaged',
    "Not registered in DIKTI's data",
    'The name on the student ID does not match the registered username',
  ];
  const [selectedRejectReason, setSelectedRejectReason] = useState(
    rejectedReasonOption[0]
  );
  const [noteRejectReason, setNoteRejectReason] = useState('');
  const [showDetailInvoice, setShowDetailInvoice] = useState({
    show: false,
    data: {},
  });
  const [rowPerPage, setrowPerPage] = useState(10);
  const [searchInvoices, setSearchInvoices] = useState('');
  const columns = [
    {
      Header: 'Invoice',
      accessor: 'invoiceNumber',
      customCell: ({ value, row }) => (
        <p
          className="text-blue50 cursor-pointer"
          onClick={() =>
            setShowDetailInvoice({
              show: true,
              data: {
                ...row.original,
                user: userDetail,
              },
            })
          }
        >
          {value}
        </p>
      ),
    },
    {
      Header: 'Product',
      accessor: 'product',
      customCell: ({ value, row }) => (
        <p className="capitalize">
          {row.original &&
            row.original.subscription &&
            row.original.subscription.option}
        </p>
      ),
    },
    {
      Header: 'Paid On',
      accessor: 'createdAt',
      sortType: 'datetime',
      customCell: ({ value }) => <>{moment(value).format('DD MMM YYYY')}</>,
    },

    {
      Header: 'Total',
      accessor: 'netPrice',
      customCell: ({ value }) => <>{currencyFormatter.format(value)}</>,
    },
    {
      Header: 'Payment Method',
      accessor: 'paymentMethod',
    },
  ];

  const acceptStudentStatus = () => {
    dispatch(
      updatedStudentStatus(
        {
          id: `${userDetail.id}`,
          rejectReason: '',
          statusApproval: 'Accepted',
        },
        () => {
          dispatch(getDetailUser(userDetail.id));
          showToastSuccess('Student account accepted');
        },
        () => {
          showToastError('Something Wrong...');
        }
      )
    );
  };
  const rejectStudentStatus = () => {
    dispatch(
      updatedStudentStatus(
        {
          id: `${userDetail.id}`,
          rejectReason: selectedRejectReason || noteRejectReason,
          statusApproval: 'Rejected',
        },
        () => {
          setShowDialogAccept(false);
          dispatch(getDetailUser(userDetail.id));
          showToastError(
            'Student account rejected!',
            'Student account is rejected and will be changed to general account'
          );
        },
        () => {
          setShowDialogAccept(false);
          showToastError('Something Wrong...');
        }
      )
    );
  };
  useEffect(() => {
    if (paramsPage.id) {
      dispatch(
        getUserInvoices(paramsPage.id, {
          page: 1,
          limit: rowPerPage,
          q: searchInvoices,
        })
      );
    }
    return () => {
      _isMounted.current = false;
    };
  }, [dispatch, paramsPage, searchInvoices, rowPerPage]);
  useEffect(() => {
    if (paramsPage.id) {
      dispatch(getDetailUser(paramsPage.id));
    }
  }, [dispatch, paramsPage]);
  return (
    <>
      <div className="w-full">
        {getDetailUserSuccess &&
          userDetail &&
          userDetail.role !== 'admin' &&
          userDetail.type === 'student' &&
          userDetail.profile &&
          (!userDetail.profile.statusApproval ||
            (userDetail.profile.statusApproval &&
              userDetail.profile.statusApproval.toLowerCase() ===
                'awaiting')) && (
            <div className="w-full flex items-center justify-between py-4 px-5 mb-10 mt-1 bg-teal70 shadow rounded ">
              <div className="flex items-center">
                {loadingUpdateStatusStudent && (
                  <div className="mr-3">
                    <Spinner className="h-6 w-6 fill-current text-grey30" />
                  </div>
                )}
                <p
                  className="text-white text-sm font-medium"
                  style={{
                    height: 'fit-content',
                  }}
                >
                  {`Would you like to accept ${
                    (userDetail &&
                      userDetail.profile &&
                      userDetail.profile.fullName) ||
                    'this'
                  } student account
                    applications?`}
                </p>
              </div>
              <div className="flex items-center">
                <Button
                  color="success"
                  size="small"
                  className="mx-2 focus:outline-none focus:ring-transparent"
                  disabled={loadingUpdateStatusStudent}
                  onClick={() => acceptStudentStatus()}
                >
                  Accept
                </Button>
                <Button
                  color="danger"
                  size="small"
                  className="mx-2 focus:outline-none focus:ring-transparent"
                  onClick={() => {
                    setShowDialogAccept(true);
                  }}
                  disabled={loadingUpdateStatusStudent}
                >
                  Reject
                </Button>
              </div>
            </div>
          )}
        {!loadingStatusDetailUser && !(Object.keys(userDetail).length > 0) && (
          <h6 className="text-grey70 text-center font-bold mt-2">
            User Not Found
          </h6>
        )}
        <h4 className="text-grey70 font-bold mb-2">Profile</h4>
        <div className="w-full mb-10 flex justify-start items-stretch">
          <div className="flex items-stretch bg-white shadow rounded mr-3">
            <div className="w-96 flex flex-col items-center justify-center py-10 px-2 ">
              <div
                className="w-24 h-24 mb-8 rounded-full overflow-hidden bg-no-repeat bg-cover bg-center shadow"
                style={{
                  backgroundImage: `url(${
                    (getDetailUserSuccess &&
                      userDetail &&
                      userDetail.photoUrl) ||
                    userPhoto
                  })`,
                }}
              ></div>

              {!getDetailUserSuccess && (
                <>
                  <div className="mb-3">
                    <Skeleton height={20} width={100} />
                  </div>
                  {[...new Array(2)].map((_, index) => (
                    <div key={`sk-${index}`}>
                      <Skeleton height={20} width={150} />
                    </div>
                  ))}
                  <div>
                    <Skeleton height={20} width={120} />
                  </div>
                </>
              )}
              {getDetailUserSuccess && (
                <>
                  <p
                    className={clsx(
                      ' py-1 px-3 mb-3 rounded-2xl text-xs font-semibold',
                      !userDetail.blockStatus
                        ? 'text-green70 bg-green5'
                        : 'text-red70 bg-red5'
                    )}
                  >
                    {!userDetail.blockStatus ? 'Active' : 'Blocked'}
                  </p>
                  <p className="text-sm text-grey100 font-semibold  mb-2">
                    {(userDetail &&
                      userDetail.profile &&
                      userDetail.profile.fullName) ||
                      '-'}
                  </p>
                  <p className="text-sm text-gray50 mb-1">
                    {(userDetail && userDetail.email) || '-'}
                  </p>
                  {userDetail && userDetail.phoneNumber && (
                    <p className="text-sm text-gray50">
                      {userDetail && userDetail.phoneNumber}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex bg-white shadow rounded items-stretch">
            <div className="min-w-min h-full flex flex-wrap py-10 px-2">
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">
                  Registered on
                </p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <p className="text-sm text-grey100 font-semibold">
                    {(userDetail &&
                      userDetail.createdAt &&
                      moment(userDetail.createdAt).format('DD MMM YYYY')) ||
                      '-'}
                  </p>
                )}
              </div>
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">
                  Last sign in
                </p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <p className="text-sm text-grey100 font-semibold">
                    {(userDetail &&
                      userDetail.lastSignin &&
                      moment(userDetail.lastSignin).format('DD MMM YYYY')) ||
                      '-'}
                  </p>
                )}
              </div>
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">
                  User role
                </p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <p className="text-sm capitalize text-grey100 font-semibold">
                    {(userDetail && userDetail.role) || '-'}
                  </p>
                )}
              </div>
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">
                  Payment due date
                </p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <p className="text-sm text-grey100 font-semibold">
                    {(userDetail &&
                      userDetail.paymentDue &&
                      moment(userDetail.paymentDue).format('DD MMM YYYY')) ||
                      '-'}
                  </p>
                )}
              </div>
              <div
                style={{
                  flex: '0 0 100%',
                }}
              ></div>
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">
                  Account type
                </p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <div className="flex items-center">
                    <p className="mr-1 text-sm capitalize text-grey100 font-semibold">
                      {userDetail && userDetail.type}
                    </p>
                    {userDetail &&
                      userDetail.type === 'student' &&
                      userDetail.profile && (
                        <>
                          {
                            <p
                              className={clsx(
                                'py-1 px-3 rounded-2xl text-white text-xs font-medium capitalize',
                                (!userDetail.profile.statusApproval ||
                                  (userDetail.profile.statusApproval &&
                                    userDetail.profile.statusApproval.toLowerCase() ===
                                      'awaiting')) &&
                                  'bg-orange50',
                                userDetail.profile.statusApproval &&
                                  userDetail.profile.statusApproval.toLowerCase() ===
                                    'accepted' &&
                                  'bg-green50',
                                userDetail.profile.statusApproval &&
                                  userDetail.profile.statusApproval.toLowerCase() ===
                                    'rejected' &&
                                  'bg-red50'
                              )}
                            >
                              {userDetail.profile.statusApproval || 'awaiting'}
                            </p>
                          }
                        </>
                      )}
                  </div>
                )}
              </div>
              {getDetailUserSuccess &&
                userDetail &&
                userDetail.type === 'student' && (
                  <div className="mx-4 mb-6 px-3">
                    <p className="text-sm font-medium text-gray50 mb-2">
                      Student Identification Number
                    </p>
                    <p className="text-sm text-grey100 font-semibold">
                      {(userDetail && userDetail.profile.nim) || '-'}
                    </p>
                  </div>
                )}
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">
                  Institution
                </p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <p className="text-sm text-grey100 font-semibold">
                    {(userDetail && userDetail.profile.institution) || '-'}
                  </p>
                )}
              </div>
              <div
                style={{
                  flex: '0 0 100%',
                }}
              ></div>
              <div className="mx-4 mb-6 px-3">
                <p className="text-sm font-medium text-gray50 mb-2">Document</p>
                {!getDetailUserSuccess && <Skeleton />}
                {getDetailUserSuccess && (
                  <>
                    {userDetail && userDetail.type !== 'general' ? (
                      <div className="flex items-center">
                        <a
                          href={
                            (userDetail &&
                              userDetail.profile &&
                              userDetail.profile.cardIdUrl) ||
                            '#'
                          }
                          target={
                            userDetail &&
                            userDetail.profile &&
                            userDetail.profile.cardIdUrl
                              ? '_blank'
                              : '_self'
                          }
                          rel="noreferrer"
                          className="mr-2 text-sm text-blue50 font-semibold"
                        >
                          {`Student ID Card ${
                            userDetail &&
                            userDetail.profile &&
                            userDetail.profile.fullName &&
                            userDetail.profile.fullName.split(/\s+/)[0] + '.jpg'
                          }`}
                        </a>
                        <Icon
                          iconSize={18}
                          icon={IconNames.DOWNLOAD}
                          className="fill-current text-blue50"
                        />
                      </div>
                    ) : (
                      <p className="text-sm text-grey100 font-semibold">-</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {getDetailUserSuccess && (
          <>
            <h4 className="text-grey70 font-bold mb-2">Purchase History</h4>
            <CustomTable
              isLoading={loadingStatusUserInvoices}
              dataTable={
                userInvoices.invoices && userInvoices.invoices.length > 0
                  ? userInvoices.invoices.map((v) => ({
                      ...v,
                      createdAt: new Date(v.createdAt),
                    }))
                  : []
              }
              columnsTable={columns}
              page={userInvoices.currentPageNumber || 1}
              totalPage={userInvoices.totalPages || 1}
              totalRow={rowPerPage}
              onChangeTotalRow={(row) => {
                setrowPerPage(row);
              }}
              onInputSearch={(text) => {
                setSearchInvoices(text);
              }}
              onChangePage={(page) => {
                dispatch(
                  getUserInvoices(paramsPage.id, {
                    page: page,
                    limit: rowPerPage,
                    q: searchInvoices,
                  })
                );
              }}
            />
          </>
        )}
      </div>
      <DialogReasonRejected
        showDialog={showDialogAccept}
        setShowDialog={setShowDialogAccept}
        rejectedReasonOption={rejectedReasonOption}
        setSelectedRejectReason={setSelectedRejectReason}
        selectedRejectReason={selectedRejectReason}
        setNoteRejectReason={setNoteRejectReason}
        noteRejectReason={noteRejectReason}
        rejectStudentStatus={rejectStudentStatus}
      />
      <ModalDetailInvoice
        showDialog={showDetailInvoice.show}
        detailInvoice={showDetailInvoice.data}
        setShowDialog={setShowDetailInvoice}
      />
    </>
  );
}

export default UserDetailPage;
